import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Typography, Link, Breadcrumbs, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AddBoxOutlined } from '@mui/icons-material';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import CustomizeMaterialReactTable from '../Layout/CustomizeMaterialReactTable';

const MODULE = "roles";
const MODULE_SINGLE = "role";

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/${MODULE}`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const columns = useMemo(() => ([
        {
            accessorKey: 'name',
            header: `${t(`${MODULE}.roleName`)}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Link underline='none' to={`/${MODULE}/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.name}</Link>
                    :
                    <>{cell.row.original.name}</>
            )
        },
        {
            accessorFn: (row) => (row.display_name[i18n.language] ? row.display_name[i18n.language] : row.display_name.en),
            id: 'status_display.' + i18n.language,
            header: `${t(`${MODULE}.displayName`)}`,
        },
        {
            accessorKey: 'role_level',
            header: `${t(`${MODULE}.roleLevel`)}`,
        },
        {
            accessorFn: (row) => (`${t(`roles.types.${row.type}`)}`),
            id: 'type',
            header: `${t(`${MODULE}.roleType`)}`,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <CustomizeMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'RoleList'}
            />
        </>
    )
}

export default Listing