import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Typography, Link, Breadcrumbs, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AddBoxOutlined } from '@mui/icons-material';
import { IoPencil, IoTrashOutline } from "react-icons/io5";

import { deleteUrl, getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "subscribes";
const MODULE_SINGLE = "subscribe";

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [deleteId, setDeleteId] = useState("");

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const deleteSubscribers = () => {
        setLoading(true);
        deleteUrl(`/${MODULE}/${deleteId}`)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.deleteSuccess'), 'success', '');
                    callApi();
                } else {
                    addAlert('', t('error.deleteError'), 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        setDeleteId("");
    };

    const columns = useMemo(() => ([
        {
            accessorKey: 'name',
            id: 'name',
            header: `${t('user.name')}`,
        },
        {
            accessorKey: 'email',
            id: 'email',
            header: `${t('user.email')}`,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        {
            id: 'action',
            header: `${t(`table.actions`)}`,
            enableColumnOrdering: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <IconButton><IoTrashOutline size={20} onClick={() => setDeleteId(cell.row.original.id)} /></IconButton>
                    </Box>
                    :
                    null
            ),
            maxSize: '100'
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'SubscribeList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
            <Dialog
                open={deleteId !== "" ? true : false}
                onClose={() => setDeleteId("")}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t(`${MODULE}.confirmationOnDelete`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`${MODULE}.askDeleteSubscriber`)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setDeleteId("")} color="secondary">{t('button.cancel')}</Button>
                    <Button variant='contained' onClick={deleteSubscribers} color="primary" autoFocus>{t('button.confirm')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Listing