import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Typography, Link, Breadcrumbs, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AddBoxOutlined } from '@mui/icons-material';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import CustomizeMaterialReactTable from '../Layout/CustomizeMaterialReactTable';

const MODULE = "adjustments";
const MODULE_SINGLE = "adjustment";

const Listing = () => {
    const [data, setData] = useState([]);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/price-${MODULE}`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, status => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: textDisplay });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'title',
            header: `${t(`${MODULE}.title`)}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `price-${MODULE_SINGLE}-edit`) ?
                    <Link underline='none' to={`/price-${MODULE_SINGLE}/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.title}</Link>
                    :
                    <>{cell.row.original.title}</>
            ),
            minSize: '350',
        },
        {
            accessorFn: (row) => ((row.type==='percent' ? '' : 'MYR ') + row.value + (row.type==='percent' ? ' %' : '')),
            id: 'value',
            header: `${t(`${MODULE}.discount_value`)}`,
        },
        {
            accessorKey: 'min_spend_value',
            header: `${t(`${MODULE}.min_spend_value`)}`,
        },
        {
            accessorKey: 'capped_value',
            header: `${t(`${MODULE}.capped_value`)}`,
        },
        {
            accessorKey: 'start_date',
            header: `${t(`${MODULE}.startDate`)}`,
        },
        {
            accessorKey: 'end_date',
            header: `${t(`${MODULE}.endDate`)}`,
        },
        {
            accessorFn: (row) => (row.status_display ? row.status_display[i18n.language] : row.status_display.en),
            id: 'status_display.' + i18n.language,
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {_.includes(permissions, `price-${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/price-${MODULE_SINGLE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <CustomizeMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'PriceAdjustmentList'}
            />
        </>
    )
}

export default Listing