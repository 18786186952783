import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import { Box, Button, Grid, Link, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const MODULE = "agencies";

const Edit = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [address, setAddress] = useState({
        address_name: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    });

    let { id } = useParams();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        getUrl(`/${MODULE}/${id}`).then(response => {
            const { agency } = response.data;
            if (response.status) {
                setAddress({
                    address_name: agency.address_name,
                    address1: _.size(agency.address) ? agency.address.address1 : '',
                    address2: _.size(agency.address) ? agency.address.address2 : '',
                    city: _.size(agency.address) ? agency.address.city : '',
                    state: _.size(agency.address) ? agency.address.state : '',
                    zip: _.size(agency.address) ? agency.address.zip : '',
                    country: _.size(agency.address) ? agency.address.country : '',
                });
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setAddress({ ...address, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        putUrl(`/${MODULE}/${id}/address`, address).then(response => {
            setLoading(false);
            const { status, errors } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.address1`)}
                            variant="outlined"
                            value={address.address1}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.address1 ? inputErrors.address1 : ''}
                            error={inputErrors && inputErrors.address1 ? true : false}
                            name="address1"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.address2`)}
                            variant="outlined"
                            value={address.address2}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                            error={inputErrors && inputErrors.address2 ? true : false}
                            name="address2"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.city`)}
                            variant="outlined"
                            value={address.city}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                            error={inputErrors && inputErrors.city ? true : false}
                            name="city"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.state`)}
                            variant="outlined"
                            value={address.state}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                            error={inputErrors && inputErrors.state ? true : false}
                            name="state"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.zip`)}
                            variant="outlined"
                            value={address.zip}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                            error={inputErrors && inputErrors.zip ? true : false}
                            name="zip"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.country`)}
                            variant="outlined"
                            value={address.country}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                            error={inputErrors && inputErrors.country ? true : false}
                            name="country"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save') + ' ' + t(`${MODULE}.address`)}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default Edit