import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, InputAdornment, InputLabel, Link, FormHelperText, MenuItem, Select, TextField, Typography, IconButton} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { AddBoxOutlined, Delete } from '@mui/icons-material';

import { postUrl, getUrl, putUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "roomtypes";
const MODULE_SINGLE = "roomtype";

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [agencyList, setAgencyList] = useState({});
    const [statusList, setStatusList] = useState({});
    const [state, setState] = useState({
        agency_id: '',
        type: '',
        name: '',
        quantity: 0, 
        price: 0.00,
        min_pax: 0,
        max_pax: 0,
        description: {
            details: '',
            cancellation: '',
            reservation: '',
            points: [],
        },
        status: 0,
    });
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [inputErrors, setInputErrors] = useState({});
    const formType = location.pathname === `/${MODULE}-add` ? "add" : "edit";

    const handlePointsChange = (target, key) => {
        const { value } = target;
        setState({ ...state, description: { ...state.description, points: { ...state.description.points, [key]: value } } });
    }
    const handleNewPoint = () => {
        let newKey = _.size(state.description.points);
        setState({ ...state, description: { ...state.description, points: { ...state.description.points, [newKey]: '' } } });
    }
    const handleDeletePoint = (key) => {
        let newPoints = _.filter(state.description.points, function (o, index) { return index !== key });
        setState({ ...state, description: { ...state.description, points: newPoints } });
    }

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`/agencies`).then(response => {
            setLoading(false);
            setAgencyList(response.data.listing);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/${MODULE}`).then(response => {
            setLoading(false);
            setStatusList(response.data.status_list);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        if (formType === 'edit' && id) {
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        let { roomtype } = response.data;

                        setState({
                            agency_id: roomtype.agency_id,
                            type: roomtype.type,
                            name: roomtype.name,
                            quantity: roomtype.quantity, 
                            price: roomtype.price,
                            min_pax: roomtype.min_pax,
                            max_pax: roomtype.max_pax,
                            description: {
                                details: roomtype.description.details,
                                cancellation: roomtype.description.cancellation,
                                reservation: roomtype.description.reservation,
                                points: roomtype.description.points,
                            },
                            status: roomtype.status,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id]);

    const submitData = () => {
        let postData = {
            agency_id: state.agency_id,
            type: state.type,
            name: state.name,
            quantity: state.quantity, 
            price: state.price,
            min_pax: state.min_pax,
            max_pax: state.max_pax,
            description: {
                details: state.description.details,
                cancellation: state.description.cancellation,
                reservation: state.description.reservation,
                points: state.description.points,
            },
            status: state.status,
            _method: formType === "edit" ? "put" : "post",
        };

        const formData = new FormData();
        buildFormData(formData, postData);

        setLoading(true);
        setInputErrors();

        if (formType === "edit" && id) {
            putUrl(`/${MODULE}/${id}`, postData).then(response => {
                setLoading(false);
                let { status, data, errors, message } = response;
                addAlert('', message, status ? 'success' : 'error', '');

                if (status) {
                    addAlert('', response.message, 'success', '');
                    if (formType !== "edit") {
                        navigate(`/${MODULE}/${data.id}`);
                    }
                } else {
                    setInputErrors(errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl(`/${MODULE}`, formData).then(response => {
                setLoading(false);
                let { status, data, errors, message } = response;
                addAlert('', message, status ? 'success' : 'error', '');

                if (status) {
                    addAlert('', response.message, 'success', '');
                    if (formType !== "edit") {
                        navigate(`/${MODULE}/${data.id}`);
                    }
                } else {
                    setInputErrors(errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to="/roomtypes">
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>{t(`${MODULE}.agency_id`)}*</InputLabel>
                                <Select
                                    value={state.agency_id}
                                    label={`${t(`${MODULE}.agency_id`)}*`}
                                    name="agency_id"
                                    error={inputErrors && inputErrors.agency_id ? true : false}
                                    onChange={({ target }) => setState({ ...state, agency_id: target.value })}
                                >
                                    {_.map(agencyList, resultItem => {
                                        return <MenuItem key={resultItem.id} value={resultItem.id} disabled={resultItem.status ? false : true}>{resultItem.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.agency_id ? inputErrors.agency_id : ''}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.type')+"*"}
                                variant="outlined"
                                value={state.type}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                                error={inputErrors && inputErrors.type ? true : false}
                                onChange={({ target }) => setState({ ...state, type: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.name')+"*"}
                                variant="outlined"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.quantity')+"*"}
                                variant="outlined"
                                value={state.quantity}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.quantity ? inputErrors.quantity : ''}
                                error={inputErrors && inputErrors.quantity ? true : false}
                                onChange={({ target }) => setState({ ...state, quantity: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.price')+"*"}
                                variant="outlined"
                                value={state.price}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">MYR</InputAdornment>,
                                }}
                                helperText={inputErrors && inputErrors.price ? inputErrors.price : ''}
                                error={inputErrors && inputErrors.price ? true : false}
                                onChange={({ target }) => setState({ ...state, price: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.min_pax')+"*"}
                                variant="outlined"
                                value={state.min_pax}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.min_pax ? inputErrors.min_pax : ''}
                                error={inputErrors && inputErrors.min_pax ? true : false}
                                onChange={({ target }) => setState({ ...state, min_pax: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.max_pax')+"*"}
                                variant="outlined"
                                value={state.max_pax}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.max_pax ? inputErrors.max_pax : ''}
                                error={inputErrors && inputErrors.max_pax ? true : false}
                                onChange={({ target }) => setState({ ...state, max_pax: target.value })}
                            />
                        </Box>
                    </Grid>
                    {
                        formType === 'edit'
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.status`)}*</InputLabel>
                                    <Select
                                        value={statusList ? state.status : ''}
                                        label={`${t(`${MODULE}.status`)}*`}
                                        name="status"
                                        error={inputErrors && inputErrors.status ? true : false}
                                        onChange={({ target }) => setState({ ...state, status: target.value })}
                                    >
                                        {_.map(statusList, resultItem => {
                                            return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language]}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                    }
                </Grid>

                {/* Description */}
                <Grid container>
                    <Grid item xs={12} sm={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <Typography variant="h6">
                                {t(`${MODULE}.description.description`)}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.description.details')}
                                variant="outlined"
                                multiline
                                rows={5}
                                value={state.description.details}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.description && inputErrors.description.details ? inputErrors.description.details : ''}
                                error={inputErrors && inputErrors.description && inputErrors.description.details ? true : false}
                                onChange={({ target }) => setState({ ...state, description: {...state.description, details: target.value} })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.description.cancellation')}
                                variant="outlined"
                                multiline
                                rows={2}
                                value={state.description.cancellation}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.description && inputErrors.description.cancellation ? inputErrors.description.cancellation : ''}
                                error={inputErrors && inputErrors.description && inputErrors.description.cancellation ? true : false}
                                onChange={({ target }) => setState({ ...state, description: {...state.description, cancellation: target.value} })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roomtypes.description.reservation')}
                                variant="outlined"
                                multiline
                                rows={2}
                                value={state.description.reservation}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.description && inputErrors.description.reservation ? inputErrors.description.reservation : ''}
                                error={inputErrors && inputErrors.description && inputErrors.description.reservation ? true : false}
                                onChange={({ target }) => setState({ ...state, description: {...state.description, reservation: target.value} })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Typography variant="body2">
                                    {t(`${MODULE}.description.points`)}
                                </Typography>
                                <Box display="flex" alignItems="center">
                                    <IconButton style={{ color: theme.palette.button.main }} onClick={handleNewPoint}>
                                        <AddBoxOutlined />
                                    </IconButton>
                                    <Typography>{t('roomtypes.addPoint')}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    {
                        _.map(state.description.points, (point, key) => {
                            return (
                                <Grid item xs={12} sm={12} key={key}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                            <TextField
                                                fullWidth
                                                multiline
                                                rows={2}
                                                size="small"
                                                variant="outlined"
                                                value={point}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors['description.points.' + key] ? inputErrors['description.points.' + key] : ''}
                                                error={inputErrors && inputErrors['description.points.' + key] ? true : false}
                                                onChange={(event) => handlePointsChange(event.target, key)}
                                            />
                                            <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeletePoint(key)} key={key}>
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })
                    }
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                        <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={styles.buttonStyle}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                </Box>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
}))