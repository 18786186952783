import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Button, CardMedia, Checkbox, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Link, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { FiAlertCircle, FiCheckCircle, FiCircle } from "react-icons/fi";
import { IoTrashOutline } from "react-icons/io5";
import { MdExpandLess, MdExpandMore } from "react-icons/md";
import { updateCartTotal } from '../../actions';
import { LOCAL_URL } from '../../configs/Config';
import { deleteUrl, getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

const Checkout = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    const classes = useStyles();
    const dispatch = useDispatch();

    const [inputErrors, setInputErrors] = useState({});
    const [policyChecked, setPolicyChecked] = useState(false);
    const [currencyRate, setCurrencyRate] = useState({});
    const [currentCurrency, setCurrentCurrency] = useState({ "code": "MYR", "name": { 'en': "Ringgit Malaysia", 'cn': "马币" }, "symbol": "RM", "rate": "1.00", "status": "1" });
    const [carts, setCarts] = useState({});
    const [systemPublicHoliday, setSystemPublicHoliday] = useState({});
    const [discountData, setDiscountData] = useState({});
    const [autoApplyDiscountCode, setAutoApplyDiscountCode] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [sumTotal, setSumTotal] = useState(0);
    const [selectedTotal, setSelectedTotal] = useState(0);
    const [pADiscountAmount, setPADiscountAmount] = useState(0);
    const [discountCode, setDiscountCode] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [discountCodeDialogOpen, setDiscountCodeDialogOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchField, setSearchField] = useState({
        currency: searchParams.get('currency') ? searchParams.get('currency') : 'MYR'
    });

    useEffect(() => {
        calculateTotal(carts);
        // eslint-disable-next-line
    }, [carts, discountData]);

    useEffect(() => {
        getUrl("/carts/agent").then(response => {
            if (response.status) {
                setCarts(response.data.listing);
                setSystemPublicHoliday(response.data.system_public_holidays);
                setAutoApplyDiscountCode(response.data.price_adjustments);
                calculateTotal(response.data.listing);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        getUrl(`/currency-rates`).then(response => {
            if (response.status) {
                const currencyList = [];
                for (let i = 0; i < _.size(response.data); i++) {
                    if (response.data[i].status !== "0") {
                        currencyList.push(response.data[i]);
                        if (searchField.currency === response.data[i].code) {
                            setCurrentCurrency(response.data[i])
                        }
                    }
                }
                setCurrencyRate(currencyList);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        // eslint-disable-next-line
    }, []);

    const calculateTotal = (carts) => {
        let _subTotal = 0;
        let _selectedTotal = 0;
        let priceAdjustmentMin = 0;
        let priceAdjustmentDiscount = 0;
        _.map(carts, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (groupItem, groupId) => {
                if (groupItem.selected) {
                    _.map(groupItem.items, mainOption => {
                        // main option
                        if (mainOption.status) {
                            let price = parseFloat(mainOption.quantity * getSellPrice(mainOption.booking_date, mainOption.package_option.price, agencyCartList.agency_public_holidays));
                            _subTotal += price;
                            _selectedTotal += parseInt(mainOption.quantity);
                            if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (agencyId).toString())) {
                                priceAdjustmentMin += price;
                            }
                        }
                    })
                    _.map(groupItem.add_on, add_on => {
                        _.map(add_on.items, addOnOption => {
                            let priceArray = _.size(addOnOption.add_on_price) > 0 ? addOnOption.add_on_price : addOnOption.package_option.price;
                            // add on option
                            if (addOnOption.status) {
                                let price = parseFloat(addOnOption.quantity * getSellPrice(addOnOption.booking_date, priceArray, agencyCartList.agency_public_holidays));
                                _subTotal += price;
                                _selectedTotal += parseInt(addOnOption.quantity);
                                if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (agencyId).toString())) {
                                    priceAdjustmentMin += price;
                                }
                            }
                        })
                    })
                }
            })
        })
        if (priceAdjustmentMin >= discountData.min_spend_value) {
            if (discountData.type === 'percent') {
                priceAdjustmentDiscount = parseFloat(priceAdjustmentMin * parseFloat(discountData.value / 100));
            } else {
                priceAdjustmentDiscount = parseFloat(discountData.value);
            }
            if (discountData.capped_value > 0) {
                priceAdjustmentDiscount = Math.min(parseFloat(discountData.capped_value), parseFloat(priceAdjustmentDiscount));
            }
            if (discountData.action === 'deduct') {
                priceAdjustmentDiscount = 0 - priceAdjustmentDiscount;
            }
        }
        setSubTotal(_subTotal);
        setSumTotal(_subTotal + priceAdjustmentDiscount);
        setPADiscountAmount(priceAdjustmentDiscount);
        setSelectedTotal(_selectedTotal);
    }

    const handleCheckboxAcceptChange = (target) => {
        if (target.checked) {
            setPolicyChecked(true);
        } else {
            setPolicyChecked(false);
        }
    }

    const deleteCart = (group_id) => {
        let newCart = carts;
        deleteUrl(`/carts/agent/${group_id}`).then(response => {
            if (response.status) {
                _.map(carts, (agencyCartList, agencyId) => {
                    let agencyList = false;
                    delete newCart[agencyId]['data'][group_id];
                    if (_.size(agencyCartList.data) !== 0) {
                        agencyList = true;
                    }
                    if (!agencyList) {
                        delete newCart[agencyId];
                    }
                })
                setCarts({ ...newCart });
                calculateTotal(newCart);
                dispatch(updateCartTotal(response.cart_count));
                addAlert('', response.message, 'success', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    const placeOrder = () => {
        if (policyChecked) {
            setLoading(true);
            let apiData = {
                price_adjustment_id: discountData?.id ? discountData.id : '',
                discount_code_id: discountData?.discount_codes?.[0]?.id ? discountData.discount_codes[0].id : '',
            }
            postUrl(`/agent-order`, apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    setInputErrors({});
                    getUrl("/carts/agent").then(response => {
                        if (response.status) {
                            dispatch(updateCartTotal(response.data.cart_count));
                        }
                    })
                    let purchase_no = response.data[0]['purchase_no'];
                    navigate({ pathname: `/payment/${encodeURIComponent(purchase_no)}`, search: `?currency=${currentCurrency.code}` }, { replace: true });
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert("", t('placeOrder.acceptPolicy'), 'error', '');
        }
    }

    const displayBookingPeriod = (booking_date, duration_period) => {
        let displayText = booking_date;
        if (duration_period > 1) {
            let endDate = new Date(booking_date);
            endDate.setDate(endDate.getDate() + (duration_period - 1));
            displayText = displayText + ' - ' + Moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).format('YYYY-MM-DD');
        }
        return displayText;
    }

    const getSellPrice = (booking_date, price, agency_public_holidays) => {
        let selectedPrice = price['ranks'][0]['weekday'];
        if (booking_date) {
            let publicHoliday = agency_public_holidays ? agency_public_holidays : systemPublicHoliday;
            let day = parseInt((new Date(booking_date)).getDay());
            if (_.find(publicHoliday, function (date) { return date === booking_date })) {
                selectedPrice = price['ranks'][0]['holiday'];
            } else if (day === 6 || day === 0) {
                selectedPrice = price['ranks'][0]['weekend'];
            } else {
                selectedPrice = price['ranks'][0]['weekday'];
            }
        }
        return selectedPrice;
    }

    const [collapseOpen, setCollapseOpen] = useState(false);
    const [collapseGroupId, setCollapseGroupId] = useState('');
    const handleCustomerInfoClick = (group_id) => {
        if (collapseGroupId === '') {
            setCollapseGroupId(group_id);
            setCollapseOpen(true);
        } else if (collapseGroupId !== group_id) {
            setCollapseGroupId(group_id);
        } else if (collapseGroupId === group_id) {
            setCollapseGroupId('');
            setCollapseOpen(false);
        }
    }
    const cartItemBox = (groupId, groupItem, agency_public_holidays) => {
        let addOn = false;
        _.map(groupItem.add_on, add_on => {
            if (_.size(add_on.items) > 0) {
                addOn = true;
            }
        })
        if (groupItem.selected && _.size(groupItem.items) > 0) {
            return (
                <Grid container spacing={2} className={classes.packageBox}>
                    <Grid item xs={2} md={1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <IconButton onClick={() => deleteCart(groupId)}><IoTrashOutline size={20} /></IconButton>
                    </Grid>
                    <Grid item xs={10} md={2} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                        <CardMedia
                            component="img"
                            image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                            sx={{ flex: 1, width: "100%", padding: 0 }}
                            alt={'package\'s image'}
                            className={classes.imgStyle}
                        />
                    </Grid>
                    <Grid item xs={12} md={9} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                        {
                            groupItem.booking_date
                                ?
                                <Typography className={classes.bookingDateStyle}>{displayBookingPeriod(groupItem.booking_date, 1)}</Typography>
                                :
                                null
                        }
                        {
                            _.map(groupItem.items, mainOption => {
                                return (
                                    <Box key={mainOption.id}>
                                        {optionBox(mainOption, agency_public_holidays)}
                                    </Box>
                                )
                            })
                        }
                        <Box>
                            {
                                addOn &&
                                <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                    {t('placeOrder.addOnPackage') + ": "}
                                </Typography>
                            }
                            {
                                _.map(groupItem.add_on, (add_on, key) => {
                                    if (_.size(add_on.items) > 0) {
                                        return (
                                            <Box key={key}>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                                {_.map(add_on.items, addOnOption => {
                                                    return (
                                                        <Box key={addOnOption.id}>
                                                            {optionBox(addOnOption, agency_public_holidays)}
                                                        </Box>
                                                    )
                                                })}
                                            </Box>
                                        )
                                    }
                                })
                            }
                        </Box>
                        <Box>
                            <Box style={{ display: "flex", alignItems: "center", marginTop: 20 }} onClick={() => handleCustomerInfoClick(groupId)}>
                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{t('placeOrder.guestDetail')}</Typography>
                                <IconButton>
                                    {
                                        collapseOpen && (groupId === collapseGroupId) ?
                                            <MdExpandLess size={20} />
                                            : <MdExpandMore size={20} />
                                    }
                                </IconButton>
                            </Box>
                            {
                                _.map(inputErrors, (msg, row) => {
                                    if (_.includes(row, 'booking_info')) {
                                        return (
                                            <Typography variant="caption" color="error">{msg}</Typography>
                                        )
                                    }
                                })
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={collapseOpen && (groupId === collapseGroupId) ? true : false} timeout="auto" unmountOnExit>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('orders.packageName')}</TableCell>
                                            <TableCell>{t('orders.option')}</TableCell>
                                            <TableCell>{t('user.name')}</TableCell>
                                            <TableCell>{t('user.ic')}</TableCell>
                                            <TableCell>{t('user.dob')}</TableCell>
                                            <TableCell>{t('user.mobile')}</TableCell>
                                            <TableCell>{t('user.email')}</TableCell>
                                            <TableCell>{t('user.gender')}</TableCell>
                                            <TableCell>{t('placeOrder.user_role')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            _.map(groupItem.items, (mainOption, key) => (
                                                _.map(mainOption.customer_info, cinfo => {
                                                    return (
                                                        <TableRow
                                                            key={cinfo.id}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell>{groupItem.package.name}</TableCell>
                                                            <TableCell>{mainOption.package_option.name}</TableCell>
                                                            <TableCell>{cinfo.user_name}</TableCell>
                                                            <TableCell>{cinfo.user_ic}</TableCell>
                                                            <TableCell>{cinfo.user_dob}</TableCell>
                                                            <TableCell>{cinfo.user_mobile}</TableCell>
                                                            <TableCell>{cinfo.user_email}</TableCell>
                                                            <TableCell>{t('user.' + cinfo.user_gender)}</TableCell>
                                                            <TableCell>{t('placeOrder.tour_' + cinfo.user_role)}</TableCell>
                                                        </TableRow>
                                                    )
                                                })
                                            ))
                                        }
                                        {
                                            _.map(groupItem.add_on, (add_on, key) => (
                                                _.map(add_on.items, addOnOption => (
                                                    _.map(addOnOption.customer_info, cinfo => {
                                                        return (
                                                            <TableRow
                                                                key={cinfo.id}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell>{add_on.package.name}</TableCell>
                                                                <TableCell>{addOnOption.package_option.name}</TableCell>
                                                                <TableCell>{cinfo.user_name}</TableCell>
                                                                <TableCell>{cinfo.user_ic}</TableCell>
                                                                <TableCell>{cinfo.user_dob}</TableCell>
                                                                <TableCell>{cinfo.user_mobile}</TableCell>
                                                                <TableCell>{cinfo.user_email}</TableCell>
                                                                <TableCell>{t('user.' + cinfo.user_gender)}</TableCell>
                                                                <TableCell>{t('placeOrder.tour_' + cinfo.user_role)}</TableCell>
                                                            </TableRow>
                                                        )
                                                    })
                                                ))
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Collapse>
                    </Grid>
                </Grid>
            )
        }
    }

    const optionBox = (option, agency_public_holidays) => {
        let priceArray = _.size(option.add_on_price) > 0 ? option.add_on_price : option.package_option.price;
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <Typography className={option.status ? '' : classes.inactiveStyle}>{option.package_option.name}</Typography>
                    {
                        _.map(inputErrors, (msg, row) => {
                            if (_.includes(row, 'cart.' + option.id)) {
                                return (
                                    <Typography variant="caption" color="error">{msg}</Typography>
                                )
                            }
                        })
                    }
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" align="center">x{option.quantity}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ margin: "auto !important", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {
                            option.package_option.price['retail'] > 0 && (option.package_option.price['retail'] - getSellPrice(option.booking_date, priceArray, agency_public_holidays)) > 0
                                ?
                                <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                    {
                                        " " + currentCurrency.symbol + " "
                                        + parseFloat((option.package_option.price['retail'] * currentCurrency.rate) * option.quantity).toFixed(2)
                                    }
                                </Typography>
                                :
                                null
                        }
                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                            {
                                " " + currentCurrency.symbol + " "
                                + parseFloat((getSellPrice(option.booking_date, priceArray, agency_public_holidays) * currentCurrency.rate) * option.quantity).toFixed(2)
                            }
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const totalBox = () => {
        return (
            <Box className={classes.totalBox1}>
                <Grid container spacing={2} className={classes.totalBox2}>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{t('placeOrder.discountCode') + ':'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Button size="small" color="gray" onClick={() => setDiscountCodeDialogOpen(true)}>
                            {
                                _.size(discountData) > 0
                                    ?
                                    (discountData.title).length > 20 ?
                                        ((discountData.title).substring(0, 20) + '...  > ')
                                        :
                                        (discountData.title + '  > ')
                                    :
                                    (t('placeOrder.selectOrEnterCode') + '  > ')
                            }
                        </Button>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{t('placeOrder.itemPriceSum', { num: selectedTotal }) + ':'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>
                            {
                                " " + currentCurrency.symbol + " "
                                + (parseFloat(subTotal) * currentCurrency.rate).toFixed(2)
                            }
                        </Typography>
                    </Grid>
                    {
                        _.size(discountData) ?
                            <>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>{t('placeOrder.discountCodeAmount') + ':'}</Typography>
                                </Grid>
                                <Grid item xs={6} className={classes.gridItemBoxRight}>
                                    <Typography>
                                        {
                                            (pADiscountAmount > 0 ? '' : '-')
                                            +
                                            " " + currentCurrency.symbol + " "
                                            + (parseFloat(pADiscountAmount > 0 ? pADiscountAmount : -pADiscountAmount) * currentCurrency.rate).toFixed(2)
                                        }
                                    </Typography>
                                </Grid>
                            </>
                            :
                            null
                    }
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography>{t('placeOrder.totalSum') + ':'}</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.gridItemBoxRight}>
                        <Typography className={classes.finalPriceStyle}>
                            {
                                " " + currentCurrency.symbol + " "
                                + (parseFloat(sumTotal) * currentCurrency.rate).toFixed(2)
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const bottomActionRow = () => {
        return (
            <Box>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={policyChecked}
                                onChange={(event) => handleCheckboxAcceptChange(event.target)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={
                            <div>
                                <span>{t('placeOrder.agreement1')}</span>
                                <Link component={RouterLink} to={`${LOCAL_URL}/policy?type=termcondition`} target="_blank">{t('placeOrder.termscondition')}</Link>
                                <span>{t('placeOrder.agreement2')}</span>
                                <Link component={RouterLink} to={`${LOCAL_URL}/policy?type=privacypolicy`} target="_blank">{t('placeOrder.privacypolicy')}</Link>
                                <span>.</span>
                            </div>
                        }
                        style={{ marginLeft: 10 }}
                    />
                </Box>
                <Box>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }} >
                        <Button variant="contained" style={{ margin: "0px 10px" }} onClick={() => placeOrder()}>{t('placeOrder.checkout')}</Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    const applyCode = () => {
        if (discountCode) {
            getUrl("/price-adjustment/manual-code", { code: discountCode, site: "agency" }).then(response => {
                setLoading(false);
                if (response.status) {
                    if (_.find(autoApplyDiscountCode, function (c) { return c.id === response.data.price_adjustment.id; })) {
                        setDiscountError(t('placeOrder.discountCodeRepeat'));
                    } else {
                        setDiscountError('');
                        setDiscountCode('');
                        if (getVoucherValid(response.data.price_adjustment, false)) {
                            setDiscountData(response.data.price_adjustment);
                        }
                        addAlert('', response.message, 'success', '');
                        let tempAuto = autoApplyDiscountCode;
                        tempAuto[_.size(autoApplyDiscountCode)] = response.data.price_adjustment;
                        setAutoApplyDiscountCode(tempAuto);
                    }
                } else {
                    setDiscountError(response.message);
                }
            }).catch(err => {
                setLoading(false);
                addAlert('', JSON.stringify(err), 'error', '');
            })
        }
    }

    const handleDiscountCodeDialogClose = (clearDiscountData) => {
        if (clearDiscountData) {
            setDiscountData({});
        }
        setDiscountCode('');
        setDiscountError('');
        setDiscountCodeDialogOpen(false);
    }

    const getVoucherValid = (discount_code, getText) => {
        let selectedQuantity = false;
        let selectedMerchant = false;
        let priceAdjustmentMin = 0;
        _.map(carts, (agencyCartList, agencyId) => {
            _.map(agencyCartList.data, (groupItem, groupId) => {
                if (groupItem.selected) {
                    _.map(groupItem.items, mainOption => {
                        // main option
                        if (mainOption.status) {
                            let price = parseFloat(mainOption.quantity * getSellPrice(mainOption.booking_date, mainOption.package_option.price, agencyCartList.agency_public_holidays));
                            selectedQuantity += parseInt(mainOption.quantity);

                            if (_.size(discount_code.agencies_ids) === 0 || _.includes(discount_code.agencies_ids, (mainOption.package.agency_id).toString())) {
                                selectedMerchant = true;
                                priceAdjustmentMin += price;
                            }
                        }
                    })
                    _.map(groupItem.add_on, add_on => {
                        _.map(add_on.items, addOnOption => {
                            let priceArray = _.size(addOnOption.add_on_price) > 0 ? addOnOption.add_on_price : addOnOption.package_option.price;
                            // add on option
                            if (addOnOption.status) {
                                let price = parseFloat(addOnOption.quantity * getSellPrice(addOnOption.booking_date, priceArray, agencyCartList.agency_public_holidays));
                                selectedQuantity += parseInt(addOnOption.quantity);

                                if (_.size(discount_code.agencies_ids) === 0 || _.includes(discount_code.agencies_ids, (addOnOption.package.agency_id).toString())) {
                                    selectedMerchant = true;
                                    priceAdjustmentMin += price;
                                }
                            }
                        })
                    })
                }
            })
        })
        if (!selectedQuantity) {
            return getText ? t('placeOrder.errorSelectQuantity') : false;
        }
        if (!selectedMerchant) {
            return getText ? t('placeOrder.errorSelectMerchant') : false;
        }
        if (priceAdjustmentMin < discount_code.min_spend_value) {
            return getText ? t('placeOrder.errorSpendMin', { min: currentCurrency.symbol + ' ' + parseFloat((discount_code.min_spend_value - priceAdjustmentMin) * currentCurrency.rate).toFixed(2) }) : false;
        }
        return getText ? '' : true;
    }

    const discountCodeDialog = () => {
        return (
            <Dialog open={discountCodeDialogOpen} onClose={() => handleDiscountCodeDialogClose(true)} minWidth="md">
                <DialogTitle>{t('placeOrder.selectOrEnterCode')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <Box style={{ width: "70%" }}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="code"
                                        value={discountCode}
                                        onChange={(event) => setDiscountCode(event.target.value)}
                                    />
                                    {
                                        discountError
                                            ?
                                            <Typography variant="caption" color="error">{discountError}</Typography>
                                            :
                                            null
                                    }
                                </Box>
                                <Box style={{ width: "30%" }}>
                                    <Button variant="outlined" size="small" onClick={() => applyCode()} disabled={discountCode ? false : true} style={{ margin: "0px 5px" }}>
                                        {t('placeOrder.applyCode')}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        {
                            (_.size(autoApplyDiscountCode) > 0) &&
                            <Grid item xs={12}>
                                <Typography>{t('placeOrder.discountVoucher')}</Typography>
                            </Grid>
                        }
                        {
                            _.map(autoApplyDiscountCode, dcode => {
                                return (
                                    <Grid item xs={12} key={dcode.id}>
                                        <Box className={getVoucherValid(dcode, false) ? (discountData.id === dcode.id ? classes.selectedDiscountVoucherBox : classes.discountVoucherBox) : classes.invalidDiscountVoucherBox} onClick={getVoucherValid(dcode, false) ? () => setDiscountData(dcode) : null} style={{ wdith: "100%", display: "flex", alignItems: "center" }}>
                                            <Box style={{ width: "85%" }}>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{dcode.title}</Typography>
                                                {
                                                    dcode.min_spend_value > 0
                                                        ?
                                                        <Typography variant="caption" style={{ display: "block", lineHeight: 1.2 }}>{t('placeOrder.minSpend') + ' ' + currentCurrency.symbol + ' ' + parseFloat(dcode.min_spend_value * currentCurrency.rate).toFixed(2)}</Typography>
                                                        :
                                                        null
                                                }
                                                {
                                                    dcode.end_date
                                                        ?
                                                        <Typography variant="caption" style={{ display: "block", lineHeight: 1.2 }}>{t('placeOrder.validTill') + ': ' + dcode.end_date}</Typography>
                                                        :
                                                        null
                                                }
                                            </Box>
                                            <Box style={{ width: "15%", display: "flex", justifyContent: "center" }}>
                                                <Checkbox
                                                    checked={discountData.id === dcode.id ? true : false}
                                                    icon={<FiCircle size={20} />}
                                                    checkedIcon={<FiCheckCircle size={20} color="white" />}
                                                    disabled={getVoucherValid(dcode, false) ? false : true}
                                                    onChange={getVoucherValid(dcode, false) ? () => setDiscountData(dcode) : null}
                                                />
                                            </Box>
                                        </Box>
                                        {
                                            getVoucherValid(dcode, true)
                                                ?
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <FiAlertCircle color="red" style={{ marginRight: "5px" }} />
                                                    <Typography variant="caption" color="error">{getVoucherValid(dcode, true)}</Typography>
                                                </Box>
                                                :
                                                null
                                        }
                                    </Grid>
                                )
                            })
                        }
                        {
                            _.size(discountData) ?
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    <Typography variant="h6">
                                        {t('placeOrder.discountCodeAmount') + ': ' + (pADiscountAmount > 0 ? '' : '-') + " " + currentCurrency.symbol + " " + (parseFloat(pADiscountAmount > 0 ? pADiscountAmount : -pADiscountAmount) * currentCurrency.rate).toFixed(2)}
                                    </Typography>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handleDiscountCodeDialogClose(false)}>{t('general.ok')}</Button>
                    <Button variant="outlined" color="primary" onClick={() => handleDiscountCodeDialogClose(true)}>{t('button.cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.checkout`)}</b></Typography>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" style={{ marginRight: 15, color: 'black' }}>{t('currencies.currency')}: </Typography>
                        <Select
                            labelId="demo-simple-select-label"
                            id="currency"
                            disableUnderline
                            variant='standard'
                            value={currentCurrency.code}
                            onChange={(event) => {
                                _.map(currencyRate, (currRate, key) => {
                                    if (currencyRate[key].code === event.target.value) {
                                        setCurrentCurrency(currencyRate[key])
                                    }
                                })
                            }}
                            style={{ color: 'black', padding: "2%" }}
                        >
                            {
                                _.map(currencyRate, (currRate, key) => {
                                    return (
                                        <MenuItem key={key} value={currRate.code}>{currRate.code}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Box>
                </Box>
            </Grid>
            {
                _.size(carts) > 0
                    ?
                    <Box style={{ margin: "0px 40px" }}>
                        {
                            _.map(carts, (agencyCartList, agencyId) => (
                                <Box key={agencyId}>
                                    {
                                        _.map(agencyCartList.data, (groupItem, groupId) => (
                                            <Box key={groupId}>
                                                {cartItemBox(groupId, groupItem, agencyCartList.public_holidays)}
                                            </Box>
                                        ))
                                    }
                                </Box>
                            ))
                        }
                        {totalBox()}
                        {bottomActionRow()}
                        {discountCodeDialog()}
                    </Box>
                    :
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        <Typography variant="body2" style={{ marginBottom: 10 }}>{t('placeOrder.noSelectedCart')}</Typography>
                    </Box>
            }
        </>
    )
}

export default Checkout

const useStyles = makeStyles((theme) => ({
    packageBox: {
        padding: "16px",
        borderBottom: "dashed 1px " + theme.palette.primary.main,
    },
    imgStyle: {
        objectFit: "contain !important",
        maxHeight: "150px",
    },
    imgAlignTop: {
        padding: '0 !important',
        margin: 'unset !important',
        marginTop: '20px!important',
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    totalBox1: {
        marginTop: "20px !important",
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    totalBox2: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: "500px !important",
        maxWidth: '100%',
        padding: 16
    },
    finalPriceStyle: {
        color: theme.palette.primary.main,
        fontWeight: "bold !important",
        fontSize: "1.2rem !important"
    },
    bookingDateStyle: {
        color: theme.palette.primary.main + ' !important',
        padding: "5px 10px 5px 0px",
        fontSize: "1rem !important",
        margin: "5px 5px 5px 0px !important",
    },
    discountVoucherBox: {
        backgroundColor: theme.palette.primary.background + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        cursor: "pointer",
    },
    selectedDiscountVoucherBox: {
        backgroundColor: theme.palette.primary.main + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        cursor: "pointer",
        color: "white !important",
    },
    invalidDiscountVoucherBox: {
        backgroundColor: theme.palette.primary.background + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        opacity: "0.5"
    },
}))