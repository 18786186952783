import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, MenuItem, Select, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { updateCartTotal } from '../../actions';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PlaceOrderStep1 from './PlaceOrderStep1';
import PlaceOrderStep2 from './PlaceOrderStep2';
import PlaceOrderStep3 from './PlaceOrderStep3';

const PlaceOrder = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [inputErrors, setInputErrors] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [state, setState] = useState({
        package: "",
        package_id: 0,
        options: {},
        booking_date: "",
        booking_info: [],
        add_on_package: {},
    });
    const [systemPublicHoliday, setSystemPublicHoliday] = useState({});
    const [displayPrice, setDisplayPrice] = useState(0);
    const [userRank, setUserRank] = useState(0);
    const [currencyRate, setCurrencyRate] = useState({});
    const [currentCurrency, setCurrentCurrency] = useState({ "code": "MYR", "name": { 'en': "Ringgit Malaysia", 'cn': "马币" }, "symbol": "RM", "rate": "1.00", "status": "1" });
    const [discountData, setDiscountData] = useState({});
    const [autoApplyDiscountCode, setAutoApplyDiscountCode] = useState({});

    useEffect(() => {
        setLoading(true);
        getUrl(`/user`).then(response => {
            setLoading(false);
            if (response.status) {
                setUserRank(response.data.rank ? response.data.rank : 0);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/currency-rates`).then(response => {
            if (response.status) {
                const currencyList = [];
                for (let i = 0; i < _.size(response.data); i++) {
                    if (response.data[i].status !== "0") {
                        currencyList.push(response.data[i]);
                    }
                }
                setCurrencyRate(currencyList);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })

        getUrl(`/price-adjustment/auto-code`).then(response => {
            if (response.status) {
                setAutoApplyDiscountCode(response.data.price_adjustment);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (state.package_id) {
            setLoading(true);
            getUrl(`/agent-packages/${state.package_id}`).then(response => {
                setLoading(false);
                if (response.status) {
                    let options = [];
                    let booking_info = {};
                    _.map(response.data.package.package_options, option => {
                        options.push({ id: option.id, quantity: 0, name: option.name });
                        booking_info[option.id] = [];
                    })
                    setSystemPublicHoliday(response.data.system_public_holidays);
                    let add_on_package = {};
                    _.map(response.data.package.add_on_packages, add_on => {
                        let add_on_option = [];
                        _.map(add_on.package.package_options, option => {
                            if (option.status === 1) {
                                add_on_option.push({ id: option.id, quantity: 0, name: option.name });
                                booking_info[option.id] = [];
                            }
                        })
                        add_on_package[add_on.id] = add_on_option;
                    })
                    setState({ ...state, package: response.data.package, options: options, booking_info: booking_info, add_on_package: add_on_package });
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [state.package_id]);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
    };
    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    const addToCart = (nextStep) => {
        setLoading(true);
        setInputErrors();
        let apiData = {
            package_id: state.package_id,
            booking_date: state.booking_date,
            options: state.options,
            booking_info: state.booking_info,
            add_on_package: state.add_on_package,
        }
        postUrl(`/carts/agent`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(updateCartTotal(response.cart_count));
                addAlert('', response.message, 'success', '');
                if (nextStep === 'add-package') {
                    setState({
                        package: "",
                        package_id: 0,
                        options: {},
                        booking_date: "",
                        booking_info: [],
                        add_on_package: {},
                    });
                    setActiveStep(0);
                    setDisplayPrice(0);
                } else if (nextStep === 'checkout') {
                    navigate({pathname: `/checkout`, search: `?currency=${currentCurrency.code}`});
                }
            } else {
                setInputErrors(response.errors);
                let activeStep = 2;
                _.map(response.errors, (err, key) => {
                    if (key.includes('package_id')) {
                        activeStep = 0;
                    }
                    if (key.includes('booking_date') && activeStep > 1) {
                        activeStep = 1;
                    }
                })
                setActiveStep(activeStep);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const selectDateSubLabel = () => {
        let label = '';
        if (state.booking_date) {
            label = state.booking_date;
        }
        if (state.options) {
            _.map(state.options, option => {
                if (option.quantity > 0) {
                    let optionText = option.name + ' x' + option.quantity;
                    label = label ? label + ', ' + optionText : optionText;
                }
            })
        }
        if (state.add_on_package) {
            _.map(state.add_on_package, (add_on, add_on_id) => {
                let package_name = '';
                _.map(state.package.add_on_packages, (add_on_package) => {
                    if (add_on_package.id === parseInt(add_on_id)) {
                        package_name = add_on_package.package.name;
                    }
                })
                let quantity = 0;
                _.map(add_on, stateOption => {
                    quantity += stateOption.quantity;
                })
                if (quantity > 0) {
                    let optionText = package_name + ' x' + quantity;
                    label = label ? label + ', ' + optionText : optionText;
                }
            })
        }
        if (displayPrice) {
            let price = ' ' + currentCurrency.symbol + " " + parseFloat(displayPrice * currentCurrency.rate).toFixed(2);
            label = label ? label + ', ' + price : price;
        }
        return label;
    }

    const steps = [
        {
            label: t('placeOrder.searchPackage'),
            subLabel: state.package ? state.package.name : '',
            child: <PlaceOrderStep1 addAlert={addAlert} setLoading={setLoading} state={state} setState={setState} currentCurrency={currentCurrency} handleNext={handleNext} inputErrors={inputErrors} />,
        },
        {
            label: t('placeOrder.selectDate'),
            subLabel: selectDateSubLabel(),
            child: <PlaceOrderStep2 addAlert={addAlert} setLoading={setLoading} state={state} setState={setState} currentCurrency={currentCurrency} handleNext={handleNext} handleBack={handleBack} inputErrors={inputErrors} systemPublicHoliday={systemPublicHoliday} displayPrice={displayPrice} setDisplayPrice={setDisplayPrice} setInputErrors={setInputErrors} userRank={userRank} discountData={discountData} setDiscountData={setDiscountData} autoApplyDiscountCode={autoApplyDiscountCode} setAutoApplyDiscountCode={setAutoApplyDiscountCode} />,
        },
        {
            label: t('placeOrder.selectOptionCustomerInfo'),
            subLabel: '',
            child: <PlaceOrderStep3 addAlert={addAlert} state={state} setState={setState} handleBack={handleBack} inputErrors={inputErrors} addToCart={addToCart} />,
        },
    ];

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.orderPlace`)}</b></Typography>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h6" style={{ marginRight: 15, color: 'black' }}>{t('currencies.currency')}: </Typography>
                        <Select
                            labelId="demo-simple-select-label"
                            id="currency"
                            disableUnderline
                            variant='standard'
                            value={currentCurrency.code}
                            onChange={(event) => {
                                _.map(currencyRate, (currRate, key) => {
                                    if (currencyRate[key].code === event.target.value) {
                                        setCurrentCurrency(currencyRate[key])
                                    }
                                })
                            }}
                            style={{ color: 'black', padding: "2%" }}
                        >
                            {
                                _.map(currencyRate, (currRate, key) => {
                                    return (
                                        <MenuItem key={key} value={currRate.code}>{currRate.code}</MenuItem>
                                    )
                                })
                            }
                        </Select>
                    </Box>
                </Box>
            </Grid>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={index}>
                        <StepLabel
                            optional={step.subLabel}>
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            {step.child}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </>
    )
}

export default PlaceOrder