import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "wallets";
const MODULE_SINGLE = "wallet";

const UserWalletReport = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [walletList, setWalletList] = useState([]);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            status: 50,
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}/wallet_balance`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.listing.data);
                setRowCount(response.listing.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        getUrl(`/${MODULE}`).then(result => {
            if (result.status === 1) {
                setWalletList(result.data);
            }
        }).catch((error) => {
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const columns = useMemo(() => {
        let returnArray = [];
        if (walletList) {
            returnArray.push(
                {
                    accessorFn: (row) => (row.name ? row.name : '-'),
                    id: 'name',
                    header: `${t(`${MODULE}.username`)}`,
                }
            );
            _.map(walletList, wallet => {
                returnArray.push(
                    {
                        accessorFn: (row) => {
                            const existWallet = _.find(row.wallet_balance, { wallet_id: wallet.id });
                            return (existWallet ? parseFloat(existWallet.balance).toFixed(wallet.decimal) : "0")
                        },
                        id: wallet.wallet_name,
                        header: wallet.wallet_name,
                        enableSorting: false,
                        enableColumnFilter: false,
                    }
                );
            })
            returnArray.push({
                accessorKey: 'updated_at',
                header: `${t('general.updatedAt')}`,
            });
        }
        return returnArray;
    }, [i18n.language, t, walletList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`reports.userWallet`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`reports.userWallet`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'UserWalletReport'}
                exportType={'table'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default UserWalletReport