import _ from 'lodash';
import React, { Fragment } from "react";
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { APP_NAME } from '../configs/Config';

import { Box, Container, Link, Typography } from '@mui/material';

//NOTIFICATON AND LOADING
import NotificationLoadingProvider from "../providers/NotificationLoadingProvider";
import NotificationLoading from "./Layout/NotificationLoading";

//ICONS
import { BsCurrencyExchange } from "react-icons/bs";
import { MdAccountTree, MdArticle, MdAssessment, MdAttachMoney, MdCategory, MdDashboard, MdLocalActivity, MdPermMedia, MdPriceChange, MdRoomPreferences, MdSettings, MdShoppingBasket, MdStore, MdSubscriptions, MdSupervisorAccount, MdWork, MdOutlineBallot, MdAssignmentTurnedIn } from "react-icons/md";

// PAGES
import Dashboard from "./Dashboard";
import Sidebar from './Framework/Sidebar';

import ForgetPassword from "./Auth/ForgetPassword";
import Login from "./Auth/Login";
import ResetPassword from "./Auth/ResetPassword";

import AdminCreate from "./Admin/Create";
import AdminEdit from "./Admin/Edit";
import Admin from "./Admin/Index";
import Advertisement from './Advertisement/Index';
import AgencyCreate from "./Agency/Create";
import AgencyEdit from "./Agency/Edit";
import Agency from "./Agency/Index";
import BannerCreate from "./Banner/Create";
import BannerEdit from "./Banner/Edit";
import Banner from "./Banner/Index";
import CategoryCreate from "./Category/Create";
import CategoryEdit from "./Category/Edit";
import Category from "./Category/Index";
import CollectionDetail from './Collection/Detail';
import Collection from './Collection/Index';
import Comment from "./Comment";
import CommentDetail from "./Comment/Detail";
import CurrencyDetail from './Currency/Detail';
import Currency from './Currency/Index';
import LocationCreate from "./Location/Create";
import LocationEdit from "./Location/Edit";
import Location from "./Location/Index";
import MediaGallery from './MediaGallery';
import MemberCreate from "./Member/Create";
import MemberEdit from "./Member/Edit";
import Member from "./Member/Index";
import News from "./News";
import NewsDetail from "./News/Detail";
import OrderEdit from "./Order/Edit";
import Order from "./Order/Index";
import OrderClaim from "./OrderClaim/Index";
import PackageCreate from "./Package/Create";
import PackageEdit from "./Package/Edit";
import Package from "./Package/Index";
import PermissionCreate from "./Permission/Create";
import PermissionEdit from "./Permission/Edit";
import Permission from "./Permission/Index";
import PriceAdjustmentDetail from "./PriceAdjustment/Detail";
import PriceAdjustment from './PriceAdjustment/Index';
import Profile from "./Profile/Index";
import Rank from './Rank/Index';
import RankDetail from './Rank/Detail';
import Review from "./Review";
import ReviewDetail from "./Review/Detail";
import RoleCreate from "./Role/Create";
import RoleEdit from "./Role/Edit";
import Role from "./Role/Index";
import Room from './Room';
import RoomDetail from './Room/Detail';
import RoomType from './RoomType';
import RoomTypeDetail from './RoomType/Detail';
import SettingCreate from "./Setting/Create";
import SettingEdit from "./Setting/Edit";
import Setting from "./Setting/Index";
import SourcePaymentCreate from "./SourcePayment/Create";
import SourcePaymentEdit from "./SourcePayment/Edit";
import SourcePayment from "./SourcePayment/Index";
import StaffCreate from "./Staff/Create";
import StaffEdit from "./Staff/Edit";
import Staff from "./Staff/Index";
import Subscribe from './Subscribe/Index';
import Voucher from "./Voucher";
import VoucherEdit from "./Voucher/Edit";
import VoucherRedeem from "./Voucher/Redeem";
import WalletCreate from "./Wallet/Create";
import WalletEdit from "./Wallet/Edit";
import Wallet from "./Wallet/Index";
import WalletAddDeduct from "./Wallet/WalletAddDeduct";
import WalletTransaction from "./WalletTransaction/Index";
import PlaceOrder from "./OrderAgent/PlaceOrder";
import Payment from "./Payment";
import PaymentCallback from "./Payment/PaymentCallback";
import AgentOrder from "./OrderAgent/Index";
import AgentOrderDetail from "./OrderAgent/Detail";
import OrderSettlement from "./OrderSettlement/Index";
import OrderReceiptApprove from "./Order/OrderReceiptApprove";
import Checkout from "./OrderAgent/Checkout";

import BookingReport from "./Report/Booking";
import CheckInReport from "./Report/CheckIn";
import UserWalletReport from "./Report/UserWallet";
import WalletTransactionReport from "./Report/WalletTransaction";

const routes = [
    // category: true; if show as category, fill in category info, and state all category children
    // name in english, label in translate, children icon wont show if category
    // category: false; if show as self, put details at children

    // DASHBOARD
    {
        category: false,
        categoryName: 'dashboard',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: [],
        categorySidebar: true,
        children: [
            {
                path: "/dashboard",
                component: <Dashboard />,
                permission: [],
                sidebar: true,
                icon: <MdDashboard size="20px" />,
                title: 'title.dashboard',
            },
        ]
    },
    // USER
    {
        category: true,
        categoryName: 'user',
        categoryLabel: 'title.user',
        categoryIcon: <MdSupervisorAccount size="20px" />,
        categoryPermission: ['admin', 'staff', 'member'],
        categorySidebar: true,
        children: [
            {
                path: "/admins",
                component: <Admin />,
                permission: ['admin'],
                sidebar: true,
                icon: null,
                title: 'title.admins',
            },
            {
                path: "/admins-add",
                component: <AdminCreate />,
                permission: ['admin-create'],
                sidebar: false,
                icon: null,
                title: 'title.adminCreate',
            },
            {
                path: "/admins/:id",
                component: <AdminEdit />,
                permission: ['admin-edit'],
                sidebar: false,
                icon: null,
                title: 'title.adminEdit',
            },
            {
                path: "/staffs",
                component: <Staff />,
                permission: ['staff'],
                sidebar: true,
                icon: null,
                title: 'title.staffs',
            },
            {
                path: "/staffs-add",
                component: <StaffCreate />,
                permission: ['staff-create'],
                sidebar: false,
                icon: null,
                title: 'title.staffCreate',
            },
            {
                path: "/staffs/:id",
                component: <StaffEdit />,
                permission: ['staff-edit'],
                sidebar: false,
                icon: null,
                title: 'title.staffEdit',
            },
            {
                path: "/members",
                component: <Member />,
                permission: ['member'],
                sidebar: true,
                icon: null,
                title: 'title.members',
            },
            {
                path: "/members-add",
                component: <MemberCreate />,
                permission: ['member-create'],
                sidebar: false,
                icon: null,
                title: 'title.memberCreate',
            },
            {
                path: "/members/:id",
                component: <MemberEdit />,
                permission: ['member-edit'],
                sidebar: false,
                icon: null,
                title: 'title.memberEdit',
            },
        ]
    },
    // AGENCY
    {
        category: true,
        categoryName: 'agencies',
        categoryLabel: 'title.agencies',
        categoryIcon: <MdStore size="20px" />,
        categoryPermission: ['agency'],
        categorySidebar: true,
        children: [
            {
                path: "/agencies",
                component: <Agency />,
                permission: ['agency'],
                sidebar: true,
                icon: <MdAccountTree />,
                title: 'title.agencies',
            },
            {
                path: "/agencies-add",
                component: <AgencyCreate />,
                permission: ['agency-create'],
                sidebar: true,
                icon: null,
                title: 'title.agencyAdd',
            },
            {
                path: "/agencies/:id",
                component: <AgencyEdit />,
                permission: ['agency-edit'],
                sidebar: false,
                icon: null,
                title: 'title.agencyEdit',
            },
        ]
    },
    // PACKAGE & REVIEW
    {
        category: true,
        categoryName: 'packages',
        categoryLabel: 'title.packagesAndReviews',
        categoryIcon: <MdShoppingBasket size="20px" />,
        categoryPermission: ['package', 'review','collection'],
        categorySidebar: true,
        children: [
            {
                path: "/packages",
                component: <Package />,
                permission: ['package'],
                sidebar: true,
                icon: null,
                title: 'title.packages',
            },
            {
                path: "/packages-add",
                component: <PackageCreate />,
                permission: ['package-create'],
                sidebar: true,
                icon: null,
                title: 'title.packageAdd',
            },
            {
                path: "/packages/:id",
                component: <PackageEdit />,
                permission: ['package-edit'],
                sidebar: false,
                icon: null,
                title: 'title.packageEdit',
            },
            {
                path: "/reviews",
                component: <Review />,
                permission: ['review'],
                sidebar: true,
                icon: null,
                title: 'title.reviews',
            },
            {
                path: "/reviews-add",
                component: <ReviewDetail />,
                permission: ['review-create'],
                sidebar: false,
                icon: null,
                title: 'title.reviewAdd',
            },
            {
                path: "/reviews/:id",
                component: <ReviewDetail />,
                permission: ['review-edit'],
                sidebar: false,
                icon: null,
                title: 'title.reviewEdit',
            },
            {
                path: "/collections",
                component: <Collection />,
                permission: ['collection'],
                sidebar: true,
                icon: null,
                title: 'title.collections',
            },
            {
                path: "/collection-add",
                component: <CollectionDetail />,
                permission: ['collection-create'],
                sidebar: false,
                icon: null,
                title: 'title.collectionAdd',
            },
            {
                path: "/collections/:id",
                component: <CollectionDetail />,
                permission: ['collection-edit'],
                sidebar: false,
                icon: null,
                title: 'title.collectionEdit',
            },
        ]
    },
    // PRICE ADJUSTMENT
    {
        category: false,
        categoryName: 'price-adjustment',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: ['price-adjustment'],
        categorySidebar: true,
        children: [
            {
                path: "/price-adjustments",
                component: <PriceAdjustment />,
                permission: ['price-adjustment'],
                sidebar: true,
                icon: <MdPriceChange size="20px" />,
                title: 'title.adjustments',
            },
            {
                path: "/price-adjustment-add",
                component: <PriceAdjustmentDetail />,
                permission: ['price-adjustment-create'],
                sidebar: false,
                icon: null,
                title: 'title.adjustmentAdd',
            },
            {
                path: "/price-adjustment/:id",
                component: <PriceAdjustmentDetail />,
                permission: ['price-adjustment-edit'],
                sidebar: false,
                icon: null,
                title: 'title.adjustmentEdit',
            },
        ]
    },
    // ROOM TYPE & ROOM
    // {
    //     category: true,
    //     categoryName: 'roomtypes',
    //     categoryLabel: 'title.roomTypesAndRooms',
    //     categoryIcon: <MdRoomPreferences size="20px" />,
    //     categoryPermission: ['room'],
    //     categorySidebar: true,
    //     children: [
    //         {
    //             path: "/roomtypes",
    //             component: <RoomType />,
    //             permission: ['room'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.roomtypes',
    //         },
    //         {
    //             path: "/roomtypes-add",
    //             component: <RoomTypeDetail />,
    //             permission: ['room-create'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.roomtypeAdd',
    //         },
    //         {
    //             path: "/roomtypes/:id",
    //             component: <RoomTypeDetail />,
    //             permission: ['room-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.roomtypeEdit',
    //         },
    //         {
    //             path: "/rooms",
    //             component: <Room />,
    //             permission: ['room'],
    //             sidebar: true,
    //             icon: null,
    //             title: 'title.rooms',
    //         },
    //         {
    //             path: "/rooms-add",
    //             component: <RoomDetail />,
    //             permission: ['room-create'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.roomtypeAdd',
    //         },
    //         {
    //             path: "/rooms/:id",
    //             component: <RoomDetail />,
    //             permission: ['room-edit'],
    //             sidebar: false,
    //             icon: null,
    //             title: 'title.roomEdit',
    //         },
    //     ]
    // },
    // ORDER & VOUCHER
    {
        category: true,
        categoryName: 'orders',
        categoryLabel: 'title.ordersAndVouchers',
        categoryIcon: <MdLocalActivity size="20px" />,
        categoryPermission: ['order', 'voucher', 'order-settlement', 'order-claim'],
        categorySidebar: true,
        children: [
            {
                path: "/orders",
                component: <Order />,
                permission: ['order'],
                sidebar: true,
                icon: null,
                title: 'title.orders',
            },
            {
                path: "/orders/:id",
                component: <OrderEdit />,
                permission: ['order-edit'],
                sidebar: false,
                icon: null,
                title: 'title.orderEdit',
            },
            {
                path: "/order-receipt-approve",
                component: <OrderReceiptApprove />,
                permission: ['order-edit'],
                sidebar: true,
                icon: null,
                title: 'title.orderReceiptApprove',
            },
            {
                path: "/order-settlement",
                component: <OrderSettlement />,
                permission: ['order-settlement'],
                sidebar: true,
                icon: null,
                title: 'title.order-settlement',
            },
            {
                path: "/order-claims",
                component: <OrderClaim />,
                permission: ['order-claim'],
                sidebar: true,
                icon: null,
                title: 'title.order-claims',
            },
            {
                path: "/vouchers",
                component: <Voucher />,
                permission: ['voucher'],
                sidebar: true,
                icon: null,
                title: 'title.vouchers',
            },
            {
                path: "/vouchers/:id",
                component: <VoucherEdit />,
                permission: ['voucher-edit'],
                sidebar: false,
                icon: null,
                title: 'title.voucherEdit',
            },
            {
                path: "/voucher-redeem",
                component: <VoucherRedeem />,
                permission: ['voucher-redeem'],
                sidebar: false,
                icon: null,
                title: 'title.voucherRedeem',
            },
        ]
    },
    // AGENT ORDER
    {
        category: true,
        categoryName: 'agentorders',
        categoryLabel: 'title.agentOrder',
        categoryIcon: <MdAssignmentTurnedIn size="20px" />,
        categoryPermission: ['agent-order'],
        categorySidebar: true,
        children: [
            {
                path: "/agent-orders",
                component: <AgentOrder />,
                permission: ['agent-order'],
                sidebar: true,
                icon: null,
                title: 'title.agentOrder',
            },
            {
                path: "/agent-order/:id",
                component: <AgentOrderDetail />,
                permission: ['agent-order'],
                sidebar: false,
                icon: null,
                title: 'title.agentOrderDetail',
            },
            {
                path: "/place-order",
                component: <PlaceOrder />,
                permission: ['agent-order-create'],
                sidebar: true,
                icon: null,
                title: 'title.orderPlace',
            },
            {
                path: "/checkout",
                component: <Checkout />,
                permission: ['agent-order-create'],
                sidebar: true,
                icon: null,
                title: 'title.checkout',
            },
            {
                path: "/payment/:purchase_no",
                component: <Payment />,
                permission: ['agent-order-create'],
                sidebar: false,
                icon: null,
                title: '',
            },
            {
                path: "/revenuemonster/:payment_id/callback",
                component: <PaymentCallback />,
                permission: [],
                sidebar: false,
                icon: null,
                title: '',
            },
            {
                path: "/revenuemonster/:payment_id/callback/settlement",
                component: <PaymentCallback />,
                permission: [],
                sidebar: false,
                icon: null,
                title: '',
            },
        ]
    },
    // NEWS & COMMENT
    {
        category: true,
        categoryName: 'news',
        categoryLabel: 'title.newsAndComment',
        categoryIcon: <MdOutlineBallot size="20px" />,
        categoryPermission: ['news', 'comment'],
        categorySidebar: true,
        children: [
            {
                path: "/news",
                component: <News />,
                permission: ['news'],
                sidebar: true,
                icon: null,
                title: 'title.news',
            },
            {
                path: "/news-add",
                component: <NewsDetail />,
                permission: ['news-create'],
                sidebar: true,
                icon: null,
                title: 'title.newsAdd',
            },
            {
                path: "/news/:id",
                component: <NewsDetail />,
                permission: ['news-edit'],
                sidebar: false,
                icon: null,
                title: 'title.newsEdit',
            },
            {
                path: "/comments",
                component: <Comment />,
                permission: ['comment'],
                sidebar: true,
                icon: null,
                title: 'title.comments',
            },
            {
                path: "/comments-add",
                component: <CommentDetail />,
                permission: ['comment-create'],
                sidebar: false,
                icon: null,
                title: 'title.commentAdd',
            },
            {
                path: "/comments/:id",
                component: <CommentDetail />,
                permission: ['comment-edit'],
                sidebar: false,
                icon: null,
                title: 'title.commentEdit',
            },
        ]
    },
    // MEDIA GALLERY
    {
        category: false,
        categoryName: 'gallery',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: ['gallery'],
        categorySidebar: true,
        children: [
            {
                path: "/media-gallery",
                component: <MediaGallery />,
                permission: ['gallery'],
                sidebar: true,
                icon: <MdPermMedia size="20px" />,
                title: 'title.mediaGallery',
            },
        ]
    },
    // REPORT
    {
        category: true,
        categoryName: 'reports',
        categoryLabel: 'title.reports',
        categoryIcon: <MdAssessment size="20px" />,
        categoryPermission: ['report'],
        categorySidebar: true,
        children: [
            {
                path: "/reports/booking",
                component: <BookingReport />,
                permission: ['report-booking'],
                sidebar: true,
                icon: null,
                title: 'reports.booking',
            },
            {
                path: "/reports/check-in",
                component: <CheckInReport />,
                permission: ['report-booking'],
                sidebar: true,
                icon: null,
                title: 'reports.checkin',
            },
            {
                path: "/members-wallet-balance",
                component: <UserWalletReport />,
                permission: ['wallet_balance'],
                sidebar: true,
                icon: null,
                title: 'title.walletBalance',
            },
            {
                path: "/wallet-transaction",
                component: <WalletTransactionReport />,
                permission: ['wallet_balance'],
                sidebar: true,
                icon: null,
                title: 'title.walletTransaction',
            },
        ]
    },
    // SUBSCRIBE
    {
        category: false,
        categoryName: 'subscribe',
        categoryLabel: '',
        categoryIcon: null,
        categoryPermission: ['subscribe'],
        categorySidebar: true,
        children: [
            {
                path: "/subscribes",
                component: <Subscribe />,
                permission: ['subscribe'],
                sidebar: true,
                icon: <MdSubscriptions size="20px" />,
                title: 'title.subscribes',
            },
        ]
    },
    // BASIC SETTING: banner, category, location, wallet, advertisement, rank, currency
    {
        category: true,
        categoryName: 'setting',
        categoryLabel: 'title.setting',
        categoryIcon: <MdSettings size="20px" />,
        categoryPermission: ['banner', 'category', 'location', 'wallet', 'advertisement', 'rank', 'currency'],
        categorySidebar: true,
        children: [
            {
                path: "/ranks",
                component: <Rank />,
                permission: ['rank'],
                sidebar: true,
                icon: null,
                title: 'title.ranks',
            },
            {
                path: "/ranks-add",
                component: <RankDetail />,
                permission: ['rank-create'],
                sidebar: false,
                icon: null,
                title: 'title.rankCreate',
            },
            {
                path: "/ranks/:id",
                component: <RankDetail />,
                permission: ['rank-edit'],
                sidebar: false,
                icon: null,
                title: 'title.rankEdit',
            },
            {
                path: "/advertisement",
                component: <Advertisement />,
                permission: ['advertisement'],
                sidebar: true,
                icon: null,
                title: 'title.commercials',
            },
            // {
            //     path: "/banners",
            //     component: <Banner />,
            //     permission: ['banner'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.banners',
            // },
            // {
            //     path: "/banners-create",
            //     component: <BannerCreate />,
            //     permission: ['banner-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.bannerCreate',
            // },
            // {
            //     path: "/banners-edit/:id",
            //     component: <BannerEdit />,
            //     permission: ['banner-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.bannerEdit',
            // },
            {
                path: "/categories",
                component: <Category />,
                permission: ['category'],
                sidebar: true,
                icon: <MdAccountTree />,
                title: 'title.categories',
            },
            {
                path: "/categories-add",
                component: <CategoryCreate />,
                permission: ['category-create'],
                sidebar: false,
                icon: null,
                title: 'title.categoryAdd',
            },
            {
                path: "/categories/:id",
                component: <CategoryEdit />,
                permission: ['category-edit'],
                sidebar: false,
                icon: null,
                title: 'title.categoryEdit',
            },
            {
                path: "/currencies",
                component: <Currency />,
                permission: ['currency'],
                sidebar: true,
                icon: <BsCurrencyExchange />,
                title: 'title.currencies',
            },
            {
                path: "/currencies-add",
                component: <CurrencyDetail />,
                permission: ['currency-create'],
                sidebar: false,
                icon: null,
                title: 'title.currencyAdd',
            },
            {
                path: "/currencies/:id",
                component: <CurrencyDetail />,
                permission: ['currency-edit'],
                sidebar: false,
                icon: null,
                title: 'title.currencyEdit',
            },
            {
                path: "/locations",
                component: <Location />,
                permission: ['location'],
                sidebar: true,
                icon: null,
                title: 'title.locations',
            },
            {
                path: "/locations-add",
                component: <LocationCreate />,
                permission: ['location-create'],
                sidebar: false,
                icon: null,
                title: 'title.locationCreate',
            },
            {
                path: "/locations/:id",
                component: <LocationEdit />,
                permission: ['location-edit'],
                sidebar: false,
                icon: null,
                title: 'title.locationEdit',
            },
            {
                path: "/wallets",
                component: <Wallet />,
                permission: ['wallet'],
                sidebar: true,
                icon: null,
                title: 'title.wallets',
            },
            {
                path: "/wallets-add",
                component: <WalletCreate />,
                permission: ['wallet-create'],
                sidebar: false,
                icon: null,
                title: 'title.walletCreate',
            },
            {
                path: "/wallets/:id",
                component: <WalletEdit />,
                permission: ['wallet-edit'],
                sidebar: false,
                icon: null,
                title: 'title.walletEdit',
            },
            {
                path: "/members-wallet",
                component: <WalletAddDeduct />,
                permission: ['wallet_adjust'],
                sidebar: true,
                icon: null,
                title: 'title.walletAdjust',
            },
            {
                path: "/wallet-transactions/:wallet_id",
                component: <WalletTransaction />,
                permission: [],
                sidebar: false,
                icon: null,
                title: 'title.walletTransaction',
            },
        ]
    },
    // ADVANCE SETTING: roles, permission, system-setting, source-payment
    {
        category: true,
        categoryName: 'advanceSetting',
        categoryLabel: 'title.advanceSetting',
        categoryIcon: <MdSettings size="20px" />,
        categoryPermission: ['role', 'permission', 'setting', 'source-payment'],
        categorySidebar: true,
        children: [
            {
                path: "/roles",
                component: <Role />,
                permission: ['role'],
                sidebar: true,
                icon: <MdAccountTree />,
                title: 'title.roles',
            },
            {
                path: "/roles-add",
                component: <RoleCreate />,
                permission: ['role-create'],
                sidebar: false,
                icon: null,
                title: 'title.roleCreate',
            },
            {
                path: "/roles/:id",
                component: <RoleEdit />,
                permission: ['role-edit'],
                sidebar: false,
                icon: null,
                title: 'title.roleEdit',
            },
            {
                path: "/permissions",
                component: <Permission />,
                permission: ['permission'],
                sidebar: true,
                icon: null,
                title: 'title.permissions',
            },
            {
                path: "/permissions-add",
                component: <PermissionCreate />,
                permission: ['permission-create'],
                sidebar: false,
                icon: null,
                title: 'title.permissionCreate',
            },
            {
                path: "/permissions/:id",
                component: <PermissionEdit />,
                permission: ['permission-edit'],
                sidebar: false,
                icon: null,
                title: 'title.permissionEdit',
            },
            {
                path: "/profile",
                component: <Profile />,
                permission: [],
                sidebar: false,
                icon: null,
                title: 'title.profile',
            },
            {
                path: "/settings",
                component: <Setting />,
                permission: ['setting'],
                sidebar: true,
                icon: null,
                title: 'title.settings',
            },
            {
                path: "/settings-add",
                component: <SettingCreate />,
                permission: ['setting-create'],
                sidebar: false,
                icon: null,
                title: 'title.settingCreate',
            },
            {
                path: "/settings/:id",
                component: <SettingEdit />,
                permission: ['setting-edit'],
                sidebar: false,
                icon: null,
                title: 'title.settingEdit',
            },
            // {
            //     path: "/source-payments",
            //     component: <SourcePayment />,
            //     permission: ['source-payment'],
            //     sidebar: true,
            //     icon: null,
            //     title: 'title.source-payments',
            // },
            // {
            //     path: "/source-payments-add",
            //     component: <SourcePaymentCreate />,
            //     permission: ['source-payment-create'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.source-paymentCreate',
            // },
            // {
            //     path: "/source-payments/:id",
            //     component: <SourcePaymentEdit />,
            //     permission: ['source-payment-edit'],
            //     sidebar: false,
            //     icon: null,
            //     title: 'title.source-paymentEdit',
            // },
        ]
    },
];

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center" >
            {'© '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="/">
                {APP_NAME}
            </Link>
            {'. '}
            All Rights Reserved.
        </Typography >
    );
}

function PrivateRoute({ children, ...rest }) {
    let location = useLocation();
    const { accessToken } = useSelector(state => state.general);
    return (
        accessToken ? (
            children
        ) : (
            <Navigate to="/login" state={{ from: location }} replace />
        )
    );
}


export default function App(props) {
    const { accessToken, permissions } = useSelector(state => state.general);

    const Wrapper = (children, sidebar) => (
        <NotificationLoadingProvider>
            <div>
                {
                    sidebar ?
                        <Sidebar routeProps={routes} content={
                            <>
                                <Container maxWidth="xl">
                                    {children}
                                    <Box pt={4}>
                                        <Copyright />
                                    </Box>
                                </Container>
                            </>
                        } />
                        :
                        <>
                            {children}
                            <Box pt={4}>
                                <Copyright />
                            </Box>
                        </>
                }
            </div>
            <NotificationLoading />
        </NotificationLoadingProvider>
    );

    return (
        <Fragment>
            <div>
                <Routes>
                    <Route path="/login" element={accessToken ? <Navigate replace to="/dashboard" /> : Wrapper(<Login />, false)} />
                    <Route path="/forget-password" element={Wrapper(<ForgetPassword />, false)} />
                    <Route path="/reset-password" element={Wrapper(<ResetPassword />, false)} />
                    {
                        routes.map(category => {
                            return (
                                _.map(category.children, (route) => {
                                    let fulfilledAllPermission = true;
                                    if (_.size(route.permission) > 0) {
                                        _.map(route.permission, (contentPermission) => {
                                            if (!_.includes(permissions, contentPermission)) {
                                                fulfilledAllPermission = false;
                                            }
                                        })
                                    }
                                    return <Route
                                        key={route.path}
                                        path={route.path}
                                        element={fulfilledAllPermission ? <PrivateRoute>
                                            {Wrapper(route.component, true)}
                                        </PrivateRoute> : <Navigate replace to="/dashboard" />} />
                                })
                            )
                        })
                    }
                    <Route path="*" element={accessToken ? <Navigate replace to="/dashboard" /> : <Navigate replace to="/login" />} />
                </Routes>
            </div>
        </Fragment>
    );
};