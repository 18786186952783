import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography, Tabs, Tab } from '@mui/material';
import { useTheme } from '@mui/styles';
import PropTypes from 'prop-types';

import PendingTab from './PendingTab';
import PaidTab from './PaidTab';

const MODULE = "order-settlement";

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const Listing = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Tabs value={tabValue} onChange={handleTabChange}>
                <Tab label={t('orders.pendingSettle')} {...a11yProps(0)} />
                <Tab label={t('orders.pendingApproved')} {...a11yProps(1)} />
                <Tab label={t('orders.completed')} {...a11yProps(2)} />
            </Tabs>
            <TabPanel value={tabValue} index={0}>
                <PendingTab />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <PaidTab status={10} />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
                <PaidTab status={20} />
            </TabPanel>
        </>
    )
}

export default Listing