import _ from 'lodash';
import { React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography, Pagination, PaginationItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import OrderItemCard from './OrderItemCard';

const Order = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [searchParams, setSearchParams] = useSearchParams();
    const [orders, setOrders] = useState({});
    const [statusList, setStatusList] = useState({});
    const [refreshData, setRefreshData] = useState(false);
    const [total, setTotal] = useState(0);

    let order_no = searchParams.get('order_no');
    let package_name = searchParams.get('package_name');
    let status = searchParams.get('status');
    let page = searchParams.get('page');
    const [searchField, setSearchField] = useState({
        order_no: order_no ? order_no : '',
        package_name: package_name ? package_name : '',
        status: status ? status : 0,
        page: page ? page : 1,
    });

    useEffect(() => {
        setLoading(true);
        getUrl("/order", { ...searchField }).then(response => {
            setLoading(false);
            if (response.status) {
                setTotal(response.data.orders.last_page);
                setOrders(response.data.listing);
                setStatusList(response.data.status_list);
            }
        }).catch(err => {
            setLoading(false);
            addAlert('', JSON.stringify(err), 'error', '');
        })
        // eslint-disable-next-line
    }, [order_no, package_name, status, page, refreshData]);

    const handlePaginationChange = (event, value) => {
        searchData(value);
    }

    const searchData = (page) => {
        setSearchParams({
            order_no: searchField.order_no ? searchField.order_no : '',
            package_name: searchField.package_name ? searchField.package_name : '',
            status: searchField.status ? searchField.status : 0,
            page: page,
        });
        setSearchField({ ...searchField, page: page });
    }
    const clearSearchData = () => {
        setSearchParams({ page: 1 });
        setSearchField({
            order_no: '',
            package_name: '',
            status: 0,
            page: 1,
        });
    }

    return (
        <Box className={classes.paper}>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.agentOrder`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Typography color="text.primary">{t(`title.agentOrder`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            {/* SEARCH */}
            <Grid container columnSpacing={2} style={{ paddingBottom: 10 }}>
                <Grid item xs={12} sm={6} md={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={t('orders.orderNo')}
                            value={searchField.order_no}
                            name="order_no"
                            onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label={t('orders.packageName')}
                            value={searchField.package_name}
                            name="package_name"
                            onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t('orders.status')}</InputLabel>
                            <Select
                                value={searchField.status}
                                label={t('orders.status')}
                                name="status"
                                onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                            >
                                <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('orders.status')}</MenuItem>
                                {_.map(statusList, resultItem => {
                                    return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language] ? resultItem[i18n.language] : resultItem.en}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={1}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, marginBottom: 1, width: '100%' }, }} >
                        <Button variant="contained" color="primary" onClick={() => searchData(1)}>{t('button.search')}</Button>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3} md={1}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, marginBottom: 1, width: '100%' }, }} >
                        <Button variant="contained" color="primary" onClick={clearSearchData}>{t('button.clear')}</Button>
                    </Box>
                </Grid>
            </Grid>
            {/* DATA */}
            {
                _.size(orders) > 0
                    ?
                    <Box>
                        {
                            _.map(orders, (order_list) => {
                                if (order_list['payment']) {
                                    let paymentTotal = 0;
                                    return (
                                        <Box key={order_list['order'][0]['purchase_no']} >
                                            {
                                                _.map(order_list['order'], (order, key) => {
                                                    if (order.status === 20) {
                                                        paymentTotal += parseFloat(order.total_amount);
                                                    }
                                                    return (
                                                        <OrderItemCard key={key} order={order} refreshData={refreshData} setRefreshData={setRefreshData} />
                                                    )
                                                })
                                            }
                                            <Box style={{ textAlign: "right", margin: 5 }}>
                                                <Link component={RouterLink} to={`/payment/${encodeURIComponent(order_list['order'][0]['purchase_no'])}`} underline="none">
                                                    <Button variant="contained" style={{ marginRight: 10 }}>
                                                        {t('orders.payNow') + ': MYR ' + parseFloat(paymentTotal).toFixed(2)}
                                                    </Button>
                                                </Link>
                                            </Box>
                                        </Box>
                                    )
                                } else {
                                    return (
                                        <div key={order_list['order'][0]['purchase_no']}>
                                            {
                                                _.map(order_list['order'], (order, key) => {
                                                    return (
                                                        <Box key={key} style={{ marginBottom: 20 }}>
                                                            <OrderItemCard order={order} />
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </div>
                                    )
                                }
                            })
                        }
                        <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                            <Pagination count={total} page={parseInt(searchField.page)} onChange={handlePaginationChange} color="secondary" variant="text"
                                renderItem={(item) => (
                                    <PaginationItem
                                        sx={{ color: theme.palette.secondary.main }}
                                        {...item}
                                    />
                                )}
                            />
                        </Box>
                    </Box>
                    :
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        {t('general.noData')}
                    </Box>
            }
        </Box>
    )
}

export default Order;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
    },
}))