import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Typography, Link, Breadcrumbs, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AddBoxOutlined } from '@mui/icons-material';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "staffs";
const MODULE_SINGLE = "staff";

const Listing = () => {
    const [data, setData] = useState([]);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/users-listing/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'name',
            header: `${t('user.name')}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Link underline='none' to={`/${MODULE}/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.name}</Link>
                    :
                    <>{cell.row.original.name}</>
            )
        },
        {
            accessorFn: (row) => (row.ic ? row.ic : '-'),
            id: 'ic',
            header: `${t('user.ic')}`,
        },
        {
            accessorKey: 'username',
            header: `${t('user.username')}`,
        },
        {
            accessorKey: 'email',
            header: `${t('user.email')}`,
        },
        {
            accessorFn: (row) => (row.mobile ? row.mobile : '-'),
            id: 'mobile',
            header: `${t('user.mobile')}`,
        },
        {
            accessorKey: 'roles',
            header: `${t('user.roles')}`,
            id: 'roles',
            Cell: ({ cell }) => (
                _.map(cell.row.original.roles, (roleDetail, key) => { 
                    return (roleDetail.display_name[i18n.language])
                })
            ),
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.status_display ? row.status_display[i18n.language] : row.status_display.en),
            id: 'status_display.' + i18n.language,
            header: `${t('user.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            enableSorting: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'StaffList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing