import { Box, Button, Chip, Dialog, DialogActions, DialogContent, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, Link, TextField, Typography, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useNotificationLoading from '../../helpers/useNotificationLoading';

const MODULE = "collections";
const MODULE_SINGLE = "collection";

const CollectionModal = ({ dialog, closeDialog, completeDialog, state , setState , collections }) => {

    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);


    const changeCollectionList = id => {
        let list = state.collection;

        if (_.includes(list, id)) {
            list = _.difference(list, [id]);
        } else {
            list.push(id);
        }
        setState({ ...state, collection: list })
    }

    return (
        <Dialog open={dialog.open} onClose={closeDialog} fullWidth maxWidth="sm" >
            <DialogContent>
                {
                    _.size(collections) > 0 ?
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                            <Grid item xs={12}>
                                <Typography>{t(`${MODULE}.chooseCollection`)}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormGroup>
                                    {
                                        _.map(collections, (list, key) => {
                                            return (
                                                <FormControlLabel key={key} control={<Checkbox checked={_.includes(state.collection, list.id)} onChange={() => changeCollectionList(list.id)} />} label={i18n.language === 'cn' ? list.display_name['cn'] : list.display_name['en']} />
                                            )
                                        })
                                    }
                                </FormGroup>
                            </Grid>
                        </Grid>
                        :
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Typography padding={2} variant="subtitle1">{t(`${MODULE}.noCollection`)}</Typography>
                            {
                                _.includes(permissions, `${MODULE_SINGLE}-create`) &&
                                <Link underline='none' to={`/${MODULE_SINGLE}-add`} component={RouterLink}>
                                    <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle}>{t('title.collectionAdd')}</Button>
                                </Link>
                            }
                        </Grid>
                }
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="center" alignItems="center" padding={2}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle} onClick={closeDialog}>{t('button.cancel')}</Button>
                    {
                        _.size(collections) >  0 &&
                        <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={completeDialog}>{t('button.complete')}</Button>
                    }
                </Box>
            </DialogActions>
        </Dialog>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default CollectionModal;