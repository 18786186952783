import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { FiMinus, FiPlus, FiAlertCircle, FiCircle, FiCheckCircle } from "react-icons/fi";

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { CUSTOMER_SERVICE_EMAIL } from '../../configs/Config';

const PlaceOrderStep2 = (props) => {
    const { addAlert, setLoading, state, setState, currentCurrency, handleNext, handleBack, inputErrors, systemPublicHoliday, displayPrice, setDisplayPrice, setInputErrors, userRank, discountData, setDiscountData, autoApplyDiscountCode, setAutoApplyDiscountCode } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [subTotal, setSubTotal] = useState(0);
    const [pADiscountAmount, setPADiscountAmount] = useState(0);
    const [discountCode, setDiscountCode] = useState('');
    const [discountError, setDiscountError] = useState('');
    const [discountCodeDialogOpen, setDiscountCodeDialogOpen] = useState(false);
    const [cutOffPopup, setCutOffPopup] = useState(false);

    useEffect(() => {
        calculateTotalPrice(state.options, state.add_on_package, state.booking_date);
        // eslint-disable-next-line
    }, [discountData]);

    useEffect(() => {
        if (!getVoucherValid(discountData, false)) {
            setDiscountData({});
        }
        // eslint-disable-next-line
    }, [pADiscountAmount]);

    const handleDateSelect = (value) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setState({ ...state, booking_date: date });
        calculateTotalPrice(state.options, state.add_on_package, date);
    }

    const calculateTotalPrice = (selectedOptions, addOnOptions, booking_date) => {
        let selectedTotalPrice = 0;
        let priceAdjustmentMin = 0;
        let priceAdjustmentDiscount = 0;
        if (booking_date) {
            _.map(selectedOptions, stateOption => {
                if (stateOption.quantity > 0) {
                    let option = _.find(state.package.package_options, { id: stateOption.id });
                    let selectedPrice = getSellPrice(option.price, booking_date);
                    selectedTotalPrice += stateOption.quantity * selectedPrice;
                    if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (state.package.agency.id).toString())) {
                        priceAdjustmentMin += stateOption.quantity * selectedPrice;
                    }
                }
            })
            _.map(addOnOptions, add_on => {
                _.map(add_on, stateOption => {
                    if (stateOption.quantity > 0) {
                        let price = [];
                        _.map(state.package.add_on_packages, (add_on_package) => {
                            _.map(add_on_package.package.package_options, (option) => {
                                if (option.id === stateOption.id) {
                                    price = add_on_package.option_prices[option.id] ? add_on_package.option_prices[option.id] : option.price;
                                }
                            })
                        })
                        let selectedPrice = getSellPrice(price, booking_date);
                        selectedTotalPrice += stateOption.quantity * selectedPrice;
                        if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (state.package.agency.id).toString())) {
                            priceAdjustmentMin += stateOption.quantity * selectedPrice;
                        }
                    }
                })
            })
            if (priceAdjustmentMin >= discountData.min_spend_value) {
                if (discountData.type === 'percent') {
                    priceAdjustmentDiscount = parseFloat(priceAdjustmentMin * parseFloat(discountData.value / 100));
                } else {
                    priceAdjustmentDiscount = parseFloat(discountData.value);
                }
                if (discountData.capped_value > 0) {
                    priceAdjustmentDiscount = Math.min(parseFloat(discountData.capped_value), parseFloat(priceAdjustmentDiscount));
                }
                if (discountData.action === 'deduct') {
                    priceAdjustmentDiscount = 0 - priceAdjustmentDiscount;
                }
            }
        }
        setPADiscountAmount(priceAdjustmentDiscount);
        setSubTotal(selectedTotalPrice);
        setDisplayPrice(selectedTotalPrice + priceAdjustmentDiscount);
    }

    const handleQuantityChange = (type, quantity, option_id, add_on) => {
        let newQuantity = quantity ? parseInt(quantity) : '';
        let stateOption = {};
        if (add_on) {
            stateOption = state.add_on_package;
            _.map(stateOption, (add_on, key1) => {
                _.map(add_on, (option, key2) => {
                    if (option.id === option_id) {
                        if (type === 'quantity') {
                            stateOption[key1][key2]['quantity'] = newQuantity;
                        } else if (type === 'add') {
                            stateOption[key1][key2]['quantity'] = option.quantity + newQuantity;
                        } else if (type === 'deduct') {
                            stateOption[key1][key2]['quantity'] = (option.quantity - newQuantity) > 0 ? (option.quantity - newQuantity) : 0;
                        }
                    }
                })
            })
            setState({ ...state, add_on_package: stateOption });
            calculateTotalPrice(state.options, stateOption, state.booking_date);
        } else {
            stateOption = state.options;
            _.map(stateOption, (option, key) => {
                if (option.id === option_id) {
                    if (type === 'quantity') {
                        stateOption[key]['quantity'] = newQuantity;
                    } else if (type === 'add') {
                        stateOption[key]['quantity'] = option.quantity + newQuantity;
                    } else if (type === 'deduct') {
                        stateOption[key]['quantity'] = (option.quantity - newQuantity) > 0 ? (option.quantity - newQuantity) : 0;
                    }
                }
            })
            setState({ ...state, options: stateOption });
            calculateTotalPrice(stateOption, state.add_on_package, state.booking_date);
        }
    }

    const getSellPrice = (price, booking_date) => {
        let selectedPrice = 0;
        if (booking_date) {
            let agency_public_holidays = state.package.agency ? state.package.agency.public_holidays : [];
            let publicHoliday = agency_public_holidays ? agency_public_holidays : systemPublicHoliday;
            let day = parseInt((new Date(booking_date)).getDay());
            if (_.find(publicHoliday, function (date) { return date === booking_date })) {
                selectedPrice = price['ranks'][userRank]['holiday'];
            } else if (day === 6 || day === 0) {
                selectedPrice = price['ranks'][userRank]['weekend'];
            } else {
                selectedPrice = price['ranks'][userRank]['weekday'];
            }
        }
        return selectedPrice;
    }

    const handleBackToSelectPackage = () => {
        setState({ ...state, package: "", package_id: 0, booking_date: "", options: [], booking_info: [] });
        handleBack();
    }

    const handleNextToCustomerInfo = () => {
        let totalQuantity = 0;
        _.map(state.options, option => {
            totalQuantity += option.quantity;
        })
        if (totalQuantity > 0 && state.booking_date) {
            setLoading(true);
            let apiData = {
                package_id: state.package_id,
                booking_date: state.booking_date,
                options: state.options,
                add_on_package: state.add_on_package,
            }
            postUrl(`carts/agent/check-available`, apiData).then(response => {
                setLoading(false);
                if (response.status) {
                    setInputErrors({});
                    handleNext();
                } else {
                    if (response.cutoff_popup) {
                        setCutOffPopup(true);
                    } else {
                        setInputErrors(response.errors);
                        addAlert('', response.message, 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            addAlert('', t('placeOrder.bookingDateQuantityRequired'), 'error', '');
        }
    }

    const applyCode = () => {
        if (discountCode) {
            getUrl("/price-adjustment/manual-code", { code: discountCode, site: "agency" }).then(response => {
                setLoading(false);
                if (response.status) {
                    if (_.find(autoApplyDiscountCode, function (c) { return c.id === response.data.price_adjustment.id; })) {
                        setDiscountError(t('placeOrder.discountCodeRepeat'));
                    } else {
                        setDiscountError('');
                        setDiscountCode('');
                        if (getVoucherValid(response.data.price_adjustment, false)) {
                            setDiscountData(response.data.price_adjustment);
                        }
                        addAlert('', response.message, 'success', '');
                        let tempAuto = autoApplyDiscountCode;
                        tempAuto[_.size(autoApplyDiscountCode)] = response.data.price_adjustment;
                        setAutoApplyDiscountCode(tempAuto);
                    }
                } else {
                    setDiscountError(response.message);
                }
            }).catch(err => {
                setLoading(false);
                addAlert('', JSON.stringify(err), 'error', '');
            })
        }
    }

    const handleDiscountCodeDialogClose = (clearDiscountData) => {
        if (clearDiscountData) {
            setDiscountData({});
        }
        setDiscountCode('');
        setDiscountError('');
        setDiscountCodeDialogOpen(false);
    }

    const getVoucherValid = (discount_code, getText) => {
        if (!state.booking_date) {
            return getText ? t('placeOrder.errorSelectBookingDate') : false;
        }
        let selectedQuantity = false;
        let selectedMerchant = false;
        let priceAdjustmentMin = 0;
        _.map(state.options, stateOption => {
            if (stateOption.quantity > 0) {
                selectedQuantity = true;
                let option = _.find(state.package.package_options, { id: stateOption.id });
                let selectedPrice = getSellPrice(option.price, state.booking_date);
                if (_.size(discount_code.agencies_ids) === 0 || _.includes(discount_code.agencies_ids, (state.package.agency.id).toString())) {
                    selectedMerchant = true;
                    priceAdjustmentMin += stateOption.quantity * selectedPrice;
                }
            }
        })
        _.map(state.add_on_packages, add_on => {
            _.map(add_on, stateOption => {
                if (stateOption.quantity > 0) {
                    let price = [];
                    _.map(state.package.add_on_packages, (add_on_package) => {
                        _.map(add_on_package.package.package_options, (option) => {
                            if (option.id === stateOption.id) {
                                price = add_on_package.option_prices[option.id] ? add_on_package.option_prices[option.id] : option.price;
                            }
                        })
                    })
                    let selectedPrice = getSellPrice(price, state.booking_date);
                    if (_.size(discountData.agencies_ids) === 0 || _.includes(discountData.agencies_ids, (state.package.agency.id).toString())) {
                        selectedMerchant = true;
                        priceAdjustmentMin += stateOption.quantity * selectedPrice;
                    }
                }
            })
        })
        if (!selectedQuantity) {
            return getText ? t('placeOrder.errorSelectQuantity') : false;
        }
        if (!selectedMerchant) {
            return getText ? t('placeOrder.errorSelectMerchant') : false;
        }
        if (priceAdjustmentMin < discount_code.min_spend_value) {
            return getText ? t('placeOrder.errorSpendMin', { min: currentCurrency.symbol + ' ' + parseFloat((discount_code.min_spend_value - priceAdjustmentMin) * currentCurrency.rate).toFixed(2) }) : false;
        }
        return getText ? '' : true;
    }

    const discountCodeDialog = () => {
        return (
            <Dialog open={discountCodeDialogOpen} onClose={() => handleDiscountCodeDialogClose(true)}>
                <DialogTitle>{t('placeOrder.selectOrEnterCode')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box style={{ display: "flex", alignItems: "center", width: "100%" }}>
                                <Box style={{ width: "70%" }}>
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        margin="normal"
                                        fullWidth
                                        name="code"
                                        value={discountCode}
                                        onChange={(event) => setDiscountCode(event.target.value)}
                                    />
                                    {
                                        discountError
                                            ?
                                            <Typography variant="caption" color="error">{discountError}</Typography>
                                            :
                                            null
                                    }
                                </Box>
                                <Box style={{ width: "30%" }}>
                                    <Button variant="outlined" size="small" onClick={() => applyCode()} disabled={discountCode ? false : true} style={{ margin: "0px 5px" }}>
                                        {t('placeOrder.applyCode')}
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>{t('placeOrder.discountVoucher')}</Typography>
                        </Grid>
                        {
                            _.map(autoApplyDiscountCode, dcode => {
                                return (
                                    <Grid item xs={12} key={dcode.id}>
                                        <Box className={getVoucherValid(dcode, false) ? (discountData.id === dcode.id ? classes.selectedDiscountVoucherBox : classes.discountVoucherBox) : classes.invalidDiscountVoucherBox} onClick={getVoucherValid(dcode, false) ? () => setDiscountData(dcode) : null} style={{ wdith: "100%", display: "flex", alignItems: "center" }}>
                                            <Box style={{ width: "85%" }}>
                                                <Typography variant="body1" style={{ fontWeight: "bold" }}>{dcode.title}</Typography>
                                                {
                                                    dcode.min_spend_value > 0
                                                        ?
                                                        <Typography variant="caption" style={{ display: "block", lineHeight: 1.2 }}>{t('placeOrder.minSpend') + ' ' + currentCurrency.symbol + ' ' + parseFloat(dcode.min_spend_value * currentCurrency.rate).toFixed(2)}</Typography>
                                                        :
                                                        null
                                                }
                                                {
                                                    dcode.end_date
                                                        ?
                                                        <Typography variant="caption" style={{ display: "block", lineHeight: 1.2 }}>{t('placeOrder.validTill') + ': ' + dcode.end_date}</Typography>
                                                        :
                                                        null
                                                }
                                            </Box>
                                            <Box style={{ width: "15%", display: "flex", justifyContent: "center" }}>
                                                <Checkbox
                                                    checked={discountData.id === dcode.id ? true : false}
                                                    icon={<FiCircle size={20} />}
                                                    checkedIcon={<FiCheckCircle size={20} color="white" />}
                                                    disabled={getVoucherValid(dcode, false) ? false : true}
                                                    onChange={getVoucherValid(dcode, false) ? () => setDiscountData(dcode) : null}
                                                />
                                            </Box>
                                        </Box>
                                        {
                                            getVoucherValid(dcode, true)
                                                ?
                                                <Box style={{ display: "flex", alignItems: "center" }}>
                                                    <FiAlertCircle color="red" style={{ marginRight: "5px" }} />
                                                    <Typography variant="caption" color="error">{getVoucherValid(dcode, true)}</Typography>
                                                </Box>
                                                :
                                                null
                                        }
                                    </Grid>
                                )
                            })
                        }
                        {
                            _.size(discountData) ?
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    <Typography variant="h6">
                                        {t('placeOrder.discountCodeAmount') + ': ' + (pADiscountAmount > 0 ? '' : '-') + " " + currentCurrency.symbol + " " + (parseFloat(pADiscountAmount > 0 ? pADiscountAmount : -pADiscountAmount) * currentCurrency.rate).toFixed(2)}
                                    </Typography>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handleDiscountCodeDialogClose(false)}>{t('general.ok')}</Button>
                    <Button variant="outlined" color="primary" onClick={() => handleDiscountCodeDialogClose(true)}>{t('button.cancel')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const cutOffPopupBox = () => {
        return (
            <Dialog open={cutOffPopup} onClose={() => setCutOffPopup(false)} fullWidth={true} maxWidth="sm">
                <DialogTitle>{t('placeOrder.cutOffTitle')}</DialogTitle>
                <DialogContent>
                    {t('placeOrder.cutOffContent', { email: CUSTOMER_SERVICE_EMAIL })}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" onClick={() => setCutOffPopup(false)}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const quantityBox = (option, add_on, addOnPrice = []) => {
        let priceArray = add_on && addOnPrice ? addOnPrice : option.price;
        let quantityOption = [];
        if (add_on) {
            _.map(state.add_on_package, add_on_data => {
                _.map(add_on_data, optionData => {
                    if (optionData.id === option.id) {
                        quantityOption = optionData;
                    }
                })
            })
        } else {
            quantityOption = _.find(state.options, { id: option.id });
        }
        return (
            <Grid item xs={12} key={option.id}>
                <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", width: "100%" }}>
                    <Box style={{ width: "50%" }}>
                        <Box style={{ border: "1px solid gray", padding: "0px 15px", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box>
                                <Typography variant="body2">{option.name}</Typography>
                            </Box>
                            <Box style={{ padding: '5px 0', display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 100 }}>
                                <IconButton onClick={() => handleQuantityChange('deduct', 1, option.id, add_on)}>
                                    <FiMinus style={{ fontSize: "1rem" }} />
                                </IconButton>
                                <TextField
                                    sx={{ input: { textAlign: "center" } }}
                                    variant="standard"
                                    name="quantity"
                                    value={quantityOption.quantity}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    fullWidth
                                    onChange={(event) => handleQuantityChange('quantity', event.target.value, option.id, add_on)}
                                />
                                <IconButton onClick={() => handleQuantityChange('add', 1, option.id, add_on)}>
                                    <FiPlus style={{ fontSize: "1rem" }} />
                                </IconButton>
                            </Box>
                        </Box>
                        {
                            inputErrors && (inputErrors['options.' + option.id + '.id'] || inputErrors['options.' + option.id + '.quantity'])
                                ?
                                <Typography variant="body2" style={{ color: theme.palette.indicator.red }}>{(inputErrors['options.' + option.id + '.id'] || inputErrors['options.' + option.id + '.quantity'])}</Typography>
                                :
                                null
                        }
                    </Box>
                    <Box style={{ display: "flex", justifyContent: "flex-end", width: "15%" }}>
                        {currentCurrency.symbol + " " + parseFloat(getSellPrice(priceArray, state.booking_date) * currentCurrency.rate * quantityOption.quantity).toFixed(2)}
                    </Box>
                </Box>
            </Grid>
        )
    }

    return (
        <Box>
            <Grid container spacing={1}>
                {
                    inputErrors && (inputErrors['price_adjustment_id'] || inputErrors['discount_codes_id'])
                        ?
                        <Grid item xs={12}>
                            <Typography variant="caption" style={{ color: theme.palette.indicator.red }}>{(inputErrors['price_adjustment_id'] || inputErrors['discount_codes_id'])}</Typography>
                        </Grid>
                        :
                        null
                }
                {
                    inputErrors && (inputErrors['mustHaveAdult'])
                        ?
                        <Grid item xs={12}>
                            <Typography variant="caption" style={{ color: theme.palette.indicator.red }}>{inputErrors['mustHaveAdult']}</Typography>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '50%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t('placeOrder.bookingDate')}
                                value={state.booking_date}
                                onChange={(newValue) => handleDateSelect(newValue)}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.booking_date ? inputErrors.booking_date : ''}
                                        error={inputErrors && inputErrors.booking_date ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" style={{ fontWeight: "bold", marginTop: 10 }}>{state.package.name}</Typography>
                </Grid>
                {
                    (_.size(state.package.package_options) > 0) &&
                    _.map(state.package.package_options, (option) => {
                        if (option.status === 1) {
                            return quantityBox(option, false)
                        }
                    })
                }
                {
                    (_.size(state.package.add_on_packages) > 0) &&
                    _.map(state.package.add_on_packages, (add_on_package) => {
                        if (add_on_package.package.status === 1) {
                            return (
                                <Grid item xs={12} key={add_on_package.id}>
                                    <Typography variant="body2" style={{ marginTop: 10 }}>{t('placeOrder.addOnPackage') + ': '}<b>{add_on_package.package.name}</b></Typography>
                                    <Grid container spacing={1}>
                                        {_.map(add_on_package.package.package_options, (option) => {
                                            let addOnPrice = add_on_package.option_prices[option.id] ? add_on_package.option_prices[option.id] : [];
                                            if (option.status === 1) {
                                                return quantityBox(option, true, addOnPrice)
                                            }
                                        })}
                                    </Grid>
                                </Grid>
                            )
                        }
                    })
                }
                {/* <Grid item xs={12}>
                    <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", width: "100%", paddingTop: "20px" }}>
                        <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "50%" }}>
                            <Typography variant="body1">{t('placeOrder.discountCode') + ": "}</Typography>
                        </Box>
                        <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", width: "50%", paddingLeft: "4%" }}>
                            <Button size="small" color="gray" onClick={() => setDiscountCodeDialogOpen(true)}>
                                {
                                    _.size(discountData) > 0
                                        ?
                                        (discountData.title).length > 50 ?
                                            ((discountData.title).substring(0, 50) + '...  > ')
                                            :
                                            (discountData.title + '  > ')
                                        :
                                        (t('placeOrder.selectOrEnterCode') + '  > ')
                                }
                            </Button>
                        </Box>
                    </Box>
                </Grid> */}
                {
                    _.map([
                        // { title: t('placeOrder.subTotal') + ": ", value: currentCurrency.symbol + " " + parseFloat(subTotal * currentCurrency.rate).toFixed(2) },
                        // { title: t('placeOrder.discountCodeAmount') + ": ", value: (pADiscountAmount > 0 ? '' : '-') + " " + currentCurrency.symbol + " " + (parseFloat(pADiscountAmount > 0 ? pADiscountAmount : -pADiscountAmount) * currentCurrency.rate).toFixed(2) },
                        { title: t('placeOrder.total') + ": ", value: currentCurrency.symbol + " " + parseFloat(displayPrice * currentCurrency.rate).toFixed(2) },
                    ], data => {
                        return (
                            <Grid item xs={12} key={data.title}>
                                <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-end", width: "100%" }}>
                                    <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "50%" }}>
                                        <Typography variant="body1">{data.title}</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", justifyContent: "flex-end", alignItems: "flex-end", width: "15%" }}>
                                        <Typography variant="body1">{data.value}</Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        )
                    })
                }
                <Grid item xs={12}>
                    <Box style={{ display: "flex", alignItems: "center" }} >
                        <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={() => handleBackToSelectPackage()}>{t('button.back')}</Button>
                        <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={() => handleNextToCustomerInfo()}>{t('button.next')}</Button>
                    </Box>
                </Grid>
            </Grid>
            {/* {discountCodeDialog()} */}
            {cutOffPopupBox()}
        </Box>
    )
}

export default PlaceOrderStep2

const useStyles = makeStyles((theme) => ({
    discountVoucherBox: {
        backgroundColor: theme.palette.primary.background + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        cursor: "pointer",
    },
    selectedDiscountVoucherBox: {
        backgroundColor: theme.palette.primary.main + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        cursor: "pointer",
        color: "white",
    },
    invalidDiscountVoucherBox: {
        backgroundColor: theme.palette.primary.background + ' !important',
        border: "1px solid " + theme.palette.primary.main,
        padding: "3%",
        opacity: "0.5"
    },
}))