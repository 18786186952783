import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import Moment from 'moment';

import { Box, Button, Chip, FormControlLabel, FormLabel, Grid, Link, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { MdAttachFile } from "react-icons/md";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CUSTOMER_INFO = {
    user_name: '',
    user_email: '',
    user_mobile: '',
    user_ic: '',
    user_dob: '',
    user_gender: '',
    user_role: '',
}

const UPLOAD_FIELDS = [
    'option',
    'user_name',
    'user_email',
    'user_mobile',
    'user_ic',
    'user_dob',
    'user_gender',
    'user_role',
    'add_on_option_ids',
]

const PlaceOrderStep3 = (props) => {
    const { state, setState, handleBack, inputErrors, addToCart } = props;
    const { t } = useTranslation();

    const [uploadData, setUploadData] = useState([]);

    useEffect(() => {
        let tempBookingInfos = state.booking_info;
        _.map(state.booking_info, (booking_info, option_id) => {
            let tempBookingInfo = booking_info;
            let add_on = true;
            _.map(state.options, option => {
                if (parseInt(option.id) === parseInt(option_id)) {
                    add_on = false;
                    while (_.size(tempBookingInfo) < option.quantity) {
                        tempBookingInfo[_.size(tempBookingInfo)] = CUSTOMER_INFO;
                    }
                    if (_.size(tempBookingInfo) > option.quantity) {
                        tempBookingInfo = _.filter(tempBookingInfo, function (o, index) { return index < option.quantity; });
                    }
                }
            })
            if (add_on) {
                _.map(state.add_on_package, add_on => {
                    _.map(add_on, option => {
                        if (parseInt(option.id) === parseInt(option_id)) {
                            while (_.size(tempBookingInfo) < option.quantity) {
                                tempBookingInfo[_.size(tempBookingInfo)] = CUSTOMER_INFO;
                            }
                            if (_.size(tempBookingInfo) > option.quantity) {
                                tempBookingInfo = _.filter(tempBookingInfo, function (o, index) { return index < option.quantity; });
                            }
                        }
                    })
                })
            }
            tempBookingInfos[option_id] = tempBookingInfo;
        })
        setState({ ...state, booking_info: tempBookingInfos });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let bookingIndex = [];
        let tempBookingInfo = state.booking_info;
        if (_.size(uploadData) > 0) {
            const chunksData = _.chunk(uploadData, 9);
            _.map(chunksData, (item, chunkIndex) => {
                if (chunkIndex !== 0) {
                    if (item[0] !== '') {
                        let newData = {};
                        _.map(UPLOAD_FIELDS, (field, index) => {
                            if (index >= 1 && index <= 7) {
                                newData[field] = item[index]
                            }
                        });
                        let option = _.find(state.options, function (o) { return o.name === item[0]; });
                        if (option) {
                            if (!bookingIndex[option.id]) {
                                bookingIndex[option.id] = 1;
                            } else {
                                bookingIndex[option.id] += 1;
                            }
                            if (tempBookingInfo[option.id][bookingIndex[option.id] - 1]) {
                                tempBookingInfo[option.id][bookingIndex[option.id] - 1] = newData;
                            }
                            if (item[8]) {
                                let add_on_option_ids = _.split(item[8], '|');
                                _.map(add_on_option_ids, option_id => {
                                    if (_.size(tempBookingInfo[option_id])) {
                                        if (!bookingIndex[option_id]) {
                                            bookingIndex[option_id] = 1;
                                        } else {
                                            bookingIndex[option_id] += 1;
                                        }
                                        if (tempBookingInfo[option_id][bookingIndex[option_id] - 1]) {
                                            tempBookingInfo[option_id][bookingIndex[option_id] - 1] = newData;
                                        }
                                    }
                                })
                            }
                        }
                    }
                }
            })
        }
        setState({ ...state, booking_info: tempBookingInfo });
        // eslint-disable-next-line
    }, [uploadData]);

    const handleBackToSelectDate = () => {
        handleBack();
    }

    const handleBookingInfoChange = (option_id, key, target) => {
        const { value, name } = target;
        setState({ ...state, booking_info: { ...state.booking_info, [option_id]: { ...state.booking_info[option_id], [key]: { ...state.booking_info[option_id][key], [name]: value } } } });
    }

    const handleDOBChange = (option_id, key, value) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setState({ ...state, booking_info: { ...state.booking_info, [option_id]: { ...state.booking_info[option_id], [key]: { ...state.booking_info[option_id][key], user_dob: date } } } });
    }

    const handleFiles = (e) => {
        const files = e.target.files;
        let file = "";
        if (files && files[0]) {
            file = files[0];
            const reader = new FileReader();
            const rABS = !!reader.readAsBinaryString;
            reader.onload = (evt) => { // evt = on_file_select event
                /* Parse data */
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
                /* Get first worksheet */
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                /* Convert array of arrays */
                let data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
                /* replace line break with , */
                data = data.replace(/(?:\r\n|\r|\n)/g, ',');
                // data = data.replaceAll('"',"");
                // console.log("data2", splitCSV(data));
                data = splitCSV(data);
                /* convert to array */
                // data = data.split(",")
                /* remove empty values */
                // data = _.compact(data);
                setUploadData(data);
            };
            if (rABS) reader.readAsBinaryString(file); else reader.readAsArrayBuffer(file);
        }
    };

    const customerInfo = (option_id, option_name, userInfos) => {
        return (
            <Grid item xs={12} key={option_id}>
                <Chip label={option_name} />
                {
                    _.map(inputErrors, (msg, row) => {
                        if (_.includes(row, 'options.'+option_id+'.')) {
                            return (
                                <Typography variant="caption" color="error" key={row}>{msg}</Typography>
                            )
                        }
                    })
                }
                {
                    _.map(userInfos, (userInfo, key) => {
                        return (
                            <Grid container key={key}>
                                <Grid item xs={12}>
                                    <Box style={{ display: "flex", alignItems: "center", marginTop: 10, paddingLeft: 10 }}>
                                        <Typography variant="body2">
                                            {t('placeOrder.guestDetail') + " " + (parseInt(key) + 1)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        fullWidth
                                        label={t('user.name') + '*'}
                                        value={userInfo.user_name}
                                        name="user_name"
                                        onChange={(event) => handleBookingInfoChange(option_id, key, event.target)}
                                        error={inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_name'] ? true : false}
                                        helperText={(inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_name']) || ''}
                                        style={{ padding: "0px 5px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        fullWidth
                                        label={t('user.email')}
                                        value={userInfo.user_email}
                                        name="user_email"
                                        onChange={(event) => handleBookingInfoChange(option_id, key, event.target)}
                                        error={inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_email'] ? true : false}
                                        helperText={(inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_email']) || ''}
                                        style={{ padding: "0px 5px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        fullWidth
                                        label={t('user.mobile')}
                                        value={userInfo.user_mobile}
                                        name="user_mobile"
                                        onChange={(event) => handleBookingInfoChange(option_id, key, event.target)}
                                        error={inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_mobile'] ? true : false}
                                        helperText={(inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_mobile']) || ''}
                                        style={{ padding: "0px 5px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <TextField
                                        variant="outlined"
                                        margin="dense"
                                        size="small"
                                        fullWidth
                                        label={t('user.ic') + '*'}
                                        value={userInfo.user_ic}
                                        name="user_ic"
                                        onChange={(event) => handleBookingInfoChange(option_id, key, event.target)}
                                        error={inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_ic'] ? true : false}
                                        helperText={(inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_ic']) || ''}
                                        style={{ padding: "0px 5px" }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label={t('user.dob') + '*'}
                                            value={userInfo.user_dob}
                                            onChange={(newValue) => handleDOBChange(option_id, key, newValue)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    size="small"
                                                    fullWidth
                                                    error={inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_dob'] ? true : false}
                                                    helperText={(inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_dob']) || ''}
                                                    style={{ padding: "0px 5px" }}
                                                />
                                            }
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Box style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 5px" }}>
                                        <FormLabel style={{ marginRight: 20 }}>{t('user.gender') + '*'}</FormLabel>
                                        <RadioGroup row name="user_gender" value={userInfo.user_gender} onChange={(event) => handleBookingInfoChange(option_id, key, event.target)}>
                                            <FormControlLabel value={'male'} control={<Radio size="small" />} label={t('user.male')} />
                                            <FormControlLabel value={'female'} control={<Radio size="small" />} label={t('user.female')} />
                                        </RadioGroup>
                                    </Box>
                                    {
                                        inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_gender']
                                            ?
                                            <Typography variant="caption" color="error">{inputErrors['booking_info.' + option_id + '.' + key + '.user_gender']}</Typography>
                                            :
                                            null
                                    }
                                </Grid>
                                <Grid item sm={12} md={6}>
                                    <Box style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 5px" }}>
                                        <FormLabel style={{ marginRight: 20 }}>{t('placeOrder.user_role') + '*'}</FormLabel>
                                        <RadioGroup row name="user_role" value={userInfo.user_role} onChange={(event) => handleBookingInfoChange(option_id, key, event.target)}>
                                            <FormControlLabel value={'member'} control={<Radio size="small" />} label={t('placeOrder.tour_member')} />
                                            <FormControlLabel value={'guide'} control={<Radio size="small" />} label={t('placeOrder.tour_guide')} />
                                            <FormControlLabel value={'leader'} control={<Radio size="small" />} label={t('placeOrder.tour_leader')} />
                                        </RadioGroup>
                                    </Box>
                                    {
                                        inputErrors && inputErrors['booking_info.' + option_id + '.' + key + '.user_role']
                                            ?
                                            <Typography variant="caption" color="error">{inputErrors['booking_info.' + option_id + '.' + key + '.user_role']}</Typography>
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        )
    }

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                {
                    (_.size(state.package.package_options) > 0) &&
                    _.map(state.package.package_options, (option) => {
                        if (option.status === 1) {
                            let stateOption = _.find(state.options, { id: option.id });
                            let option_name = state.package.name + ' - ' + stateOption.name;
                            if (stateOption.quantity > 0) {
                                return (
                                    <Box style={{marginBottom: 5}}>
                                        <Chip label={option_name} />
                                        {" " + t('packages.id') + ": " + option.id}
                                    </Box>
                                );
                            }
                        }
                    })
                }
                {
                    (_.size(state.package.add_on_packages) > 0) &&
                    _.map(state.package.add_on_packages, (add_on_package) => {
                        if (add_on_package.package.status === 1) {
                            return (
                                <>
                                    {
                                        _.map(add_on_package.package.package_options, (option) => {
                                            let option_name = '';
                                            let option_quantity = '';
                                            _.map(state.add_on_package, add_on => {
                                                let stateOption = _.find(add_on, { id: option.id });
                                                if (stateOption) {
                                                    option_name = t('placeOrder.addOnPackage') + ": " + add_on_package.package.name + ' - ' + stateOption.name;
                                                    option_quantity = stateOption.quantity;
                                                }
                                            })
                                            if (option_quantity > 0) {
                                                return (
                                                    <Box style={{ marginBottom: 5 }}>
                                                        <Chip label={option_name} />
                                                        {" " + t('packages.id') + ": " + option.id}
                                                    </Box>
                                                );
                                            }
                                        })
                                    }
                                </>
                            )
                        }
                    })
                }
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        variant="outlined"
                        label={t('placeOrder.bulkUpload')}
                        onChange={handleFiles}
                        type="file"
                        fullWidth
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{
                            accept: ".xlsx,.xlsm"
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Link href="/downloads/Customer_Info_Upload_Excel.xlsx" download underline="none">
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<MdAttachFile />}
                        >{t('placeOrder.downloadExcelTemplate')}</Button>
                    </Link>
                </Grid>
                {
                    (_.size(state.package.package_options) > 0) &&
                    _.map(state.package.package_options, (option) => {
                        if (option.status === 1) {
                            let stateOption = _.find(state.options, { id: option.id });
                            let option_name = state.package.name + ' - ' + stateOption.name;
                            if (stateOption.quantity > 0) {
                                return customerInfo(option.id, option_name, state.booking_info[option.id])
                            }
                        }
                    })
                }
                {
                    (_.size(state.package.add_on_packages) > 0) &&
                    _.map(state.package.add_on_packages, (add_on_package) => {
                        if (add_on_package.package.status === 1) {
                            return (
                                <>
                                    {
                                        _.map(add_on_package.package.package_options, (option) => {
                                            let option_name = '';
                                            let option_quantity = '';
                                            _.map(state.add_on_package, add_on => {
                                                let stateOption = _.find(add_on, { id: option.id });
                                                if (stateOption) {
                                                    option_name = t('placeOrder.addOnPackage') + ": " + add_on_package.package.name + ' - ' + stateOption.name;
                                                    option_quantity = stateOption.quantity;
                                                }
                                            })
                                            if (option_quantity > 0) {
                                                return customerInfo(option.id, option_name, state.booking_info[option.id])
                                            }
                                        })
                                    }
                                </>
                            )
                        }
                    })
                }
                <Grid item xs={12}>
                    <Box style={{ display: "flex", alignItems: "center" }} >
                        <Button variant="outlined" style={{ margin: "0px 10px" }} onClick={() => handleBackToSelectDate()}>{t('button.back')}</Button>
                        <Button variant="contained" style={{ margin: "0px 10px" }} onClick={() => addToCart('add-package')}>{t('placeOrder.addToCart')}</Button>
                        <Button variant="contained" style={{ margin: "0px 10px" }} onClick={() => addToCart('checkout')}>{t('placeOrder.checkout')}</Button>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default PlaceOrderStep3

function splitCSV(text) {
    var matches = text.match(/(\s*"[^"]+"\s*|\s*[^,]+|,)(?=,|$)/g);
    for (var n = 0; n < matches.length; ++n) {
        matches[n] = matches[n].trim();
        if (matches[n] == ',') matches[n] = '';
    }
    if (text[0] == ',') matches.unshift("");
    return matches;
}