import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import AddressEdit from './AddressEdit';
import BasicInfoProfile from './BasicInfoProfile';
import PasswordProfile from './PasswordProfile';
import BackToPrevious from '../Layout/BackToPrevious';
import MemberAgentEdit from './MemberAgentEdit';
import TwoFactorAuth from './TwoFactorAuth';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function Profile() {
    const styles = useStyles();
    const { t } = useTranslation();
    const { id } = useSelector(state => state.user);
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };
    const [roles, setRoles] = useState({});
    const { addAlert } = useNotificationLoading();

    useEffect(() => {
        getUrl(`/users/${id}/member-agent-details`).then(response => {
            if (response.status) {
                setRoles(response.data.roles);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id])

    return (
        <div>
            <BackToPrevious />
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.profile')}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard`}>
                                {t('title.dashboard')}
                            </Link>
                            <Typography color="text.primary">{t('title.profile')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label={t("user.basicInfo")}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={t("user.password")}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label={t("user.addresses")}
                                {...a11yProps(2)}
                            />
                            {/* <Tab
                                label={t("2fa.title")}
                                {...a11yProps(3)}
                            /> */}
                            <Tab
                                label={t("user.memberAgentDetail")}
                                {...a11yProps(3)}
                                disabled = {!_.includes(roles, 'member-agent')}
                            />
                        </Tabs>
                    </Box>
                </Box>
                <TabPanel value={tabValue} index={0}><BasicInfoProfile /></TabPanel>
                <TabPanel value={tabValue} index={1}><PasswordProfile /></TabPanel>
                <TabPanel value={tabValue} index={2}><AddressEdit /></TabPanel>
                {/* <TabPanel value={tabValue} index={3}><TwoFactorAuth /></TabPanel> */}
                <TabPanel value={tabValue} index={3}><MemberAgentEdit /></TabPanel>
            </div>
        </div >

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));