import { Editor } from '@tinymce/tinymce-react';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, InputAdornment, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "news";
const MODULE_SINGLE = "news";

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [state, setState] = useState({
        title: {},
        content: {},
        image: null,
        existing_image: ''
    });
    const [fieldLang, setFieldLang] = useState({ title: 'en', content: 'en' });
    const fieldLanguages = ['en', 'cn'];
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const location = useLocation();
    const formType = location.pathname === `/${MODULE_SINGLE}-add` ? "add" : "edit";

    useEffect(() => {
        isMountedRef.current = true;

        if (formType === 'edit' && id) {
            setLoading(true);
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setState({
                            ...state,
                            ...response.data,
                            image: null,
                            existing_image: response.data.image,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    const submitData = () => {
        let postData = {
            title: state.title,
            content: state.content,
            image: state.image,
            _method: formType === "edit" ? "put" : "post",
        };

        const formData = new FormData();
        buildFormData(formData, postData);

        setLoading(true);
        setInputErrors();
        postUrl(`/${MODULE}${formType === "edit" ? `/${id}` : ""}`, formData).then(response => {
            setLoading(false);
            let { status, data, errors, message } = response;
            addAlert('', message, status ? 'success' : 'error', '');
            if (status) {
                setState({
                    ...state,
                    existing_image: data.image,
                });

            } else {
                setInputErrors(errors);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={12}>
                    <TextField
                        fullWidth
                        id="standard-basic"
                        label={t('news.title')}
                        variant="outlined"
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                {
                                    _.map(fieldLanguages, (value) => (
                                        <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                    ))
                                }
                            </InputAdornment>,
                        }}
                        onChange={({ target }) => setState({ ...state, title: { ...state.title, [fieldLang.title]: target.value } })}
                        value={state.title && state.title[fieldLang.title] ? state.title[fieldLang.title] : ""}
                        helperText={inputErrors && (inputErrors['title'] || inputErrors['title.en'] || inputErrors['title.cn'])}
                        error={inputErrors && (inputErrors['title'] || inputErrors['title.en'] || inputErrors['title.cn']) ? true : false}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <FormControl variant="outlined" fullWidth size="small" error={inputErrors && (inputErrors['title'] || inputErrors['title.en'] || inputErrors['title.cn']) ? true : false}>
                        <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                            <Grid>
                                <Typography variant="subtitle2" component="label">{t('news.content')}</Typography>
                            </Grid>
                            <Grid>
                                {
                                    _.map(fieldLanguages, (value) => (
                                        <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, content: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.content === value ? theme.palette.primary.main : '#efefef', color: fieldLang.content === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Editor
                            apiKey="l6o3xjwfrfdguy9q8fxm5e0l6wxndodtq2uw5yjc2f2zpmj0"
                            value={state.content && state.content[fieldLang.content] ? state.content[fieldLang.content] : ""}
                            init={{
                                // height: "70vh",
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                // plugins: [
                                //     'advlist autolink lists link image charmap print preview anchor',
                                //     'searchreplace visualblocks code fullscreen',
                                //     'insertdatetime media table paste code help wordcount quickbars'
                                // ],
                                toolbar:
                                    'undo redo | formatselect | bold italic backcolor | quickimage image media | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | removeformat | help'
                            }}
                            onEditorChange={(content, editor) => setState({ ...state, content: { ...state.content, [fieldLang.content]: content } })}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="standard-basic"
                        label={t(`${MODULE}.image`)}
                        variant="outlined"
                        type="file"
                        inputProps={{ accept: 'image/*' }}
                        InputLabelProps={{ shrink: true }}
                        helperText={inputErrors && inputErrors.image ? inputErrors.image : t(`${MODULE}.recommendedImageSize`, { width: '460px', height: '300px' })}
                        error={inputErrors && inputErrors.image ? true : false}
                        onChange={({ target }) => setState({ ...state, image: target.files[0] })}
                    />
                    {state.existing_image && <img src={state.existing_image.file_name} alt={`${MODULE} img`} style={{ width: '100%', aspectRatio: '2/1.5', paddingTop: 10 }} />}
                </Grid>
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button type="button" size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15, color: theme.palette.button.main, border: theme.palette.button.borderMain, boxShadow: '2px 4px 8px 0 #9f9f9f45' }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main, boxShadow: '2px 4px 8px 0 #00000045' }} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}));