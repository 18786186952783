import { resetCodeState, resetProfile } from '../actions';
import { API } from '../configs/AxiosConfig';
import { store } from '../configs/Store';

export const removeLoginAccess = () => {
    store.dispatch(resetCodeState());
    store.dispatch(resetProfile());
}

const setHeader = () => {
    const accessToken = store.getState().general.accessToken;
    const language = store.getState().general.language;
    if(accessToken){
        API.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    }else{
        delete API.defaults.headers.common['Authorization'];
    }
    API.defaults.headers.common['Language'] = language === 'cn' ? 'cn' : 'en';
    API.defaults.headers.common['Site'] = 'Admin';
    return true;
}

export const getUrl = async (url, param) => {
    setHeader();
    let result = [];
    await API.get(url, {params: param})
    .then((response) => {
        console.log(url, response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log('error', error);
        console.log('error data', error.response.data);
        if(error.request.status === 401){
            removeLoginAccess();
        }else if(error.response.data){
            throw new Error(error.response.data.message || error);
        }else{
            throw new Error(error);
        }
    });
    return result;
}

export const postUrl = async (url, postData) => {
    setHeader();
    let result = [];
    await API.post(url, postData)
    .then((response) => {
        console.log(url, response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log(error);
        console.log('error data', error.response.data);
        if(error.request.status === 401){
            removeLoginAccess();
        }else if(error.response.data){
            throw new Error(error.response.data.message || error);
        }else{
            throw new Error(error);
        }
    });
    return result;
}

export const putUrl = async (url, putData) => {
    setHeader();
    let result = [];
    await API.put(url, putData)
    .then((response) => {
        console.log(url, response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log(error);
        console.log('error data', error.response.data);
        if(error.request.status === 401){
            removeLoginAccess();
        }else if(error.response.data){
            throw new Error(error.response.data.message || error);
        }else{
            throw new Error(error);
        }
    });
    return result;
}

export const deleteUrl = async (url) => {
    setHeader();
    let result = [];
    await API.delete(url)
    .then((response) => {
        console.log(response);
        if(response.status === 200) {
            result = response.data;
        }
    }).catch((error) => {
        console.log(error);
        console.log('error data', error.response.data);
        if(error.request.status === 401){
            removeLoginAccess();
        }else if(error.response.data){
            throw new Error(error.response.data.message || error);
        }else{
            throw new Error(error);
        }
    });
    return result;
}