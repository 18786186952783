import { makeStyles, useTheme } from '@mui/styles';
import _ from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useDropzone } from 'react-dropzone';

// MUI
import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography, ImageList, ImageListItem, ImageListItemBar, MenuItem, InputLabel, FormControl, Select } from '@mui/material';
import { IoAdd, IoTrashOutline } from "react-icons/io5";
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "banners";
const MODULE_SINGLE = "banner";

export default function Detail() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [state, setState] = useState({
        images: [],
        description: '',
        status: 1,
    });
    const [uploadImages, setUploadImages] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();

    const onDrop = useCallback(acceptedFiles => {
        setUploadImages(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    useEffect(() => {
        if (uploadImages) {
            if (FileReader) {
                _.map(uploadImages, (img, index) => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        setDisplayImages(prevState => (
                            [...prevState, fr.result]
                        ))
                    }
                    fr.readAsDataURL(img);
                    setState(prevState => ({
                        ...prevState,
                        images: [...prevState.images, img]
                    }))
                })
            }
        }
        // eslint-disable-next-line
    }, [uploadImages]);

    const removeImage = (index) => {
        let copyImages = [...state.images];
        let duplicateDisplayImages = [...displayImages];
        copyImages.splice(index, 1);
        duplicateDisplayImages.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            images: copyImages
        }))
        setDisplayImages(duplicateDisplayImages)
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        const formData = new FormData();
        buildFormData(formData, state);
        postUrl(`/${MODULE}/create`, formData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/${MODULE}`, { replace: true });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    };

    return (
        <div>
            <BackToPrevious />
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, color: theme.palette.gray.dtext }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                                {t(`title.${MODULE}`)}
                            </Link>
                            <Typography style={{ color: theme.palette.button.main }}>{t(`title.${MODULE_SINGLE}Add`)}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Box component="form" autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        {/* IMAGES */}
                        <Grid item xs={12} sm={12}>
                            {_.size(inputErrors) > 0 && inputErrors.images && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{inputErrors.images}</Typography>}
                            {_.size(inputErrors) > 0 && (Object.keys(state.images).some(key => inputErrors[`images.${key}`])) && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{t('error.uploadImageError', {size: '5MB'})}</Typography>}
                            <label htmlFor="banner-images-upload" className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                                <input accept="image/*" id="banner-images-upload" multiple type="file" style={{ display: "none" }} onChange={({ target }) => setUploadImages(target.files)} {...getInputProps()} />
                                <IoAdd style={{ fontSize: "3rem" }} />
                                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
                            </label>
                            <Typography style={{ fontSize: 14 }}>{t(`${MODULE}.recommendedSize`, { width: '3000px', height: '1026px' })}</Typography>
                            {
                                _.size(displayImages) > 0 ?
                                    <ImageList sx={{ width: "100%", height: 300, transform: "translateZ(0)", }} sm={{ overflow: "visible" }} rowHeight={200} gap={1} >
                                        {_.map(displayImages, (item, index) => {
                                            return (
                                                <ImageListItem key={index} cols={2} rows={1} style={{ position: "relative" }} >
                                                    <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeImage(index)} />
                                                    <img src={item} srcSet={item} alt={`uploaded_${index}`} loading="lazy" />
                                                    <ImageListItemBar sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top" actionPosition="left" />
                                                </ImageListItem>
                                            );
                                        })}
                                    </ImageList>
                                    : null
                            }
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    fullWidth
                                    id="standard-basic"
                                    label={t('banners.description')}
                                    variant="outlined"
                                    value={state.description}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.description ? inputErrors.description : ''}
                                    error={inputErrors && inputErrors.description ? true : false}
                                    onChange={({ target }) => setState({ ...state, description: target.value })}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                                    <Select
                                        value={state.status}
                                        label={t(`${MODULE}.status`)}
                                        name="status"
                                        onChange={({ target }) => setState({ ...state, status: target.value })}
                                    >
                                        {_.map([1, 0], statusType => {
                                            return <MenuItem key={statusType} value={statusType}>{t(`${MODULE}.status_${statusType}`)}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                            <Button type="button" size="large" variant="outlined" className={classes.buttonStyle} style={{ marginRight: 15, color: theme.palette.button.main, border: theme.palette.button.borderMain, boxShadow: '2px 4px 8px 0 #9f9f9f45' }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button type="submit" size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main, boxShadow: '2px 4px 8px 0 #00000045' }} className={classes.buttonStyle}>{t(`button.save`)}</Button>
                    </Box>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed #aeaeae",
        width: "100%",
        height: 100,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    }
}));