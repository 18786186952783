import {
    STORE_PROFILE,
    RESET_PROFILE,
    UPDATE_ADDRESSES,
} from '../actions/types';

const INITIAL_STATE = {
    id: null,
    name: '',
    first_name: '',
    last_name: '',
    username: '',
    email: '',
    mobile: '',
    addresses: [],
    profile_picture: '',
    two_factor_auth: '',
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, actions) => {
    switch (actions.type) {
        case STORE_PROFILE:
            return { 
                ...state,
                id: actions.payload.id,
                name: actions.payload.name,
                first_name: actions.payload.first_name,
                last_name: actions.payload.last_name,
                username: actions.payload.username,
                email: actions.payload.email,
                mobile: actions.payload.mobile,
                ic: actions.payload.ic,
                addresses: actions.payload.addresses,
                profile_picture: actions.payload.profile_picture,
                agency_id: actions.payload.agency_id ? actions.payload.agency_id : null,
                two_factor_auth: actions.payload.two_factor_auth,
            };
        case UPDATE_ADDRESSES:
            return { ...state, addresses: actions.payload };
        case RESET_PROFILE:
            return INITIAL_STATE;
        default:
            return state;
    }
}