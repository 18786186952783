import React from 'react';
import { Dialog, DialogActions, Typography, DialogContent, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { BsX } from "react-icons/bs";

const DeleteConfirmationModal = ({ open, closeDeleteDialog, submitData }) => {
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={closeDeleteDialog} fullWidth maxWidth="sm" >
            <DialogActions>
                <BsX onClick={closeDeleteDialog} style={{ cursor: 'pointer' }} scroll="true" size={30} />
            </DialogActions>
            <DialogContent>
                <Typography variant="h5" >{t('general.deleteDescription')}</Typography>
            </DialogContent>
            <DialogActions style={{ padding: 20 }}>
                <Button variant="outlined" onClick={closeDeleteDialog} style={{ marginRight: 5 }}>
                    <Typography variant="button">{t('button.cancel')}</Typography>
                </Button>
                <Button variant="contained" >
                    <Typography onClick={submitData} variant="button">{t('button.confirm')}</Typography>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DeleteConfirmationModal;