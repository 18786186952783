import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiDownload } from "react-icons/fi";
import { IoAdd, IoTrashOutline } from "react-icons/io5";

const UploadDocuments = (props) => {
    const { documents, setDocuments, preview, existingDocuments, setExistingDocuments, deleteDocuments, setDeleteDocuments } = props;
    const [uploadDocuments, setUploadDocuments] = useState([]);
    const [previewDocuments, setPreviewDocuments] = useState([]);

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();

    const onDrop = useCallback(acceptedFiles => {
        setUploadDocuments(acceptedFiles);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    useEffect(() => {
        if (uploadDocuments) {
            if (FileReader) {
                _.map(uploadDocuments, (doc, index) => {
                    var fr = new FileReader();
                    fr.readAsDataURL(doc);
                    setDocuments(prevState => (
                        [...prevState, doc]
                    ))
                })
            }
        }
        // eslint-disable-next-line
    }, [uploadDocuments]);

    const handlePreviewDocumentChange = (previewIndex, type) => {
        if (FileReader) {
            if(type === 'documents'){
                _.map(documents, (doc, index) => {
                    if (index === previewIndex) {
                        var fr = new FileReader();
                        fr.onload = function () {
                            setPreviewDocuments({
                                data: fr.result
                            })
                        }
                        fr.readAsDataURL(doc);
                    }
                })
            }
            if(type === 'existingDocuments'){
                _.map(existingDocuments, (doc, index) => {
                    if (index === previewIndex) {
                        setPreviewDocuments({
                            data: doc.file_name
                        })
                    }
                })
            }
        }
    }

    const removeDocument = (index) => {
        let copyDocuments = [...documents];
        copyDocuments.splice(index, 1);
        setDocuments(copyDocuments);
        setPreviewDocuments([]);
    };

    const removeExistingDocument = (index) => {
        let copyDocuments = [...existingDocuments];
        let existingDocumentId = copyDocuments[index].id;
        copyDocuments.splice(index, 1);
        setExistingDocuments(copyDocuments);
        setDeleteDocuments([...deleteDocuments, existingDocumentId]);
        setPreviewDocuments([]);
    };

    return (
        <>
            <label
                htmlFor="agency-documents-upload"
                className={classes.uploadMedia}
                style={{ color: theme.palette.gray.main, cursor: 'pointer' }}
                {...getRootProps()}
                component="div"
            >
                <input
                    accept="application/pdf"
                    id="agency-documents-upload"
                    multiple
                    type="file"
                    style={{ display: "none" }}
                    onChange={({ target }) => setUploadDocuments(target.files)}
                    {...getInputProps()}
                />
                <IoAdd style={{ fontSize: "3rem" }} />
                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
            </label>
            {
                _.size(documents) > 0 || _.size(existingDocuments) > 0 ?
                    <Box>
                        {_.size(existingDocuments) > 0 && _.map(existingDocuments, (doc, index) => {
                            return (
                                <Box key={index} style={{ justifyContent: "space-between", display: "flex" }}>
                                    {
                                        preview
                                            ?
                                            <Typography className={classes.documentTitleStyle} onClick={() => handlePreviewDocumentChange(index, 'existingDocuments')}>{doc.original_file_name} </Typography>
                                            :
                                            <Typography>{doc.original_file_name} </Typography>
                                    }
                                    <Box>
                                        <a href={doc.file_name} rel="noreferrer" target="_blank">
                                            <IconButton size="small"><FiDownload /></IconButton>
                                        </a>
                                        <IconButton size="small" onClick={() => removeExistingDocument(index)}><IoTrashOutline /></IconButton>
                                    </Box>
                                </Box>
                            );
                        })}
                        {_.size(documents) > 0 && _.map(documents, (doc, index) => {
                            return (
                                <Box key={index} style={{ justifyContent: "space-between", display: "flex" }}>
                                    {
                                        preview
                                            ?
                                            <Typography className={classes.documentTitleStyle} onClick={() => handlePreviewDocumentChange(index, 'documents')}>{doc.name} </Typography>
                                            :
                                            <Typography>{doc.name} </Typography>
                                    }
                                    <IconButton size="small" onClick={() => removeDocument(index)}><IoTrashOutline /></IconButton>
                                </Box>
                            );
                        })}
                        {preview && _.size(previewDocuments) > 0 ?
                            <iframe src={previewDocuments.data} title="preview" height="300px" width="450px" />
                            : null}
                    </Box>
                    : null
            }
        </>
    )
}

const useStyles = makeStyles(theme => ({
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed #aeaeae",
        width: "100%",
        height: 100,
        marginTop: 15,
        padding: 20,
    },
    documentTitleStyle: {
        cursor: 'pointer',
        "&:hover": {
            color: theme.palette.secondary.main
        },
    }
}))

export default UploadDocuments