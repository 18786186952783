import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "wallets";
const MODULE_SINGLE = "wallet";

const WalletTransactionReport = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [walletList, setWalletList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            status: 50,
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}/wallet_transaction`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.data);
                setRowCount(response.data.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        getUrl(`/${MODULE}`).then(result => {
            if (result.status === 1) {
                setWalletList(result.data);
            }
        }).catch((error) => {
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let newStatusList = [];
        newStatusList.push({ text: t(`${MODULE}.add`), value: "1" });
        newStatusList.push({ text: t(`${MODULE}.deduct`), value: "-1" });
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorFn: (row) => {
                return (i18n.language === 'cn' ? row.type_display[i18n.language] : row.type_display['en'])
            },
            id: 'type',
            header: `${t(`${MODULE}.walletTransactionType`)}`,
        },
        {
            accessorFn: (row) => (row.name ? row.name : '-'),
            id: 'name',
            header: `${t(`${MODULE}.username`)}`,
        },
        {
            accessorFn: (row) => {
                return (row.factor > 0 ? `${t(`${MODULE}.add`)}` : `${t(`${MODULE}.deduct`)}`)
            },
            id: 'action',
            header: `${t(`${MODULE}.action`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            enableSorting: false,
        },
        {
            accessorFn: (row) => {
                let text = '';
                _.map(row.transaction_details, trans => {
                    let name = '';
                    let decimal = 2;
                    if(trans.pay_mode === 'wallet'){
                        _.map(walletList, wallet => {
                            if(parseInt(wallet.id) === parseInt(trans.pay_code)){
                                name = wallet.wallet_name;
                                decimal = wallet.decimal;
                            }
                        })
                    }else{
                        name = trans.pay_mode;
                    }
                    let detail = (' '+name+' '+parseFloat(row.factor*trans.amount).toFixed(decimal));
                    text = text?(text+', '+detail):detail;
                })
                return (text ? text  : '-')
            },
            id: 'amount',
            header: `${t(`${MODULE}.amount`)}`,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, t, walletList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`reports.walletTransaction`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`reports.walletTransaction`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'WalletTransactionReport'}
                exportType={'table'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default WalletTransactionReport