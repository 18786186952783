import _ from 'lodash';
import Moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, IconButton, InputAdornment, Link, MenuItem, Select, TextField, Typography, FormControlLabel, FormGroup, Checkbox, FormHelperText } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';
import UploadDocuments from './UploadDocuments';

const MODULE = "agencies";
const MODULE_SINGLE = "agency";

const Create = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        name: '',
        slug: '',
        type: '',
        permission: [],
        mobile: '',
        email: '',
        register_no: '',
        public_holidays: [],
        profit_sharing: '',
        // address_name: '',
        // address1: '',
        // address2: '',
        // city: '',
        // state: '',
        // zip: '',
        // country: '',
        // status: 1,
        cover_photo: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bank_swift_code: '',
        first_name: '',
        last_name: '',
        username: '',
        user_email: '',
        user_mobile: '',
        ic: '',
        password: '',
        password_confirmation: '',
        showPassword: {
            password: false,
            password_confirmation: false,
        },
        profile_picture: '',
    });
    const [documents, setDocuments] = useState([]);
    const [agencyType, setAgencyType] = useState([]);
    const [agencyPermission, setAgencyPermission] = useState([]);

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const { role } = useSelector(state => state.general);

    useEffect(() => {
        setLoading(true);
        getUrl(`/${MODULE}/create`).then(response => {
            setLoading(false);
            if (response.status) {
                setAgencyType(response.data.agency_type);
                setAgencyPermission(response.data.agency_permission);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleClickShowPassword = (value) => {
        setState({
            ...state,
            showPassword: { ...state.showPassword, [value]: !state.showPassword[value] }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === "name") {
            const slug = _.kebabCase(value);
            setState({ ...state, "name": value, "slug": slug });
        } else if (name === "cover_photo") {
            setState({ ...state, [name]: target.files[0] });
        } else if (name === 'user_mobile' || name === 'zip') {
            setState({ ...state, [name]: !/^\s*$/.test(value) && !isNaN(value) ? value : "" });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const handleAgencyPermissionChange = ({ target }) => {
        const { name, checked } = target;
        let permission_array = state.permission;
        if(checked){
            permission_array.push(name);
        }else{
            permission_array = _.filter(permission_array, function (p) { return p !== name });
        }
        setState({ ...state, "permission": permission_array });
    }

    const handlePublicHolidayChange = (dates) => {
        let dateArray = [];
        _.map(dates, date => {
            dateArray.push(Moment([date.year, date.monthIndex, date.day]).format('YYYY-MM-DD'));
        })
        setState({ ...state, "public_holidays": dateArray });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();

        let postData = {
            ...state,
            documents: documents,
            cover_photo: state.cover_photo ? state.cover_photo : null,
            _method: "post",
        };
        const formData = new FormData();
        buildFormData(formData, postData);

        postUrl(`/${MODULE}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/${MODULE}/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.slug`)}
                            variant="outlined"
                            value={state.slug}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.slug ? inputErrors.slug : ''}
                            error={inputErrors && inputErrors.slug ? true : false}
                            name="slug"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`${MODULE}.type`)}</InputLabel>
                            <Select
                                value={state.type}
                                label={t(`${MODULE}.type`)}
                                name="type"
                                onChange={handleChange}
                            >
                                {_.map(agencyType, (type_translate, type) => {
                                    return <MenuItem key={type} value={type}>{i18n.language === 'cn'?type_translate.cn:type_translate.en}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: theme.palette.indicator.red }}>{inputErrors && inputErrors.type ? inputErrors.type : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormGroup>
                            <InputLabel>{t(`${MODULE}.permission`)}</InputLabel>
                            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                {_.map(agencyPermission, (permission_translate, permission) => (
                                    <Grid item xs={2} sm={4} md={4} key={permission}>
                                        <FormControlLabel control={<Checkbox />} label={permission_translate[i18n.language]} name={permission} onChange={handleAgencyPermissionChange} checked={_.includes(state.permission, permission)} />
                                    </Grid>
                                ))}
                            </Grid>
                            <FormHelperText style={{ color: theme.palette.indicator.red }}>{inputErrors && inputErrors.permission ? inputErrors.permission : ''}</FormHelperText>
                        </FormGroup>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.register_no`)}
                            variant="outlined"
                            value={state.register_no}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.register_no ? inputErrors.register_no : ''}
                            error={inputErrors && inputErrors.register_no ? true : false}
                            name="register_no"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={state.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.profitSharing`)}
                                    variant="outlined"
                                    value={state.profit_sharing}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.profit_sharing ? inputErrors.profit_sharing : ''}
                                    error={inputErrors && inputErrors.profit_sharing ? true : false}
                                    name="profit_sharing"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        :
                        null
                }
                {/* <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                            <Select
                                value={state.status}
                                label={t(`${MODULE}.status`)}
                                name="status"
                                onChange={handleChange}
                            >
                                {_.map([1, 0], statusType => {
                                    return <MenuItem key={statusType} value={statusType}>{t(`${MODULE}.statusList.${statusType}`)}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                </Grid> */}
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.coverPhoto`)}
                            type="file"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.cover_photo ? inputErrors.cover_photo : ''}
                            error={inputErrors && inputErrors.cover_photo ? true : false}
                            name="cover_photo"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`${MODULE}.public_holidays`)}</Typography>
                        <Typography variant="caption">{t(`${MODULE}.phNote`)}</Typography>
                        <DatePicker
                            sort
                            multiple
                            plugins={[
                                <DatePanel />
                            ]}
                            name="public_holidays"
                            value={state.public_holidays}
                            onChange={(dates) => handlePublicHolidayChange(dates)}
                            style={{ width: "100%", padding: "25px 20px" }}
                        />
                    </Box>
                </Grid>
                {/* BANK INFO */}
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`${MODULE}.bank_info`)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.bank_name`)}
                            variant="outlined"
                            value={state.bank_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bank_name ? inputErrors.bank_name : ''}
                            error={inputErrors && inputErrors.bank_name ? true : false}
                            name="bank_name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.account_name`)}
                            variant="outlined"
                            value={state.account_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.account_name ? inputErrors.account_name : ''}
                            error={inputErrors && inputErrors.account_name ? true : false}
                            name="account_name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.account_number`)}
                            variant="outlined"
                            value={state.account_number}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.account_number ? inputErrors.account_number : ''}
                            error={inputErrors && inputErrors.account_number ? true : false}
                            name="account_number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.bank_swift_code`)}
                            variant="outlined"
                            value={state.bank_swift_code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bank_swift_code ? inputErrors.bank_swift_code : ''}
                            error={inputErrors && inputErrors.bank_swift_code ? true : false}
                            name="bank_swift_code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`${MODULE}.documents`)}</Typography>
                        <UploadDocuments documents={documents} setDocuments={setDocuments} preview={true} />
                    </Box>
                </Grid>
                {/* ADD STAFF */}
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`title.managerAdd`)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.firstName')}
                            name="first_name"
                            variant="outlined"
                            type="text"
                            value={state.first_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.first_name ? inputErrors.first_name : ''}
                            error={inputErrors && inputErrors.first_name ? true : false}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.lastName')}
                            name="last_name"
                            variant="outlined"
                            type="text"
                            value={state.last_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.last_name ? inputErrors.last_name : ''}
                            error={inputErrors && inputErrors.last_name ? true : false}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.username')}
                            name="username"
                            variant="outlined"
                            type="text"
                            value={state.username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                            error={inputErrors && inputErrors.username ? true : false}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.ic')}
                            name="ic"
                            variant="outlined"
                            autoComplete="ic"
                            value={state.ic}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                            error={inputErrors && inputErrors.ic ? true : false}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.email')}
                            name="user_email"
                            variant="outlined"
                            type="email"
                            autoComplete="user_email"
                            value={state.user_email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_email ? inputErrors.user_email : ''}
                            error={inputErrors && inputErrors.user_email ? true : false}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.mobile')}
                            name="user_mobile"
                            variant="outlined"
                            autoComplete="user_mobile"
                            value={state.user_mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_mobile ? inputErrors.user_mobile : ''}
                            error={inputErrors && inputErrors.user_mobile ? true : false}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.password')}
                            name="password"
                            type={state.showPassword.password ? 'text' : 'password'}
                            value={state.password}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                            error={inputErrors && inputErrors.password ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('password')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t('user.confirmPassword')}
                            name="password_confirmation"
                            type={state.showPassword.password_confirmation ? 'text' : 'password'}
                            value={state.password_confirmation}
                            InputLabelProps={{ shrink: true }}
                            variant="outlined" helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                            error={inputErrors && inputErrors.password_confirmation ? true : false}
                            onChange={handleChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword('password_confirmation')}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {state.showPassword.password_confirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            {/* <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography variant="h6">{t(`${MODULE}.address`)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.address1`)}
                            variant="outlined"
                            value={state.address1}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.address1 ? inputErrors.address1 : ''}
                            error={inputErrors && inputErrors.address1 ? true : false}
                            name="address1"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.address2`)}
                            variant="outlined"
                            value={state.address2}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                            error={inputErrors && inputErrors.address2 ? true : false}
                            name="address2"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.city`)}
                            variant="outlined"
                            value={state.city}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                            error={inputErrors && inputErrors.city ? true : false}
                            name="city"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.state`)}
                            variant="outlined"
                            value={state.state}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                            error={inputErrors && inputErrors.state ? true : false}
                            name="state"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.zip`)}
                            variant="outlined"
                            value={state.zip}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                            error={inputErrors && inputErrors.zip ? true : false}
                            name="zip"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.country`)}
                            variant="outlined"
                            value={state.country}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                            error={inputErrors && inputErrors.country ? true : false}
                            name="country"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid> */}
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Create