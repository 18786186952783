import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Breadcrumbs, Grid, Link, Step, StepButton, Stepper, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../../helpers/ApiAction';
import { buildFormData } from '../../../helpers/Tools';
import useNotificationLoading from '../../../helpers/useNotificationLoading';
import BackToPrevious from '../../Layout/BackToPrevious';

import Info from './Info';
import Option from './Option';

const MODULE = "packages";
const MODULE_SINGLE = "package";

const tncPrefill = [
    "All tours are required to have a minimum of two (2) persons to go. Price rates shown are applicable for Malaysians & International.",
    "Itineraries, meals, transportation, etc. are subject to change without prior notice.",
    "Tour fee excludes travel insurance. It’s essential that you have comprehensive travel insurance before travel.",
    "The daily cutoff time is 8.00 p.m; bookings for the next day will not be accepted later than this time.",
    "No tours will be entertained if no booking confirmation is received from ReLuxe.",
    "Land transportation charges will be incurred. The charge for City Area Hotels is RM 30 per person, while for Outskirts Hotels (eg. Shangri-La Rasa Ria Resort/ Nexus Karambunai), the charge is RM 50 per person.",
    "ReLuxe transportation is based on Seat–In–Coach (SIC) basis. Guests are advised to wait at the designated place before the designated departure time.",
    "ReLuxe follows a strict \"On-time Policy\" to consider other guests. The transportation will stay at the designated location for 5 minutes only. ReLuxe reserves the right to deem any guest who shows up later than 5 minutes as forfeiting the booking by default. No refunds will be entertained for the booking deemed as forfeited.",
    "ReLuxe is not responsible for omissions, printing, and presentation errors in brochures or in any media where such information may be presented and reserves the right to correct them.",
    "ReLuxe will not be responsible for any additional expenses, loss of property, or injuries sustained during the trip, due to delays or changes in transport services, sickness, weather, strikes, war, or any other cause. Therefore, every person participating in ReLuxe tours shall assume their own risk.",
    "All pictures shown are for illustration purposes only.",
    "ReLuxe reserves the right to make changes to the Terms & Conditions at any time due to unforeseen circumstances without prior notice.",
    ". Cancellation & Return & Refund Policies apply in case of inclement weather and unforeseen circumstances. Refunds are limited to the following: a. Bad weather conditions; b. Natural disaster; c. Cancellation by ReLuxe.",
    "The act of registering for our packages indicates your consent for us to utilize your photos or videos in our marketing and promotional materials. Please view our Terms of Use for further information.",
    "Steps for Cancellation/Refund: In the event of No.13 Terms & Conditions occur, please follow these steps: 1. Send an email to ReLuxe customer service at booking@reluxeofficial.com and include your booking details. 2. Our customer service team will respond to your email with a cancellation/refund form. Please complete the form with your booking details, the reason for cancellation/refund, and payment information, and send it back to us via email. 3. After receiving your completed form, our customer service team will process your request and provide you with an update on the status of your cancellation/refund. You will be notified whether your request has been approved or denied, and if approved when you can expect to receive your refund. 4. If your request is approved, you will receive your refund via the same payment method used to make the original booking. Please note that the timeframe for receiving the refund may vary depending on the payment method and processing times. Please keep in mind that Return & Refund Policy may differ depending on the type of booking and the terms and conditions agreed upon at the time of booking. We recommend reviewing our Return & Refund Policy before making a booking with ReLuxe to avoid any confusion or unexpected fees.",
]

const Create = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const [completed, setCompleted] = useState({});
    const [state, setState] = useState({
        agency_id: '',
        category_id: '',
        location_id: '',
        type: 'tour',
        name: '',
        voucher_count: '1',
        valid_date_from: '',
        valid_date_to: '',
        v_available_days: [],
        v_except_date: [],
        duration_period: '1',
        description: {
            title: "",
            subtitle: "",
            body: "",
            points: [],
            tnc: tncPrefill,
            itinerary: [],
            note: [],
        },
        images: [],
        label:'',
        collection:[],
        youtube_video_url: '',
        youku_video_url: '',
        sequence: '',
        visible_to: [],
        add_on: 0,
    });
    const [agencyList, setAgencyList] = useState([]);
    const [agencyData, setAgencyData] = useState({});
    const [categoryList, setCategoryList] = useState([]);
    const [locationList, setLocationList] = useState([]);
    const [availableDaysList, setAvailableDaysList] = useState([]);
    const [uploadImages, setUploadImages] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);
    const [typeList, setTypeList] = useState([]);
    const [labelList, setLabelList] =useState([]);
    const [collectionList, setCollectionList] =useState([]);
    const [rank, setRank] = useState([]);
    const [allRank, setAllRank] = useState([]);
    const [collectionDialog, setCollectionDialog]=useState({
        open:false,
    })

    const INITIAL_OPTION_DATA = {
        name: '',
        // sell_price: '',
        // retail_price: '',
        price: {
            retail: '',
            ranks: { "0": { weekday: '', weekend: '', holiday: '' } },
            applyToAll: 0,
        },
        quantity: '',
        daily_limit: '',
        profit_sharing: '',
        description: '',
        status: '',
    };
    const [options, setOptions] = useState([
        { ...INITIAL_OPTION_DATA, name: "Adult (12 years and above)"},
        { ...INITIAL_OPTION_DATA, name: "Children (3 to 11 years)"}
    ]);

    const { t } = useTranslation();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();

    const steps = [t('packages.packageInfo'), t('packages.optionInfo')];

    useEffect(() => {
        getUrl(`/ranks`).then(response => {
            if (response.status) {
                let item = { weekday: '', weekend: '', holiday: '' };
                let value = {"0": item};
                let rankValue = {
                    "0": 
                    {
                        id: 0,
                        code: "0001",
                        name: "Normal Price",
                        display_name: {en: 'Normal Price', cn: '正常价格'},
                        status: 1,
                        status_display: {en: 'Active', cn: '活跃'},
                        created_at: "2023-03-23 11:53:12",
                        created_by: null,
                        updated_at: "2023-03-23 11:53:12",
                        updated_by: null,
                    }
                };

                let allRanking = rankValue;

                for (var i = 0; i < _.size(response.data.listing); i++) {
                    if (response.data.listing[i]['status'] === 1) {
                        value[response.data.listing[i]['id']] = item;
                        rankValue[response.data.listing[i]['id']] = response.data.listing[i]
                    }
                    allRanking[response.data.listing[i]['id']] = response.data.listing[i];
                }
                let tempOptions = options;
                _.map(tempOptions, (option, key) => {
                    tempOptions[key].price.ranks = Object.assign({}, value);
                })
                setOptions(tempOptions);
                setRank(rankValue);
                setAllRank(allRanking);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/${MODULE}/create`).then(response => {
            const { agency_list, category_list, location_list, available_days_list, type_list, label_list, collection_list } = response.data;
            setAgencyList(agency_list);
            setCategoryList(category_list);
            setLocationList(location_list);
            setAvailableDaysList(available_days_list);
            setTypeList(type_list);
            setLabelList(label_list);
            setCollectionList(collection_list);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    // STEPPER
    const handleNext = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        setActiveStep(activeStep + 1);
        setUploadImages([]);
    };
    const handleBack = () => {
        const newCompleted = completed;
        newCompleted[activeStep - 1] = false;
        setCompleted(newCompleted);
        setActiveStep(activeStep - 1);
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let newOptions = [];

        _.map(options, (option, key) => {
            newOptions[key] = {
                ...option,
                deduct_quantity: state.type === 'entrance' ? 1 : 0,
                quantity: state.type === 'entrance' ? option.quantity : 0,
                daily_limit: state.type === 'tour' ? option.daily_limit : 0,
            };
        });
        let postData = {
            ...state,
            location_id: state.location_id > 0 ? state.location_id : '',
            v_available_days: state.type === 'entrance' ? state.v_available_days : null,
            v_except_date: state.type === 'tour' ? state.v_except_date : null,
            duration_period: state.type === 'tour' ? state.duration_period : null,
            description: {
                title: state.description.title,
                subtitle: state.description.subtitle,
                body: state.description.body,
                points: _.size(state.description.points)>0?state.description.points:null,
                tnc: _.size(state.description.tnc)>0?state.description.tnc:null,
                itinerary: _.size(state.description.itinerary)>0?state.description.itinerary:null,
                note: _.size(state.description.note)>0?state.description.note:null,
            },
            options: newOptions,
            _method: "post",
            label: state.label,
            collection:state.collection,
            visible_to: state.visible_to ? state.visible_to : null,
            add_on: state.add_on,
        }
        const formData = new FormData();
        buildFormData(formData, postData);

        postUrl(`/${MODULE}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/${MODULE}/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', t('error.createError'), 'error', '');
                let packageError = false;
                _.map(errors, (err, key) => {
                    if (!key.includes('options')) {
                        packageError = true;
                    }
                })
                if (packageError) {
                    handleBack();
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const closeCollectionDialog = () => {
        setCollectionDialog({ ...collectionDialog, open: false });
        setState({ ...state, collection: [] });
    }

    const completeCollectionDialog = () => {
        setCollectionDialog({ ...collectionDialog, open: false });
    }

    const deleteCollectionChip = (id) => {
        const listing = [...state.collection];
        listing.splice(listing.indexOf(id), 1);
        setState({ ...state, collection: listing });
    }

    const clearCollection = () =>{
        setState({...state, collection:[]});
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            {/* STEPPER */}
            <Stepper nonLinear activeStep={activeStep} style={{ padding: 20 }}>
                {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit">
                            {label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            {
                activeStep === 0 &&
                <Info agencyList={agencyList} options={options} setOptions={setOptions} setAgencyData={setAgencyData} categoryList={categoryList} locationList={locationList} availableDaysList={availableDaysList} typeList={typeList} state={state} setState={setState} uploadImages={uploadImages} setUploadImages={setUploadImages} displayImages={displayImages} setDisplayImages={setDisplayImages} inputErrors={inputErrors} handleNext={handleNext} labelList={labelList} collectionDialog={collectionDialog} setCollectionDialog={setCollectionDialog} closeCollectionDialog={closeCollectionDialog} completeCollectionDialog={completeCollectionDialog} collectionList={collectionList} deleteCollectionChip={deleteCollectionChip} clearCollection={clearCollection} />
            }
            {
                activeStep === 1 &&
                <Option options={options} setOptions={setOptions} agencyData={agencyData} rank={rank} allRank={allRank} INITIAL_OPTION_DATA={INITIAL_OPTION_DATA} inputErrors={inputErrors} handleBack={handleBack} submitData={submitData} packageType={state.type} />
            }
        </>
    )
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
}))

export default Create