import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { AddBoxOutlined } from '@mui/icons-material';
import { Grid, Typography, Link, Breadcrumbs, Box, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { IoPencil, IoTrashOutline } from "react-icons/io5";

import { getUrl, deleteUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import CustomizeMaterialReactTable from '../Layout/CustomizeMaterialReactTable';

const MODULE = "currencies";
const MODULE_SINGLE = "currency";

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [statusList, setStatusList] = useState([]);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [deleteCode, setDeleteCode] = useState("");

    const callApi = () => {
        setTableLoading(true);
        getUrl(`/${MODULE}`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }
    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setStatusList([
            { text: `${t(`${MODULE}.status.1`)}`, value: `${t(`${MODULE}.status.1`)}` },
            { text: `${t(`${MODULE}.status.0`)}`, value: `${t(`${MODULE}.status.0`)}` },
        ]);
        // eslint-disable-next-line
    }, [i18n.language]);

    const deleteCurrency = () => {
        setLoading(true);
        deleteUrl(`/${MODULE}/${deleteCode}`)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.deleteSuccess'), 'success', '');
                    callApi();
                } else {
                    addAlert('', t('error.deleteError'), 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        setDeleteCode("");
    };

    const columns = useMemo(() => ([
        {
            accessorKey: 'code',
            id: 'code',
            header: `${t(`${MODULE}.code`)}`,
        },
        {
            accessorKey: 'name',
            header: `${t(`${MODULE}.name`)}`,
            minSize: '250',
            Cell: ({ cell }) => (cell.row.original.name['en'] + "|" + cell.row.original.name['cn']),
        },
        {
            accessorKey: 'symbol',
            header: `${t(`${MODULE}.symbol`)}`,
            minSize: '250',
        },
        {
            accessorKey: 'rate',
            header: `${t(`${MODULE}.rate`)}`,
            minSize: '250',
        },
        {
            accessorFn: (row) => (`${t(`${MODULE}.status.` + row.status)}`),
            id: 'status',
            header: `${t(`${MODULE}.status.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
        },
        {
            id: 'action',
            header: `${t(`table.actions`)}`,
            enableColumnOrdering: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Link underline='none' to={`/${MODULE}/${cell.row.original.code}`} component={RouterLink}>
                            <IconButton><IoPencil size={20} /></IconButton>
                        </Link>
                        <IconButton onClick={() => setDeleteCode(cell.row.original.code)}><IoTrashOutline size={20} /></IconButton>
                    </Box>
                    :
                    null
            ),
            maxSize: '100'
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <CustomizeMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'CurrencyList'}
            />
            <Dialog
                open={deleteCode !== "" ? true : false}
                onClose={() => setDeleteCode("")}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t(`${MODULE}.confirmationOnDelete`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`${MODULE}.askDeleteCurrency`)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setDeleteCode("")} color="secondary">{t('button.cancel')}</Button>
                    <Button variant='contained' onClick={deleteCurrency} color="primary" autoFocus>{t('button.confirm')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Listing