import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "order-settlement";

const Listing = () => {
    const [data, setData] = useState([]);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions, role } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [rowSelection, setRowSelection] = useState({});

    const [state, setState] = useState({
        open: false,
        status: '',
        receipt: null,
        ordersNo: '',
        total_amount: 0,
        type: '',
    });
    const [inputErrors, setInputErrors] = useState([]);
    const [refreshData, setRefreshData] = useState(false);
    const [paymentInfo, setPaymentInfo] = useState({
        open: false,
        ordersNo: '',
        total_amount: 0,
    });

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData, refreshData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, status => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: (status.code).toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'order_no',
            header: `${t(`orders.orderNo`)}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `order-edit`) ?
                    <Link underline='none' to={`/orders/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.order_no}</Link>
                    :
                    <>{cell.row.original.order_no}</>
            )
        },
        {
            accessorFn: (row) => (row.user ? row.user.name : '-'),
            id: 'user.name',
            header: `${t(`orders.user`)}`,
            enableSorting: false,
        },
        {
            accessorFn: (row) => ("RM " + row.total_amount),
            id: 'total_amount',
            header: `${t(`orders.totalAmount`)}`,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    const updateStatus = () => {
        setLoading(true);
        setInputErrors({});
        let apiData = {
            _method: "put",
            status: state.status,
            receipt: state.receipt,
            order_ids: _.keys(rowSelection),
        }
        const formData = new FormData();
        buildFormData(formData, apiData);
        postUrl(`/${MODULE}/` + state.type, formData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                handleDialogClose();
                handlePaymentDialogClose();
                setRefreshData(!refreshData);
                setRowSelection({});
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const revenuemonsterPayment = () => {
        setLoading(true);
        let apiData = {
            _method: "post",
            order_ids: _.keys(rowSelection),
        }
        const formData = new FormData();
        buildFormData(formData, apiData);
        postUrl(`/payments/order-settlement/revenuemonster`, formData).then(response => {
            setLoading(false);
            if (response.status) {
                // redirect to payment page
                if (response.data.code === 'SUCCESS' && response.data.item.url) {
                    window.location.href = response.data.item.url;
                }
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const topActionsButton = () => {
        return (
            <>
                {
                    _.includes(permissions, "order-settlement-status")
                        ?
                        <Button variant="contained" onClick={() => handleDialogOpen()} disabled={_.size(rowSelection) > 0 ? false : true}>
                            {t('orders.uploadReceipt')}
                        </Button>
                        :
                        null
                }
                {
                    _.size(_.intersection(role, ["member-agent"])) > 0
                        ?
                        <Button variant="contained" onClick={() => handlePaymentDialogOpen()} disabled={_.size(rowSelection) > 0 ? false : true}>
                            {t('orders.payNow')}
                        </Button>
                        :
                        null
                }
            </>
        )
    }

    const handleDialogOpen = () => {
        // let status = '';
        let ordersNo = '';
        let totalAmount = 0;
        _.map(data, (order, key) => {
            if (_.includes(_.keys(rowSelection), (order.id).toString())) {
                // status = (order.settlement).toString();
                ordersNo = ordersNo ? ordersNo + ', ' + order.order_no : order.order_no;
                totalAmount += parseFloat(order.total_amount);
            }
        })
        setState({
            open: true,
            status: '2',
            receipt: null,
            ordersNo: ordersNo,
            total_amount: totalAmount,
            type: _.includes(permissions, `order-settlement-status`) ? 'status' : 'receipt'
        });
    }
    const handleDialogClose = () => {
        setInputErrors({});
        setState({
            open: false,
            status: '',
            receipt: null,
            ordersNo: '',
            total_amount: 0,
            type: '',
        });
    }
    const updateStatusDialog = () => {
        return (
            <Dialog
                open={state.open}
                onClose={() => handleDialogClose()}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{t(`orders.updateStatus`)}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('orders.orderNo') + ': ' + state.ordersNo}</Typography>
                            <Typography>{t('orders.totalAmount') + ': RM ' + parseFloat(state.total_amount).toFixed(2)}</Typography>
                            <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.order_ids ? inputErrors.order_ids : ''}</FormHelperText>
                        </Grid>
                        {
                            state.type === 'status'
                                ?
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <InputLabel>{t('user.status')}</InputLabel>
                                        <Select
                                            value={state.status}
                                            label={t('order.status')}
                                            error={inputErrors && inputErrors.status ? true : false}
                                            onChange={({ target }) => setState({ ...state, status: target.value })}
                                        >
                                            {
                                                _.map(statusList, (statusType, statusIndex) => {
                                                    if (statusType.value !== '0') {
                                                        return <MenuItem key={statusIndex} value={statusType.value}>{statusType.text}</MenuItem>
                                                    }
                                                })
                                            }
                                        </Select>
                                        <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                :
                                null
                        }
                        {
                            state.status === '2'
                                ?
                                <Grid item xs={12}>
                                    <TextField
                                        label={t('orders.receipt')}
                                        type="file"
                                        variant="outlined"
                                        helperText={inputErrors && inputErrors.receipt ? inputErrors.receipt : ''}
                                        error={inputErrors && inputErrors.receipt ? true : false}
                                        onChange={({ target }) => setState({ ...state, receipt: target.files[0] })}
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose()} variant="outlined">{t('button.cancel')}</Button>
                    <Button onClick={() => updateStatus()} variant="contained">{t('button.update')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handlePaymentDialogOpen = () => {
        let ordersNo = '';
        let totalAmount = 0;
        _.map(data, (order, key) => {
            if (_.includes(_.keys(rowSelection), (order.id).toString())) {
                ordersNo = ordersNo ? ordersNo + ', ' + order.order_no : order.order_no;
                totalAmount += parseFloat(order.total_amount);
            }
        })
        setPaymentInfo({
            open: true,
            ordersNo: ordersNo,
            total_amount: totalAmount,
        });
    }
    const handlePaymentDialogClose = () => {
        setPaymentInfo({
            open: false,
            ordersNo: '',
            total_amount: 0,
        });
    }
    const updatePaymentDialog = () => {
        return (
            <Dialog
                open={paymentInfo.open}
                onClose={() => handlePaymentDialogClose()}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{t(`orders.payNow`)}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('orders.orderNo') + ': ' + paymentInfo.ordersNo}</Typography>
                            <Typography>{t('orders.totalAmount') + ': RM ' + parseFloat(paymentInfo.total_amount).toFixed(2)}</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handlePaymentDialogClose()} variant="outlined">{t('button.cancel')}</Button>
                    <Button onClick={() => revenuemonsterPayment()} variant="contained">{t('orders.payNowFPX')}</Button>
                    <Button onClick={() => handleDialogOpen()} variant="contained">{t('orders.uploadReceipt')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'OrderSettlementList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                enableRowSelection={(row) => row.original.settlement == 1}
                onRowSelectionChange={setRowSelection}
                rowSelection={rowSelection}
                getRowId={(row) => row.id}
                topActions={topActionsButton()}
                enableSelectAll={true}
            />
            {updateStatusDialog()}
            {updatePaymentDialog()}
        </>
    )
}

export default Listing