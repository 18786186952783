import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import { Link, TextField, Box, Button, FormGroup, FormControlLabel, Checkbox, Grid, Typography, FormControl, Select, InputLabel, MenuItem, FormHelperText } from '@mui/material';

export default function BasicInfoEdit(props) {
    const { setValidMemberAgentDetail } = props;
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        // name: '',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        mobile: '',
        ic: '',
        status: 0,
        role_type: [],
        profile_picture: '',
        existing_image: '',
        wallet_limit: '',
        lock_period: '',
        two_factor_auth: '',
    });
    const [roles, setRoles] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const [permissions, setPermissions] = useState([]);
    const [allPermissions, setAllPermissions] = useState([]);
    const [rolePermission, setRolePermission] = useState([]);
    const [memberPermission, setMemberPermission] = useState([]);
    const [statusList, setStautsList] = useState({});
    let navigate = useNavigate();
    const editor_permissions = useSelector(state => state.general.permissions);
    const [disabledAuthenticator, setDisabledAuthenticator] = useState(false);

    useEffect(() => {
        setLoading(true);
        getUrl('/users/members/options').then(response => {
            setLoading(false);
            if (response.status) {
                setRoles(response.data.role_list);
                setStautsList(response.data.status_list);
                // setPermissions(response.data.permission_by_roles);
                // setAllPermissions(response.data.all_permissions);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setLoading(true);

        getUrl(`/users/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                if (response.data.roleType === "members") {
                    let listing = [];
                    setMemberPermission(response.data.directPermissions);
                    if (_.size(response.data.roles) > 0) {
                        _.map(response.data.roles, (roleList, index) => {
                            listing[index] = roleList.name;
                        })
                    }
                    setState({
                        ...state,
                        name: response.data.name,
                        firstName: response.data.first_name,
                        lastName: response.data.last_name,
                        username: response.data.username,
                        email: response.data.email,
                        mobile: response.data.mobile,
                        ic: response.data.ic,
                        status: response.data.status,
                        existing_image: response.data.profile_picture,
                        role_type: listing,
                        wallet_limit: response.wallet_limit,
                        lock_period: response.data.lock_period,
                        two_factor_auth: response?.data?.two_factor_auth || "",
                    });
                } else {
                    navigate(`/dashboard`, { replace: true });
                }
            } else {
                addAlert('', t('error.contactSupport') + response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        // eslint-disable-next-line
    }, [addAlert, id])

    useEffect(() => {
        let permissionList = [];

        if (_.size(state.role_type) > 0) {
            _.map(state.role_type, (roleList) => {
                let permissionDetail = permissions[roleList];
                if (_.size(permissionDetail) > 0) {
                    _.map(permissionDetail, detail => {
                        permissionList.push(detail);
                    })
                }
                setRolePermission(permissionList);
            });
        } else {
            setRolePermission([]);
        }
        // eslint-disable-next-line
    }, [state.role_type, permissions]);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'zip') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }

        setState({ ...state, [name]: value });
    };

    const authorizeRole = roleName => {
        let roleAuthorize = state.role_type;
        // let permissionList = [];

        if (_.includes(roleAuthorize, roleName)) {

            roleAuthorize = _.difference(roleAuthorize, [roleName]);
        } else {
            roleAuthorize.push(roleName);
        }

        // if (_.size(roleAuthorize) > 0) {
        //     _.map(roleAuthorize, (roleList) => {
        //         let permissionDetail = permissions[roleList];
        //         if (_.size(permissionDetail) > 0) {
        //             _.map(permissionDetail, detail => {
        //                 permissionList.push(detail);
        //             })
        //         }
        //         setRolePermission(permissionList);
        //     });
        // } else {
        //     setRolePermission([]);
        // }

        if(!_.includes(roleAuthorize, 'member-agent')){
            setState({ ...state, role_type: roleAuthorize, discount_rate:0 });
        }else{
            setState({ ...state, role_type: roleAuthorize });
        }
    }

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        const formData = new FormData();
        formData.append("_method", "put");
        if (state.profile_picture) {
            formData.append("profile_picture", state.profile_picture);
        }
        // formData.append("name", state.name);
        formData.append("first_name", state.firstName);
        formData.append("last_name", state.lastName);
        formData.append("username", state.username);
        formData.append("email", state.email);
        formData.append("mobile", state.mobile || "");
        formData.append("ic", state.ic || "");
        formData.append("status", state.status);
        // formData.append("wallet_id", 1);
        formData.append("wallet_limit", state.wallet_limit);
        formData.append("lock_period", state.lock_period || "");
        _.each(state.role_type, (val, key) => {
            formData.append(`role_type[${key}]`, val);
        });
        _.each(memberPermission, (val, key) => {
            formData.append(`permissions[${key}]`, val);
        });
        postUrl(`/users/${id}`, formData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
                setValidMemberAgentDetail(true);
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // const changeAuthorize = name => {
    //     let permissionList = memberPermission;
    //     const checkpermission = permissionList.indexOf(name);

    //     if (checkpermission !== -1) {
    //         permissionList.splice(checkpermission, 1);
    //     } else {
    //         permissionList.push(name);
    //     }

    //     setmemberPermission(permissionList => ([...permissionList]));
    // }

    const disable2FA = async () => {
        setLoading(true);
        postUrl(`admin-disable2fa/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setDisabledAuthenticator(true);
                let msg = t('user.disable2FASuccess');
                addAlert('', msg, 'success', '');
            } else {
                let msg = response.message;
                addAlert('', msg, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            let msg = error + "\n" + t('error.contactSupport');
            addAlert('', msg, 'error', '');
        });
    }

    return (
        <div>
            <div style={{ paddingBottom: '5%' }}>
                <Box component="form" autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.name')}
                                name="name"
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.firstName')}
                                name="firstName"
                                variant="outlined"
                                type="text"
                                value={state.firstName}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.first_name ? inputErrors.first_name : ''}
                                error={inputErrors && inputErrors.first_name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.lastName')}
                                name="lastName"
                                variant="outlined"
                                type="text"
                                value={state.lastName}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.last_name ? inputErrors.last_name : ''}
                                error={inputErrors && inputErrors.last_name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.username')}
                                name="username"
                                variant="outlined"
                                type="text"
                                value={state.username}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.ic')}
                                name="ic"
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.email')}
                                name="email"
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.mobile')}
                                name="mobile"
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile || ''}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} style={{ marginTop: 8 }}>
                            <FormControl fullWidth style={{ marginLeft: 8 }}>
                                <InputLabel id="demo-simple-select-label">{t('user.status')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.status}
                                    label={t('user.status')}
                                    error={inputErrors && inputErrors.status ? true : false}
                                    onChange={({ target }) => setState({ ...state, status: target.value })}
                                >
                                    {
                                        _.map(statusList, (statusType, statusIndex) => {
                                            return <MenuItem key={statusIndex} value={statusIndex}>{statusType[i18n.language]}</MenuItem>
                                        })
                                    }
                                </Select>
                                <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            {state.existing_image && <img src={state.existing_image.file_name} alt="profile img" style={{ width: 'auto', height: 150, paddingLeft: 10 }} />}
                            <TextField
                                label={t('user.profilePicture')}
                                type="file"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.profile_picture ? inputErrors.profile_picture : ''}
                                error={inputErrors && inputErrors.profile_picture ? true : false}
                                onChange={({ target }) => setState({ ...state, profile_picture: target.files[0] })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.postpaidLimit')}
                                name="wallet_limit"
                                variant="outlined"
                                value={state.wallet_limit || ''}
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.wallet_limit ? inputErrors.wallet_limit : ''}
                                error={inputErrors && inputErrors.wallet_limit ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        {
                            _.includes(editor_permissions, "order-lock")
                                ?
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={t('user.lockPeriod')}
                                        name="lock_period"
                                        variant="outlined"
                                        value={state.lock_period || ''}
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.lock_period ? inputErrors.lock_period : ''}
                                        error={inputErrors && inputErrors.lock_period ? true : false}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                :
                                null
                        }
                        {/* <Grid item xs={12} sm={6}>
                            <Typography variant="body2" color="textPrimary" style={{ paddingBottom: '10px' }}>
                                {state.two_factor_auth === 1 ? t('user.2FAActive') : t('user.2FAInactive')}
                            </Typography>
                            <Button variant="contained" onClick={disable2FA} disabled={(state.two_factor_auth === 1 && !disabledAuthenticator) ? false : true}>
                                <Typography variant="button">{t('user.disable2FA')}</Typography>
                            </Button>
                        </Grid> */}
                        <Grid item xs={12} >
                            <div style={{ paddingBottom: 15 }}>
                                <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('title.roles')}</b></Typography>
                                <FormGroup>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {Array.from(roles).map((list) => (
                                            <Grid item xs={2} sm={4} md={4} key={list.name}>
                                                <FormControlLabel control={<Checkbox />} label={list.display_name[i18n.language]} onChange={() => authorizeRole(list.name)} checked={_.includes(state.role_type, list.name)} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {inputErrors && inputErrors.role_type && <FormHelperText style={{ color: 'red' }}>{inputErrors.role_type}</FormHelperText>}
                                </FormGroup>
                            </div>
                        </Grid>
                        {/* {
                            _.size(state.role_type) > 0 &&
                            <Grid item xs={12} >
                                <div style={{ paddingBottom: 15 }}>
                                    <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('title.permissions')}</b></Typography>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container>
                                            {
                                                _.size(allPermissions) > 0 &&
                                                _.map(allPermissions, listing => {
                                                    let selectedPermission = false;
                                                    let memberSelectedPermission = false;
                                                    if (_.size(rolePermission) > 0) {
                                                        _.map(rolePermission, list => {
                                                            if (listing.name === list) {
                                                                selectedPermission = true;
                                                            }
                                                        });
                                                    }
                                                    if (_.size(memberPermission) > 0) {
                                                        _.map(memberPermission, member => {
                                                            if (listing.name === member) {
                                                                memberSelectedPermission = true;
                                                            }
                                                        });
                                                    }
                                                    if (listing.authorize) {
                                                        return (
                                                            <Grid item md={6} key={listing.id}>
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={selectedPermission ? true : (memberSelectedPermission ? true : false)} color="primary" onChange={() => changeAuthorize(listing.name)} disabled={selectedPermission} />}
                                                                    label={listing.name}
                                                                />
                                                                {
                                                                    _.size(listing.children) > 0 && _.map(listing.children, child => {
                                                                        let selectedChildPermission = false;
                                                                        let memberSelectedChildPermission = false;
                                                                        if (_.size(rolePermission) > 0) {
                                                                            _.map(rolePermission, list => {
                                                                                if (child.name === list) {
                                                                                    selectedChildPermission = true;
                                                                                }
                                                                            });
                                                                        }
                                                                        if (_.size(memberPermission) > 0) {
                                                                            _.map(memberPermission, memberChild => {
                                                                                if (child.name === memberChild) {
                                                                                    memberSelectedChildPermission = true;
                                                                                }
                                                                            });
                                                                        }
                                                                        if (child.authorize) {
                                                                            return (
                                                                                <Box key={child.id} spacing={2} paddingLeft={5}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox checked={selectedChildPermission ? true : (memberSelectedChildPermission ? true : false)} color="primary" onChange={() => changeAuthorize(child.name)} disabled={selectedChildPermission} />}
                                                                                        label={child.name}
                                                                                    />
                                                                                </Box>
                                                                            )
                                                                        } else { return null; }
                                                                    })
                                                                }
                                                            </Grid>
                                                        )
                                                    } else { return null; }
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </div>
                            </Grid>
                        } */}
                    </Grid>
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/members`} component={RouterLink}>
                            <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" className={styles.buttonStyle} type="submit" >{t('button.save')}</Button>
                    </Box>
                </Box>
            </div>
        </div >

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));