import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { AddBoxOutlined, Delete } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, TextField, Typography, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const MODULE = "packages";
const MODULE_SINGLE = "package";

const CreateOption = (props) => {
    const { options, setOptions, agencyData, rank, allRank, INITIAL_OPTION_DATA, handleBack, inputErrors, submitData, packageType } = props;

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { role } = useSelector(state => state.general);

    const handleChange = (target, key) => {
        const { name, value } = target;
        setOptions({ ...options, [key]: { ...options[key], [name]: value } });
    }

    const handlePriceChange = (target, key, index) => {
        const { name, value } = target;

        if (name === 'retail') {
            setOptions({ ...options, [key]: { ...options[key], price: { ...options[key].price, [name]: value } } });
        } else {
            if (options[key].price.applyToAll === 1) {
                if (name !== 'weekday') {
                    setOptions({ ...options, [key]: { ...options[key], price: { ...options[key].price, applyToAll: 0, ranks: { ...options[key].price.ranks, [index]: { ...options[key].price.ranks[index], [name]: value } } } } });
                } else {
                    setOptions({ ...options, [key]: { ...options[key], price: { ...options[key].price, ranks: { ...options[key].price.ranks, [index]: { weekday: value, weekend: value, holiday: value } } } } });
                }
            } else {
                setOptions({ ...options, [key]: { ...options[key], price: { ...options[key].price, ranks: { ...options[key].price.ranks, [index]: { ...options[key].price.ranks[index], [name]: value } } } } });
            }
        }
    }

    const handleNewOption = () => {
        let newKey = _.size(options);
        let item = { weekday: '', weekend: '', holiday: '' };
        let temp_item = [item];

        for (var i = 0; i < _.size(allRank); i++) {
            if (allRank[i].status) {
                temp_item[rank[i].id] = item;
            }
        }

        setOptions({ ...options, [newKey]: { ...options[0], name: '', quantity: '', daily_limit: '', profit_sharing: agencyData ? agencyData.profit_sharing : '', description: '', price: { ...options[0].price, retail: '', applyToAll: 0, ranks: Object.assign({}, temp_item) } } });
    }

    const handleDeleteOption = (key) => {
        let newOptions = _.filter(options, function (o, index) { return index !== key });
        setOptions(newOptions);
    }

    const handleCheckChange = (target, key) => {
        setOptions({ ...options, [key]: { ...options[key], price: { ...options[key].price, applyToAll: target.checked ? 1 : 0 } } });

        let temp_item = options[key].price;

        if (target.checked === true) {
            _.map(options[key].price.ranks, (price_ranks, key) => {
                temp_item.ranks[key].weekend = temp_item.ranks[key].holiday = price_ranks.weekday;
            })
        }
    };

    return (
        <>
            {
                _.map(options, (option, key) => {
                    return (
                        <Grid container spacing={1} style={{ paddingBottom: '5%' }} key={key}>
                            <Grid item xs={12}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} display="flex" alignItems="center">
                                    <Box><Typography variant="h6" style={{ fontWeight: "bold" }}>{t('packages.option') + ' ' + (parseInt(key) + 1)}</Typography></Box>
                                    {
                                        _.size(options) > 1
                                            ?
                                            <Box display="flex" alignItems="center" justifyContent="flex-end">
                                                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeleteOption(key)}>
                                                    <Delete />
                                                </IconButton>
                                            </Box>
                                            : null
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={6} sm={4.5}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={`${t(`${MODULE}.name`)}*`}
                                        variant="outlined"
                                        value={option.name}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors['options.' + key + '.name'] ? inputErrors['options.' + key + '.name'] : ''}
                                        error={inputErrors && inputErrors['options.' + key + '.name'] ? true : false}
                                        name="name"
                                        onChange={(event) => handleChange(event.target, key)}
                                    />
                                </Box>
                            </Grid>
                            {
                                packageType === 'entrance'
                                    ?
                                    <Grid item xs={6} sm={2.5}>
                                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                            <TextField
                                                label={`${t(`${MODULE}.quantity`)}*`}
                                                type="number"
                                                variant="outlined"
                                                value={option.quantity}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors['options.' + key + '.quantity'] ? inputErrors['options.' + key + '.quantity'] : ''}
                                                error={inputErrors && inputErrors['options.' + key + '.quantity'] ? true : false}
                                                name="quantity"
                                                onChange={(event) => handleChange(event.target, key)}
                                            />
                                        </Box>
                                    </Grid>
                                    :
                                    null
                            }
                            {
                                packageType === 'tour'
                                    ?
                                    <Grid item xs={6} sm={2.5}>
                                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                            <TextField
                                                label={`${t(`${MODULE}.daily_limit`)}*`}
                                                type="number"
                                                variant="outlined"
                                                value={option.daily_limit}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors['options.' + key + '.daily_limit'] ? inputErrors['options.' + key + '.daily_limit'] : ''}
                                                error={inputErrors && inputErrors['options.' + key + '.daily_limit'] ? true : false}
                                                name="daily_limit"
                                                onChange={(event) => handleChange(event.target, key)}
                                            />
                                        </Box>
                                    </Grid>
                                    :
                                    null
                            }
                            {
                                _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0
                                    ?
                                    <Grid item xs={6} sm={2.5}>
                                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                            <TextField
                                                label={`${t(`${MODULE}.profit_sharing`)}*`}
                                                type="number"
                                                variant="outlined"
                                                value={option.profit_sharing}
                                                InputLabelProps={{ shrink: true }}
                                                helperText={inputErrors && inputErrors['options.' + key + '.profit_sharing'] ? inputErrors['options.' + key + '.profit_sharing'] : ''}
                                                error={inputErrors && inputErrors['options.' + key + '.profit_sharing'] ? true : false}
                                                name="profit_sharing"
                                                onChange={(event) => handleChange(event.target, key)}
                                            />
                                        </Box>
                                    </Grid>
                                    :
                                    null
                            }
                            <Grid item xs={6} sm={2.5}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={`${t(`${MODULE}.price.retail`)}`}
                                        variant="outlined"
                                        value={option.price.retail}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors['options.' + key + '.price.retail'] ? inputErrors['options.' + key + '.price.retail'] : ''}
                                        error={inputErrors && inputErrors['options.' + key + '.price.retail'] ? true : false}
                                        name={'retail'}
                                        onChange={(event) => handlePriceChange(event.target, key, 0)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} display="flex" alignItems="center">
                                    <Box><Typography style={{ fontWeight: "bold" }}>{t('user.rank')} {t('packages.price.price')}</Typography></Box>
                                </Box>
                            </Grid>
                            {
                                _.size(rank) > 0 &&
                                _.map(Object.values(rank).sort((a, b) => a.code - b.code), (rankDetail, value) => {
                                    if (rankDetail.status) {
                                        return (
                                            <Grid item xs={12} key={value}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6} sm={1.5}>
                                                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} display="flex" alignItems="center">
                                                            <Box><Typography>{rankDetail.display_name[i18n.language]}</Typography></Box>
                                                        </Box>
                                                    </Grid>
                                                    {
                                                        _.map(option.price['ranks'], (amount, name) => {
                                                            return (
                                                                _.map(amount, (amountDetail, index) => {
                                                                    if (rankDetail.id === parseInt(name)) {
                                                                        return (
                                                                            <Grid item xs={6} sm={3.5} key={index}>
                                                                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} display="flex" alignItems="center">
                                                                                    <TextField
                                                                                        size="small"
                                                                                        margin="dense"
                                                                                        label={`${t(`${MODULE}.price.ranks.${index}`)}*`}
                                                                                        variant="outlined"
                                                                                        value={amountDetail}
                                                                                        InputLabelProps={{ shrink: true }}
                                                                                        helperText={inputErrors && inputErrors['options.' + key + '.price.ranks.' + name + "." + index] ? inputErrors['options.' + key + '.price.ranks.' + name + "." + index] : ''}
                                                                                        error={inputErrors && inputErrors['options.' + key + '.price.ranks.' + name + "." + index] ? true : false}
                                                                                        name={index}
                                                                                        onChange={(event) => handlePriceChange(event.target, key, name)}
                                                                                    />
                                                                                </Box>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                })
                                                            )
                                                        })
                                                    }
                                                </Grid>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                <FormGroup>
                                    <FormControlLabel label={t('packages.applyToAll')} control={
                                        <Checkbox checked={options[key].price.applyToAll === 1 ? true : false} onChange={(event) => handleCheckChange(event.target, key)} inputProps={{ 'aria-label': 'controlled' }} />
                                    } />
                                </FormGroup>
                            </Grid>
                            {/* <Grid item xs={12} sm={6}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={`${t(`${MODULE}.sell_price`)}*`}
                                        variant="outlined"
                                        value={option.sell_price}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors['options.' + key + '.sell_price'] ? inputErrors['options.' + key + '.sell_price'] : ''}
                                        error={inputErrors && inputErrors['options.' + key + '.sell_price'] ? true : false}
                                        name="sell_price"
                                        onChange={(event) => handleChange(event.target, key)}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={`${t(`${MODULE}.retail_price`)}*`}
                                        variant="outlined"
                                        value={option.retail_price}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors['options.' + key + '.retail_price'] ? inputErrors['options.' + key + '.retail_price'] : ''}
                                        error={inputErrors && inputErrors['options.' + key + '.retail_price'] ? true : false}
                                        name="retail_price"
                                        onChange={(event) => handleChange(event.target, key)}
                                    />
                                </Box>
                            </Grid> */}
                            {/* <Grid item xs={12} sm={12}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={t(`${MODULE}.description.description`)}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={option.description}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors['options.' + key + '.description'] ? inputErrors['options.' + key + '.description'] : ''}
                                        error={inputErrors && inputErrors['options.' + key + '.description'] ? true : false}
                                        name="description"
                                        onChange={(event) => handleChange(event.target, key)}
                                    />
                                </Box>
                            </Grid> */}
                        </Grid>
                    )
                })
            }
            <Box display="flex" alignItems="center" justifyContent="flex-end">
                <IconButton style={{ color: theme.palette.button.main }} onClick={handleNewOption}>
                    <AddBoxOutlined />
                </IconButton>
                <Typography>{t('packages.addOption')}</Typography>
            </Box>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle} onClick={() => handleBack()}>{t('button.back')}</Button>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default CreateOption