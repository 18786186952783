import _ from 'lodash';
import Moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button, Checkbox, FormGroup, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, ImageList, ImageListItem, ImageListItemBar, InputLabel, Link, ListItemText, MenuItem, RadioGroup, Select, TextField, Typography, Radio, Chip, InputAdornment, IconButton } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { IoAdd, IoTrashOutline } from "react-icons/io5";
import { Edit, Clear } from '@mui/icons-material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as XDatePicker } from '@mui/x-date-pickers/DatePicker';

import PackageDescription from '../Description';
import CollectionModal from '../CollectionModal';

const MODULE = "packages";
const MODULE_SINGLE = "package";
const LANG = ['en', 'cn'];


const CreateInfo = (props) => {
    const { agencyList, options, setOptions, setAgencyData, categoryList, locationList, availableDaysList, typeList, state, setState, uploadImages, setUploadImages, displayImages, setDisplayImages, handleNext, inputErrors, labelList, setCollectionDialog, collectionDialog, closeCollectionDialog, completeCollectionDialog, collectionList, deleteCollectionChip, clearCollection } = props;

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [fieldLang, setFieldLang] = useState({ label: 'en' });

    const onDrop = useCallback(acceptedFiles => {
        setUploadImages(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    useEffect(() => {
        if (uploadImages) {
            if (FileReader) {
                _.map(uploadImages, (img, index) => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        setDisplayImages(prevState => (
                            [...prevState, fr.result]
                        ))
                    }
                    fr.readAsDataURL(img);
                    setState(prevState => ({
                        ...prevState,
                        images: [...prevState.images, img]
                    }))
                })
            }
        }
        // eslint-disable-next-line
    }, [uploadImages]);

    const removeImage = (index) => {
        let copyImages = [...state.images];
        let duplicateDisplayImages = [...displayImages];
        copyImages.splice(index, 1);
        duplicateDisplayImages.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            images: copyImages
        }))
        setDisplayImages(duplicateDisplayImages)
    };

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
        
        if (name === 'agency_id') {
            _.map(agencyList, (findItem, key) => {
                if (findItem.id === value) {
                    setAgencyData(findItem);
                    let tempOption = options;
                    _.map(tempOption, (option, key) => {
                        tempOption[key].profit_sharing = findItem.profit_sharing;
                    })
                    setOptions(tempOption);
                }
            })
        }
    }
    
    const handleDateChange = (value, name) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setState({ ...state, [name]: date });
    }

    const getAvailableDaysDisplay = (selected) => {
        let display = '';
        _.map(availableDaysList, day => {
            if (selected.includes(day.code)) {
                display = display ? (display + ', ' + day[i18n.language]) : day[i18n.language];
            }
        })
        return display;
    }

    const handleDateArrayChange = (dates) => {
        let dateArray = [];
        _.map(dates, date => {
            dateArray.push(Moment([date.year, date.monthIndex, date.day]).format('YYYY-MM-DD'));
        })
        setState({ ...state, "v_except_date": dateArray });
    }

    const handleLabelChange = ({ target }) => {
        const { value } = target;
        if (value !== 'other') {
            setState({ ...state, label: { ...state.label, code: value, detail: labelList[value] } });
        } else {
            setState({ ...state, label: { ...state.label, code: value, detail: '' } });
        }
    }

    const videoUrl = (type) => {
        let video_url = '';
        if (_.size(state.youku_video_url) > 0 || _.size(state.youtube_video_url) > 0) {
            if (type == "youku" && state.youku_video_url !== null) {
                video_url = (state.youku_video_url)?.split('/');
                video_url = (video_url[video_url.length - 1])?.split(/(id_)|(.html)/);
                video_url = 'https://player.youku.com/embed/' + video_url[3];
            } else {
                if (state.youtube_video_url !== null || state.youtube_video_url !== '') {
                    video_url = (state.youtube_video_url)?.split('/');
                    video_url = 'https://www.youtube.com/embed/' + video_url[video_url.length - 1];
                }
            }
        }
        return video_url;
    }

    const updateVisibleTo = (target, value) => {
        let tempVisibleTo = state.visible_to;
        if (target.checked && !_.includes(tempVisibleTo, value)) {
            tempVisibleTo.push(value);
        } else if (!target.checked && _.includes(tempVisibleTo, value)) {
            tempVisibleTo = _.filter(tempVisibleTo, function (data) { return data !== value; });
        }
        setState({ ...state, visible_to: tempVisibleTo });
    }

    return (
        <>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                {/* IMAGES */}
                <Grid item xs={12} sm={6} md={4}>
                    {_.size(inputErrors) > 0 && inputErrors.images && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{inputErrors.images}</Typography>}
                    {_.size(inputErrors) > 0 && (Object.keys(state.images).some(key => inputErrors[`images.${key}`])) && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{t('error.uploadImageError', {size: '5MB'})}</Typography>}
                    <Typography variant="caption">{t("general.uploadImgMinQty")}</Typography>
                    <label htmlFor="package-images-upload" className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                        <input accept="image/*" id="package-images-upload" multiple type="file" style={{ display: "none" }} onChange={({ target }) => setUploadImages(target.files)} {...getInputProps()} />
                        <IoAdd style={{ fontSize: "3rem" }} />
                        <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
                    </label>
                    <div style={{ paddingTop: 10 }}>
                        {/* <Typography style={{ fontSize: 14 }}>{t(`${MODULE}.recommendedSizeOne`, { width: '1193px', height: '246px' })}</Typography>
                        <Typography style={{ fontSize: 14 }}>{t(`${MODULE}.recommendedSizeMore`, { width: '595px', height: '246px' })}</Typography> */}
                        <Typography style={{ fontSize: 14 }}>{t(`${MODULE}.recommendedSize`, { width: '610px', height: '250px' })}</Typography>
                    </div>
                    {
                        _.size(displayImages) > 0 ?
                            <ImageList sx={{ width: "100%", height: 500, transform: "translateZ(0)" }} sm={{ overflow: "visible" }} >
                                {_.map(displayImages, (item, index) => {
                                    return (
                                        <ImageListItem key={index} cols={2} rows={1} style={{ position: "relative", aspectRatio: '2/1' }} >
                                            <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeImage(index)} />
                                            <img src={item} srcSet={item} alt={`uploaded_${index}`} loading="lazy" style={{ aspectRatio: '2/1' }} />
                                            <ImageListItemBar sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top" actionPosition="left" />
                                        </ImageListItem>
                                    );
                                })}
                            </ImageList>
                            : null
                    }
                </Grid>
                {/* PACKAGE INFO */}
                <Grid item xs={12} sm={6} md={8}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.name`)}*`}
                                    variant="outlined"
                                    value={state.name}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                    error={inputErrors && inputErrors.name ? true : false}
                                    name="name"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.type`)}*</InputLabel>
                                    <Select
                                        value={state.type}
                                        label={`${t(`${MODULE}.type`)}*`}
                                        name="type"
                                        error={inputErrors && inputErrors.type ? true : false}
                                        onChange={handleChange}
                                    >
                                        {_.map(typeList, resultItem => {
                                            return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language] ? resultItem[i18n.language] : resultItem.en}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.type ? inputErrors.type : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.agency_id`)}*</InputLabel>
                                    <Select
                                        value={state.agency_id}
                                        label={`${t(`${MODULE}.agency_id`)}*`}
                                        name="agency_id"
                                        error={inputErrors && inputErrors.agency_id ? true : false}
                                        onChange={handleChange}
                                    >
                                        {_.map(agencyList, resultItem => {
                                            return <MenuItem key={resultItem.id} value={resultItem.id} disabled={resultItem.status ? false : true}>{resultItem.name}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.agency_id ? inputErrors.agency_id : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.category_id`)}*</InputLabel>
                                    <Select
                                        value={state.category_id}
                                        label={`${t(`${MODULE}.category_id`)}*`}
                                        name="category_id"
                                        error={inputErrors && inputErrors.category_id ? true : false}
                                        onChange={handleChange}
                                    >
                                        {_.map(categoryList, resultItem => {
                                            return <MenuItem key={resultItem.id} value={resultItem.id} disabled={resultItem.status ? false : true}>{resultItem.name}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.category_id ? inputErrors.category_id : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.location_id`)}</InputLabel>
                                    <Select
                                        value={state.location_id}
                                        label={`${t(`${MODULE}.location_id`)}`}
                                        name="location_id"
                                        error={inputErrors && inputErrors.location_id ? true : false}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={0}>{t(`${MODULE}.noLocation`)}</MenuItem>
                                        {_.map(locationList, resultItem => {
                                            return <MenuItem key={resultItem.id} value={resultItem.id}>{resultItem.name}</MenuItem>
                                        })}
                                    </Select>
                                    <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.location_id ? inputErrors.location_id : ''}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <XDatePicker
                                        label={`${t(`${MODULE}.valid_date_from`)}`}
                                        value={state.valid_date_from}
                                        onChange={(newValue) => handleDateChange(newValue, 'valid_date_from')}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                helperText={inputErrors && inputErrors.valid_date_from ? inputErrors.valid_date_from : ''}
                                                error={inputErrors && inputErrors.valid_date_from ? true : false}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <XDatePicker
                                        label={`${t(`${MODULE}.valid_date_to`)}`}
                                        value={state.valid_date_to}
                                        onChange={(newValue) => handleDateChange(newValue, 'valid_date_to')}
                                        renderInput={(params) =>
                                            <TextField
                                                {...params}
                                                helperText={inputErrors && inputErrors.valid_date_to ? inputErrors.valid_date_to : ''}
                                                error={inputErrors && inputErrors.valid_date_to ? true : false}
                                            />
                                        }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.voucher_count`)}*`}
                                    type="number"
                                    variant="outlined"
                                    value={state.voucher_count}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.voucher_count ? inputErrors.voucher_count : ''}
                                    error={inputErrors && inputErrors.voucher_count ? true : false}
                                    name="voucher_count"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.sequence`)}`}
                                    variant="outlined"
                                    value={state.sequence}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.sequence ? inputErrors.sequence : ''}
                                    error={inputErrors && inputErrors.sequence ? true : false}
                                    name="sequence"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormLabel>{t(`${MODULE}.visibleTo`)}</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={<Checkbox checked={_.includes(state.visible_to, 'admin')} color="primary" onChange={(event) => updateVisibleTo(event.target, 'admin')} />}
                                        label={t(`${MODULE}.visibleToAdmin`)}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={_.includes(state.visible_to, 'member')} color="primary" onChange={(event) => updateVisibleTo(event.target, 'member')} />}
                                        label={t(`${MODULE}.visibleToMember`)}
                                    />
                                    {inputErrors && inputErrors?.visible_to && <FormHelperText style={{ color: 'red' }}>{inputErrors.visible_to}</FormHelperText>}
                                </FormGroup>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl>
                                    <FormLabel>{t(`${MODULE}.packageAddOn`)}</FormLabel>
                                    <RadioGroup row value={state.add_on} onChange={(event) => setState({ ...state, add_on: event.target.value })} >
                                        <FormControlLabel value={0} control={<Radio />} label={t(`${MODULE}.normalPackage`)} />
                                        <FormControlLabel value={1} control={<Radio />} label={t(`${MODULE}.addOnPackage`)} />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Grid>
                        {/* youtube or youku link */}
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={6} sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.youtubeVideoUrl`)}`}
                                            variant="outlined"
                                            value={state.youtube_video_url}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.youtube_video_url ? inputErrors.youtube_video_url : ''}
                                            error={inputErrors && inputErrors.youtube_video_url ? true : false}
                                            name="youtube_video_url"
                                            onChange={handleChange}
                                            type="url"
                                        />
                                    </Box>
                                    {
                                        _.size(state.youtube_video_url) > 0 && 
                                        <embed src={videoUrl('youtube')} width="100%" height="300px" />
                                    }
                                </Grid>
                                <Grid item xs={6} sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.youkuVideoUrl`)}`}
                                            variant="outlined"
                                            value={state.youku_video_url}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.youku_video_url ? inputErrors.youku_video_url : ''}
                                            error={inputErrors && inputErrors.youku_video_url ? true : false}
                                            name="youku_video_url"
                                            onChange={handleChange}
                                            type="url"
                                        />
                                    </Box>
                                    {
                                        _.size(state.youku_video_url) > 0 && 
                                        <embed src={videoUrl('youku')} width="100%" height="300px" />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* entrance package */}
                        {
                            state.type === 'entrance'
                                ?
                                <Grid item xs={12} sm={6}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <FormControl fullWidth>
                                            <InputLabel>{t(`${MODULE}.v_available_days`)}*</InputLabel>
                                            <Select
                                                multiple
                                                value={state.v_available_days}
                                                label={`${t(`${MODULE}.v_available_days`)}*`}
                                                name="v_available_days"
                                                renderValue={(selected) => getAvailableDaysDisplay(selected)}
                                                error={inputErrors && inputErrors.v_available_days ? true : false}
                                                onChange={handleChange}
                                            >
                                                {_.map(availableDaysList, resultItem => {
                                                    return (
                                                        <MenuItem key={resultItem.code} value={resultItem.code}>
                                                            <Checkbox checked={state.v_available_days.indexOf(resultItem.code) > -1} />
                                                            <ListItemText primary={resultItem[i18n.language]} />
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.v_available_days ? inputErrors.v_available_days : ''}</FormHelperText>
                                        </FormControl>
                                    </Box>
                                </Grid>
                                : null
                        }
                        {/* tour package */}
                        {/* {
                            state.type === 'tour'
                                ?
                                <Grid item xs={12} sm={6}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.duration_period`)}*`}
                                            type="number"
                                            variant="outlined"
                                            value={state.duration_period}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.duration_period ? inputErrors.duration_period : ''}
                                            error={inputErrors && inputErrors.duration_period ? true : false}
                                            name="duration_period"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                : null
                        } */}
                        {
                            state.type === 'tour'
                                ?
                                <Grid item xs={12}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <Typography variant="caption" style={{ color: "gray" }}>{t(`${MODULE}.v_except_date`)}</Typography>
                                        <DatePicker
                                            sort
                                            multiple
                                            plugins={[
                                                <DatePanel />
                                            ]}
                                            name="v_except_date"
                                            value={state.v_except_date}
                                            onChange={(dates) => handleDateArrayChange(dates)}
                                            style={{ width: "100%", padding: "25px 20px" }}
                                        />
                                    </Box>
                                </Grid>
                                : null
                        }
                        {
                            _.size(labelList) > 0 &&
                            <Grid item xs={12}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <FormControl>
                                        <FormLabel>{t(`${MODULE}.label`)}</FormLabel>
                                        <RadioGroup row value={state.label.code} onChange={handleLabelChange} >
                                            {
                                                _.map(labelList, (list, key) => {
                                                    return <FormControlLabel value={key} control={<Radio />} label={list[i18n.language]} key={key} />
                                                })
                                            }
                                            <FormControlLabel control={<Button onClick={() => setState({ ...state, label: '' })}><Clear /> {t('button.clear')}</Button>} />
                                        </RadioGroup>
                                    </FormControl>
                                    {
                                        state?.label?.code === 'other' &&
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            margin='normal'
                                            label={t(`${MODULE}.customLabel`)}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    {
                                                        _.map(LANG, (value) => (
                                                            <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, label: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.label === value ? theme.palette.primary.main : '#efefef', color: fieldLang.label === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                                        ))
                                                    }
                                                </InputAdornment>,
                                            }}
                                            onChange={({ target }) => setState({ ...state, label: { ...state.label, detail: { ...state.label.detail, [fieldLang.label]: target.value } } })}
                                            value={state.label && state.label.detail[fieldLang.label] ? state.label.detail[fieldLang.label] : ""}
                                        />
                                    }
                                </Box>
                            </Grid>
                        }
                        {/*choose collections for your product, your product can belong to multiple collections */}
                        <Grid item xs={12}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    fullWidth
                                    label={t(`${MODULE}.collection`)}
                                    InputProps={{
                                        startAdornment:
                                            <>
                                                {
                                                    _.size(state.collection) > 0 &&
                                                    _.map(state.collection, (list) => {
                                                        let displayItem = _.find(collectionList, function (o) { return o.id === list; })
                                                        if (displayItem) {
                                                            return (
                                                                <Chip
                                                                    key={list}
                                                                    label={i18n.language === 'cn' ? displayItem.display_name['cn'] : displayItem.display_name['en']}
                                                                    style={{ marginLeft: 3, marginRight: 3 }}
                                                                    onDelete={()=>deleteCollectionChip(list.id)}
                                                                    variant="outlined"
                                                                    color="primary"
                                                                />
                                                            )
                                                        } else {
                                                            return null
                                                        }
                                                    })
                                                }
                                            </>
                                        ,
                                        endAdornment:
                                            <>
                                                <IconButton onClick={() => setCollectionDialog({ ...collectionDialog, open: true })}><Edit /></IconButton>
                                                <IconButton onClick={clearCollection}><Clear /></IconButton>
                                            </>
                                    }}
                                />
                            </Box>
                        </Grid>
                        {/* description part: title, subtitle, body, points */}
                        <PackageDescription state={state} setState={setState} inputErrors={inputErrors} />
                    </Grid>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => handleNext()}>{t('button.next')}</Button>
            </Box>
            <CollectionModal dialog={collectionDialog} closeDialog={closeCollectionDialog} completeDialog={completeCollectionDialog} state={state} setState={setState}  collections={collectionList} />
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed #aeaeae",
        width: "100%",
        height: 100,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    }
}))

export default CreateInfo