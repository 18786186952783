import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Link, Typography, IconButton, Select, MenuItem, FormControl } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AddBoxOutlined } from '@mui/icons-material';
import { IoPencil, IoTrashOutline } from "react-icons/io5";

import { deleteUrl, getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "comments";
const MODULE_SINGLE = "comment";

const Listing = () => {
    const [data, setData] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    const [deleteId, setDeleteId] = useState("");
    const [newsList, setNewsList] = useState([]);
    const [newsId, setNewsId] = useState("all");

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/news`, {all: true}).then(response => {
            setTableLoading(false);
            if (response.status) {
                setNewsList(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const callApi = () => {
        setTableLoading(true);
        let apiData = {
            news_id: newsId,
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [newsId]);

    useEffect(() => {
        let newStatusList = [];
        newStatusList.push({ text: t('comments.status.0'), value: "0" });
        newStatusList.push({ text: t('comments.status.1'), value: "1" });
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [i18n.language]);

    const deleteComment = () => {
        setLoading(true);
        deleteUrl(`/${MODULE}/${deleteId}`)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.deleteSuccess'), 'success', '');
                    callApi();
                } else {
                    addAlert('', t('error.deleteError'), 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        setDeleteId("");
    };

    const columns = useMemo(() => ([
        {
            accessorKey: 'name',
            header: `${t('user.name')}`,
        },
        {
            accessorKey: 'email',
            header: `${t('user.email')}`,
        },
        {
            accessorKey: 'comment',
            header: `${t('comments.message')}`,
            minSize: '300'
        },
        {
            accessorFn: (row) => t('comments.status.' + row.status),
            id: 'status',
            header: `${t('comments.status.status')}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            enableSorting: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        {
            id: 'action',
            header: `${t(`table.actions`)}`,
            enableColumnOrdering: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Link underline='none' to={`/${MODULE}/${cell.row.original.id}`} component={RouterLink}>
                            <IconButton><IoPencil size={20} /></IconButton>
                        </Link>
                        <IconButton onClick={() => setDeleteId(cell.row.original.id)}><IoTrashOutline size={20} /></IconButton>
                    </Box>
                    :
                    null
            ),
            maxSize: '100'
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Box style={{ width: "50%", paddingBottom: 10 }}>
                <FormControl fullWidth>
                    <Select
                        value={newsId}
                        label={t(`title.news`)}
                        variant="standard"
                        name="news_id"
                        onChange={(event) => setNewsId(event.target.value)}
                    >
                        <MenuItem value="all">{t(`${MODULE}.allNews`)}</MenuItem>
                        {_.map(newsList, news => {
                            return <MenuItem key={news.id} value={news.id}>{news.title[i18n.language] ? news.title[i18n.language] : news.title.en}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Box>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'CommentList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
            <Dialog
                open={deleteId !== "" ? true : false}
                onClose={() => setDeleteId("")}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t(`${MODULE}.confirmationOnDelete`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`${MODULE}.askDeleteComment`)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' onClick={() => setDeleteId("")} color="secondary">{t('button.cancel')}</Button>
                    <Button variant='contained' onClick={deleteComment} color="primary" autoFocus>{t('button.confirm')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Listing