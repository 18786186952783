import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import { Box, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

const MODULE = "agencies";
const INITIAL_OPERATION_HOURS = {
    "1": [{ "from_time": '', "to_time": '' }],
    "2": [{ "from_time": '', "to_time": '' }],
    "3": [{ "from_time": '', "to_time": '' }],
    "4": [{ "from_time": '', "to_time": '' }],
    "5": [{ "from_time": '', "to_time": '' }],
    "6": [{ "from_time": '', "to_time": '' }],
    "7": [{ "from_time": '', "to_time": '' }]
};

const OperationHoursEdit = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    let { id } = useParams();
    const [availableDaysList, setAvailableDaysList] = useState([]);
    const [operationHours, setOperationHours] = useState(INITIAL_OPERATION_HOURS);

    useEffect(() => {
        getUrl(`/${MODULE}/${id}`).then(response => {
            const { agency, available_days_list } = response.data;
            if (response.status) {
                setAvailableDaysList(available_days_list);
                if (agency.operation_hours) {
                    let newOperationHour = agency.operation_hours;
                    _.map(newOperationHour, (operationDay, _dkey) => {
                        _.map(operationDay, (operationHour, _hkey) => {
                            _.map(operationHour, (value, type) => {
                                if (value === null) {
                                    newOperationHour[_dkey][_hkey][type] = '';
                                }
                            })
                        })
                    })
                    setOperationHours(newOperationHour);
                } else {
                    setOperationHours(INITIAL_OPERATION_HOURS);
                }
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleOperationHourChange = (dkey, hkey, type, value) => {
        let newOperationHour = operationHours;
        _.map(newOperationHour, (operationDay, _dkey) => {
            if (_dkey === dkey) {
                _.map(operationDay, (operationHour, _hkey) => {
                    if (_hkey === hkey) {
                        newOperationHour[_dkey][_hkey][type] = value;
                    }
                })
            }
        })
        setOperationHours({ ...newOperationHour });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        putUrl(`/${MODULE}/${id}/operation_hours`, { operation_hours: operationHours }).then(response => {
            setLoading(false);
            const { status, errors, message } = response;
            if (status) {
                addAlert('', message || t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            {
                _.map(operationHours, (operationDay, dkey) => {
                    return (
                        <Grid container spacing={3} key={dkey}>
                            <Grid item xs={12} style={{ margin: 5 }}>
                                {
                                    _.map(availableDaysList, (day, key) => {
                                        if (day.code === dkey) {
                                            return (
                                                <Typography variant="body1" key={key}>{day[i18n.language] ? day[i18n.language] : day.en}</Typography>
                                            )
                                        }
                                    })
                                }
                                {
                                    _.map(operationDay, (operationHour, hkey) => {
                                        return (
                                            <div key={hkey} style={{ display: "flex" }}>
                                                <Box style={{ width: "50%", margin: 10 }}>
                                                    <TextField
                                                        label={t(`${MODULE}.operationHoursFrom`)}
                                                        variant="outlined"
                                                        value={operationHour.from_time}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={inputErrors && inputErrors.operation_hours ? inputErrors.operation_hours : ''}
                                                        error={inputErrors && inputErrors.operation_hours ? true : false}
                                                        name="from_time"
                                                        onChange={(event) => handleOperationHourChange(dkey, hkey, event.target.name, event.target.value)}
                                                        type="time"
                                                        fullWidth
                                                    />
                                                </Box>
                                                <Box style={{ width: "50%", margin: 10 }}>
                                                    <TextField
                                                        label={t(`${MODULE}.operationHoursTo`)}
                                                        variant="outlined"
                                                        value={operationHour.to_time}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={inputErrors && inputErrors.operation_hours ? inputErrors.operation_hours : ''}
                                                        error={inputErrors && inputErrors.operation_hours ? true : false}
                                                        name="to_time"
                                                        onChange={(event) => handleOperationHourChange(dkey, hkey, event.target.name, event.target.value)}
                                                        type="time"
                                                        fullWidth
                                                    />
                                                </Box>
                                            </div>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    )
                })
            }
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save') + ' ' + t(`${MODULE}.operation_hours`)}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default OperationHoursEdit