import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import AddressEdit from './AddressEdit';
import BasicInfoEdit from './BasicInfoEdit';
import OperationHoursEdit from './OperationHoursEdit';
import PublicViewEdit from './PublicViewEdit';
import BackToPrevious from '../Layout/BackToPrevious';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MODULE = "agencies";
const MODULE_SINGLE = "agency";

export default function Edit() {
    const { t } = useTranslation();
    const theme = useTheme();
    const classes = useStyles();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Edit`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Edit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                    >
                        <Tab
                            label={t(`${MODULE}.basicInfo`)}
                            {...a11yProps(0)}
                        />
                        <Tab
                            label={t(`${MODULE}.operation_hours`)}
                            {...a11yProps(1)}
                        />
                        <Tab
                            label={t(`${MODULE}.address`)}
                            {...a11yProps(2)}
                        />
                        <Tab
                            label={t(`${MODULE}.publicView`)}
                            {...a11yProps(3)}
                        />
                    </Tabs>
                </Box>
            </Box>
            <TabPanel value={tabValue} index={0}><BasicInfoEdit /></TabPanel>
            <TabPanel value={tabValue} index={1}><OperationHoursEdit /></TabPanel>
            <TabPanel value={tabValue} index={2}><AddressEdit /></TabPanel>
            <TabPanel value={tabValue} index={3}><PublicViewEdit /></TabPanel>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));