import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Typography, Link, Breadcrumbs, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AddBoxOutlined } from '@mui/icons-material';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import CustomizeMaterialReactTable from '../Layout/CustomizeMaterialReactTable';

const MODULE = "categories";
const MODULE_SINGLE = "category";

const Listing = () => {
    const [data, setData] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setTableLoading(true);
        getUrl(`/${MODULE}?status=all`).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setStatusList([
            { text: `${t(`${MODULE}.statusList.1`)}`, value: `${t(`${MODULE}.statusList.1`)}` },
            { text: `${t(`${MODULE}.statusList.0`)}`, value: `${t(`${MODULE}.statusList.0`)}` },
        ]);
        // eslint-disable-next-line
    }, [i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'name',
            header: `${t(`${MODULE}.name`)}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Link underline='none' to={`/${MODULE}/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.name}</Link>
                    :
                    <>{cell.row.original.name}</>
            )
        },
        {
            accessorFn: (row) => (row.parent ? row.parent.name : '-'),
            id: 'parent.name',
            header: `${t(`${MODULE}.parent`)}`,
            Cell: ({ cell }) => (
                cell.row.original.parent_id ?
                    <Link underline='none' to={`/${MODULE}/${cell.row.original.parent_id}`} component={RouterLink}>{cell.row.original.parent ? cell.row.original.parent.name : '-'}</Link>
                    :
                    <>{cell.row.original.parent ? cell.row.original.parent.name : '-'}</>
            )
        },
        {
            accessorKey: 'code',
            header: `${t(`${MODULE}.code`)}`,
        },
        {
            accessorFn: (row) => (`${t(`${MODULE}.statusList.` + row.status)}`),
            id: 'status',
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <CustomizeMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'CategoryList'}
            />
        </>
    )
}

export default Listing