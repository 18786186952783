import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Tab, Tabs, Typography } from '@mui/material';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const StatusTab = (props) => {
    const { t, i18n } = useTranslation();

    const { tabValue, setTabValue, moduleStatusList } = props;
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: "100%", marginBottom: 5 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        label={t('general.all')}
                        value={''}
                    />
                    {
                        _.map(moduleStatusList, (status, key) => {
                            return (
                                <Tab
                                    key={key}
                                    label={status[i18n.language] ? status[i18n.language] : status.en}
                                    value={key}
                                />
                            )
                        })
                    }
                </Tabs>
            </Box>
        </Box>
    );
};

export default StatusTab;