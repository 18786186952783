import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, TextField, Typography } from '@mui/material';

import { getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "order-settlement";

const Listing = (props) => {
    const { status } = props;
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    const [state, setState] = useState({
        open: false,
        id: '',
        authentication_code: '',
    });
    const [refreshData, setRefreshData] = useState(false);
    const [inputErrors, setInputErrors] = useState([]);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            status: status ? status : ''
        }
        getUrl(`/settlement`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData, refreshData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, status => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: (status.code).toString() });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'id',
            id: 'id',
            header: 'ID',
        },
        {
            accessorKey: 'order_no',
            header: `${t(`orders.orderNo`)}`,
            Cell: ({ cell }) => (
                _.map(cell.row.original.orders, order => {
                    return (
                        <Box key={order.id}>
                            {
                                _.includes(permissions, `order-edit`) ?
                                    <Link underline='none' to={`/orders/${order.id}`} component={RouterLink}>{order.order_no}</Link>
                                    :
                                    <>{order.order_no}</>
                            }
                        </Box>
                    )
                })
            ),
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.user ? row.user.name : '-'),
            id: 'user.name',
            header: `${t(`orders.user`)}`,
            enableSorting: false,
        },
        {
            accessorFn: (row) => ("RM " + row.amount),
            id: 'amount',
            header: `${t(`orders.totalAmount`)}`,
        },
        {
            accessorKey: 'receipt',
            header: `${t(`orders.receipt`) + ' / ' + t(`orders.remark`)}`,
            Cell: ({ cell }) => (
                _.size(cell.row.original.receipt) > 0
                    ?
                    <Link href={cell.row.original.receipt.file_name} rel="noreferrer" target="_blank" underline="none">
                        <Button variant="outlined" size="small">
                            {t('orders.viewReceipt')}
                        </Button>
                    </Link>
                    :
                    (
                        cell.row.original.remark
                            ?
                            t(`orders.remark`) + ": " + cell.row.original.remark
                            :
                            null
                    )
            ),
            enableSorting: false,
            enableColumnFilter: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        {
            id: 'action',
            header: `${t(`table.actions`)}`,
            enableColumnOrdering: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE}-status`) ?
                    <Button variant="outlined" size="small" onClick={() => handleDialogOpen(cell.row.original.id)}>
                        {t('orders.updateStatus')}
                    </Button>
                    :
                    null
            ),
            enableSorting: false,
            enableColumnFilter: false,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    const updateStatus = () => {
        setLoading(true);
        setInputErrors({});
        putUrl(`/settlement/${state.id}`, {authentication_code: state.authentication_code}).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
                handleDialogClose();
                setRefreshData(!refreshData);
            } else {
                setInputErrors(response.errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleDialogOpen = (id) => {
        setState({
            open: true,
            id: id,
            authentication_code: '',
        });
    }
    const handleDialogClose = () => {
        setState({
            open: false,
            id: '',
            authentication_code: '',
        });
    }
    const updateStatusDialog = () => {
        return (
            <Dialog
                open={state.open}
                onClose={() => handleDialogClose()}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle>{t(`orders.updateStatus`)}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography>{t('orders.settlementStatusNote')}</Typography>
                        </Grid>
                        {/* <Grid item xs={12}>
                            <TextField
                                label={t('user.authentication_code')}
                                variant="outlined"
                                helperText={inputErrors && inputErrors.authentication_code ? inputErrors.authentication_code : ''}
                                error={inputErrors && inputErrors.authentication_code ? true : false}
                                onChange={({ target }) => setState({ ...state, authentication_code: target.value })}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleDialogClose()} variant="outlined">{t('button.cancel')}</Button>
                    <Button onClick={() => updateStatus()} variant="contained">{t('button.update')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'OrderSettlementList'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                initialColumnVisibility={{ 'action': (_.includes(permissions, `${MODULE}-status`) && status === 10) ? true : false }}
            />
            {updateStatusDialog()}
        </>
    )
}

export default Listing

const useStyles = makeStyles((theme) => ({
    backgroundStyle: {
        backgroundImage: `url('../images/logo-light.png')`,
        backgroundRepeat: 'no-repeat',
        backgroundBlendMode: 'multiply',
        backgroundSize: '50%',
        backgroundPosition: 'center 110%',
    }
}))