import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';

import { AddBoxOutlined, Delete, Edit, ProductionQuantityLimits } from '@mui/icons-material';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { deleteUrl, postUrl, putUrl, getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

const MODULE = "daily-limits";

const DailyLimit = (props) => {
    const { package_option_id, option_name } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const [inputErrors, setInputErrors] = useState({});

    const [optionDailyLimits, setOptionDailyLimits] = useState([]);
    const [limitDialog, setLimitDialog] = useState({
        open: false,
        daily_limit_id: 0,
        date: '',
        daily_limit: ''
    });
    const [deleteLimitDialog, setDeleteLimitDialog] = useState({
        open: false,
        daily_limit_id: '',
    });
    const [dailyLimitDialog, setDailyLimitDialogDialog] = useState({
        open: false,
        product_option_id: '',
    });

    useEffect(() => {
        if (package_option_id) {
            setLoading(true);
            getUrl(`/${MODULE}`, { package_option_id: package_option_id }).then(response => {
                setLoading(false);
                if (response.status) {
                    setOptionDailyLimits(response.data);
                } else {
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [package_option_id]);

    const handleDailyLimitDialogOpen = (product_option_id) => {
        setDailyLimitDialogDialog({
            open: true,
            product_option_id: product_option_id,
        });
    }
    const handleDailyLimitDialogClose = () => {
        setDailyLimitDialogDialog({
            open: false,
            product_option_id: '',
        });
    }

    const handleDialogOpen = (daily_limit_id) => {
        let limit = [];
        if (daily_limit_id) {
            limit = _.find(optionDailyLimits, function (o) { return o.id === daily_limit_id; });
        }
        setLimitDialog({
            open: true,
            daily_limit_id: daily_limit_id,
            date: _.size(limit)>0 ? limit.date : '',
            daily_limit: _.size(limit)>0 ? limit.daily_limit : ''
        });
    }
    const handleDialogClose = () => {
        setInputErrors({});
        setLimitDialog({
            open: false,
            daily_limit_id: 0,
            date: '',
            daily_limit: ''
        });
    }

    const handleDeleteDialogOpen = (daily_limit_id) => {
        setDeleteLimitDialog({
            open: true,
            daily_limit_id: daily_limit_id,
        });
    }
    const handleDeleteDialogClose = () => {
        setDeleteLimitDialog({
            open: false,
            daily_limit_id: '',
        });
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'status') {
            setLimitDialog({ ...limitDialog, status: target.checked ? 1 : 0 });
        } else {
            setLimitDialog({ ...limitDialog, [name]: value });
        }
    }
    const handleDateChange = (value) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setLimitDialog({ ...limitDialog, date: date });
    }

    const deleteDailyLimit = () => {
        setLoading(true);
        deleteUrl(`/${MODULE}/${deleteLimitDialog.daily_limit_id}`).then(response => {
            setLoading(false);
            handleDeleteDialogClose();
            if (response.status) {
                let newOptions = _.filter(optionDailyLimits, function (o) { return o.id !== deleteLimitDialog.daily_limit_id });
                setOptionDailyLimits(newOptions);
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const createEditDailyLimit = () => {
        setLoading(true);
        setInputErrors({});
        let postData = {
            date: limitDialog.date,
            daily_limit: limitDialog.daily_limit,
            package_option_id: package_option_id
        };
        if (limitDialog.daily_limit_id > 0) {
            // update old option daily limit
            putUrl(`/${MODULE}/${limitDialog.daily_limit_id}`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    let oldKey = _.findKey(optionDailyLimits, function (o) { return o.id === limitDialog.daily_limit_id; });
                    setOptionDailyLimits({ ...optionDailyLimits, [oldKey]: response.data });
                    handleDialogClose();
                    addAlert('', response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            // create new option
            postUrl(`/${MODULE}`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    let newKey = _.size(optionDailyLimits);
                    setOptionDailyLimits({ ...optionDailyLimits, [newKey]: response.data });
                    handleDialogClose();
                    addAlert('', response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <Box style={{ display: "flex", alignItems: "flex-start" }}>
            <Box>
                <Tooltip title={t(`${MODULE}.daily_limit`)}>
                    <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDailyLimitDialogOpen(package_option_id)}>
                        <ProductionQuantityLimits />
                    </IconButton>
                </Tooltip>
                {
                    _.map(optionDailyLimits, limit => {
                        return (
                            <Box key={limit.id} style={{ display: "flex", alignItems: "flex-start" }}>
                                {limit.date + ": " + limit.daily_limit}
                            </Box>
                        )
                    })
                }
            </Box>
            {/* VIEW OPTION DAILY LIMIT DIALOG */}
            <Dialog open={dailyLimitDialog.open} onClose={handleDailyLimitDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>{t(`${MODULE}.daily_limit`) + ' (' + option_name + ')'}</DialogTitle>
                <DialogContent>
                    {/* OPTION DAILY LIMIT LIST */}
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t(`table.actions`)}</TableCell>
                                    <TableCell>{t(`${MODULE}.date`)}</TableCell>
                                    <TableCell>{t(`${MODULE}.daily_limit`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(optionDailyLimits, (limit) => {
                                        return (
                                            <TableRow
                                                key={limit.id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>
                                                    <Tooltip title={t(`${MODULE}.editDailyLimit`)}>
                                                        <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDialogOpen(limit.id)}>
                                                            <Edit />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={t(`${MODULE}.deleteDailyLimit`)}>
                                                        <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeleteDialogOpen(limit.id)}>
                                                            <Delete />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell>{limit.date}</TableCell>
                                                <TableCell>{limit.daily_limit}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* ADD NEW OPTION DAILY LIMIT */}
                    <Box display="flex" alignItems="center" justifyContent="flex-end">
                        <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDialogOpen(0)}>
                            <AddBoxOutlined />
                        </IconButton>
                        <Typography>{t(t(`${MODULE}.addDailyLimit`))}</Typography>
                    </Box>
                    {/* ADD/EDIT OPTION DIALOG */}
                    <Dialog open={limitDialog.open} onClose={handleDialogClose} fullWidth maxWidth="sm">
                        <DialogTitle>{limitDialog.daily_limit_id ? t(`${MODULE}.editDailyLimit`) : t(`${MODULE}.addDailyLimit`)}</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} style={{ paddingBottom: '5%' }}>
                                <Grid item xs={12} sm={6}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label={`${t(`${MODULE}.date`)}*`}
                                                value={limitDialog.date}
                                                onChange={(newValue) => handleDateChange(newValue)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        helperText={inputErrors && inputErrors.date ? inputErrors.date : ''}
                                                        error={inputErrors && inputErrors.date ? true : false}
                                                    />
                                                }
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.daily_limit`)}*`}
                                            type="number"
                                            variant="outlined"
                                            value={limitDialog.daily_limit}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.daily_limit ? inputErrors.daily_limit : ''}
                                            error={inputErrors && inputErrors.daily_limit ? true : false}
                                            name="daily_limit"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.cancelButton} onClick={handleDialogClose}>{t('button.cancel')}</Button>
                            <Button className={classes.confirmButton} onClick={createEditDailyLimit}>{t('button.save')}</Button>
                        </DialogActions>
                    </Dialog>
                    {/* DELETE OPTION DIALOG */}
                    <Dialog open={deleteLimitDialog.open} onClose={handleDeleteDialogClose} fullWidth maxWidth="sm">
                        <DialogTitle>{t(`${MODULE}.deleteDailyLimit`)}</DialogTitle>
                        <DialogContent>
                            {t(`${MODULE}.deleteDailyLimitNote`)}
                        </DialogContent>
                        <DialogActions>
                            <Button className={classes.cancelButton} onClick={handleDeleteDialogClose}>{t('button.no')}</Button>
                            <Button className={classes.confirmButton} onClick={deleteDailyLimit}>{t('button.yes')}</Button>
                        </DialogActions>
                    </Dialog>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.confirmButton} onClick={handleDailyLimitDialogClose}>{t('button.close')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    confirmButton: {
        backgroundColor: theme.palette.secondary.main + '! important',
        color: theme.palette.secondary.secondary + '! important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.secondary + '! important',
        color: theme.palette.secondary.main + '! important',
        border: '1px solid ' + theme.palette.secondary.main + '! important',
    },
}))

export default DailyLimit