import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { Grid, Typography, Link, Breadcrumbs, Box } from '@mui/material';
import { useTheme } from '@mui/styles';

import { postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

const MODULE = "vouchers";
const MODULE_SINGLE = "voucher";

const Listing = () => {
    const [successMsg, setSuccessMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState(false);
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [searchParams, setSearchParams] = useSearchParams();
    let code = searchParams.get('code');
    let order_id = searchParams.get('order_id');

    useEffect(() => {
        if ((code || order_id) && _.includes(permissions, `${MODULE_SINGLE}-redeem`)) {
            setLoading(true);
            postUrl(`/${MODULE}/redeem`, { code: code, order_id: order_id }).then(response => {
                setLoading(false);
                if (response.status) {
                    setSuccessMsg(t(`${MODULE}.redeemSuccess`, { code: response.redeem_codes }));
                } else {
                    let msg = t(`${MODULE}.redeemFail`);
                    if (response.errors) {
                        _.map(response.errors, (value, index) => {
                            msg += ` ${value}`;
                        })
                    }
                    setErrorMsg(msg);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        // eslint-disable-next-line
    }, [code, order_id, permissions]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Redeem`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Redeem`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Box style={{ display: "flex", textAlign: "center", color: successMsg ? theme.palette.indicator.green : theme.palette.indicator.red }}>
                <Typography variant="h6">{successMsg ? successMsg : errorMsg}</Typography>
            </Box>
        </>
    )
}

export default Listing