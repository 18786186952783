import { QRCodeSVG } from 'qrcode.react';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';

import { Box, Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { LOCAL_URL } from '../../configs/Config';

const VoucherPdf = ({ label, voucher }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const componentRef = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Voucher_' + voucher.code + '_' + voucher.user_name,
    });

    const ComponentToPrint = ({ innerRef }) => {
        return (
            <div style={{ padding: 60 }} ref={innerRef} className={classes.backgroundStyle}>
                <Box style={{ marginBottom: "20%" }} />
                {/* <Box style={{width: "100%", display: "flex", justifyContent: "center"}}>
                <img alt='ReLuxe' src="/images/logo-text.png" style={{ width: "40%" }} />
                </Box> */}
                <Box style={{ display: "flex", justifyContent: "center" }}>
                    <Typography>{t('vouchers.voucherReminder')}</Typography>
                </Box>
                <Box style={{ display: "flex", justifyContent: "center", margin: 10, padding: 10, border: "2px solid gray", borderRadius: "2px" }}>
                    <Grid container>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Box>
                                <QRCodeSVG value={`${LOCAL_URL}/voucher-redeem?code=${voucher.code}`} />
                                <Typography variant="body1" style={{ fontWeight: "bold" }} align="center">{voucher.code}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h6" style={{ fontWeight: "bold", margin: "3% 0%" }}>{voucher.voucher_name}</Typography>
                            <Typography variant="body1" style={{ fontWeight: "bold" }}>{t('vouchers.booking_date') + ": " + voucher.start_date}</Typography>
                            <Typography variant="body1">{t('user.name') + ": " + voucher.user_name}</Typography>
                            <Typography variant="body1">{t('user.ic') + ": " + voucher.user_ic}</Typography>
                            <Typography variant="body1">{t('user.dob') + ": " + (voucher.user_dob ? voucher.user_dob : '-')}</Typography>
                            <Typography variant="body1">{t('user.mobile') + ": " + (voucher.user_mobile ? voucher.user_mobile : '-')}</Typography>
                            <Typography variant="body1">{t('user.email') + ": " + (voucher.user_email ? voucher.user_email : '-')}</Typography>
                            <Typography variant="body1">{t('user.gender') + ": " + (voucher.user_gender ? t('user.' + voucher.user_gender) : '-')}</Typography>
                            <Typography variant="body1">{t('placeOrder.user_role') + ": " + (voucher.user_role ? t('placeOrder.tour_' + voucher.user_role) : '-')}</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{ marginRight: 60 }} align="right">
                    <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: 10 }}>Scan for whole order:</Typography>
                    <QRCodeSVG value={`${LOCAL_URL}/voucher-redeem?order_id=${voucher.order_id}`} />
                </Box>
            </div>
        )
    }

    return (
        <div>
            <Button variant="outlined" color="primary" style={{ marginRight: 15 }} onClick={handlePrint}>{label}</Button>
            <div style={{ display: "none" }}>
                <ComponentToPrint innerRef={componentRef} />
            </div>
        </div>
    )
}

export default VoucherPdf;

const useStyles = makeStyles((theme) => ({
    backgroundStyle: {
        backgroundImage: `url('../images/letterhead.png')`,
        backgroundRepeat: 'no-repeat',
        // backgroundBlendMode: 'multiply',
        backgroundSize: '100%',
        // backgroundPosition: 'center 110%',
    }
}))