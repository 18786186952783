import _ from 'lodash';
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, InputAdornment, InputLabel, Link, FormHelperText, MenuItem, Select, TextField, Typography, IconButton} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { AddBoxOutlined, Delete } from '@mui/icons-material';

import { postUrl, getUrl, putUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "rooms";
const MODULE_SINGLE = "room";

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    let navigate = useNavigate();
    const location = useLocation();
    let { id } = useParams();
    const isMountedRef = useRef(null);

    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [agencyList, setAgencyList] = useState({});
    const [statusList, setStatusList] = useState({});
    const [monthList, setMonthList] = useState({});
    const [roomtypeList, setRoomtypeList] = useState({});
    const [state, setState] = useState({
        agency_id: '',
        room_type_id: '',
        room_name: '',
        room_no: '',
        booking_date: [], 
        status: 0,
    });
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [inputErrors, setInputErrors] = useState({});
    const formType = location.pathname === `/${MODULE}-add` ? "add" : "edit";

    const newRoomTypeList = [];
    var room_type = '';

    const redefineRoomTypeList = () => {
        var temp_item = "";
        var curr_item = "";
        
        for (var $i = 0; $i < roomtypeList.length; $i++) {
            curr_item = roomtypeList[$i].type;

            if (curr_item !== temp_item) {
                newRoomTypeList.push(roomtypeList[$i]);
                temp_item = roomtypeList[$i].type;
            }
        }

        return newRoomTypeList;
    }

    const searchRoomTypeList = () => {
        for (var $i = 0; $i < roomtypeList.length; $i++) {
            console.log(roomtypeList[$i]);
        }
    }

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);

        getUrl(`/agencies`).then(response => {
            setLoading(false);
            setAgencyList(response.data.listing);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/${MODULE}`).then(response => {
            setLoading(false);
            setStatusList(response.data.status_list);
            setMonthList(response.data.month_list);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/roomtypes`).then(response => {
            setLoading(false);
            setRoomtypeList(response.data.listing);
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        if (formType === 'edit' && id) {
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        let { room } = response.data;

                        setState({
                            agency_id: room.agency_id,
                            room_type_id: room.room_type.type,
                            room_name: room.room_type.name,
                            room_no: room.room_no,
                            booking_date: room.booking_date.booking_dates, 
                            status: room.status,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id]);

    const submitData = () => {
        let postData = {
            room_no: state.room_no, 
            status: state.status,
            _method: formType === "edit" ? "put" : "post",
        };

        const formData = new FormData();
        buildFormData(formData, postData);

        setLoading(true);
        setInputErrors();

        if (formType === "edit" && id) {
            putUrl(`/${MODULE}/${id}`, postData).then(response => {
                setLoading(false);
                let { status, data, errors, message } = response;
                addAlert('', message, status ? 'success' : 'error', '');

                if (status) {
                    addAlert('', response.message, 'success', '');
                    if (formType !== "edit") {
                        navigate(`/${MODULE}/${data.id}`);
                    }
                } else {
                    setInputErrors(errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            postUrl(`/${MODULE}`, formData).then(response => {
                setLoading(false);
                let { status, data, errors, message } = response;
                addAlert('', message, status ? 'success' : 'error', '');

                if (status) {
                    addAlert('', response.message, 'success', '');
                    if (formType !== "edit") {
                        navigate(`/${MODULE}/${data.id}`);
                    }
                } else {
                    setInputErrors(errors);
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to="/roomtypes">
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>{t(`${MODULE}.agency_id`)}*</InputLabel>
                                <Select
                                    value={state.agency_id}
                                    label={`${t(`${MODULE}.agency_id`)}`}
                                    name="agency_id"
                                    disabled={ formType === 'edit' ? true : null}
                                    error={inputErrors && inputErrors.agency_id ? true : false}
                                    onChange={({ target }) => setState({ ...state, agency_id: target.value })}
                                >
                                    {_.map(agencyList, resultItem => {
                                        return <MenuItem key={resultItem.id} value={resultItem.id} disabled={resultItem.status ? false : true}>{resultItem.name}</MenuItem>
                                    })}
                                </Select>
                                <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.agency_id ? inputErrors.agency_id : ''}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>{t(`${MODULE}.room_type_id`)}*</InputLabel>
                                <Select
                                    value={state.room_type_id}
                                    label={`${t(`${MODULE}.room_type_id`)}`}
                                    name="room_type_id"
                                    disabled={ formType === 'edit' ? true : null}
                                    error={inputErrors && inputErrors.room_type_id ? true : false}
                                    onChange={({ target }) => {
                                        searchRoomTypeList();
                                        setState({ ...state, room_type_id: target.value })
                                    }}
                                >
                                    {_.map(redefineRoomTypeList(), resultItem => {
                                        if (resultItem.agency_id === state.agency_id) {
                                            return <MenuItem key={resultItem.id} value={resultItem.id} disabled={resultItem.status ? false : true}>{resultItem.type}</MenuItem>
                                        }
                                    })}
                                </Select>
                                <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.agency_id ? inputErrors.agency_id : ''}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>{t(`${MODULE}.name`)}*</InputLabel>
                                <Select
                                    value={state.room_name}
                                    label={`${t(`${MODULE}.name`)}`}
                                    name="room_name"
                                    disabled={ formType === 'edit' ? true : null}
                                    error={inputErrors && inputErrors.room_name ? true : false}
                                    onChange={({ target }) => setState({ ...state, room_name: target.value })}
                                >
                                    {_.map(roomtypeList, resultItem => {
                                        if (resultItem.type === room_type) {
                                            return <MenuItem key={resultItem.id} value={resultItem.id} disabled={resultItem.status ? false : true}>{resultItem.name}</MenuItem>
                                        }
                                    })}
                                </Select>
                                <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.agency_id ? inputErrors.agency_id : ''}</FormHelperText>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t(`${MODULE}.room_no`)+"*"}
                                variant="outlined"
                                value={state.room_no}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.quantity ? inputErrors.quantity : ''}
                                error={inputErrors && inputErrors.quantity ? true : false}
                                onChange={({ target }) => setState({ ...state, room_no: target.value })}
                            />
                        </Box>
                    </Grid>
                    {
                        formType === 'edit'
                        ?
                        <>
                            <Grid item xs={12} sm={6}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body2">
                                            {t(`${MODULE}.booking_date`)}
                                        </Typography>
                                    </Box>
                                </Box>
                                {
                                    _.map(state.booking_date, (booking, key) => {
                                        var booking_display = i18n.language === 'en' 
                                                                ? (new Date(booking).getDate() + " " + monthList[new Date(booking).getMonth()].label + " " + new Date(booking).getFullYear())
                                                                : (new Date(booking).getDate() + "-" + monthList[new Date(booking).getMonth()].label + "-" + new Date(booking).getFullYear())
                                            
                                        return (
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} key={key} >
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <TextField
                                                        fullWidth
                                                        size="default"
                                                        variant="outlined"
                                                        disabled
                                                        value={booking_display}
                                                        InputLabelProps={{ shrink: true }}
                                                        helperText={inputErrors && inputErrors['booking_date.' + key] ? inputErrors['booking_date.' + key] : ''}
                                                        error={inputErrors && inputErrors['booking_date.' + key] ? true : false}
                                                    />
                                                </Box>
                                            </Box>
                                        )
                                    })
                                }
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <FormControl fullWidth>
                                        <InputLabel>{t(`${MODULE}.status`)}*</InputLabel>
                                        <Select
                                            value={statusList ? state.status : ''}
                                            label={`${t(`${MODULE}.status`)}*`}
                                            name="status"
                                            error={inputErrors && inputErrors.status ? true : false}
                                            onChange={({ target }) => setState({ ...state, status: target.value })}
                                        >
                                            {_.map(statusList, resultItem => {
                                                return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language]}</MenuItem>
                                            })}
                                        </Select>
                                        <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Grid>
                        </>
                        :
                        null
                    }
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                        <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={styles.buttonStyle}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                </Box>
            </div>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
}))