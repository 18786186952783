import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, Switch, FormControl, FormControlLabel, FormLabel, FormGroup, FormHelperText, RadioGroup, Radio } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import { HiArrowNarrowRight } from "react-icons/hi";
import { IoAdd } from "react-icons/io5";

const MODULE = "profile-advertisements";
const MODULE_SINGLE = "profile-advertisement";

const ProfileDetail = (props) => {
    const { type, recommendWidth, recommendHeight } = props;
    
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);

    const [inputErrors, setInputErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        backgroundImage: "",
        link: "",
        status: false,
    });

    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();

    const onDrop = useCallback(acceptedFiles => {
        setState({ ...state, newImage: acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })) });
    }, [state]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 1, multiple: false });

    useEffect(() => {
        isMountedRef.current = true;
        if (type === 'profile_adv') {
            getUrl(`/${MODULE_SINGLE}/${type}`).then(response => {
                if (isMountedRef.current) {
                    if (response.status) {
                        setState({
                            ...state,
                            backgroundImage: response.data.file_name,
                            link: response.data.link,
                            status: response.data.status === "1" ? true : false,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [addAlert, type])

    const submitData = () => {
        let apiData = {
            link: state.link,
            status: state.status ? 1:0, 
            _method: "put",
        };
 
        if(state.newImage){
            apiData={
                ...apiData,
                new_image: state.newImage,
            }
        }

        setLoading(true);
        setInputErrors();
 
        const formData = new FormData();
        buildFormData(formData, apiData);

        postUrl(`/${MODULE_SINGLE}/${type}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === "newImage") {
            setState({ ...state, [name]: target.files[0] });
        } else if (name === 'status') {
            setState({ ...state, status: target.checked });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const removeImage = () => {
        setState({ ...state, newImage: '' });
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <Box >
                    {
                        state?.newImage?.[0]?.preview ?
                            <div style={{ height: 163, width: 'auto', marginBottom: 25 }}>
                                <img
                                    src={state?.newImage?.[0]?.preview}
                                    alt="profile advertisement"
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(state?.newImage?.[0]?.preview) }}
                                    className={classes.ads}
                                />
                                <Button onClick={removeImage}>{t('button.removeImage')}</Button>
                            </div>
                            :
                            (
                                state?.backgroundImage === null && isEmpty(state.backgroundImage) ?
                                    <p>{t('general.pleaseUploadImage')}</p> :
                                    <div style={{ height: 163, width: 'auto', marginBottom: 25 }}>
                                        <img
                                            src={state?.backgroundImage}
                                            alt="profile advertisement"
                                            // Revoke data uri after image is loaded
                                            onLoad={() => { URL.revokeObjectURL(state?.backgroundImage) }}
                                            className={classes.ads}
                                        />
                                    </div>
                            )
                    }
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={t(`${MODULE}.link`)}
                                variant="outlined"
                                value={state.link || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.link ? inputErrors.link : ''}
                                error={inputErrors && inputErrors.link ? true : false}
                                name="link"
                                onChange={handleChange}
                                fullWidth
                            />
                            <Typography style={{ fontSize: 14 }}>{t('general.example', { example: "https://www.google.com" })}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} style={{ paddingTop:0}}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={state.status || false } onChange={handleChange} name="status" />}
                                    label={t(`${MODULE}.status`)}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                            <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <label htmlFor={type} className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                                <input accept="image/*" id={type} type="file" style={{ display: "none" }} onChange={handleChange} {...getInputProps()} />
                                <IoAdd style={{ fontSize: "3rem" }} />
                                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
                            </label>
                            {
                                inputErrors && inputErrors?.new_image && <Typography variant="caption" style={{ color: 'red' }}>{inputErrors.new_image}</Typography>
                            }
                            <Typography style={{ fontSize: 14 }}>{t('general.recommendedSize', { width: recommendWidth, height: recommendHeight })}</Typography>
                        </Box>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

const useStyles = makeStyles(theme => ({
    uploadMedia: {
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
       flexDirection: "column",
       backgroundColor: "#fff",
       boxShadow: "3px 3px 20px 0 #d8d8d8",
       borderRadius: 15,
       border: "2px dashed #aeaeae",
       width: "100%",
       height: 100,
       marginTop: 15,
       padding: 20,
    },
    documentTitleStyle: {
       cursor: 'pointer',
       "&:hover": {
           color: theme.palette.secondary.main
       },
    },
    // rsvp
    rsvpTextBox: {
       padding: "3% 0% 3% 10% !important",
       [theme.breakpoints.up('xs')]: {
           width: "100% !important",
           padding: "3%",
       },
       [theme.breakpoints.up('sm')]: {
           width: "55% !important",
       },
       [theme.breakpoints.up('md')]: {
           width: "60% !important",
       },
    },
    rsvpImageBox: {
       [theme.breakpoints.up('xs')]: {
           width: "0% !important",
       },
       [theme.breakpoints.up('sm')]: {
           width: "45% !important",
       },
       [theme.breakpoints.up('md')]: {
           width: "40% !important",
       },
       boxShadow: "14px 0 20px 0 " + theme.palette.primary.main + " inset",
       backgroundSize: "cover",
       backgroundRepeat: "no-repeat",
    },
    rsvpText: {
       // do nothing
    },
    rsvpLink: {
       "&:hover": {
           borderColor: "#fff !important"
       }
    },
    //ads
    ads: {
       width: '100%',
       height: '100%',
       objectFit: 'fill',
    },
}))
    
export default ProfileDetail;