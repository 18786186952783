import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { AddBoxOutlined, Delete, Edit, Save } from '@mui/icons-material';
import { Box, Button, FormGroup, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, putUrl, postUrl, deleteUrl } from '../../../helpers/ApiAction';
import { buildFormData } from '../../../helpers/Tools';
import useNotificationLoading from '../../../helpers/useNotificationLoading';

import OptionDailyLimit from '../../PackageDailyLimit';

const MODULE = "packages";
const MODULE_SINGLE = "package";

const EditOption = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [packageType, setPackageType] = useState('');
    const [agencyData, setAgencyData] = useState({});
    const [rank, setRank] = useState({});
    const [rankDialog, setRankDialog] = useState({});
    const [options, setOptions] = useState([]);
    const [optionDialog, setOptionDialog] = useState({
        open: false,
        product_option_id: '',
        name: '',
        // sell_price: '',
        // retail_price: '',
        price: {
            retail: '',
            ranks: { "0": { weekday: '', weekend: '', holiday: '' } },
            applyToAll: 0,
        },
        quantity: '',
        daily_limit: '',
        profit_sharing: '',
        description: '',
        status: 0,
    });
    const [deleteOptionDialog, setDeleteOptionDialog] = useState({
        open: false,
        product_option_id: '',
    });

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { role } = useSelector(state => state.general);

    useEffect(() => {
        setLoading(true);
        getUrl(`/ranks`).then(response => {
            if (response.status) {
                let item = { weekday: '', weekend: '', holiday: '' };
                let value = { "0": item };
                let rankValue = {
                    "0":
                    {
                        id: 0,
                        code: "0001",
                        name: "Normal Price",
                        display_name: { en: 'Normal Price', cn: '正常价格' },
                        status: 1,
                        status_display: { en: 'Active', cn: '活跃' },
                        created_at: "2023-03-23 11:53:12",
                        created_by: null,
                        updated_at: "2023-03-23 11:53:12",
                        updated_by: null,
                    }
                };
                let rankDialogValue = rankValue;

                for (var i = 0; i < _.size(response.data.listing); i++) {
                    if (response.data.listing[i]['status'] === 1) {
                        value[response.data.listing[i]['id']] = item;
                        rankDialogValue[response.data.listing[i]['id']] = response.data.listing[i]
                    }
                    rankValue[response.data.listing[i]['id']] = response.data.listing[i];
                }

                setOptionDialog({ ...optionDialog, price: { ...optionDialog.price, ranks: Object.assign({}, value) } });
                setRankDialog(rankDialogValue);
                setRank(rankValue);
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/${MODULE}/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setPackageType(response.data.package.type);
                setOptions(response.data.package.package_options);
                setAgencyData(response.data.package.agency);
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleDialogOpen = (product_option_id) => {
        if (product_option_id) {
            let optionData = _.find(options, function (o) { return o.id === product_option_id; });
            setOptionDialog({
                open: true,
                product_option_id: product_option_id,
                name: optionData.name,
                // sell_price: optionData.sell_price,
                // retail_price: optionData.retail_price,
                price: {
                    retail: optionData.price['retail'],
                    ranks: Object.assign({}, optionData.price['ranks']),
                    applyToAll: optionData.price['applyToAll'] ? 1 : 0,
                },
                quantity: optionData.quantity,
                daily_limit: optionData.daily_limit,
                description: optionData.description ? optionData.description : '',
                profit_sharing: optionData.profit_sharing,
                status: optionData.status,
            });
        } else {
            let item = { weekday: '', weekend: '', holiday: '' };
            let temp_item = [item];

            for (var i = 0; i < _.size(rankDialog); i++) {
                if (rankDialog[i].status) {
                    temp_item[i] = item;
                }
            }

            setOptionDialog({
                open: true,
                name: '',
                product_option_id: '',
                // sell_price: '',
                // retail_price: '',
                price: {
                    retail: '',
                    ranks: Object.assign({}, temp_item),
                    applyToAll: 0,
                },
                quantity: '',
                daily_limit: '',
                description: '',
                profit_sharing: agencyData ? agencyData.profit_sharing : '',
                status: 0,
            });
        }
    }
    const handleDialogClose = () => {
        setInputErrors();

        let item = { weekday: '', weekend: '', holiday: '' };
        let temp_item = [item];

        for (var i = 0; i < _.size(rankDialog); i++) {
            if (rankDialog[i].status) {
                temp_item[i] = item;
            }
        }

        setOptionDialog({
            product_option_id: '',
            open: false,
            name: '',
            // sell_price: '',
            // retail_price: '',
            price: {
                retail: '',
                ranks: Object.assign({}, temp_item),
                applyToAll: 0,
            },
            quantity: '',
            daily_limit: '',
            description: '',
            profit_sharing: '',
            status: 0,
        });
    }

    const handleDeleteDialogOpen = (product_option_id) => {
        setDeleteOptionDialog({
            open: true,
            product_option_id: product_option_id,
        });
    }
    const handleDeleteDialogClose = () => {
        setDeleteOptionDialog({
            open: false,
            product_option_id: '',
        });
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'status') {
            setOptionDialog({ ...optionDialog, status: target.checked ? 1 : 0 });
        } else {
            setOptionDialog({ ...optionDialog, [name]: value });
        }
    }

    const handlePriceChange = (target, index) => {
        const { name, value } = target;

        if (name === 'retail') {
            setOptionDialog({ ...optionDialog, price: { ...optionDialog.price, [name]: value } });
        } else {
            if (optionDialog.price.applyToAll === 1) {
                if (name !== 'weekday') {
                    setOptionDialog({ ...optionDialog, price: { ...optionDialog.price, applyToAll: 0, ranks: { ...optionDialog.price.ranks, [index]: { ...optionDialog.price.ranks[index], [name]: value } } } });
                } else {
                    setOptionDialog({ ...optionDialog, price: { ...optionDialog.price, ranks: { ...optionDialog.price.ranks, [index]: { weekday: value, weekend: value, holiday: value } } } });
                }
            } else {
                setOptionDialog({ ...optionDialog, price: { ...optionDialog.price, ranks: { ...optionDialog.price.ranks, [index]: { ...optionDialog.price.ranks[index], [name]: value } } } });
            }
        }
    }

    const deleteOption = () => {
        setLoading(true);
        deleteUrl(`/${MODULE}/${id}/options/${deleteOptionDialog.product_option_id}`).then(response => {
            setLoading(false);
            handleDeleteDialogClose();
            if (response.status) {
                let newOptions = _.filter(options, function (o) { return o.id !== deleteOptionDialog.product_option_id });
                setOptions(newOptions);
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleCheckChange = ({ target }) => {
        setOptionDialog({ ...optionDialog, price: { ...optionDialog.price, applyToAll: target.checked ? 1 : 0 } });

        let temp_item = optionDialog.price;

        if (target.checked) {
            _.map(optionDialog.price.ranks, (price_ranks, key) => {
                temp_item.ranks[key].weekend = temp_item.ranks[key].holiday = price_ranks.weekday;
            })
        } else {
            _.map(optionDialog.price.ranks, (price_ranks, key) => {
                temp_item.ranks[key].weekday = price_ranks.weekday;
                temp_item.ranks[key].weekend = price_ranks.weekend;
                temp_item.ranks[key].holiday = price_ranks.holiday;
            })
        }
    };

    const createEditOption = () => {
        //setLoading(true);
        setInputErrors();
        let postData = {
            name: optionDialog.name,
            // sell_price: optionDialog.sell_price,
            // retail_price: optionDialog.retail_price,
            price: optionDialog.price,
            deduct_quantity: packageType === 'entrance' ? 1 : 0,
            quantity: packageType === 'entrance' ? optionDialog.quantity : 0,
            daily_limit: packageType === 'tour' ? optionDialog.daily_limit : 0,
            description: optionDialog.description ? optionDialog.description : null,
            profit_sharing: optionDialog.profit_sharing ? optionDialog.profit_sharing : null,
            status: optionDialog.status,
        };
        if (optionDialog.product_option_id > 0) {
            // update old option
            putUrl(`/${MODULE}/${id}/options/${optionDialog.product_option_id}`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    let oldKey = _.findKey(options, function (o) { return o.id === optionDialog.product_option_id; });
                    setOptions({ ...options, [oldKey]: response.data });
                    handleDialogClose();
                    addAlert('', response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            // create new option
            postUrl(`/${MODULE}/${id}/options`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    let newKey = _.size(options);
                    setOptions({ ...options, [newKey]: response.data });
                    handleDialogClose();
                    addAlert('', response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const list = reorder(
            options,
            result.source.index,
            result.destination.index
        );
        setOptions(list);
    }

    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        boxShadow: '0 0 6px 0 #0002',
        backgroundColor: theme.palette.primary.main,
        padding: "5px 10px",
        border: "1px white solid",
        borderRadius: "10px",
        color: "white",
        // styles we need to apply on draggables
        ...draggableStyle,
    });
    const getListStyle = isDraggingOver => ({
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });

    const updateSequence = () => {
        let postData = {
            option_ids: _.values(_.mapValues(options, 'id'))
        }
        putUrl(`/${MODULE}/${id}/options/sequence`, postData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            {/* SEQUENCE */}
            <Box style={{ display: "flex", alignItems: "center" }}>
                <Typography variant="body2" style={{ marginRight: 10 }}>{t('packages.optionSequence')}</Typography>
                <IconButton style={{ color: theme.palette.primary.main }} onClick={() => updateSequence()}><Save /></IconButton>
            </Box>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='package_option' direction="horizontal">
                    {
                        (provided, snapshot) => (
                            <Box
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}
                            >
                                {
                                    _.map(options, (detail, key) => {
                                        return (
                                            <Draggable key={detail.id} draggableId={`${detail.id}`} index={key}>
                                                {
                                                    (provided, snapshot) => (
                                                        <Box
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                        >
                                                            {detail.name}
                                                        </Box>
                                                    )
                                                }
                                            </Draggable>
                                        )
                                    })
                                }
                                {provided.placeholder}
                            </Box>
                        )
                    }
                </Droppable>
            </DragDropContext>
            {/* OPTION LIST */}
            <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{t(`table.actions`)}</TableCell>
                            <TableCell>{t(`${MODULE}.name`)}</TableCell>
                            <TableCell>{t(`${MODULE}.status`)}</TableCell>
                            {
                                packageType === 'entrance'
                                    ?
                                    <TableCell align="right">{t(`${MODULE}.quantity`)}</TableCell>
                                    :
                                    null
                            }
                            {
                                packageType === 'tour'
                                    ?
                                    <TableCell>{t(`${MODULE}.daily_limit`)}</TableCell>
                                    :
                                    null
                            }
                            <TableCell align="right">{t(`${MODULE}.profit_sharing`)}</TableCell>
                            {/* <TableCell align="right">{t(`${MODULE}.sell_price`)}</TableCell>
                            <TableCell align="right">{t(`${MODULE}.retail_price.price`)}</TableCell> */}
                            <TableCell align="right" style={{ minWidth: 600 }}>{t(`${MODULE}.price.price`)}</TableCell>
                            {/* <TableCell>{t(`${MODULE}.description.description`)}</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            _.map(options, (detail) => {
                                return (
                                    <TableRow
                                        key={detail.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <Tooltip title={t(`${MODULE}.editOption`)}>
                                                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDialogOpen(detail.id)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t(`${MODULE}.deleteOption`)}>
                                                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeleteDialogOpen(detail.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell>{detail.name}</TableCell>
                                        <TableCell>{t(`${MODULE}.statusList.` + detail.status)}</TableCell>
                                        {
                                            packageType === 'entrance'
                                                ?
                                                <TableCell align="right">{detail.quantity}</TableCell>
                                                :
                                                null
                                        }
                                        {
                                            packageType === 'tour'
                                                ?
                                                <TableCell>
                                                    <Box>{t(`${MODULE}.defaultDailyLimit`) + ': ' + detail.daily_limit}</Box>
                                                    <OptionDailyLimit package_option_id={detail.id} option_name={detail.name} />
                                                </TableCell>
                                                :
                                                null
                                        }
                                        <TableCell align="right">{detail.profit_sharing ? detail.profit_sharing : '-'}</TableCell>
                                        {/* <TableCell align="right">{detail.sell_price}</TableCell>
                                        <TableCell align="right">{detail.retail_price}</TableCell> */}
                                        <TableCell align="right">
                                            {
                                                _.size(rank) > 0 &&
                                                _.map(detail.price, (amount, name) => {
                                                    return (
                                                        name !== 'applyToAll' &&
                                                        <Box key={name}>
                                                            {
                                                                name === 'retail'
                                                                    ? t(`${MODULE}.price.${name}`) + ": " + parseFloat(amount).toFixed(2)
                                                                    :
                                                                    _.map(Object.values(rank).sort((a, b) => a.code - b.code), (rankDetail, value) => {
                                                                        return (
                                                                            _.map(detail.price.ranks, (amountDetail, nameKey) => {
                                                                                if (rankDetail.id === parseInt(nameKey)) {
                                                                                    return (
                                                                                        <div key={nameKey} style={{ whiteSpace: 'pre-wrap' }}>
                                                                                            {
                                                                                                rank[nameKey]
                                                                                                    ? rank[nameKey].display_name[i18n.language] + ": [" +
                                                                                                    t(`${MODULE}.price.ranks.weekday`) + ":" + parseFloat(amountDetail.weekday).toFixed(2) + ", " +
                                                                                                    t(`${MODULE}.price.ranks.weekend`) + ":" + parseFloat(amountDetail.weekend).toFixed(2) + ", " +
                                                                                                    t(`${MODULE}.price.ranks.holiday`) + ":" + parseFloat(amountDetail.holiday).toFixed(2) + "]\n"
                                                                                                    : ""
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            })
                                                                        )
                                                                    })
                                                            }
                                                        </Box>
                                                    )
                                                })
                                            }
                                        </TableCell>
                                        {/* <TableCell>{detail.description ? detail.description : '-'}</TableCell> */}
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            {/* ADD NEW OPTION */}
            <Box display="flex" alignItems="center" justifyContent="flex-end">
                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDialogOpen(0)}>
                    <AddBoxOutlined />
                </IconButton>
                <Typography>{t(t(`${MODULE}.addOption`))}</Typography>
            </Box>
            {/* ADD/EDIT OPTION DIALOG */}
            <Dialog open={optionDialog.open} onClose={handleDialogClose} fullWidth maxWidth="lg">
                <DialogTitle>{optionDialog.product_option_id ? t(`${MODULE}.editOption`) : t(`${MODULE}.addOption`)}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ paddingBottom: '5%' }}>
                        <Grid item xs={12}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={optionDialog.status ? true : false}
                                                name="status"
                                                onChange={handleChange}
                                            />
                                        }
                                        labelPlacement="start"
                                        label={t(`${MODULE}.status`)}
                                    />
                                    {
                                        inputErrors && inputErrors.status
                                            ?
                                            <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.status}</Typography>
                                            :
                                            null
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={4.5}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.name`)}*`}
                                    variant="outlined"
                                    value={optionDialog.name}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                    error={inputErrors && inputErrors.name ? true : false}
                                    name="name"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        {
                            packageType === 'entrance'
                                ?
                                <Grid item xs={6} sm={2.5}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.quantity`)}*`}
                                            type="number"
                                            variant="outlined"
                                            value={optionDialog.quantity}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.quantity ? inputErrors.quantity : ''}
                                            error={inputErrors && inputErrors.quantity ? true : false}
                                            name="quantity"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                :
                                null
                        }
                        {
                            packageType === 'tour'
                                ?
                                <Grid item xs={6} sm={2.5}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.daily_limit`)}*`}
                                            type="number"
                                            variant="outlined"
                                            value={optionDialog.daily_limit}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.daily_limit ? inputErrors.daily_limit : ''}
                                            error={inputErrors && inputErrors.daily_limit ? true : false}
                                            name="daily_limit"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                :
                                null
                        }
                        {
                            _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0
                                ?
                                <Grid item xs={6} sm={2.5}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            label={`${t(`${MODULE}.profit_sharing`)}*`}
                                            type="number"
                                            variant="outlined"
                                            value={optionDialog.profit_sharing}
                                            InputLabelProps={{ shrink: true }}
                                            helperText={inputErrors && inputErrors.profit_sharing ? inputErrors.profit_sharing : ''}
                                            error={inputErrors && inputErrors.profit_sharing ? true : false}
                                            name="profit_sharing"
                                            onChange={handleChange}
                                        />
                                    </Box>
                                </Grid>
                                :
                                null
                        }
                        <Grid item xs={6} sm={2.5}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.price.retail`)}`}
                                    variant="outlined"
                                    value={optionDialog.price.retail}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors['price.retail'] ? inputErrors['price.retail'] : ''}
                                    error={inputErrors && inputErrors['price.retail'] ? true : false}
                                    name={'retail'}
                                    onChange={(event) => handlePriceChange(event.target, 0)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            {
                                _.size(rankDialog) > 0 &&
                                _.map(Object.values(rankDialog).sort((a, b) => a.code - b.code), (rankDetail, value) => {
                                    if (rankDetail.status) {
                                        return (
                                            <Grid container spacing={2} key={value}>
                                                <Grid item xs={12} sm={1.5} className={classes.rankPrice}>
                                                    <Typography>{rankDetail.display_name[i18n.language]}</Typography>
                                                </Grid>
                                                {
                                                    _.map(optionDialog.price.ranks, (amount, name) => {
                                                        return (
                                                            _.map(amount, (amountDetail, index) => {
                                                                if (rankDetail.id === parseInt(name)) {
                                                                    return (
                                                                        <Grid item xs={6} sm={3.5} key={index}>
                                                                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                                                <TextField
                                                                                    size="small"
                                                                                    margin="dense"
                                                                                    label={`${t(`${MODULE}.price.ranks.${index}`)}*`}
                                                                                    variant="outlined"
                                                                                    value={amountDetail}
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    helperText={inputErrors && inputErrors['price.ranks.' + name + "." + index] ? inputErrors['price.ranks.' + name + "." + index] : ''}
                                                                                    error={inputErrors && inputErrors['price.ranks.' + name + "." + index] ? true : false}
                                                                                    name={index}
                                                                                    onChange={(event) => handlePriceChange(event.target, name)}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    )
                                                                }
                                                            })
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        )
                                    }
                                })
                            }
                        </Grid>
                        <Grid item xs={12} style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                            <FormGroup>
                                <FormControlLabel label={t('packages.applyToAll')} control={
                                    <Checkbox checked={optionDialog.price.applyToAll === 1 ? true : false} onChange={handleCheckChange} inputProps={{ 'aria-label': 'controlled' }} />
                                } />
                            </FormGroup>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.sell_price`)}*`}
                                    variant="outlined"
                                    value={optionDialog.sell_price}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.sell_price ? inputErrors.sell_price : ''}
                                    error={inputErrors && inputErrors.sell_price ? true : false}
                                    name="sell_price"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={`${t(`${MODULE}.retail_price`)}*`}
                                    variant="outlined"
                                    value={optionDialog.retail_price}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.retail_price ? inputErrors.retail_price : ''}
                                    error={inputErrors && inputErrors.retail_price ? true : false}
                                    name="retail_price"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid> */}
                        {/* <Grid item xs={12} sm={12}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.description.description`)}
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={optionDialog.description}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.description ? inputErrors.description : ''}
                                    error={inputErrors && inputErrors.description ? true : false}
                                    name="description"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid> */}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleDialogClose}>{t('button.cancel')}</Button>
                    <Button className={classes.confirmButton} onClick={createEditOption}>{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
            {/* DELETE OPTION DIALOG */}
            <Dialog open={deleteOptionDialog.open} onClose={handleDeleteDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>{t(`${MODULE}.deleteOption`)}</DialogTitle>
                <DialogContent>
                    {t(`${MODULE}.deleteOptionNote`)}
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleDeleteDialogClose}>{t('button.no')}</Button>
                    <Button className={classes.confirmButton} onClick={deleteOption}>{t('button.yes')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    confirmButton: {
        backgroundColor: theme.palette.secondary.main + '! important',
        color: theme.palette.secondary.secondary + '! important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.secondary + '! important',
        color: theme.palette.secondary.main + '! important',
        border: '1px solid ' + theme.palette.secondary.main + '! important',
    },
    rankPrice: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up('xs')]: {
            justifyContent: "flex-start",
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end",
        },
    }
}))

export default EditOption