import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, FormControlLabel, Grid, InputAdornment, Link, Switch, TextField, Typography } from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "ranks";
const MODULE_SINGLE = "rank";
const LANG = ['en', 'cn'];

const Detail = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        code: '',
        name: '',
        display_name: '',
        status: 1,
    });
    const [fieldLang, setFieldLang] = useState({ display_name: 'en' });

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const location = useLocation();
    const formType = location.pathname === `/${MODULE}-add` ? "add" : "edit";

    useEffect(() => {
        isMountedRef.current = true;

        if (formType === 'edit' && id) {
            setLoading(true);
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setState(response.data);
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === 'status') {
            setState({ ...state, status: target.checked ? 1 : 0 });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const submitData = () => {
        let urlMethod = '';
        const postData = {
            code: state.code,
            name: state.name,
            display_name: state.display_name,
            status: state.status,
        }

        if (formType === 'edit') {
            urlMethod = putUrl;
        } else {
            urlMethod = postUrl;
        }

        setLoading(true);
        setInputErrors();
        urlMethod(`/${MODULE}${formType === "edit" ? `/${id}` : ""}`, postData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', formType === "edit" ? t('success.editSuccess') : t('success.createSuccess'), 'success', '');
                navigate(`/${MODULE}/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', formType === "edit" ? t('error.editError') : t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.code`)}
                            variant="outlined"
                            value={state.code || ""}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                            InputProps={{ readOnly: formType === 'edit' ? true : false }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name || ""}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            margin='normal'
                            label={t(`${MODULE}.displayName`)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {
                                            _.map(LANG, (value) => (
                                                <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, display_name: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.display_name === value ? theme.palette.primary.main : '#efefef', color: fieldLang.display_name === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                            ))
                                        }
                                    </InputAdornment>
                            }}
                            onChange={({ target }) => setState({ ...state, display_name: { ...state.display_name, [fieldLang.display_name]: target.value } })}
                            value={state.display_name && state.display_name[fieldLang.display_name] ? state.display_name[fieldLang.display_name] : ""}
                            helperText={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn'])}
                            error={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.status ? true : false}
                                        onChange={handleChange}
                                        name="status"
                                    />
                                }
                                labelPlacement="start"
                                label={t(`${MODULE}.status`)}
                            />
                            {
                                inputErrors && inputErrors.status
                                    ?
                                    <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.status}</Typography>
                                    :
                                    null
                            }
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Detail