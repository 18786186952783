import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { AddBoxOutlined, Close } from '@mui/icons-material';
import { IoTrashOutline } from "react-icons/io5";
import { Box, Breadcrumbs, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, Grid, IconButton, Link, Tab, Tabs, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl, deleteUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

import HomeDetail from './HomeDetail';
import ProfileDetail from './ProfileDetail';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MODULE = "commercials";
const MODULE_SINGLE = "commercial";

const Index = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [statusList, setStatusList] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [refreshData, setRefreshData] = useState(false);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const [currId, setCurrId] = useState(0);
    const [currAction, setCurrAction] = useState("add");
    const [deleteId, setDeleteId] = useState("");

    const callApi = () => {
        if (value === 0) {
            setTableLoading(true);
            let apiData = {
                page: pagination.pageIndex+1,
                per_page: pagination.pageSize,
                filters: columnFilters,
                sorting: sorting[0],
            }

            getUrl(`/${MODULE}`, apiData).then(response => {
                setTableLoading(false);
                if (response.status) {
                    setData(response.data.listing.data);
                    setModuleStatusList(response.data.status_list);
                    setRowCount(response.data.listing.total);
                } else {
                    addAlert("", t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setTableLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setTableLoading(false);
        }
    }

    useEffect(() => {
        callApi();
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData, refreshData]);

    useEffect(() => {
        let advStatusList = [];
        _.map(moduleStatusList, status => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            advStatusList.push({ text: textDisplay, value: textDisplay });
        })
        setStatusList(advStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClickOpen = (value, id) => {
        if (value === 'edit') {
            setCurrId(id);
            setCurrAction(value);
            setOpen(true);
        } else {
            setCurrAction('add');
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const deleteAdvertisement = () => {
        setLoading(true);
        deleteUrl(`/${MODULE}/${deleteId}`)
            .then((response) => {
                setLoading(false);
                if (response.status) {
                    addAlert('', t('success.deleteSuccess'), 'success', '');
                    callApi();
                } else {
                    addAlert('', t('error.deleteError'), 'error', '');
                }
            }).catch((error) => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        setDeleteId("");
    };

    const columns = useMemo(() => ([
        {
            accessorKey: 'title',
            header: `${t(`${MODULE}.title`)}`,
            id: 'title',
            Cell: ({ cell }) => (
                _.includes(permissions, `advertisement-edit`) ?
                <Link underline='none' onClick={() => handleClickOpen('edit', cell.row.original.id)} component={RouterLink}>
                    {cell.row.original.title ? cell.row.original.title : '-'}
                </Link>
                : <>{cell.row.original.title ? cell.row.original.title : '-'}</>
            )
        },
        {
            accessorKey: 'subtitle',
            header: `${t(`${MODULE}.subtitle`)}`,
            id: 'subtitle',
        },
        {
            accessorKey: 'link',
            header: `${t(`${MODULE}.link`)}`,
            id: 'link',
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.status_display ? row.status_display[i18n.language] : row.status_display.en),
            id: 'status_display.' + i18n.language,
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            enableSorting: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        {
            id: 'action',
            header: `${t(`table.actions`)}`,
            enableColumnOrdering: false,
            Cell: ({ cell }) => (
                _.includes(permissions, `advertisement-edit`) ?
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                    <IconButton onClick={() => setDeleteId(cell.row.original.id)}><IoTrashOutline size={20} /></IconButton>
                </Box>
                : null
            ),
            maxSize: '100'
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    const DialogDeleteField = () => {
        return (
            <Dialog
                open={deleteId !== "" ? true : false}
                onClose={() => setDeleteId("")}
                aria-labelledby="delete-dialog-title"
                aria-describedby="delete-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t(`${MODULE}.confirmationOnDelete`)}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{t(`${MODULE}.askDeleteAdv`)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => setDeleteId("")} color="secondary">{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={deleteAdvertisement} color="primary" autoFocus>{t('button.confirm')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>

            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={t(`${MODULE}.homeAdvertisement`)} {...a11yProps(0)} />
                <Tab label={t(`${MODULE}.profileAdvertisement`)} {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Box display="flex" alignItems="center" paddingBottom="15px">
                    <Typography variant="subtitle1">{t(`${MODULE}.homeAdvertisement`)}</Typography>
                    {_.includes(permissions, `advertisement-create`) ?
                        <Link underline='none' onClick={handleClickOpen} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <PaginationMaterialReactTable
                    columns={columns}
                    data={data}
                    isLoading={tableLoading}
                    exportFile={false}
                    rowCount={rowCount}
                    pagination={pagination}
                    setPagination={setPagination}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                    sorting={sorting}
                    setSorting={setSorting}
                    searchData={searchData}
                    setSearchData={setSearchData}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Box display="flex" alignItems="center" paddingBottom='15px'>
                    <Typography variant="subtitle1">{t(`${MODULE}.profileAdvertisement`)}</Typography>
                </Box>
                <ProfileDetail type='profile_adv' recommendWidth="1178" recommendHeight="163" />
            </TabPanel>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth={'lg'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {currAction === 'add' ? t(`${MODULE}.add`) : t(`${MODULE}.edit`)} {t(`${MODULE}.homeAdvertisement`)}
                    {open ? (
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                        >
                            <Close />
                        </IconButton>
                    ) : null}
                </DialogTitle>
                <DialogContent>
                    <HomeDetail type='home_adv' action={currAction} id={currId} handleClose={handleClose} refreshData={refreshData} setRefreshData={setRefreshData} recommendWidth="500" recommendHeight="250" />
                </DialogContent>
            </Dialog>
            <DialogDeleteField />
        </>
    )
}

export default Index