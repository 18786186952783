import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "locations";
const MODULE_SINGLE = "location";

const Edit = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        name: '',
        state: '',
        country: '',
        cover_photo: '',
        existing_image: '',
    });

    let { id } = useParams();
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        getUrl(`/${MODULE}/${id}`).then(response => {
            const { status, data } = response;
            if (status) {
                setState({
                    name: data.name,
                    state: data.state ? data.state : '',
                    country: data.country,
                    cover_photo: '',
                    existing_image: data.cover_photo ? data.cover_photo : null,
                });
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === "cover_photo") {
            setState({ ...state, [name]: target.files[0] });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let postData = {
            ...state,
            cover_photo: state.cover_photo ? state.cover_photo : null,
            _method: "put",
        };
        const formData = new FormData();
        buildFormData(formData, postData);
        postUrl(`/${MODULE}/${id}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                setState({
                    ...state,
                    existing_image: data.cover_photo,
                });
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Edit`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Edit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.state`)}
                            variant="outlined"
                            value={state.state}
                            InputLabelProps={{ shrink: true }}
                            // InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                            error={inputErrors && inputErrors.state ? true : false}
                            name="state"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.country`)}
                            variant="outlined"
                            value={state.country}
                            InputLabelProps={{ shrink: true }}
                            // InputProps={{ readOnly: true }}
                            helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                            error={inputErrors && inputErrors.country ? true : false}
                            name="country"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        {state.existing_image && <img src={state.existing_image.file_name} alt="cover" style={{ width: 'auto', height: 150, paddingLeft: 10 }} />}
                        <TextField
                            label={t(`${MODULE}.coverPhoto`)}
                            type="file"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.cover_photo ? inputErrors.cover_photo : ''}
                            error={inputErrors && inputErrors.cover_photo ? true : false}
                            name="cover_photo"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Edit