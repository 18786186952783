import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Checkbox, FormControlLabel, FormLabel, Grid, Link, ListItemText, MenuItem, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as XDatePicker } from '@mui/x-date-pickers/DatePicker';

import { getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "vouchers";
const MODULE_SINGLE = "voucher";

const Edit = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        code: '',
        voucher_type: '',
        voucher_name: '',
        voucher_price: '',
        duration_period: '',
        valid_date_from: '',
        valid_date_to: '',
        v_available_days: [],
        v_except_date: [],
        start_date: '',
        user_name: '',
        user_email: '',
        user_mobile: '',
        user_ic: '',
        user_dob: '',
        user_gender: '',
        user_role: '',
        status: '',
        status_log: '',
        scan_date: '',
    });
    const [availableDaysList, setAvailableDaysList] = useState([]);
    const [statusList, setStatusList] = useState([]);

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        getUrl(`/${MODULE}/${id}`).then(response => {
            if (response.status) {
                const { available_days_list, status_list, voucher } = response.data;
                setAvailableDaysList(available_days_list);
                setStatusList(status_list);
                setState({ ...state, ...voucher });
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }
    const handleDateChange = (value, name) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setState({ ...state, [name]: date });
    }

    const handleDateArrayChange = (dates) => {
        let dateArray = [];
        _.map(dates, date => {
            dateArray.push(Moment([date.year, date.monthIndex, date.day]).format('YYYY-MM-DD'));
        })
        setState({ ...state, "v_except_date": dateArray });
    }

    const getAvailableDaysDisplay = (selected) => {
        let display = '';
        _.map(availableDaysList, day => {
            if (selected.includes(day.code)) {
                display = display ? (display + ', ' + day[i18n.language]) : day[i18n.language];
            }
        })
        return display;
    }

    const submitData = () => {
        let formData = {
            status: state.status,
            voucher_type: state.voucher_type,
            valid_date_from: state.valid_date_from,
            valid_date_to: state.valid_date_to,
            v_available_days: state.v_available_days,
            v_except_date: state.v_except_date,
            start_date: state.start_date,
            user_name: state.user_name,
            user_email: state.user_email,
            user_mobile: state.user_mobile,
            user_ic: state.user_ic,
            user_dob: state.user_dob,
            user_gender: state.user_gender,
            user_role: state.user_role,
        }
        setLoading(true);
        setInputErrors();
        putUrl(`/${MODULE}/${id}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors, message } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, data }))
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 15 }}>
                <Typography style={{ fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Edit`)}</b></Typography>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                        {t(`title.${MODULE}`)}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Edit`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container spacing={2} style={{ paddingBottom: '5%' }}>
                {/* NON-EDITABLE INFO */}
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.name`)}*`}
                            variant="outlined"
                            value={state.voucher_name}
                            readOnly
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.code`)}*`}
                            variant="outlined"
                            value={state.code}
                            readOnly
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.price`)}*`}
                            variant="outlined"
                            value={state.voucher_price}
                            readOnly
                        />
                    </Box>
                </Grid>
                {/* OTHERS INFO */}
                {_.size(statusList) > 0 ?
                    <Grid item xs={12} sm={6} md={4}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={`${t(`${MODULE}.status`)}`}
                                select
                                variant="outlined"
                                value={state.status}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.status ? inputErrors.status : ''}
                                error={inputErrors && inputErrors.status ? true : false}
                                name="status"
                                onChange={handleChange}
                            >
                                {_.map(statusList, (statusType, code) => {
                                    return <MenuItem key={code} value={code}>{statusType[i18n.language]}</MenuItem>
                                })}
                            </TextField>
                        </Box>
                    </Grid>
                    : null}
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <XDatePicker
                                label={`${t(`${MODULE}.valid_date_from`)}`}
                                value={state.valid_date_from}
                                onChange={(newValue) => handleDateChange(newValue, 'valid_date_from')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.valid_date_from ? inputErrors.valid_date_from : ''}
                                        error={inputErrors && inputErrors.valid_date_from ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <XDatePicker
                                label={`${t(`${MODULE}.valid_date_to`)}`}
                                value={state.valid_date_to}
                                onChange={(newValue) => handleDateChange(newValue, 'valid_date_to')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.valid_date_to ? inputErrors.valid_date_to : ''}
                                        error={inputErrors && inputErrors.valid_date_to ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                {state.voucher_type === 'entrance' ?
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                select
                                variant="outlined"
                                label={`${t(`${MODULE}.v_available_days`)}*`}
                                name="v_available_days"
                                error={inputErrors && inputErrors.v_available_days ? true : false}
                                helperText={inputErrors && inputErrors.v_available_days ? inputErrors.v_available_days : ''}
                                onChange={handleChange}
                                SelectProps={{
                                    multiple: true,
                                    value: state.v_available_days,
                                    renderValue: (selected) => getAvailableDaysDisplay(selected)
                                }}
                            >
                                {_.map(availableDaysList, resultItem => {
                                    return (
                                        <MenuItem key={resultItem.code} value={resultItem.code}>
                                            <Checkbox checked={state.v_available_days.indexOf(resultItem.code) > -1} />
                                            <ListItemText primary={resultItem[i18n.language]} />
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                        </Box>
                    </Grid>
                    : null}
                {state.voucher_type === 'tour' ?
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <Typography variant="caption" style={{ color: "gray" }}>{t(`${MODULE}.v_except_date`)}</Typography>
                            <DatePicker
                                sort
                                multiple
                                plugins={[
                                    <DatePanel />
                                ]}
                                name="v_except_date"
                                value={state.v_except_date}
                                onChange={(dates) => handleDateArrayChange(dates)}
                                style={{ width: "100%", padding: "25px 20px" }}
                            />
                        </Box>
                    </Grid>
                    : null}
                {/* BOOKING INFO */}
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{`${t(`${MODULE}.bookingInfo`)}`}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <XDatePicker
                                label={`${t(`${MODULE}.booking_date`)}`}
                                value={state.start_date}
                                onChange={(newValue) => handleDateChange(newValue, 'start_date')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.start_date ? inputErrors.start_date : ''}
                                        error={inputErrors && inputErrors.start_date ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        {state.voucher_type === 'tour' ?
                            <TextField
                                label={`${t(`${MODULE}.duration_period`)}*`}
                                variant="outlined"
                                value={state.duration_period}
                                readOnly
                            />
                            : null}
                    </Box>
                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.scan_date`)}*`}
                            type="text"
                            variant="outlined"
                            value={state.scan_date}
                            InputLabelProps={{ shrink: true }}
                            name="scan_date"
                            readOnly
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >

                        <TextField
                            label={`${t(`${MODULE}.user_name`)}*`}
                            variant="outlined"
                            value={state.user_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_name ? inputErrors.user_name : ''}
                            error={inputErrors && inputErrors.user_name ? true : false}
                            name="user_name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.user_email`)}`}
                            type="text"
                            variant="outlined"
                            value={state.user_email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_email ? inputErrors.user_email : ''}
                            error={inputErrors && inputErrors.user_email ? true : false}
                            name="user_email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.user_mobile`)}`}
                            type="text"
                            variant="outlined"
                            value={state.user_mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_mobile ? inputErrors.user_mobile : ''}
                            error={inputErrors && inputErrors.user_mobile ? true : false}
                            name="user_mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.user_ic`)}*`}
                            type="text"
                            variant="outlined"
                            value={state.user_ic}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.user_ic ? inputErrors.user_ic : ''}
                            error={inputErrors && inputErrors.user_ic ? true : false}
                            name="user_ic"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <XDatePicker
                                label={`${t(`${MODULE}.user_dob`)}*`}
                                value={state.user_dob}
                                onChange={(newValue) => handleDateChange(newValue, 'user_dob')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.user_dob ? inputErrors.user_dob : ''}
                                        error={inputErrors && inputErrors.user_dob ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 5px" }}>
                        <FormLabel style={{ marginRight: 20 }}>{t('user.gender') + '*'}</FormLabel>
                        <RadioGroup row name="user_gender" value={state.user_gender} onChange={handleChange}>
                            <FormControlLabel value={'male'} control={<Radio size="small" />} label={t('user.male')} />
                            <FormControlLabel value={'female'} control={<Radio size="small" />} label={t('user.female')} />
                        </RadioGroup>
                    </Box>
                    {
                        inputErrors && inputErrors.user_gender
                            ?
                            <Typography variant="caption" color="error">{inputErrors.user_gender}</Typography>
                            :
                            null
                    }
                </Grid>
                <Grid item xs={12} sm={6} md={4} style={{ display: "flex", alignItems: "center" }}>
                    <Box style={{ display: "flex", justifyContent: "start", alignItems: "center", padding: "0px 5px" }}>
                        <FormLabel style={{ marginRight: 20 }}>{t('placeOrder.user_role') + '*'}</FormLabel>
                        <RadioGroup row name="user_role" value={state.user_role} onChange={handleChange}>
                            <FormControlLabel value={'member'} control={<Radio size="small" />} label={t('placeOrder.tour_member')} />
                            <FormControlLabel value={'guide'} control={<Radio size="small" />} label={t('placeOrder.tour_guide')} />
                            <FormControlLabel value={'leader'} control={<Radio size="small" />} label={t('placeOrder.tour_leader')} />
                        </RadioGroup>
                    </Box>
                    {
                        inputErrors && inputErrors.user_role
                            ?
                            <Typography variant="caption" color="error">{inputErrors.user_role}</Typography>
                            :
                            null
                    }
                </Grid>
            </Grid>
            {
                _.includes(permissions, `${MODULE_SINGLE}-status`)
                    ?
                    <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                        <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                            <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                    </Box>
                    :
                    null
            }
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default Edit