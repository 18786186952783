import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "vouchers";
const MODULE_SINGLE = "voucher";

const CheckIn = () => {
    const [data, setData] = useState([]);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions, role } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    let from_date = searchParams.get('from_date');
    let to_date = searchParams.get('to_date');
    const [searchField, setSearchField] = useState({
        from_date: from_date ? from_date : '',
        to_date: to_date ? to_date : '',
    });

    useEffect(() => {
        if (!from_date && !to_date) {
            let fromDate = new Date();
            fromDate = Moment([fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()]).format('YYYY-MM-DD');
            let toDate = fromDate;
            setSearchParams({
                from_date: fromDate,
                to_date: toDate,
            }, { replace: true });
            setSearchField({
                from_date: fromDate,
                to_date: toDate,
            });
        }
        setTableLoading(true);
        let apiData = {
            scan_date_from: searchField.from_date,
            scan_date_to: searchField.to_date,
            status: 50,
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [from_date, to_date, pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, (status, key) => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: key });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setSearchField({ ...searchField, [name]: value });
    }
    const handleDateChange = (value, name) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setSearchField({ ...searchField, [name]: date });
    }

    const handleSearchData = () => {
        setSearchParams(searchField, { replace: true });
    }
    const clearSearchData = () => {
        setSearchParams({}, { replace: true });
        setSearchField({
            from_date: '',
            to_date: '',
        });
    }

    const columns = useMemo(() => ([
        {
            accessorFn: (row) => (row.scan_date ? row.scan_date : '-'),
            id: 'scan_date',
            header: `${t(`${MODULE}.scan_date`)}`,
        },
        {
            accessorFn: (row) => (row.agency ? row.agency.name : '-'),
            id: 'agency.name',
            header: `${t(`${MODULE}.agency_id`)}`,
            Cell: ({ cell }) => (
                <Link underline='none' to={`/agencies/${cell.row.original.agency_id}`} component={RouterLink}>{cell.row.original.agency ? cell.row.original.agency.name : '-'}</Link>
            ),
            enableSorting: false,
        },
        {
            accessorKey: 'code',
            header: `${t(`${MODULE}.code`)}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `${MODULE_SINGLE}-edit`) ?
                    <Link underline='none' to={`/${MODULE}/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.code}</Link>
                    :
                    <>{cell.row.original.code}</>
            )
        },
        {
            accessorKey: 'voucher_name',
            header: `${t(`${MODULE}.name`)}`,
            minSize: '250',
        },
        {
            accessorFn: (row) => (row.user_name ? row.user_name : '-'),
            id: 'user_name',
            header: `${t(`${MODULE}.user_name`)}`,
        },
        {
            accessorFn: (row) => (row.user_ic ? row.user_ic : '-'),
            id: 'user_ic',
            header: `${t(`${MODULE}.user_ic`)}`,
            minSize: '300',
        },
        {
            accessorFn: (row) => (row.user_dob ? row.user_dob : '-'),
            id: 'user_dob',
            header: `${t(`${MODULE}.user_dob`)}`,
        },
        {
            accessorFn: (row) => (row.user_email ? row.user_email : '-'),
            id: 'user_email',
            header: `${t(`${MODULE}.user_email`)}`,
        },
        {
            accessorFn: (row) => (row.user_mobile ? row.user_mobile : '-'),
            id: 'user_mobile',
            header: `${t(`${MODULE}.user_mobile`)}`,
        },
        {
            accessorFn: (row) => (row.user_gender ? t('user.' + row.user_gender) : '-'),
            id: 'user_gender',
            header: `${t(`${MODULE}.user_gender`)}`,
        },
        {
            accessorFn: (row) => (row.user_role ? t('placeOrder.tour_' + row.user_role) : '-'),
            id: 'user_role',
            header: `${t(`${MODULE}.user_role`)}`,
        },
        {
            accessorFn: (row) => (row.status_display ? row.status_display[i18n.language] : row.status_display.en),
            id: 'status_display.' + i18n.language,
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            enableSorting: false,
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`reports.checkin`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`reports.checkin`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <Grid container spacing={2} style={{ paddingBottom: 5 }}>
                <Grid item xs={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={`${t(`reports.from_date`)}`}
                                value={searchField.from_date}
                                onChange={(newValue) => handleDateChange(newValue, 'from_date')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={`${t(`reports.to_date`)}`}
                                value={searchField.to_date}
                                onChange={(newValue) => handleDateChange(newValue, 'to_date')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        error={false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Button variant="contained" onClick={() => handleSearchData()}>
                            {t('button.search')}
                        </Button>
                    </Box>
                </Grid>
                <Grid item xs={2}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Button variant="contained" onClick={() => clearSearchData()}>
                            {t('reports.today')}
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'CheckInReport'}
                initialColumnVisibility={{ 'agency.name': _.size(_.intersection(role, ["dev", "super-admin", "admin"])) > 0 ? true : false }}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default CheckIn