import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, FormControlLabel, Grid, Link, TextField, Typography, InputLabel, MenuItem, Select, FormHelperText, Switch, Rating } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "reviews";
const MODULE_SINGLE = "review";

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        refType: '',
        refId: '',
        name: '',
        content: '',
        rating: [],
        status: 0
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const formType = location.pathname === `/${MODULE}-add` ? "add" : "edit";
    const [refTypeList, setRefTypeList] = useState([]);
    const [ratingCriteria, setRatingCriteria] = useState([]);

    useEffect(() => {
        isMountedRef.current = true;
        if (formType === 'edit' && id) {
            setLoading(true);
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        let { review, ref_type, rating_criteria } = response.data;
                        setRefTypeList(ref_type);
                        setRatingCriteria(rating_criteria);
                        setState({
                            refType: review.ref_type,
                            refId: review.ref_id,
                            name: review.name,
                            content: review.content,
                            rating: review.rating,
                            status: review.status,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            setLoading(true);
            getUrl(`/${MODULE}/create`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        let { ref_type, rating_criteria } = response.data;
                        setRefTypeList(ref_type);
                        setRatingCriteria(rating_criteria);
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    useEffect(() => {
        if (_.size(ratingCriteria) > 0) {
            let tempRating = {};
            _.map(ratingCriteria, (typeCriteria, type) => {
                if (type === state.refType) {
                    _.map(typeCriteria, (criteria, cname) => {
                        let value = _.find(state.rating, function (r, key) { return key === cname; });
                        value = value ? value : 0;
                        tempRating[cname] = parseFloat(value);
                    })
                }
            })
            setState({ ...state, rating: tempRating });
        } else {
            setState({ ...state, rating: [] });
        }
        // eslint-disable-next-line
    }, [addAlert, id, state.refType, ratingCriteria])
    console.log(state.rating);

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'status') {
            setState({ ...state, status: target.checked ? 1 : 0 });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const handleRatingChange = ({ target }) => {
        let { name, value } = target;
        setState({ ...state, rating: { ...state.rating, [name]: parseFloat(value) } });
    }

    const submitData = () => {
        let postData = {
            ref_type: state.refType,
            ref_id: state.refId,
            name: state.name,
            content: state.content,
            rating: state.rating,
            status: state.status,
            _method: formType === "edit" ? "put" : "post",
        };

        const formData = new FormData();
        buildFormData(formData, postData);

        setLoading(true);
        setInputErrors();
        postUrl(`/${MODULE}${formType === "edit" ? `/${id}` : ""}`, formData).then(response => {
            setLoading(false);
            let { status, data, errors, message } = response;
            addAlert('', message, status ? 'success' : 'error', '');
            if (status) {
                addAlert('', response.message, 'success', '');
                if (formType !== "edit") {
                    navigate(`/${MODULE}/${data.id}`, {replace: true});
                }
            } else {
                setInputErrors(errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <InputLabel>{`${t(`${MODULE}.refType`)} *`}</InputLabel>
                            <Select
                                value={state.refType}
                                label={`${t(`${MODULE}.refType`)} *`}
                                variant="outlined"
                                name="refType"
                                onChange={handleChange}
                                error={inputErrors && inputErrors.ref_type ? true : false}
                                disabled={formType === 'edit' ? true : false}
                            >
                                {_.map(refTypeList, type => {
                                    return (
                                        <MenuItem key={type} value={type}>
                                            <Typography style={{ textTransform: "capitalize" }}>{type}</Typography>
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                            <FormHelperText style={{ color: theme.palette.indicator.red }}>{inputErrors && inputErrors.ref_type ? inputErrors.ref_type : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.refId`)} *`}
                            variant="outlined"
                            value={state.refId}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.ref_id ? inputErrors.ref_id : ''}
                            error={inputErrors && inputErrors.ref_id ? true : false}
                            name="refId"
                            onChange={handleChange}
                            disabled={formType === 'edit' ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`user.name`)} *`}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.content`)} *`}
                            variant="outlined"
                            value={state.content}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.content ? inputErrors.content : ''}
                            error={inputErrors && inputErrors.content ? true : false}
                            name="content"
                            multiline
                            rows={3}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <InputLabel>{`${t(`${MODULE}.rating`)} *`}</InputLabel>
                        {
                            _.map(state.rating, (value, name) => {
                                if (name !== 'average') {
                                    let displayText = _.find(ratingCriteria[state.refType], function (r, key) { return key === name; });
                                    if (displayText) {
                                        return (
                                            <Box style={{ display: "flex", alignItems: "center" }} key={name}>
                                                <Typography variant="body" style={{ marginRight: 10 }}>{i18n.language === 'cn' ? displayText.cn : displayText.en}</Typography>
                                                <Rating
                                                    name={name}
                                                    value={parseFloat(value)}
                                                    precision={0.5}
                                                    onChange={handleRatingChange}
                                                />
                                            </Box>
                                        )
                                    }
                                }
                            })
                        }
                    </Box>
                </Grid>
                {
                    formType === 'edit'
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.status ? true : false}
                                                onChange={handleChange}
                                                name="status"
                                            />
                                        }
                                        labelPlacement="start"
                                        label={t(`${MODULE}.status.status`)}
                                    />
                                    {
                                        inputErrors && inputErrors.status
                                            ?
                                            <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.status}</Typography>
                                            :
                                            null
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button type="button" size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15, color: theme.palette.button.main, border: theme.palette.button.borderMain, boxShadow: '2px 4px 8px 0 #9f9f9f45' }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main, boxShadow: '2px 4px 8px 0 #00000045' }} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
}));