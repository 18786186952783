import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

// MUI
import { Link, TextField, Box, Button, Breadcrumbs, Grid, Typography, FormControl, InputLabel, Select, MenuItem, InputAdornment, Chip, ImageList, ImageListItemBar, ImageListItem } from '@mui/material/';
import { makeStyles, useTheme } from '@mui/styles';
import { FiArrowLeft } from "react-icons/fi";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import { postUrl, getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const MODULE = "collections";
const MODULE_SINGLE = "collection";
const LANG = ['en', 'cn'];

const Detail = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        code: '',
        display_name: '',
        start_date: null,
        end_date: null,
        package_ids: "",
        packages: [],
        status: 1,
    });
    const [option, setOption] = useState({});
    const [fieldLang, setFieldLang] = useState({ display_name: 'en' });
    const [updateList, setUpdateList] = useState({
		package_ids: state.package_ids
	});

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const location = useLocation();
    const formType = location.pathname === `/${MODULE_SINGLE}-add` ? "add" : "edit";

    useEffect(() => {
        isMountedRef.current = true;

        if (formType === 'edit' && id) {
            setLoading(true);
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setState({
                            ...state,
                            ...response.data,
                        });
                        console.log(response.data)
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }

        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    useEffect(() => {
        setLoading(true);
        getUrl(`/${MODULE}-option`).then(response => {
            setLoading(false);
            if (response.status) {
                setOption(response.data);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === "name") {
            const code = _.kebabCase(value);
            setState({ ...state, [name]: value, code: code });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const handleStartDateTimeChange = newValue => {
        setState({ ...state, 'start_date': newValue });
    }

    const handleEndDateTimeChange = newValue => {
        setState({ ...state, 'end_date': newValue });
    }

    const submitData = () => {
        let urlMethod = '';
        const postData = {
            code: state.code,
            name: state.name,
            display_name: state.display_name,
            package_ids: state.package_ids,
            status: state.status,
            start_date: state.start_date ? moment(state.start_date).format('YYYY-MM-DD HH:mm:ss') : null,
            end_date: state.end_date ? moment(state.end_date).format('YYYY-MM-DD HH:mm:ss') : null,
        }
        console.log("postData", postData);

        if (formType === 'edit') {
            urlMethod = putUrl;
        } else {
            urlMethod = postUrl;
        }

        setLoading(true);
        setInputErrors();
        urlMethod(`/${MODULE}${formType === "edit" ? `/${id}` : ""}`, postData).then(response => {
            setLoading(false);
            console.log("response", response);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', formType === "edit" ? t('success.editSuccess') : t('success.createSuccess'), 'success', '');
                navigate(`/${MODULE}/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', formType === "edit" ? t('error.editError') : t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

    function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

        const list = reorder(
			state.package_ids,
			result.source.index,
			result.destination.index
		);

        const package_sequence = reorder(
			state.packages,
			result.source.index,
			result.destination.index
		);

        setState(prevState => ({
            ...prevState,
            package_ids: list,
            packages: package_sequence
        }));
        setUpdateList(prevState => ({ ...prevState, package_ids: list, packages: package_sequence }));
	}

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        width: 200,
        height: 250,
        boxShadow: '0 0 6px 0 #0002',
        backgroundColor: 'white',
      
        // styles we need to apply on draggables
        ...draggableStyle,
    });
      
    const getListStyle = isDraggingOver => ({
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });

    return (
        <>
            <Link underline='none' component={RouterLink} to={`/${MODULE}`}>
                <div className={classes.flexMiddle} style={{ paddingBottom: 30 }}>
                    <FiArrowLeft style={{ color: theme.palette.gray.main, fontSize: 16, marginRight: 10 }} />
                    <Typography style={{ color: theme.palette.gray.main, fontSize: 16, textTransform: 'uppercase' }}>{t('button.backTo')}{t(`title.${MODULE}`)}</Typography>
                </div>
            </Link>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}${formType === 'edit' ? 'Edit' : 'Add'}`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name || ""}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.code`)}
                            variant="outlined"
                            value={state.code || ""}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            InputProps={{ readOnly: true }}
                            sx={{ input: { cursor: 'default' } }}
                        // disabled
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }}>
                        <TextField
                            variant="outlined"
                            fullWidth
                            margin='normal'
                            label={t(`${MODULE}.displayName`)}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        {
                                            _.map(LANG, (value) => (
                                                <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, display_name: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.display_name === value ? theme.palette.primary.main : '#efefef', color: fieldLang.display_name === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                            ))
                                        }
                                    </InputAdornment>
                            }}
                            onChange={({ target }) => setState({ ...state, display_name: { ...state.display_name, [fieldLang.display_name]: target.value } })}
                            value={state.display_name && state.display_name[fieldLang.display_name] ? state.display_name[fieldLang.display_name] : ""}
                            helperText={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn'])}
                            error={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn']) ? true : false}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={t(`${MODULE}.startDate`)}
                                value={state.start_date || null}
                                onChange={handleStartDateTimeChange}
                                renderInput={(params) => <TextField {...params} />}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear'],
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        {inputErrors && inputErrors.start_date ?
                            <Typography variant="caption" style={{ color: 'red' }} >{inputErrors.start_date}</Typography> :
                            null}
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label={t(`${MODULE}.endDate`)}
                                value={state.end_date || null}
                                onChange={handleEndDateTimeChange}
                                renderInput={(params) => <TextField {...params} />}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear'],
                                    },
                                }}
                            />
                        </LocalizationProvider>
                        {inputErrors && inputErrors.end_date ?
                            <Typography variant="caption" style={{ color: 'red' }} >{inputErrors.end_date}</Typography> :
                            null}
                    </Box>
                </Grid>
                {
                    option && option?.status_list && _.size(option.status_list) > 0 &&
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel>{t(`${MODULE}.status`)}</InputLabel>
                                <Select
                                    value={state.status}
                                    label={t(`${MODULE}.status`)}
                                    name="status"
                                    onChange={handleChange}
                                >
                                    {
                                        _.map(option.status_list, (statusType, key) => {
                                            return <MenuItem key={key} value={key}>{statusType[i18n.language]}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                }
                {
                    formType === 'edit' && _.size(state.package_ids) > 0 &&
                    <Grid item xs={12}>
                        <Typography><b>{t(`${MODULE}.packageList`)} {t("general.displayPreview")}</b></Typography>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='package_list' direction="horizontal">
                            {
                                (provided, snapshot) => (
                                    <ImageList 
                                        sx={{ width: "100%", height: 300 }}
                                        rowHeight={350}
                                        gap={10} 
                                        cols={_.size(state.package_ids)}
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle(snapshot.isDraggingOver)}
                                    >
                                        {
                                            _.map(state.packages, (item, index) => (
                                                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                    {
                                                        (provided, snapshot) => (
                                                            <ImageListItem 
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                            >
                                                                <img
                                                                    src={`${(item.images && item.images[0]) ? item.images[0].file_name : '/images/emptyStatus/no_ticket.png'}?w=150&fit=crop&auto=format`}
                                                                    srcSet={`${(item.images && item.images[0]) ? item.images[0].file_name : '/images/emptyStatus/no_ticket.png'}?w=150&fit=crop&auto=format&dpr=2 2x`}
                                                                    alt={item.name}
                                                                    loading="lazy"
                                                                    style={{ backgroundColor: "#D6CFBF", width: 200, height: 100, objectFit: 'cover' }}
                                                                />
                                                                <ImageListItemBar
                                                                    title={<Link underline='none' component={RouterLink} to={`/packages/${item.id}`}><span>{item.name}</span></Link>}
                                                                    subtitle={<span>{t('packages.status')}: {i18n.language === 'cn' ? item.status_display['cn'] : item.status_display['en']}</span>}
                                                                    position="below"
                                                                    style={{ padding: 10 }}
                                                                />
                                                            </ImageListItem>
                                                        )
                                                    }
                                                </Draggable>
                                            ))
                                        }
                                        {provided.placeholder}
                                    </ImageList>
                                )
                            }
                            </Droppable>
                        </DragDropContext>
                    </Grid>
                }
            </Grid>

            <Box display="flex" justifyContent="center" alignItems="center">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Detail