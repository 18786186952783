import _ from 'lodash';
import React, { useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Box, Button, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { MdDownload, MdExpandLess, MdExpandMore } from "react-icons/md";

import { postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

const CodeTable = (props) => {
    const { name, codeData } = props;
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [tableExpand, setTableExpand] = useState(false);
    const [codeDialog, setCodeDialog] = useState({
        open: false,
        code: '',
        email: '',
    });
    const [excelHeaders, setExcelHeaders] = useState([
        { label: t(`adjustments.discount_code`), key: 'code' },
        { label: t(`adjustments.quantity_left`), key: 'remaining_quantity' }
    ]);

    const today = new Date();
    const datetime_now = today.getFullYear() +
        ((today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : `${(today.getMonth() + 1)}`) +
        ((today.getDate()) < 10 ? `0${(today.getDate())}` : `${(today.getDate())}`);

    const handleTableExpandClick = () => {
        setTableExpand(!tableExpand);
    }

    const handleCodeOpen = (code) => {
        setCodeDialog({
            open: true,
            code: code,
            email: '',
        });
    }
    const handleCodeClose = (code) => {
        setCodeDialog({
            open: false,
            code: '',
            email: '',
        });
    }

    const sendEmail = () => {
        setLoading(true);
        let apiData = {
            code: codeDialog.code,
            email: codeDialog.email,
        }
        postUrl(`/price-adjustment/send-email`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                handleCodeClose();
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Box>
            <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box onClick={handleTableExpandClick} style={{ cursor: "pointer", marginBottom: 5 }}>
                <Typography variant="body1">
                {t('adjustments.viewDiscountCode')}
                {tableExpand ? <MdExpandLess style={{marginLeft: 5}} /> : <MdExpandMore style={{marginLeft: 5}} />}
                </Typography>
            </Box>
            <CSVLink
                data={codeData}
                headers={excelHeaders}
                filename={name + ` Discount Code_` + datetime_now + `.csv`}
                style={{ textDecoration: "none" }}
            >
                <Button
                    startIcon={<MdDownload />}
                    variant="contained"
                >
                    {t('button.exportData')}
                </Button>
            </CSVLink>
            </Box>
            <Collapse in={tableExpand} timeout="auto" unmountOnExit>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t(`adjustments.discount_code`)}</TableCell>
                            <TableCell>{t(`adjustments.quantity_left`)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            _.map(codeData, dcode => {
                                return (
                                    <TableRow>
                                        <TableCell>
                                            {
                                                dcode.remaining_quantity > 0
                                                    ?
                                                    <Link underline="none" style={{ cursor: 'pointer' }} onClick={() => handleCodeOpen(dcode.code)}>
                                                        {dcode.code}
                                                    </Link>
                                                    :
                                                    dcode.code
                                            }
                                        </TableCell>
                                        <TableCell>{dcode.remaining_quantity}</TableCell>
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            </Collapse>
            <Dialog open={codeDialog.open} onClose={handleCodeClose}>
                <DialogTitle>
                    {t(`adjustments.discount_code`) + ": " + codeDialog.code}
                </DialogTitle>
                <DialogContent>
                    {t('adjustments.sendEmailNote')}
                    <TextField
                        margin="dense"
                        id="email"
                        label={t('user.email')}
                        type="email"
                        fullWidth
                        variant="standard"
                        value={codeDialog.email}
                        onChange={(event) => setCodeDialog({ ...codeDialog, email: event.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCodeClose}>{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={sendEmail}>{t('button.send')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default CodeTable;