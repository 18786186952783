import React, { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom'
import _ from 'lodash';

import { Button, Box, Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddressAccordion from './AddressAccordion';

import { postUrl, getUrl } from '../../../helpers/ApiAction';
import useNotificationLoading from '../../../helpers/useNotificationLoading';

const AddressEdit = memo(() => {
    const [addAddressFormShow, setAddAddressFormShow] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    let { id } = useParams();
    const INITIAL_STATE = {
        name: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
    };
    const [addressField, setAddressField] = useState(INITIAL_STATE);
    const [addresses, setAddresses] = useState({});

    const classes = useStyles();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        getApi();
        // eslint-disable-next-line
    }, [])

    const getApi = () => {
        setLoading(true);
        getUrl(`/users/${id}/address`).then(response => {
            setLoading(false);
            if (response.status) {
                setAddresses(response.data);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const toggleAddAddress = () => {
        setAddAddressFormShow(!addAddressFormShow);
    }

    const addAddressBtnClicked = () => {
        setLoading(true);
        postUrl(`/users/${id}/address`, addressField).then(response => {
            if (response.status) {
                setInputErrors({});
                toggleAddAddress();
                setAddressField(INITIAL_STATE)
                getApi();
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addAlert('', err.message || t('error.contactSupport'), 'error', '');
        })
    }

    const handleChange = ({ target }) => {
        setAddressField({ ...addressField, [target.name]: target.value });
    }

    return (
        <Card variant="elevation" elevation={3} className={classes.cardContainerStyle}>
            <CardContent className={classes.cardContentRoot}>
                <div className={classes.paper}>
                    <Box display="flex" flexDirection="row" justifyContent="space-between">
                        <Typography component="p" variant="h5">
                            {t('user.addresses')}
                        </Typography>
                        <Button onClick={toggleAddAddress} variant="contained">
                            <Typography variant="caption">
                                {t('button.create')}
                            </Typography>
                        </Button>
                    </Box>
                    {addAddressFormShow ?
                        <Box>
                            <div className={classes.formContainer} noValidate>
                                <Grid container>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('user.name')}
                                            value={addressField.name}
                                            autoComplete="name"
                                            name="name"
                                            onChange={handleChange}
                                            error={inputErrors?.name ? true : false}
                                            helperText={inputErrors?.name || ''}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            rows={2}
                                            multiline
                                            label={t('user.address')}
                                            value={addressField.address1}
                                            autoComplete="address-line1"
                                            name="address1"
                                            onChange={handleChange}
                                            error={inputErrors?.address1 ? true : false}
                                            helperText={inputErrors?.address1 || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            rows={2}
                                            multiline
                                            label={t('user.address2')}
                                            value={addressField.address2}
                                            autoComplete="address-line2"
                                            name="address2"
                                            onChange={handleChange}
                                            error={inputErrors?.address2 ? true : false}
                                            helperText={inputErrors?.address2 || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('user.zip')}
                                            value={addressField.zip}
                                            autoComplete="postal-code"
                                            name="zip"
                                            onChange={handleChange}
                                            error={inputErrors?.zip ? true : false}
                                            helperText={inputErrors?.zip || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('user.city')}
                                            value={addressField.city}
                                            autoComplete="address-level2"
                                            name="city"
                                            onChange={handleChange}
                                            error={inputErrors?.city ? true : false}
                                            helperText={inputErrors?.city || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('user.state')}
                                            value={addressField.state}
                                            autoComplete="address-level1"
                                            name="state"
                                            onChange={handleChange}
                                            error={inputErrors?.state ? true : false}
                                            helperText={inputErrors?.state || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            label={t('user.country')}
                                            value={addressField.country}
                                            autoComplete="country-name"
                                            name="country"
                                            onChange={handleChange}
                                            error={inputErrors?.country ? true : false}
                                            helperText={inputErrors?.country || ''}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                size="large"
                                                className={classes.submit}
                                                onClick={addAddressBtnClicked}
                                            >
                                                {t('button.create')}
                                            </Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                        : null}
                    <Box paddingTop={1}>
                        {_.map(addresses, addressItem => <AddressAccordion key={addressItem.id} {...addressItem} user={addressField.user_id} reloadAddress={getApi} />)}
                    </Box>
                </div>
            </CardContent>
        </Card>
    )
})

export default AddressEdit;

const useStyles = makeStyles((theme) => ({
    cardContainerStyle: {
        marginTop: 20,
    },
    cardContentRoot: {
        padding: theme.spacing(6)
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
    }
}))