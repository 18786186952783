import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import { useSelector } from 'react-redux';

import { Box, Breadcrumbs, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, Grid, IconButton, InputAdornment, Link, TextField, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            allowedDecimalSeparators={['%']}
            valueIsNumericString={true}
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default function Create() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const [state, setState] = useState({
        // name: '',
        firstName: '',
        lastName: '',
        username: '',
        email: '',
        mobile: '',
        ic: '',
        // country: '',
        // zip: '',
        // state: '',
        // city: '',
        // address1: '',
        // address2: '',
        password: '',
        password_confirmation: '',
        role_type: [],
        showPassword: {
            password: false,
            password_confirmation: false,
        },
        // permission: [],
        profile_picture: '',
        wallet_limit: 0,
        lock_period: '',
    });
    const [roles, setRoles] = useState({});
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    // const [permissions, setPermissions] = useState([]);
    // const [allPermissions, setAllPermissions] = useState([]);
    // const [rolePermission, setRolePermission] = useState([]);
    let navigate = useNavigate();
    const editor_permissions = useSelector(state => state.general.permissions);

    const handleClickShowPassword = (value) => {
        setState({
            ...state,
            showPassword: { ...state.showPassword, [value]: !state.showPassword[value] }
        });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    useEffect(() => {
        setLoading(true);
        getUrl('/users/members/options').then(response => {
            setLoading(false);
            if (response.status) {
                setRoles(response.data.role_list);
                // setPermissions(response.data.permission_by_roles);
                // setAllPermissions(response.data.all_permissions);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'zip') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const authorizeRole = roleName => {
        let roleAuthorize = state.role_type;
        // let permissionList = [];

        if (_.includes(roleAuthorize, roleName)) {

            roleAuthorize = _.difference(roleAuthorize, [roleName]);
        } else {
            roleAuthorize.push(roleName);
        }

        if (!_.includes(roleAuthorize, 'member-agent')) {
            setState({ ...state, role_type: roleAuthorize, discount_rate: 0, agent_username: '' });
        } else {
            setState({ ...state, role_type: roleAuthorize });
        }

        // if (_.size(roleAuthorize) > 0) {
        //     _.map(roleAuthorize, (roleList) => {
        //         let permissionDetail = permissions[roleList];
        //         if (_.size(permissionDetail) > 0) {
        //             _.map(permissionDetail, detail => {
        //                 permissionList.push(detail);
        //             })
        //         }
        //         setRolePermission(permissionList);
        //     });
        // } else {
        //     setRolePermission([]);
        // }
    }

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        const formData = new FormData();
        if (state.profile_picture) {
            formData.append("profile_picture", state.profile_picture);
        }
        // formData.append("name", state.name);
        formData.append("first_name", state.firstName);
        formData.append("last_name", state.lastName);
        formData.append("username", state.username);
        formData.append("email", state.email);
        formData.append("mobile", state.mobile);
        formData.append("ic", state.ic);
        // formData.append("country", state.country);
        // formData.append("zip", state.zip);
        // formData.append("state", state.state);
        // formData.append("city", state.city);
        // formData.append("address1", state.address1);
        // formData.append("address2", state.address2);
        _.each(state.role_type, (val, key) => {
            formData.append(`role_type[${key}]`, val);
        });
        // _.each(state.permission, (val, key) => {
        //     formData.append(`permissions[${key}]`, val);
        // });
        formData.append("password", state.password);
        formData.append("password_confirmation", state.password_confirmation);
        // formData.append("wallet_id", 1);
        formData.append("wallet_limit", state.wallet_limit);
        formData.append("lock_period", state.lock_period || "");
        postUrl('/users', formData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/members/${response.data.id}`, { replace: true });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // const changeAuthorize = name => {
    //     let permissionList = state.permission;

    //     if (_.includes(permissionList, name)) {
    //         permissionList = _.difference(permissionList, [name]);
    //     } else {
    //         permissionList.push(name);
    //     }
    //     setState({ ...state, permission: permissionList });
    // }

    return (
        <div>
            <BackToPrevious />
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.memberAdd')}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard`}>
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/members`}>
                                {t('title.members')}
                            </Link>
                            <Typography color="text.primary">{t('title.memberAdd')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Box component="form" autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.name')}
                                name="name"
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.firstName')}
                                name="firstName"
                                variant="outlined"
                                type="text"
                                value={state.firstName}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.first_name ? inputErrors.first_name : ''}
                                error={inputErrors && inputErrors.first_name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.lastName')}
                                name="lastName"
                                variant="outlined"
                                type="text"
                                value={state.lastName}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.last_name ? inputErrors.last_name : ''}
                                error={inputErrors && inputErrors.last_name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.username')}
                                name="username"
                                variant="outlined"
                                type="text"
                                value={state.username}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.ic')}
                                name="ic"
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.email')}
                                name="email"
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.mobile')}
                                name="mobile"
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.password')}
                                name="password"
                                type={state.showPassword.password ? 'text' : 'password'}
                                value={state.password}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.password ? inputErrors.password : ''}
                                error={inputErrors && inputErrors.password ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state.showPassword.password ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.confirmPassword')}
                                name="password_confirmation"
                                type={state.showPassword.password_confirmation ? 'text' : 'password'}
                                value={state.password_confirmation}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.password_confirmation ? inputErrors.password_confirmation : ''}
                                error={inputErrors && inputErrors.password_confirmation ? true : false}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => handleClickShowPassword('password_confirmation')}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {state.showPassword.password_confirmation ? <MdVisibility /> : <MdVisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.profilePicture')}
                                type="file"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.profile_picture ? inputErrors.profile_picture : ''}
                                error={inputErrors && inputErrors.profile_picture ? true : false}
                                onChange={({ target }) => setState({ ...state, profile_picture: target.files[0] })}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.postpaidLimit')}
                                name="wallet_limit"
                                variant="outlined"
                                value={state.wallet_limit || ''}
                                type="number"
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.wallet_limit ? inputErrors.wallet_limit : ''}
                                error={inputErrors && inputErrors.wallet_limit ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        {
                            _.includes(editor_permissions, "order-lock")
                                ?
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={t('user.lockPeriod')}
                                        name="lock_period"
                                        variant="outlined"
                                        value={state.lock_period || ''}
                                        type="number"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.lock_period ? inputErrors.lock_period : ''}
                                        error={inputErrors && inputErrors.lock_period ? true : false}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                :
                                null
                        }
                        {/* <Grid item xs={12}>
                            <Typography>{t('user.address')}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('user.address')}
                                name="address1"
                                variant="outlined"
                                type="text"
                                autoComplete="address1"
                                value={state.address1}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address1 ? inputErrors.address1 : ''}
                                error={inputErrors && inputErrors.address1 ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={t('user.address2')}
                                name="address2"
                                variant="outlined"
                                type="text"
                                autoComplete="address2"
                                value={state.address2}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.address2 ? inputErrors.address2 : ''}
                                error={inputErrors && inputErrors.address2 ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.country')}
                                name="country"
                                variant="outlined"
                                type="text"
                                autoComplete="country"
                                value={state.country}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.country ? inputErrors.country : ''}
                                error={inputErrors && inputErrors.country ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.zip')}
                                name="zip"
                                variant="outlined"
                                autoComplete="zip"
                                value={state.zip}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.zip ? inputErrors.zip : ''}
                                error={inputErrors && inputErrors.zip ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.city')}
                                name="city"
                                type="text"
                                autoComplete="city"
                                value={state.city}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.city ? inputErrors.city : ''}
                                error={inputErrors && inputErrors.city ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.state')}
                                name="state"
                                type="text"
                                autoComplete="state"
                                value={state.state}
                                InputLabelProps={{ shrink: true }}
                                variant="outlined" helperText={inputErrors && inputErrors.state ? inputErrors.state : ''}
                                error={inputErrors && inputErrors.state ? true : false}
                                onChange={handleChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} >
                            <div style={{ paddingBottom: 15 }}>
                                <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('title.roles')}</b></Typography>
                                <FormGroup>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {Array.from(roles).map((list) => (
                                            <Grid item xs={2} sm={4} md={4} key={list.name}>
                                                <FormControlLabel control={<Checkbox />} label={list.display_name[i18n.language]} onChange={() => authorizeRole(list.name)} />
                                                <Button ></Button>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {inputErrors && inputErrors.role_type && <FormHelperText style={{ color: 'red' }}>{inputErrors.role_type}</FormHelperText>}
                                </FormGroup>
                            </div>
                        </Grid>
                        {/* {
                            _.size(state.role_type) > 0 &&
                            <Grid item xs={12} >
                                <div style={{ paddingBottom: 15 }}>
                                    <Typography className={styles.subText} style={{ paddingBottom: 6 }}><b>{t('title.permissions')}</b></Typography>
                                    <Box sx={{ width: '100%' }}>
                                        <Grid container>
                                            {
                                                _.size(allPermissions) > 0 &&
                                                _.map(allPermissions, listing => {
                                                    let selectedPermission = false;
                                                    let customizePermission = false;
                                                    if (_.size(rolePermission) > 0) {
                                                        _.map(rolePermission, list => {
                                                            if (listing.name === list) {
                                                                selectedPermission = true;
                                                            }
                                                        });
                                                    }
                                                    if (_.size(state.permission) > 0) {
                                                        _.map(state.permission, memberPermisson => {
                                                            if (listing.name === memberPermisson) {
                                                                customizePermission = true;
                                                            }
                                                        })
                                                    }
                                                    if (listing.authorize) {
                                                        return (
                                                            <Grid item md={6} key={listing.id}>
                                                                <FormControlLabel
                                                                    control={<Checkbox color="primary" checked={selectedPermission ? true : (customizePermission ? true : false)} onChange={() => changeAuthorize(listing.name)} disabled={selectedPermission} />}
                                                                    label={listing.name}
                                                                />
                                                                {
                                                                    _.size(listing.children) > 0 && _.map(listing.children, child => {
                                                                        let selectedChildPermission = false;
                                                                        let customizeChildPermission = false;
                                                                        if (_.size(rolePermission) > 0) {
                                                                            _.map(rolePermission, list => {
                                                                                if (child.name === list) {
                                                                                    selectedChildPermission = true;
                                                                                }
                                                                            });
                                                                        }
                                                                        if (_.size(state.permission) > 0) {
                                                                            _.map(state.permission, childPermisson => {
                                                                                if (child.name === childPermisson) {
                                                                                    customizeChildPermission = true;
                                                                                }
                                                                            })
                                                                        }
                                                                        if (child.authorize) {
                                                                            return (
                                                                                <Box key={child.id} spacing={2} paddingLeft={5}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox color="primary" checked={selectedChildPermission ? true : (customizeChildPermission ? true : false)} onChange={() => changeAuthorize(child.name)} disabled={selectedChildPermission} />}
                                                                                        label={child.name}
                                                                                    />
                                                                                </Box>
                                                                            )
                                                                        } else {
                                                                            return null;
                                                                        }
                                                                    })
                                                                }
                                                            </Grid>
                                                        )
                                                    } else {
                                                        return null;
                                                    }
                                                })
                                            }
                                        </Grid>
                                    </Box>
                                </div>
                            </Grid>
                        } */}
                    </Grid>

                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/members`} component={RouterLink}>
                            <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" className={styles.buttonStyle} type="submit">{t('button.save')}</Button>
                    </Box>
                </Box>
            </div>
        </div >

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));