import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Box, Button, CardMedia, Grid, Typography, Dialog, DialogTitle, DialogActions, Select, MenuItem, DialogContent, DialogContentText, TextField } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { RiShoppingBag3Line } from "react-icons/ri";

import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { BANK_NAME, BANK_ACC_NAME, BANK_ACC_NO } from '../../configs/Config';

const Payment = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const { accessToken } = useSelector(state => state.general);

    let { purchase_no, currency } = useParams();
    const [orders, setOrders] = useState({});
    const [sumTotal, setSumTotal] = useState(0);
    const [codeDiscount, setCodeDiscount] = useState(0);
    const [walletBalance, setWalletBalance] = useState(0);
    const [paymentDialog, setPaymentDialog] = useState(false);
    const [paymentDoneDialog, setPaymentDoneDialog] = useState({
        open: false,
        msg: "",
    });
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchField, setSearchField] = useState({
        currency: searchParams.get('currency') ? searchParams.get('currency') : 'MYR'
    });
    const [currencyRate, setCurrencyRate] = useState({});
    const [currentCurrency, setCurrentCurrency] = useState({ "code": "MYR", "name": { 'en': "Ringgit Malaysia", 'cn': "马币" }, "symbol": "RM", "rate": "1.00", "status": "1" });
    const [receiptDialog, setReceiptDialog] = useState({
        open: false,
        receipt: '',
        errMsg: '',
    });
    const [displayOrder, setDisplayOrder] = useState({});

    useEffect(() => {
        if (accessToken) {
            setLoading(true);
            getUrl(`payments/${encodeURIComponent(purchase_no)}`).then(response => {
                setLoading(false);
                if (response.status) {
                    setOrders(response.data);
                    calculateTotal(response.data);
                    setWalletBalance(response.wallet_balance);
                    rearrangeOrderDetail(response.data);
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })
        }

        getUrl(`/currency-rates`).then(response => {
            if (response.status) {
                const currencyList = [];
                for (let i = 0; i < _.size(response.data); i++) {
                    if (response.data[i].status !== "0") {
                        currencyList.push(response.data[i]);

                        if (searchField.currency === response.data[i].code) {
                            setCurrentCurrency(response.data[i])
                        }
                    }
                }
                setCurrencyRate(currencyList);
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
        // eslint-disable-next-line
    }, [accessToken]);

    const rearrangeOrderDetail = (orders) => {
        let rearrangeOrder = {};
        if (_.size(orders) > 0) {
            {
                _.map(orders, (order, okey) => {
                    let rearrangeOrderDetail = {};
                    _.map(order.order_details, (orderDetail, dkey) => {
                        if (orderDetail.status) {
                            let main_package_id = orderDetail.package_id;
                            let main_package = orderDetail.package;
                            if (orderDetail.add_on_id) {
                                let main_detail = _.find(order.order_details, { id: parseInt(orderDetail.add_on_id) });
                                main_package_id = main_detail.package_id;
                                main_package = main_detail.package;
                            }
                            if (!(rearrangeOrderDetail[main_package_id])) {
                                rearrangeOrderDetail[main_package_id] = {};
                            }
                            if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date])) {
                                rearrangeOrderDetail[main_package_id][orderDetail.booking_date] = { items: {}, add_on: {}, package: main_package };
                            }
                            if (orderDetail.add_on_id) {
                                if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id])) {
                                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id] = { items: {}, package: orderDetail.package, display_name: orderDetail.package_name };
                                }
                                rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'])] = orderDetail;
                            } else {
                                rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'])] = orderDetail;
                            }
                        }
                    })
                    rearrangeOrder[order.id] = rearrangeOrderDetail;
                })
            }
        }
        setDisplayOrder(rearrangeOrder);
    }

    const calculateTotal = (orders) => {
        let _subTotal = 0;
        let _code_discount = 0;
        _.map(orders, (order, okey) => {
            _.map(order.order_details, (order_detail, dkey) => {
                if (order_detail.status) {
                    _subTotal += parseFloat(order_detail.quantity * order_detail.price.sell);
                    _code_discount += parseFloat(order_detail.quantity * (order_detail.price.sell - order_detail.price.adjust));
                }
            })
        })
        setSumTotal(_subTotal);
        setCodeDiscount(_code_discount);
    }

    const revenueMonsterPayment = () => {
        setLoading(true);
        // insert order payment
        postUrl(`payments/${encodeURIComponent(purchase_no)}`, {site: "admin"}).then(response => {
            if (response.status) {
                // get revenue monster link
                getUrl(`payments/${encodeURIComponent(purchase_no)}/revenuemonster`, { admin_site: true }).then(response2 => {
                    setLoading(false);
                    if (response2.status) {
                        // redirect to payment page
                        if (response2.data.code === 'SUCCESS' && response2.data.item.url) {
                            window.location.href = response2.data.item.url;
                        }
                    } else {
                        addAlert('', response2.message, 'error', '');
                    }
                }).catch(error => {
                    setLoading(false);
                    addAlert('', error.message || t('error.contactSupport'), 'error', '');
                })

                //local make payment success
                // getUrl(`payments/${encodeURIComponent(purchase_no)}/success`).then(response2 => {
                //     setLoading(false);
                //     if (response2 === 1) {
                //         navigate(`/revenuemonster/${encodeURIComponent(purchase_no)}/callback`, { replace: true });
                //     } else {
                //         addAlert('', response2.message, 'error', '');
                //     }
                // }).catch(error => {
                //     setLoading(false);
                //     addAlert('', error.message || t('error.contactSupport'), 'error', '');
                // })

            } else {
                setLoading(false);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    const walletPayment = () => {
        setLoading(true);
        setPaymentDialog(false);
        postUrl(`payments/${encodeURIComponent(purchase_no)}/wallets`, {site: "admin"}).then((response) => {
            setLoading(false);
            if (response.status) {
                setPaymentDoneDialog({
                    open: true,
                    msg: response.message,
                });
            } else {
                addAlert(response.message);
            }
        })
            .catch(error => {
                setLoading(false);
                addAlert(JSON.stringify(error.message));
            });
    }

    const displayBookingPeriod = (booking_date, duration_period) => {
        let displayText = booking_date;
        if (duration_period > 1) {
            let endDate = new Date(booking_date);
            endDate.setDate(endDate.getDate() + (duration_period - 1));
            displayText = displayText + ' - ' + Moment([endDate.getFullYear(), endDate.getMonth(), endDate.getDate()]).format('YYYY-MM-DD');
        }
        return displayText;
    }

    const orderDisplayBox = () => {
        return (
            <Box>
                {
                    _.map(orders, (order, okey) => {
                        let orderDetail = displayOrder[order.id];
                        let sellTotal = 0;
                        let adjustTotal = 0;
                        let discountTotal = 0;
                        return (
                            <Box className={classes.cartContainer} key={okey}>
                                <Box className={classes.cartAgencyBox}>
                                    <Typography className={classes.cartAgencyTitle} style={{ display: 'flex', alignItems: 'center', lineHeight: 1.0 }}>
                                        <RiShoppingBag3Line style={{ marginRight: 5, fontSize: 18 }} />{order.order_no}</Typography>
                                </Box>
                                <Box>
                                    {
                                        _.map(orderDetail, (data, packageId) => (
                                            _.map(data, (groupItem, booking_date) => {
                                                return (
                                                    <Grid container spacing={2} className={classes.packageBox}>
                                                        <Grid item xs={12} md={2} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                                                            <CardMedia
                                                                component="img"
                                                                image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                                                                sx={{ flex: 1, width: "100%", padding: 0 }}
                                                                alt={'package\'s image'}
                                                                className={classes.imgStyle}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={10} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                                                            <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                                                            {
                                                                booking_date
                                                                    ?
                                                                    <Typography className={classes.bookingDateStyle}>{displayBookingPeriod(booking_date, 1)}</Typography>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                _.map(groupItem.items, mainOption => {
                                                                    sellTotal += mainOption.price['sell'] * mainOption.quantity;
                                                                    adjustTotal += mainOption.price['adjust'] * mainOption.quantity;
                                                                    discountTotal += mainOption.price['discount'] * mainOption.quantity;
                                                                    return (
                                                                        <Box key={mainOption.id}>
                                                                            {optionBox(mainOption)}
                                                                        </Box>
                                                                    )
                                                                })
                                                            }
                                                            <Box>
                                                                {
                                                                    (_.size(groupItem.add_on) > 0) &&
                                                                    <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                                                        {t('placeOrder.addOnPackage') + ": "}
                                                                    </Typography>
                                                                }
                                                                {
                                                                    _.map(groupItem.add_on, (add_on, key) => {
                                                                        if (_.size(add_on.items) > 0) {
                                                                            return (
                                                                                <Box key={key}>
                                                                                    <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                                                                    {_.map(add_on.items, addOnOption => {
                                                                                        sellTotal += addOnOption.price['sell'] * addOnOption.quantity;
                                                                                        adjustTotal += addOnOption.price['adjust'] * addOnOption.quantity;
                                                                                        discountTotal += addOnOption.price['discount'] * addOnOption.quantity;
                                                                                        return (
                                                                                            <Box key={addOnOption.id}>
                                                                                                {optionBox(addOnOption)}
                                                                                            </Box>
                                                                                        )
                                                                                    })}
                                                                                </Box>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        ))
                                    }
                                </Box>
                                <Box style={{ padding: "20px" }}>
                                    {
                                        parseFloat(sellTotal) != parseFloat(order.total_amount)
                                            ?
                                            <Typography>{t('placeOrder.subTotal') + ': ' + currentCurrency.symbol + ' ' + parseFloat(sellTotal * currentCurrency.rate).toFixed(2)}</Typography>
                                            :
                                            null
                                    }
                                    {
                                        parseFloat(sellTotal).toFixed(2) != parseFloat(adjustTotal).toFixed(2)
                                            ?
                                            <Typography>
                                                {
                                                    t('placeOrder.discountCodeAmount') + ': ' +
                                                    ((sellTotal - adjustTotal) < 0 ? '' : '-')
                                                    +
                                                    " " + currentCurrency.symbol + " "
                                                    + (parseFloat((sellTotal - adjustTotal) > 0 ? (sellTotal - adjustTotal) : -(sellTotal - adjustTotal)) * currentCurrency.rate).toFixed(2)
                                                }
                                            </Typography>
                                            :
                                            null
                                    }
                                    <Typography>{t('placeOrder.total') + ': ' + currentCurrency.symbol + ' ' + parseFloat(order.total_amount * currentCurrency.rate).toFixed(2)}</Typography>
                                </Box>
                            </Box>
                        )
                    })
                }
            </Box>
        )
    }

    const optionBox = (option) => {
        return (
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={6}>
                    <Typography className={option.status ? '' : classes.inactiveStyle}>{option.package_option_name}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography variant="body2" align="center">x{option.quantity}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <div style={{ margin: "auto !important", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                        {
                            option.price['retail'] > 0 && (option.price['retail'] - option.price['sell']) > 0
                                ?
                                <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                    {
                                        " " + currentCurrency.symbol + " "
                                        + parseFloat((option.price['retail'] * currentCurrency.rate) * option.quantity).toFixed(2)
                                    }
                                </Typography>
                                :
                                null
                        }
                        <Typography variant="body1" style={{ marginLeft: 10 }}>
                            {
                                " " + currentCurrency.symbol + " "
                                + parseFloat((option.price['sell'] * currentCurrency.rate) * option.quantity).toFixed(2)
                            }
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        )
    }

    const bottomActionRow = () => {
        return (
            <Box className={classes.bottomActionStyle}>
                <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
                    <div style={{ textAlign: 'right', marginRight: 15 }}>
                        {
                            codeDiscount !== 0 &&
                            <Box>
                                <Typography variant="body1">{t('placeOrder.subTotal') + ": " + currentCurrency.symbol + " " + parseFloat(sumTotal * currentCurrency.rate).toFixed(2)}</Typography>
                                <Typography variant="body1">
                                    {
                                        t('placeOrder.discountCodeAmount') + ': ' +
                                        (codeDiscount < 0 ? '' : '-')
                                        +
                                        " " + currentCurrency.symbol + " "
                                        + (parseFloat(codeDiscount > 0 ? codeDiscount : -codeDiscount) * currentCurrency.rate).toFixed(2)
                                    }
                                </Typography>
                            </Box>
                        }
                        <Typography className={classes.finalPriceStyle}>{t('payment.totalSum')}</Typography>
                        {
                            currentCurrency.code !== "MYR"
                                ?
                                <>
                                    <Typography className={classes.finalPriceStyle}>
                                        {
                                            t('payment.estimateCharge') + currentCurrency.symbol + " "
                                            + parseFloat((sumTotal - codeDiscount) * currentCurrency.rate).toFixed(2)
                                        }
                                    </Typography>
                                    <Typography className={classes.finalPriceStyle}>
                                        {
                                            t('payment.actualCharge') + "RM "
                                            + parseFloat(sumTotal - codeDiscount).toFixed(2) + " "
                                        }
                                    </Typography>
                                </>
                                :
                                <Typography className={classes.finalPriceStyle}>
                                    {
                                        currentCurrency.symbol + " "
                                        + parseFloat((sumTotal - codeDiscount) * currentCurrency.rate).toFixed(2)
                                    }
                                </Typography>
                        }
                    </div>
                </div>
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', paddingTop: 20 }}>
                    <Box>
                        <Button variant="contained" onClick={revenueMonsterPayment} style={{ margin: 5 }} disabled>
                            {t('payment.payNowRevenuemonster')}
                        </Button>
                    </Box>
                    <Box>
                        <Typography style={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                            {t('payment.walletBalance') + ": RM " + parseFloat(walletBalance).toFixed(2)}
                        </Typography>
                        <Button variant="contained" onClick={confirmWalletPaymentOpen} style={{ margin: 5 }}>
                            {t('payment.payNowWallet')}
                        </Button>
                    </Box>
                    <Box>
                        <Button variant="contained" onClick={handleUploadReceiptOpen} style={{ margin: 5 }}>
                            {t('payment.uploadReceipt')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        )
    }

    const confirmWalletPaymentOpen = () => {
        setPaymentDialog(true);
    }

    const confirmWalletPaymentClose = () => {
        setPaymentDialog(false);
    }

    const walletPaymentDoneDialog = () => {
        return (
            <Dialog
                open={paymentDoneDialog.open}
                onClose={() => navigate(`/agent-orders`, { replace: true })}
            >
                <DialogTitle>{paymentDoneDialog.msg}</DialogTitle>
                <DialogActions>
                    <Button onClick={() => navigate(`/agent-orders`, { replace: true })} variant="contained" color="secondary">{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleUploadReceiptOpen = () => {
        setReceiptDialog({
            open: true,
            receipt: '',
            errMsg: '',
        });
    }
    const handleUploadReceiptClose = () => {
        setReceiptDialog({
            open: false,
            receipt: '',
            errMsg: '',
        });
    }
    const uploadReceiptDialog = () => {
        return (
            <Dialog
                open={receiptDialog.open}
                onClose={handleUploadReceiptClose}
            >
                <DialogTitle>{t('payment.uploadReceipt')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('document.bankNameTitle') + ": " + BANK_NAME}</Typography>
                    <Typography>{t('document.accountNameTitle') + ": " + BANK_ACC_NAME}</Typography>
                    <Typography>{t('document.accountNoTitle') + ": " + BANK_ACC_NO}</Typography>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            id="outlined-basic"
                            fullWidth
                            variant="outlined"
                            type="file"
                            InputLabelProps={{ shrink: true }}
                            onChange={({ target }) => setReceiptDialog({ ...receiptDialog, receipt: target.files[0] })}
                        />
                    </Box>
                    {
                        receiptDialog.errMsg
                        ?
                        <Typography variant="caption" color="error">{receiptDialog.errMsg}</Typography>
                        :
                        null
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUploadReceiptClose} variant="outlined" color="primary">{t('button.cancel')}</Button>
                    <Button onClick={uploadReceipt} variant="contained" color="primary">{t('general.upload')}</Button>
                </DialogActions>
            </Dialog>
        )
    }
    const uploadReceipt = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("_method", "post");
        formData.append("receipt", receiptDialog.receipt);
        formData.append("site", "admin");
        postUrl(`payments/${encodeURIComponent(purchase_no)}/upload_receipt`, formData).then((response) => {
            setLoading(false);
            if (response.status) {
                handleUploadReceiptClose();
                setPaymentDoneDialog({
                    open: true,
                    msg: response.message,
                });
            } else {
                setReceiptDialog({ ...receiptDialog, errMsg: response.message });
            }
        }).catch(error => {
            setLoading(false);
            addAlert(JSON.stringify(error.message));
        });
    }

    return (
        <Box className={classes.outlineBox}>
            <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" paddingBottom={'15px'}>
                <Typography variant="h6" style={{ marginBottom: 10 }}>{t('payment.checkoutItem')}</Typography>
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" style={{ marginRight: 15, color: 'black' }}>{t('currencies.currency')}: </Typography>
                    <Select
                        labelId="demo-simple-select-label"
                        id="currency"
                        disableUnderline
                        variant='standard'
                        value={currentCurrency.code}
                        onChange={(event) => {
                            _.map(currencyRate, (currRate, key) => {
                                if (currencyRate[key].code === event.target.value) {
                                    setCurrentCurrency(currencyRate[key])
                                }
                            })
                        }}
                        style={{ color: 'black', padding: "2%" }}
                    >
                        {
                            _.map(currencyRate, (currRate, key) => {
                                return (
                                    <MenuItem key={key} value={currRate.code}>{currRate.code}</MenuItem>
                                )
                            })
                        }
                    </Select>
                </Box>
            </Box>
            {
                _.size(orders) > 0
                    ?
                    <>
                        {orderDisplayBox()}
                        {bottomActionRow()}
                        {walletPaymentDoneDialog()}
                        <Dialog
                            open={paymentDialog}
                            onClose={confirmWalletPaymentClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                {t('packages.confirmPayment')}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {t('packages.confirmMessage', { amount: parseFloat(sumTotal - codeDiscount).toFixed(2) })}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button variant='outlined' color='primary' style={{ color: "black" }} onClick={confirmWalletPaymentClose}>{t('button.close')}</Button>
                                <Button variant="contained" color="secondary" style={{ color: "white" }} onClick={walletPayment} autoFocus>{t('button.confirm')}</Button>
                            </DialogActions>
                        </Dialog>
                    </>
                    :
                    <Box style={{ marginTop: 20, textAlign: "center" }}>
                        <Typography variant="body2" style={{ marginBottom: 10 }}>{t('payment.invalidPayment')}</Typography>
                    </Box>
            }
            {uploadReceiptDialog()}
        </Box>
    )
}

export default Payment;

const useStyles = makeStyles((theme) => ({
    cartContainer: {
        border: 'none',
        borderRadius: 20,
        // borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 5px 6px 0 #0000001f',
        marginBottom: 15
    },
    cartAgencyBox: {
        color: "#fff",
        backgroundColor: theme.palette.primary.main + ' !important',
    },
    cartAgencyTitle: {
        // color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
        padding: "16px"
    },
    packageBox: {
        padding: "16px",
        borderBottom: "solid 1px " + theme.palette.primary.secondary,
    },
    imgStyle: {
        objectFit: "contain !important"
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    bottomActionStyle: {
        // backgroundColor: theme.palette.primary.secondary + ' !important',
        margin: "50px 0px 20px 0px !important",
        borderRadius: 5,
    },
    bottomActionBox: {
        padding: "0px 16px 16px 16px",
        // color: theme.palette.primary.main + ' !important',
        fontWeight: "bold !important",
    },
    finalPriceStyle: {
        // color: theme.palette.primary.main,
        fontWeight: "bold !important",
        fontSize: "1.2rem !important",
        lineHeight: '1.0 !important'
    },
    outlineBox: {
        [theme.breakpoints.up('xs')]: {
            margin: "20px 0px 20px 0px !important"
        },
        [theme.breakpoints.up('md')]: {
            margin: "20px !important"
        },
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.secondary + ' !important',
        // color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
    },
    bookingDateStyle: {
        // color: theme.palette.primary.secondary + ' !important',
        padding: "5px 10px 5px 0px",
        fontSize: "1.0rem !important",
        margin: "5px 5px 5px 0px !important",
    }
}))