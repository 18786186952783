import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Box, Button, Typography, LinearProgress } from '@mui/material';
import { MdDownload } from "react-icons/md";

// 'material-react-table': https://www.material-react-table.com/
// 'react-csv': https://www.npmjs.com/package/react-csv

/** exportType: table or database
table: export from table value cache, not suitable for columns using cell
database: export from row original(database) */

const CustomizeMaterialReactTable = ({ data, columns, isLoading, exportFile, exportFileName, exportType, initialColumnVisibility }) => {
    const { t } = useTranslation();
    const [pagination] = useState({ pageSize: 20, pageIndex: 0 });
    const [excelHeaders, setExcelHeaders] = useState([]);

    useEffect(() => {
        let headers = [];
        _.map(columns, value => {
            headers.push({ label: value.header, key: value.accessorKey ? value.accessorKey : value.id });
        })
        setExcelHeaders(headers);
        // eslint-disable-next-line
    }, [columns]);

    const today = new Date();
    const datetime_now = today.getFullYear() +
        ((today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : `${(today.getMonth() + 1)}`) +
        ((today.getDate()) < 10 ? `0${(today.getDate())}` : `${(today.getDate())}`);

    return (
        <>
            {
                isLoading
                    ?
                    <Box style={{ margin: 20, textAlign: "center" }}>
                        <LinearProgress />
                    </Box>
                    :
                    _.size(data) > 0
                    ?
                    <MaterialReactTable
                        columns={columns}
                        data={data}

                        initialState={{
                            showColumnFilters: true,
                            pagination: pagination,
                            columnVisibility: initialColumnVisibility ? initialColumnVisibility : null
                        }}
                        state={{ isLoading }}

                        // disabled functions
                        enableFullScreenToggle={false}
                        enableGlobalFilter={false}
                        enableHiding={false}
                        enableDensityToggle={false}

                        // EXPORT
                        positionToolbarAlertBanner="bottom"
                        renderToolbarTopCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                {
                                    exportFile
                                        ?
                                        <CSVLink
                                            data={exportType === 'table' ? (table.getPrePaginationRowModel().rows).map((row) => row._valuesCache) : (table.getPrePaginationRowModel().rows).map((row) => row.original)}
                                            headers={excelHeaders}
                                            filename={exportFileName + `_` + datetime_now + `.csv`}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <Button
                                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                startIcon={<MdDownload />}
                                                variant="contained"
                                            >
                                                {t('button.exportData')}
                                            </Button>
                                        </CSVLink>
                                        :
                                        null
                                }
                            </Box>
                        )}
                    />
                    :
                    <Box style={{ margin: 20, textAlign: "center" }}>
                        <Typography variant="h4" style={{ fontWeight: "bold" }}>{t('general.noData')}</Typography>
                    </Box>
            }
        </>
    )
};

export default CustomizeMaterialReactTable