import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, FormControl, FormControlLabel, Grid, Link, TextField, Typography, InputLabel, MenuItem, Select, FormHelperText, Switch } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "comments";
const MODULE_SINGLE = "comment";

export default function Detail() {
    const styles = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [state, setState] = useState({
        newsId: '',
        name: '',
        email: '',
        comment: '',
        status: 0
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();
    let { id } = useParams();
    const isMountedRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const formType = location.pathname === `/${MODULE}-add` ? "add" : "edit";
    const [newsList, setNewsList] = useState([]);

    useEffect(() => {
        isMountedRef.current = true;
        setLoading(true);
        if (formType === 'edit' && id) {
            getUrl(`/${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        let { comment } = response.data;
                        setState({
                            newsId: comment.news_id,
                            name: comment.name,
                            email: comment.email,
                            comment: comment.comment,
                            status: comment.status,
                        });
                        setNewsList([comment.news]);
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }else{
            getUrl(`/news`, {all: true}).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        setNewsList(response.data.listing);
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id])

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'status') {
            setState({ ...state, status: target.checked ? 1 : 0 });
        } else {
            setState({ ...state, [name]: value });
        }
    };

    const submitData = () => {
        let postData = {
            news_id: state.newsId,
            name: state.name,
            email: state.email,
            comment: state.comment,
            status: state.status,
            _method: formType === "edit" ? "put" : "post",
        };

        const formData = new FormData();
        buildFormData(formData, postData);

        setLoading(true);
        setInputErrors();
        postUrl(`/${MODULE}${formType === "edit" ? `/${id}` : ""}`, formData).then(response => {
            setLoading(false);
            let { status, data, errors, message } = response;
            addAlert('', message, status ? 'success' : 'error', '');
            if (status) {
                addAlert('', response.message, 'success', '');
                if (formType !== "edit") {
                    navigate(`/${MODULE}/${data.id}`);
                }
            } else {
                setInputErrors(errors);
                addAlert('', response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`user.name`)} *`}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`user.email`)} *`}
                            variant="outlined"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <InputLabel>{`${t(`title.news`)} *`}</InputLabel>
                            <Select
                                value={state.newsId}
                                label={`${t(`title.news`)} *`}
                                variant="outlined"
                                name="newsId"
                                onChange={handleChange}
                                error={inputErrors && inputErrors.news_id ? true : false}
                                disabled={formType === 'edit' ? true : false}
                            >
                                {_.map(newsList, news => {
                                    return <MenuItem key={news.id} value={news.id}>{news.title[i18n.language] ? news.title[i18n.language] : news.title.en}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: theme.palette.indicator.red }}>{inputErrors && inputErrors.news_id ? inputErrors.news_id : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={`${t(`${MODULE}.message`)} *`}
                            variant="outlined"
                            value={state.comment}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.comment ? inputErrors.comment : ''}
                            error={inputErrors && inputErrors.comment ? true : false}
                            name="comment"
                            multiline
                            rows={5}
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    formType === 'edit'
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={state.status ? true : false}
                                                onChange={handleChange}
                                                name="status"
                                            />
                                        }
                                        labelPlacement="start"
                                        label={t(`${MODULE}.status.status`)}
                                    />
                                    {
                                        inputErrors && inputErrors.status
                                            ?
                                            <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.status}</Typography>
                                            :
                                            null
                                    }
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
            </Grid>
            <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button type="button" size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15, color: theme.palette.button.main, border: theme.palette.button.borderMain, boxShadow: '2px 4px 8px 0 #9f9f9f45' }}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main, boxShadow: '2px 4px 8px 0 #00000045' }} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
}));