export const ENV = 'liv'; // 'dev';
export const APP_NAME = 'Backend ReLuxe System';
export const DEFAULT_LANGUAGE = "en"; // "en"
export const BASE_URL = ENV === 'liv' ? '' : '';

const BACKEND_URL_LIST = {
    liv: `https://api.reluxeofficial.com`,
    stage: `https://ticketingapi.mib88.com`,
    dev: 'http://127.0.0.1:8000',
};

const LOCAL_URL_LIST = {
    liv: `https://backend.reluxeofficial.com`,
    stage: `https://ticketingbackend.mib88.com`,
    dev: `http://localhost:3000`,
};

export const BACKEND_URL = BACKEND_URL_LIST[ENV];
export const LOCAL_URL = LOCAL_URL_LIST[ENV];

export const API_URL = `${BACKEND_URL}/api/`;

export const BANK_NAME = 'CIMB';
export const BANK_ACC_NAME = 'Reluxe De Mantanani Sdn Bhd';
export const BANK_ACC_NO = '8010909015';
export const CUSTOMER_SERVICE_EMAIL = 'booking@reluxeofficial.com';
