import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography, Container, Alert } from '@mui/material';
import { useTheme, makeStyles } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import { putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { MdArticle, MdClose, MdDone, MdSchedule } from "react-icons/md";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            allowedDecimalSeparators={['%']}
            valueIsNumericString={true}
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};


const MemberAgentEdit = memo((props) => {
    const { details, option, setValidMemberAgentDetail } = props;
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    let { id } = useParams();
    const { addAlert, setLoading } = useNotificationLoading();
    const styles = useStyles();
    const [canEdit, setCanEdit] = useState(true); // set to false, if need ducument approved only can upload
    const [state, setState] = useState({
        agent_username: details?.referral?.username || '',
        discount_rate: details?.discount ||  '',
        rank: details?.rank || '0',
    });
    const [inputErrors, setInputErrors] = useState({});

    const status_icon = {
        0: <MdSchedule size={22} style={{ color: theme.palette.primary.main }} />,
        1: <MdDone size={22} style={{ color: theme.palette.green.main }} />,
        2: <MdClose size={22} style={{ color: theme.palette.red.main }} />,
    }

    // useEffect(() => {
    //     if (details?.documents) {
    //         let totalApprove = _.size(_.filter(details.documents, function (o, index) { return o.status === 1 }));
    //         if (_.size(details.documents) > 0 && totalApprove === _.size(details.documents)) {
    //             setCanEdit(true);
    //         }
    //     }

    //     // eslint-disable-next-line
    // }, [details])

    const changeStatus = (type, status) => {
        setLoading(true);
        let postData = {
            type: type,
            user_id: details.id,
            status: status,
        }

        putUrl(`/users/approve-reject-document`, postData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setValidMemberAgentDetail(true);
            } else {
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        let { name, value } = target;

        setState({ ...state, [name]: value });
    };

    const submitData = () => {
        setLoading(true);
        setInputErrors({});

        putUrl(`/users/${id}/update-member-agent-info`, state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setValidMemberAgentDetail(true);
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Container maxWidth="md">
            {
                !canEdit &&
                <Box style={{ width: "fit-content" }}>
                    <Alert severity="info">{t('general.extraInfoCanEditMsg')}</Alert>
                </Box>
            }
            <div style={{ paddingBottom: '5%' }}>
                {
                    _.size(option.types) > 0 &&
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" padding={2}>
                        <Grid item xs={12}>
                            <Typography variant="h6">{t('user.supportingDocument')}</Typography>
                        </Grid>
                        {
                            _.map(option.types, item => {
                                let detail = _.find(details.documents, { type: item.code });
                                return (
                                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" padding={2} key={item.code}>
                                        <Grid item xs>
                                            <Typography variant="subtitle2">{i18n.language === 'cn' ? item.display_name.cn : item.display_name.en}</Typography>
                                            {
                                                _.size(detail) > 0 ?
                                                    <Link href={detail.file_name} underline="none" target="_blank" style={{ display: 'flex' }}>
                                                        <MdArticle size={20} /><Typography>{detail.original_file_name}</Typography>
                                                    </Link>
                                                    :
                                                    <Typography variant="caption">{t('general.noUploadDocument')}</Typography>

                                            }
                                        </Grid>
                                        {
                                            _.size(detail) > 0 &&
                                            <Grid item xs>
                                                {
                                                    detail.status === 0 ?
                                                        <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center" padding={2}>
                                                            <Button variant="contained" style={{ backgroundColor: theme.palette.green.main }} startIcon={<MdDone size={20} />} onClick={() => changeStatus(item.code, 1)}>{t('button.approve')}</Button>
                                                            <Button variant="contained" style={{ backgroundColor: theme.palette.red.main, marginLeft: 5 }} startIcon={<MdClose size={20} />} onClick={() => changeStatus(item.code, 2)}>{t('button.reject')}</Button>
                                                        </Grid> :
                                                        <Grid container spacing={2} direction="row" justifyContent="flex-end" alignItems="center" padding={2}>
                                                            {status_icon[parseInt(detail.status)]} {option.statusList[parseInt(detail.status)]['en']}
                                                        </Grid>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                )
                            })
                        }
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                    </Grid>
                }
                <Grid container spacing={2} direction="row" alignItems="center">
                    <Grid item xs={12}>
                        <Typography variant="h6">{t('user.extraInfo')}</Typography>
                    </Grid>
                    {/* <Grid item xs={12} sm={4}>
                        <TextField
                            label={t('user.agentUsername')}
                            name="agent_username"
                            variant="outlined"
                            fullWidth
                            disabled={!canEdit}
                            value={state.agent_username}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.agent_username ? inputErrors.agent_username : ''}
                            error={inputErrors && inputErrors.agent_username ? true : false}
                            onChange={handleChange}
                        />
                    </Grid> */}
                    {/* <Grid item xs={12} sm={4}>
                        <TextField
                            label={t('user.discountRate')}
                            name="discount_rate"
                            variant="outlined"
                            fullWidth
                            disabled={!canEdit}
                            value={state.discount_rate}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.discount_rate ? inputErrors.discount_rate : ''}
                            error={inputErrors && inputErrors.discount_rate ? true : false}
                            onChange={handleChange}
                            InputProps={{ inputComponent: NumberFormatCustom }}
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                            <InputLabel>{t('user.rank')}</InputLabel>
                            <Select
                                value={state.rank}
                                label={t('user.rank')}
                                name="rank"
                                error={inputErrors && inputErrors.rank ? true : false}
                                onChange={handleChange}
                                disabled={!canEdit}
                            >
                                <MenuItem value='0' >{t('general.pleaseSelect')}</MenuItem>
                                {
                                    _.map(option.rankList, item => {
                                        return <MenuItem key={item.id} value={item.id} disabled={item.status ? false : true}>{i18n.language === 'cn' ? item.display_name['cn'] : item.display_name['en']}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.rank ? inputErrors.rank : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
                {
                    canEdit &&
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/members`} component={RouterLink}>
                            <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" className={styles.buttonStyle} onClick={submitData} >{t('button.save')}</Button>
                    </Box>
                }
            </div>
        </Container>
    )
})

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}));

export default MemberAgentEdit;