import {
    CHANGE_LANGUAGE, RESET_CODE_STATE, AUTH_SUCCESS, AUTH_FAIL, CART_TOTAL
} from './types';


export const changeLanguage = lang => dispatch => {
    dispatch({ type: CHANGE_LANGUAGE, payload: lang });
}

export const resetCodeState = () => dispatch => {
    dispatch({ type: RESET_CODE_STATE });
}

export const authSuccess = authData => dispatch => {
    dispatch({ type: AUTH_SUCCESS, payload: authData });
}

export const authFail = () => dispatch => {
    dispatch({ type: AUTH_FAIL });
}

export const updateCartTotal = total => ({
    type: CART_TOTAL,
    payload: total
});