import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Box, Grid, Link, Typography, Alert, Divider, Tooltip, LinearProgress } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { MdStore, MdAccountBalanceWallet, MdCreditCard } from "react-icons/md";
import { BiMenuAltRight } from "react-icons/bi";

import { getUrl } from '../helpers/ApiAction';
import useNotificationLoading from '../helpers/useNotificationLoading';

export default function Dashboard() {
    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();
    const { permissions, role } = useSelector(state => state.general);
    const { agency_id, name } = useSelector(state => state.user);
    let navigate = useNavigate();
    const { addAlert } = useNotificationLoading();

    const [checkInCount, setCheckInCount] = useState(false);
    const [allWallets, setAllWallets] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sourcePayment, setSourcePayment] = useState(false);

    useEffect(() => {
        setLoading(true);
        getUrl(`/vouchers/check-in-count`).then(response => {
            if (response.status) {
                setLoading(false);
                setCheckInCount(response.data.voucher_count);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        getUrl(`/source-payments/wallet_details`).then(response => {
            if (response.status) {
                setLoading(false);
                setAllWallets(response.data.listing);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const goToOwnAgency = () => {
        navigate(`/agencies/${agency_id}`);
    }

    return (
        <div>
            <div style={{ paddingBottom: '5%' }}>
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.dtext }}><b>{t('dashboard.general')}</b></Typography>
                <Typography style={{ marginBottom: 10 }}>
                    {t('dashboard.welcome') + name}
                </Typography>
                {
                    _.size(_.intersection(role, ['dev', 'super-admin', 'admin', 'editor', 'member-agent'])) === 0
                        ?
                        <Box style={{ width: "fit-content" }}>
                            <Alert severity="info">{t('general.agencyUpdatePendingMsg')}</Alert>
                        </Box>
                        :
                        null
                }
                <Grid container style={{ marginTop: 10 }}>
                    {
                        _.includes(permissions, "report-booking")
                            ?
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Link underline='none' to={`/reports/check-in`} component={RouterLink}>
                                    <Box className={classes.box1}>
                                        <Typography variant="body1" style={{ padding: 10 }}>{t('dashboard.dailyCheckIn')}</Typography>
                                        <Typography variant="h4" style={{ paddingLeft: 10 }}>{checkInCount}</Typography>
                                    </Box>
                                </Link>
                            </Grid>
                            :
                            null
                    }
                    {
                        _.includes(permissions, "agency-edit") && agency_id
                            ?
                            <Grid item xs={6} sm={4} md={3} lg={2}>
                                <Box className={classes.box1} onClick={goToOwnAgency}>
                                    <Typography variant="body1" style={{ padding: 10 }}>{t('title.agencyEdit')}</Typography>
                                    <MdStore size={40} style={{ paddingLeft: 10 }} />
                                </Box>
                            </Grid>
                            :
                            null
                    }
                </Grid>

                <Divider style={{ height: 10 }} />

                <Typography style={{ padding: "10px 0px", fontSize: 18, color: theme.palette.gray.dtext }}><b>{t('dashboard.myWallets')}</b></Typography>
                
                <Grid container style={{ marginTop: 10 }}>
                    {
                        loading === false ?
                            _.size(allWallets) > 0 &&
                                _.map(allWallets, (walletDetail, key) => {
                                    let wallet_balance = walletDetail.balance ? parseFloat(walletDetail.balance) : 0;
                                    let wallet_limit = walletDetail.limit ? parseFloat(walletDetail.limit) : 0;
                                    let available_balance = 0;
                                    available_balance = wallet_balance + wallet_limit;

                                    return (
                                        <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                                            <Box className={classes['wallet_' + walletDetail.id]}>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <img src="/images/logo-text.png" width={80} />
                                                    <Tooltip title={t('dashboard.viewMore')}>
                                                        <Link underline='none' to={`/wallet-transactions/${walletDetail.id}`} component={RouterLink}>
                                                            <BiMenuAltRight size={20} />
                                                        </Link>
                                                    </Tooltip>
                                                </div>
                                                <Typography variant="body1" style={{ paddingTop: 10 }}>{t('dashboard.availableBalance')}</Typography>
                                                <Typography variant="h4" style={{ textAlign: 'right' }}>{available_balance.toFixed(walletDetail.decimal)}</Typography>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <MdAccountBalanceWallet size={30} />
                                                    <Typography variant="body1" style={{ paddingLeft: 5 }}>{t('dashboard.walletLimit')}{wallet_limit.toFixed(walletDetail.decimal)}</Typography>
                                                </div>
                                                <div style={{ paddingTop: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                    <img src="/images/logo.png" width={20} />
                                                    <Typography variant="body1"><b>{walletDetail.code}</b></Typography>
                                                </div>
                                            </Box>
                                        </Grid>
                                    )
                                })
                        : 
                        <Box sx={{ width: '100%' }}>
                            <LinearProgress  />
                        </Box>
                    }
                </Grid>
            </div>
        </div >
    );
}

const useStyles = makeStyles(theme => ({
    box1: {
        backgroundColor: theme.palette.primary.secondary,
        color: theme.palette.primary.main,
        height: 100,
        width: "90%",
        border: "2px solid " + theme.palette.primary.main,
        borderRadius: 5,
        margin: "10px !important",
        cursor: 'pointer',
        "&:hover": {
            boxShadow: '0px 0px 8px 4px #0000001f',
        }
    },
    wallet_1: {
        backgroundColor: "#D6CFBF",
        backgroundImage: "linear-gradient(to bottom right, #D6CFBF, #ad9e80)",
        color: theme.palette.primary.main,
        width: "90%",
        padding: 10,
        border: "2px solid #574f41",
        borderRadius: 5,
        margin: "10px !important",
        "&:hover": {
            boxShadow: '0px 0px 8px 4px #0000001f',
        }
    },
    wallet_2: {
        backgroundColor: "#2b6777",
        backgroundImage: "linear-gradient(to bottom right, #c8d8e4, #f2f2f2)",
        color: theme.palette.primary.main,
        width: "90%",
        padding: 10,
        border: "2px solid #2b6777",
        borderRadius: 5,
        margin: "10px !important",
        "&:hover": {
            boxShadow: '0px 0px 8px 4px #0000001f',
        }
    }
}))
