import _ from 'lodash';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiEdit, FiUsers } from "react-icons/fi";
import { MdDownload } from "react-icons/md";

import { LOCAL_URL } from '../../configs/Config';
import { getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';
import VoucherPdf from '../OrderAgent/VoucherPdf';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const MODULE = "orders";
const MODULE_SINGLE = "order";

const Edit = () => {
    const [statusList, setStatusList] = useState([]);
    const [order, setOrder] = useState([]);
    const [state, setState] = useState({
        open: false,
        status: "",
        remark: ""
    });
    const [subTotal, setSubTotal] = useState(0);
    const [agentDiscount, setAgentDiscount] = useState(false);
    const [codeDiscount, setCodeDiscount] = useState(false);
    const [approveReceiptDialog, setApproveReceiptDialog] = useState(false);
    const [refreshData, setRefreshData] = useState(false);
    const [voucherDialog, setVoucherDialog] = useState({
        open: false,
        voucher: []
    });
    const [customerDialog, setCustomerDialog] = useState({
        open: false,
    });
    const [qrcodeTabValue, setQrcodeTabValue] = useState(0);
    const [displayOrder, setDisplayOrder] = useState({});

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    useEffect(() => {
        setLoading(true);
        getUrl(`/${MODULE}/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setOrder(response.data.order);
                setStatusList(response.data.status_list);
                setState({ ...state, status: response.data.order.status });
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id, refreshData]);

    useEffect(() => {
        calculateTotal(order);
        rearrangeOrderDetail();
        // eslint-disable-next-line
    }, [order]);

    const calculateTotal = (order) => {
        let _subTotal = 0;
        let _apply_agent_discount = false;
        let _codeDiscountTotal = 0;
        _.map(order.order_details, order_detail => {
            if (order_detail.status) {
                _subTotal += parseFloat(order_detail.quantity * order_detail.price.sell);
                _codeDiscountTotal += parseFloat(order_detail.quantity * (order_detail.price.adjust - order_detail.price.sell));
                if (order_detail?.price?.adjust && order_detail?.price?.final && (parseFloat(order_detail.price.adjust - order_detail.price.final) > 0)) {
                    _apply_agent_discount = true;
                }
            }
        })

        setSubTotal(_subTotal);
        setAgentDiscount(_apply_agent_discount);
        setCodeDiscount(parseFloat(_codeDiscountTotal).toFixed(2));
    }

    const handleEditStatusOpen = () => {
        setState({
            open: true,
            status: order.status,
            remark: ""
        });
    }
    const handleEditStatusClose = () => {
        setState({
            open: false,
            status: order.status,
            remark: ""
        });
    }

    const updateStatus = () => {
        setLoading(true);
        let postData = {
            status: state.status,
            remark: state.status === 60 ? (state.remark ? ('Cancel Reason: ' + state.remark) : null) : null
        };
        putUrl(`/${MODULE}/${id}`, postData).then(response => {
            setLoading(false);
            if (response.status) {
                handleEditStatusClose();
                addAlert("", response.message, 'success', '');
                setOrder(response.data);
            } else {
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const approvePayment = () => {
        setLoading(true);
        putUrl(`payments/${encodeURIComponent(order.purchase_no)}/approve_payment`).then(response => {
            setLoading(false);
            if (response.status) {
                setApproveReceiptDialog(false);
                setRefreshData(!refreshData);
                addAlert("", response.message, 'success', '');
            } else {
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleCustomerDialogOpen = () => {
        setCustomerDialog({
            open: true,
        })
    }
    const handleCustomerDialogClose = () => {
        setCustomerDialog({
            open: false,
        })
    }
    const customerList = () => {
        let excelHeaders = [
            { label: t(`vouchers.booking_date`), key: 'booking_date' },
            { label: t(`orders.packageName`), key: 'package_name' },
            { label: t(`payment.option`), key: 'package_option_name' },
            { label: t(`vouchers.code`), key: 'code' },
            { label: t(`user.name`), key: 'user_name' },
            { label: t(`user.ic`), key: 'user_ic' },
            { label: t(`user.dob`), key: 'user_dob' },
            { label: t(`user.email`), key: 'user_email' },
            { label: t(`user.mobile`), key: 'user_mobile' },
            { label: t(`user.gender`), key: 'user_gender' },
            { label: t(`placeOrder.user_role`), key: 'user_role' },
        ];
        let codeData = [];
        let csvTitle = '';
        _.map(order.order_details, (order_detail) => {
            csvTitle = order_detail.package_name;
            _.map(order_detail.vouchers, (voucher, key) => {
                codeData.push({
                    id: voucher.id,
                    booking_date: voucher.start_date,
                    package_name: order_detail.package_name,
                    package_option_name: order_detail.package_option_name,
                    code: voucher.code,
                    user_name: voucher.user_name,
                    user_ic: voucher.user_ic ? voucher.user_ic : '-',
                    user_dob: voucher.user_dob ? voucher.user_dob : '-',
                    user_email: voucher.user_email ? voucher.user_email : '-',
                    user_mobile: voucher.user_mobile ? voucher.user_mobile : '-',
                    user_gender: voucher.user_gender ? t('user.' + voucher.user_gender) : '-',
                    user_role: voucher.user_role ? t('placeOrder.tour_' + voucher.user_role) : '-',
                    fullData: voucher,
                });
            })
        })
        return (
            <Dialog open={customerDialog.open} onClose={() => handleCustomerDialogClose()} maxWidth="lg">
                <DialogTitle>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6">{t('orders.customerList') + ' (' + order.order_no + ')'}</Typography>
                        <CSVLink
                            data={codeData}
                            headers={excelHeaders}
                            filename={csvTitle + ` Customer List` + `.csv`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                startIcon={<MdDownload />}
                                variant="contained"
                            >
                                {t('button.exportData')}
                            </Button>
                        </CSVLink>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        _.map(excelHeaders, (header, key) => {
                                            return (
                                                <TableCell key={key}>{header.label}</TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(codeData, (voucher, key) => {
                                        return (
                                            <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{voucher.booking_date}</TableCell>
                                                <TableCell>{voucher.package_name}</TableCell>
                                                <TableCell>{voucher.package_option_name}</TableCell>
                                                <TableCell>
                                                    <Link key={voucher.id} onClick={() => handleVoucherDialogOpen(voucher.fullData)} underline="none" className={classes.voucherLink}>
                                                        {voucher.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{voucher.user_name}</TableCell>
                                                <TableCell>{voucher.user_ic}</TableCell>
                                                <TableCell>{voucher.user_dob}</TableCell>
                                                <TableCell>{voucher.user_email}</TableCell>
                                                <TableCell>{voucher.user_mobile}</TableCell>
                                                <TableCell>{voucher.user_gender}</TableCell>
                                                <TableCell>{voucher.user_role}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleCustomerDialogClose()}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handleVoucherDialogOpen = (voucher) => {
        setVoucherDialog({
            open: true,
            voucher: voucher
        })
    }
    const handleVoucherDialogClose = () => {
        setVoucherDialog({
            open: false,
            voucher: []
        })
    }
    const showVoucherDialog = () => {
        return (
            <Dialog open={voucherDialog.open} onClose={() => handleVoucherDialogClose()}>
                <DialogTitle>{t('vouchers.code') + ": " + voucherDialog.voucher.code}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="body1">{t('vouchers.price') + ": MYR " + parseFloat(voucherDialog.voucher.voucher_price).toFixed(2)}</Typography>
                        {
                            voucherDialog.voucher.voucher_type === 'tour'
                                ?
                                <>
                                    {/* BOOKING INFO */}
                                    <Typography variant="body1" style={{ marginTop: 10, fontWeight: "bold" }}>{t('vouchers.booking_date') + ": " + voucherDialog.voucher.start_date}</Typography>
                                    <Typography variant="body1">{t('user.name') + ": " + voucherDialog.voucher.user_name}</Typography>
                                    <Typography variant="body1">{t('user.ic') + ": " + voucherDialog.voucher.user_ic}</Typography>
                                    <Typography variant="body1">{t('user.dob') + ": " + (voucherDialog.voucher.user_dob ? voucherDialog.voucher.user_dob : '-')}</Typography>
                                    <Typography variant="body1">{t('user.email') + ": " + (voucherDialog.voucher.user_email ? voucherDialog.voucher.user_email : '-')}</Typography>
                                    <Typography variant="body1">{t('user.mobile') + ": " + (voucherDialog.voucher.user_mobile ? voucherDialog.voucher.user_mobile : '-')}</Typography>
                                    <Typography variant="body1">{t('user.gender') + ": " + (voucherDialog.voucher.user_gender ? t('user.' + voucherDialog.voucher.user_gender) : '-')}</Typography>
                                    <Typography variant="body1">{t('placeOrder.user_role') + ": " + (voucherDialog.voucher.user_role ? t('placeOrder.tour_' + voucherDialog.voucher.user_role) : '-')}</Typography>
                                </>
                                :
                                null
                        }
                    </Box>
                    {
                        voucherDialog.voucher.status === 20 || voucherDialog.voucher.status === 40
                            ?
                            <Box style={{ marginTop: 10 }}>
                                <Tabs
                                    value={qrcodeTabValue}
                                    onChange={(event, newValue) => setQrcodeTabValue(newValue)}
                                    centered
                                >
                                    <Tab style={{ backgroundColor: qrcodeTabValue == 0 ? theme.palette.primary.main : "#fff", color: qrcodeTabValue == 0 ? "#fff" : theme.palette.primary.main }} label={t('vouchers.code')} {...a11yProps(0)} />
                                    <Tab style={{ backgroundColor: qrcodeTabValue == 1 ? theme.palette.primary.main : "#fff", color: qrcodeTabValue == 1 ? "#fff" : theme.palette.primary.main }} label={t('orders.orderNo')} {...a11yProps(1)} />
                                </Tabs>
                                <Box style={{ textAlign: "center", marginTop: 10 }}>
                                    <TabPanel value={qrcodeTabValue} index={0}>
                                        <QRCodeSVG value={`${LOCAL_URL}/voucher-redeem?code=${voucherDialog.voucher.code}`} />
                                    </TabPanel>
                                    <TabPanel value={qrcodeTabValue} index={1}>
                                        <QRCodeSVG value={`${LOCAL_URL}/voucher-redeem?order_id=${voucherDialog.voucher.order_id}`} />
                                    </TabPanel>
                                    <Typography>{t('vouchers.redeemNote')}</Typography>
                                </Box>
                            </Box>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    {
                        voucherDialog.voucher.status === 20 || voucherDialog.voucher.status === 40
                            ?
                            <VoucherPdf label={t('vouchers.downloadPdf')} voucher={voucherDialog.voucher} />
                            :
                            null
                    }
                    <Button variant="contained" onClick={() => handleVoucherDialogClose()}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const rearrangeOrderDetail = () => {
        let rearrangeOrderDetail = {};
        _.map(order.order_details, (orderDetail, dkey) => {
            if (orderDetail.status) {
                let main_package_id = orderDetail.package_id;
                let main_package = orderDetail.package;
                if (orderDetail.add_on_id) {
                    let main_detail = _.find(order.order_details, { id: parseInt(orderDetail.add_on_id) });
                    main_package_id = main_detail.package_id;
                    main_package = main_detail.package;
                }
                if (!(rearrangeOrderDetail[main_package_id])) {
                    rearrangeOrderDetail[main_package_id] = {};
                }
                if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date])) {
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date] = { items: {}, add_on: {}, package: main_package };
                }
                if (orderDetail.add_on_id) {
                    if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package_id])) {
                        rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package_id] = { items: {}, package: orderDetail.package };
                    }
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package_id]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package_id]['items'])] = orderDetail;
                } else {
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'])] = orderDetail;
                }
            }
        })
        setDisplayOrder(rearrangeOrderDetail);
    }

    const optionBox = (type, option) => {
        return (
            <TableRow
                key={option.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell component="th" scope="row">
                    {type === 'add-on' ? <Typography variant="body2">Add-On: </Typography> : null}
                    <Typography variant="body2">{option.package_name}</Typography>
                    <Typography variant="body2">{option.package_option_name}</Typography>
                </TableCell>
                <TableCell>
                    {_.map(option.vouchers, voucher => {
                        return (
                            <Link key={voucher.id} onClick={() => handleVoucherDialogOpen(voucher)} underline="none" className={classes.voucherLink}>
                                <Typography variant="body2">{voucher.code}</Typography>
                            </Link>
                        )
                    })}
                </TableCell>
                <TableCell align="right">
                    {
                        option.price['retail'] > 0 && (option.price['retail'] - option.price['sell']) > 0
                            ?
                            <Typography variant="caption" style={{ textDecoration: "line-through" }}>{parseFloat(option.price['retail']).toFixed(2)}</Typography>
                            :
                            null
                    }
                    <Typography variant="body2">{parseFloat(option.price['sell']).toFixed(2)}</Typography>
                </TableCell>
                <TableCell align="right">{'x' + option.quantity}</TableCell>
                <TableCell align="right">{'RM ' + parseFloat(option.price['sell'] * option.quantity).toFixed(2)}</TableCell>
            </TableRow>
        )
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Edit`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Edit`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            {
                _.size(order) > 0
                    ?
                    <Grid container direction="row">
                        <Grid item xs={12} md={6}>
                            <Box className={classes.boxStyle}>
                                <Typography variant="h6">{t('orders.orderNo') + ": " + order.order_no}</Typography>
                                <Divider style={{ margin: "10px 0px" }} />
                                <Box style={{ display: "flex", alignItems: "center" }}>
                                    <Typography>{t('orders.status') + ": " + (order.status_display[i18n.language] ? order.status_display[i18n.language] : order.status_display.en)}</Typography>
                                    {
                                        permissions.includes('order-status')
                                            ?
                                            <IconButton size="small" style={{ marginRight: 5, color: theme.palette.primary.main }} onClick={handleEditStatusOpen}><FiEdit /></IconButton>
                                            :
                                            null
                                    }
                                </Box>
                                <Typography>{t('orders.createdAt') + ": " + order.created_at}</Typography>
                                <Typography>{t('orders.remark') + ": " + (order.remark ? order.remark : '-')}</Typography>
                                {
                                    order.receipt_id
                                        ?
                                        <Typography>
                                            {t('orders.receipt') + ": "}
                                            <a href={order.receipt?.file_name} rel="noreferrer" target="_blank">{t('orders.viewReceipt')}</a>
                                            {/* {
                                                order.status === 30
                                                    ?
                                                    <Button variant="outlined" size="small" style={{ marginLeft: 5 }} onClick={() => setApproveReceiptDialog(true)}>
                                                        {t('orders.approveReceipt')}
                                                    </Button>
                                                    :
                                                    null
                                            } */}
                                        </Typography>
                                        :
                                        null
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Box className={classes.boxStyle}>
                                <Typography>
                                    {t('orders.agency') + ": "}
                                    <Link underline='none' component={RouterLink} to={`/agencies/${order.agency_id}`}>
                                        {order.agency ? order.agency.name : '-'}
                                    </Link>
                                </Typography>
                                <Typography>
                                    {t('orders.customer') + ": "}
                                    {(order.order_customer ? order.order_customer.name : '-') + ' | '}
                                    {(order.order_customer ? order.order_customer.email : '-') + ' | '}
                                    {order.order_customer ? (order.order_customer.mobile ? order.order_customer.mobile : '-') : '-'}
                                </Typography>
                                <Typography>
                                    {t('orders.billingAddress') + ": "}
                                    {order.order_customer ? order.order_customer.full_address : '-'}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.boxStyle}>
                                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                                    <Typography variant="h6">{t('orders.orderDetail')}</Typography>
                                    <Button variant="outlined" size="small" style={{ margin: 10, }} onClick={() => handleCustomerDialogOpen()}><FiUsers style={{ marginRight: 5 }} />{t('orders.customerList')}</Button>
                                </Box>
                                <Divider />
                                <TableContainer>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{t('orders.packageName')}</TableCell>
                                                <TableCell>{t('vouchers.code')}</TableCell>
                                                <TableCell align="right">{t('orders.price')}</TableCell>
                                                <TableCell align="right">{t('orders.quantity')}</TableCell>
                                                <TableCell align="right">{t('orders.subAmount')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                _.map(displayOrder, (data, packageId) => (
                                                    _.map(data, (groupItem, booking_date) => (
                                                        <>
                                                            {
                                                                _.map(groupItem.items, mainOption => {
                                                                    return optionBox('main', mainOption);
                                                                })
                                                            }
                                                            {
                                                                _.map(groupItem.add_on, (add_on) => (
                                                                    (_.size(add_on.items) > 0) && _.map(add_on.items, addOnOption => {
                                                                        return optionBox('add-on', addOnOption);
                                                                    })
                                                                ))
                                                            }
                                                        </>
                                                    ))
                                                ))
                                            }
                                            <TableRow>
                                                <TableCell colSpan={2} />
                                                <TableCell align="right">{t('orders.subTotal')}</TableCell>
                                                <TableCell align="right">{'RM ' + parseFloat(subTotal).toFixed(2)}</TableCell>
                                            </TableRow>
                                            {
                                                agentDiscount &&
                                                <TableRow>
                                                    <TableCell colSpan={2} />
                                                    <TableCell align="right">{t('orders.agentDiscount')}</TableCell>
                                                    <TableCell align="right">{'RM ' + parseFloat(order.total_amount - subTotal).toFixed(2)}</TableCell>
                                                </TableRow>
                                            }
                                            {
                                                parseFloat(codeDiscount)
                                                    ?
                                                    <TableRow>
                                                        <TableCell colSpan={2} />
                                                        <TableCell align="right">{t('orders.codeDiscount')}</TableCell>
                                                        <TableCell align="right">{codeDiscount > 0 ? '' : '- ' + 'RM ' + parseFloat(Math.abs(codeDiscount)).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                    :
                                                    null
                                            }
                                            <TableRow>
                                                <TableCell colSpan={2} />
                                                <TableCell align="right">{t('orders.totalAmount')}</TableCell>
                                                <TableCell align="right">{'RM ' + parseFloat(order.total_amount).toFixed(2)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    null
            }
            {/* Edit Status Dialog */}
            <Dialog open={state.open} onClose={handleEditStatusClose} fullWidth maxWidth="sm">
                <DialogTitle>{t('orders.editStatus')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <InputLabel>{t('orders.status')}</InputLabel>
                                    <Select
                                        value={state.status}
                                        label={t('orders.status')}
                                        name="status"
                                        onChange={({ target }) => setState({ ...state, [target.name]: target.value })}
                                    >
                                        {_.map(statusList, resultItem => {
                                            return <MenuItem key={resultItem.code} value={resultItem.code}>{resultItem[i18n.language] ? resultItem[i18n.language] : resultItem.en}</MenuItem>
                                        })}
                                    </Select>
                                </Box>
                            </FormControl>
                        </Grid>
                        {
                            state.status === 60
                                ?
                                <Grid item xs={12}>
                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            label={t('orders.remark')}
                                            value={state.remark}
                                            name="remark"
                                            onChange={({ target }) => setState({ ...state, [target.name]: target.value })}
                                        />
                                    </Box>
                                </Grid>
                                :
                                null
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleEditStatusClose}>{t('button.cancel')}</Button>
                    <Button className={classes.confirmButton} onClick={updateStatus}>{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
            {/* Approve Payment Dialog */}
            <Dialog open={approveReceiptDialog} onClose={() => setApproveReceiptDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>{t('orders.approveReceipt')}</DialogTitle>
                <DialogContent>
                    {t('orders.approveReceiptConfirmation')}
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={() => setApproveReceiptDialog(false)}>{t('button.cancel')}</Button>
                    <Button className={classes.confirmButton} onClick={approvePayment}>{t('button.approve')}</Button>
                </DialogActions>
            </Dialog>
            {showVoucherDialog()}
            {customerList()}
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
    boxStyle: {
        borderRadius: 10,
        boxShadow: '0px 1px 6px 3px #0000001f',
        padding: 15,
        margin: 10
    },
    confirmButton: {
        backgroundColor: theme.palette.secondary.main + '! important',
        color: theme.palette.secondary.secondary + '! important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.secondary + '! important',
        color: theme.palette.secondary.main + '! important',
        border: '1px solid ' + theme.palette.secondary.main + '! important',
    },
    voucherLink: {
        "&:hover": {
            color: theme.palette.primary.secondary,
            cursor: 'pointer',
        },
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.secondary + ' !important',
        // color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
    },
}))

export default Edit