import React, { useState, memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { BsChevronDown } from 'react-icons/bs';

import { putUrl, deleteUrl } from '../../../helpers/ApiAction';
import useNotificationLoading from '../../../helpers/useNotificationLoading';

const AddressAccordion = memo((props) => {
    const { id, name, full_address, user, reloadAddress } = props;

    const [edit, setEdit] = useState(false);
    const [expandable, setExpandable] = useState(false);
    const [inputErrors, setInputErrors] = useState({});
    const [addressField, setAddressField] = useState({
        name: '',
        address1: '',
        address2: '',
        zip: '',
        city: '',
        state: '',
        country: '',
    });
    const { addAlert, setLoading } = useNotificationLoading();

    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        setAddressField({
            name: props.name || "",
            address1: props.address1 || "",
            address2: props.address2 || "",
            zip: props.zip || "",
            city: props.city || "",
            state: props.state || "",
            country: props.country || "",
        })
    }, [props]);

    const toggleEdit = () => {
        setEdit(!edit)
    }

    const toggleExpandable = () => {
        setExpandable(!expandable)
    }

    const updateAddressBtnClicked = () => {
        setLoading(true);
        putUrl(`/users/${user}/address/${id}`, addressField).then(response => {
            if (response.status) {
                setInputErrors({});
                reloadAddress();
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addAlert('', err.message || t('error.contactSupport'), 'error', '');
        })
    }

    const deleteAddress = () => {
        deleteUrl(`/users/${user}/address/${id}`).then(response => {
            if (response.status) {
                setInputErrors({});
                reloadAddress();
                addAlert('', t('success.deleteSuccess'), 'success', '');
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.deleteError'), 'error', '');
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            addAlert('', err.message || t('error.contactSupport'), 'error', '');
        })
    }

    const handleChange = ({ target }) => {
        setAddressField({ ...addressField, [target.name]: target.value });
    }

    return (
        <Accordion expanded={expandable} onChange={toggleExpandable}>
            <AccordionSummary
                expandIcon={<BsChevronDown />}
                aria-controls={`address-${id}-content`}
                id={`address-${id}-header`}
                classes={{ content: classes.summaryContainer }}
            >
                <Typography sx={{ width: '20%', flexShrink: 0 }}>
                    {name}
                </Typography>
                <Typography sx={{ width: '70%', color: 'text.secondary' }} noWrap>{full_address}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className={classes.formContainer} noValidate>
                    <Grid container>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={t('user.name')}
                                value={addressField.name}
                                autoComplete="name"
                                name="name"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.name ? true : false}
                                helperText={inputErrors?.name || ''}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                rows={2}
                                multiline
                                label={t('user.address')}
                                value={addressField.address1}
                                autoComplete="address-line1"
                                name="address1"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.address1 ? true : false}
                                helperText={inputErrors?.address1 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                rows={2}
                                multiline
                                label={t('user.address2')}
                                value={addressField.address2}
                                autoComplete="address-line2"
                                name="address2"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.address2 ? true : false}
                                helperText={inputErrors?.address2 || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={t('user.zip')}
                                value={addressField.zip}
                                autoComplete="postal-code"
                                name="zip"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.zip ? true : false}
                                helperText={inputErrors?.zip || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={t('user.city')}
                                value={addressField.city}
                                autoComplete="address-level2"
                                name="city"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.city ? true : false}
                                helperText={inputErrors?.city || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={t('address.state')}
                                value={addressField.state}
                                autoComplete="address-level1"
                                name="state"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.state ? true : false}
                                helperText={inputErrors?.state || ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label={t('user.country')}
                                value={addressField.country}
                                autoComplete="country-name"
                                name="country"
                                onChange={handleChange}
                                disabled={!edit}
                                error={inputErrors?.country ? true : false}
                                helperText={inputErrors?.country || ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display="flex" justifyContent="flex-end">
                                {edit ?
                                    <>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={toggleEdit}
                                        >
                                            {t('button.cancel')}
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={updateAddressBtnClicked}
                                        >
                                            {t('button.update')}
                                        </Button>
                                    </>

                                    : <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between">
                                        <Button
                                            variant="contained"
                                            color="error"
                                            size="large"
                                            onClick={deleteAddress}
                                        >
                                            {t('button.delete')}
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            className={classes.submit}
                                            onClick={toggleEdit}
                                        >
                                            {t('button.edit')}
                                        </Button>
                                    </Box>}
                            </Box>
                        </Grid>
                    </Grid>
                </div>
            </AccordionDetails>
        </Accordion>
    )
});

export default AddressAccordion;

const useStyles = makeStyles((theme) => ({
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        marginLeft: "10px !important",
    },
    summaryContainer: {
        width: '100%',
        paddingRight: 20,
        marginRight: "-20px !important",
    }
}))