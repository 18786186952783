import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import _ from 'lodash';
import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl, getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "wallets";
const MODULE_SINGLE = "wallet";

const WalletAddDeduct = () => {
    const { t, i18n } = useTranslation();
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        email: '',
        wallet_id: '',
        wallet_action: '',
        amount: '',
        remark: ''
    });
    const action = [{ code: "add", label: t(`${MODULE}.add`) }, { code: "deduct", label: t(`${MODULE}.deduct`) }];
    const [walletList, setWalletList] = useState([]);
    const isMountedRef = useRef(null);
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        isMountedRef.current = true;
        getUrl(`/${MODULE}`).then(result => {
            if (isMountedRef.current && result.status === 1) {
                setWalletList(result.data);
            }
        }).catch((error) => {
            addAlert('', error + "\n" + t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        try {
            let updateData = {
                email: state.email,
                wallet_id: state.wallet_id,
                wallet_action: state.wallet_action,
                amount: state.amount,
                remark: state.remark
            }
            postUrl(`wallets/add_deduct`, updateData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    setState({
                        email: '',
                        wallet_id: '',
                        wallet_action: '',
                        amount: '',
                        remark: ''
                    });
                    addAlert('', t('success.createSuccess'), 'success', '');
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } catch (e) {
            setLoading(false);
            addAlert('', t('general.notJson', { column: t(`${MODULE}.value`) }), 'error', '');
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Adjust`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}AddDeduct`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.wallet_id`)}
                            variant="outlined"
                            value={state.wallet_id}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.wallet_id ? inputErrors.wallet_id : ''}
                            error={inputErrors && inputErrors.wallet_id ? true : false}
                            name="wallet_id"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {_.map(walletList, (value, key) => {
                                let name = _.split(value.name, '|');
                                return (
                                    <option key={key} value={value.id}>
                                        {i18n.language === 'cn' ? (name[1] ? name[1] : name[0]) : name[0]}
                                    </option>
                                )
                            })}
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.action`)}
                            variant="outlined"
                            value={state.wallet_action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.wallet_action ? inputErrors.wallet_action : ''}
                            error={inputErrors && inputErrors.wallet_action ? true : false}
                            name="wallet_action"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            onChange={handleChange}
                        >
                            <option key='' value='' disabled>
                                {t('general.pleaseSelect')}
                            </option>
                            {_.map(action, (value, key) => (
                                <option key={key} value={value.code}>
                                    {value.label}
                                </option>
                            ))}
                        </TextField>
                    </Box>
                </Grid><Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.amount`)}
                            variant="outlined"
                            value={state.amount}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.amount ? inputErrors.amount : ''}
                            error={inputErrors && inputErrors.amount ? true : false}
                            name="amount"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.remark`)}
                            variant="outlined"
                            value={state.remark}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                            error={inputErrors && inputErrors.remark ? true : false}
                            name="remark"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.confirm')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default WalletAddDeduct