import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "settings";
const MODULE_SINGLE = "setting";

const Create = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        type: '',
        code: '',
        value: '',
        desc: '',
    });

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();


    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        try {
            let apiData = {
                type: state.type,
                code: state.code,
                value: JSON.parse(state.value),
                desc: state.desc,
            };
            postUrl(`/${MODULE}`, apiData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    addAlert('', t('success.createSuccess'), 'success', '');
                    navigate(`/${MODULE}/${data.id}`, { replace: true });
                } else {
                    setInputErrors(errors);
                    addAlert('', t('error.createError'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } catch (e) {
            setLoading(false);
            addAlert('', t('general.notJson', { column: t(`${MODULE}.value`) }), 'error', '');
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.type`)}
                            variant="outlined"
                            value={state.type}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                            error={inputErrors && inputErrors.type ? true : false}
                            name="type"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.code`)}
                            variant="outlined"
                            value={state.code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.code ? inputErrors.code : ''}
                            error={inputErrors && inputErrors.code ? true : false}
                            name="code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.value`)}
                            variant="outlined"
                            value={state.value}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.value ? inputErrors.value : ''}
                            error={inputErrors && inputErrors.value ? true : false}
                            name="value"
                            onChange={handleChange}
                            multiline
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.desc`)}
                            variant="outlined"
                            value={state.desc}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.desc ? inputErrors.desc : ''}
                            error={inputErrors && inputErrors.desc ? true : false}
                            name="desc"
                            onChange={handleChange}
                            multiline
                        />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Create