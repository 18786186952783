import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import { AddBoxOutlined } from '@mui/icons-material';
import { Grid, Typography, Link, Breadcrumbs, Box, IconButton } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';

const MODULE = "wallet_transactions";
const MODULE_SINGLE = "wallet_transaction";

const Listing = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);
    const [currWallet, setCurrWallet] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);
    let { wallet_id } = useParams();

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            status: 50,
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/${MODULE}/${wallet_id}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.data);
                setRowCount(response.data.total);
                setCurrWallet(response.wallet);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData]);

    const columns = useMemo(() => ([
        {
            accessorFn: (row) => {
                return (i18n.language === 'cn' ? row.type_display[i18n.language] : row.type_display['en'])
            },
            id: 'type',
            header: `${t(`${MODULE}.type_display`)}`,
            minSize: '250',
        },
        {
            accessorKey: 'amount',
            id: 'amount',
            header: `${t(`${MODULE}.amount`)}`,
            minSize: '250',
            Cell: ({ cell }) => (
                <>{parseFloat(cell.row.original.amount).toFixed(currWallet.decimal)}</>
            )
        },
        {
            accessorKey: 'remark',
            header: `${t(`${MODULE}.remark`)}`,
            minSize: '250',
            Cell: ({ cell }) => (
                <>{cell.row.original.remark !== null ? cell.row.original.remark : "-"}</>
            )
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, currWallet]);

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)} - {currWallet.wallet_name}</b></Typography>
                    {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)} - {currWallet.wallet_name}</Typography>
                </Breadcrumbs>
            </Grid>
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'WalletTransactionHistory'}
                exportType={'table'}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
            />
        </>
    )
}

export default Listing