import React, { useEffect, useState, useRef } from 'react';
import { Grid, Typography, LinearProgress, TextField, Button, CircularProgress, Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import { makeStyles } from '@mui/styles';
import _, { isEmpty } from 'lodash';
import { useTheme } from '@mui/styles';

import useNotificationLoading from '../../helpers/useNotificationLoading';

import { MdArticle, MdDone, MdClose, MdSchedule } from "react-icons/md";

const MemberAgentEdit = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const isMountedRef = useRef(null);
    const { addAlert } = useNotificationLoading();
    const [detail, setDetail] = useState({});
    const [detailLoading, setDetailLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState([]);
    const [inputErrors, setInputErrors] = useState({});
    const [document, setDocument] = useState({});
    const [statusList, setStatusList] = useState({});

    const status_icon = {
        0: <MdSchedule size={22} style={{ color: theme.palette.primary.main }} />,
        1: <MdDone size={22} style={{ color: theme.palette.green.main }} />,
        2: <MdClose size={22} style={{ color: theme.palette.red.main }} />,
    }

    useEffect(() => {
        isMountedRef.current = true;
        setDetailLoading(true);
        getUrl(`/member-documents`).then(response => {
            setDetailLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setDetail(response.data.option);
                    let tempArr = [];
                    _.map(response.data.option, (item, index) => {
                        let arr = {
                            code: item.code,
                            new_file: '',
                        };
                        tempArr[index] = arr;
                    });
                    setState(tempArr);
                    setDocument(response.data.document);
                    setStatusList(response.data.status_list);
                }
            }
        }).catch(error => {
            setDetailLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        return () => { isMountedRef.current = false };
    }, []);

    const uploadFile = (file, label) => {
        let tempData = _.clone(state);
        let detailListing = _.clone(detail);
        let labelIndex = _.findIndex(detailListing, { code: label });

        if (labelIndex >= 0) {
            tempData[labelIndex]['new_file'] = file;
        }

        setState(tempData);
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors({});
        const formData = new FormData();
        formData.append("_method", "put");
        const temp_array=[];
        // console.log(state);
        // if (_.size(document) > 0) {
        //     _.each(state,(item,key)=>{
        //         if(item.new_file !==""){
        //             temp_array.push(item);
        //          }
        //     }) 
        //     // console.log("temp", temp_array)
        //     _.each(temp_array, (item, key) => {
        //         _.each(item,(detail, index)=>{
        //             formData.append(`data[${key}][${index}]`, detail || '');
        //         })
        //     })
        // } else {
        //     _.each(state, (item, key) => {
        //         _.each(item, (detail, index) => {
        //             formData.append(`data[${key}][${index}]`, detail || '');
        //         })
        //     })
        // }

        _.each(state,(item,key)=>{
            if(item.new_file !==""){
                temp_array.push(item);
             }
        })
       
        _.each(temp_array, (item, key) => {
            _.each(item,(detail, index)=>{
                formData.append(`data[${key}][${index}]`, detail || '');
            })
        })
        // console.log(...formData);

        postUrl(`/upload-documents`, formData).then(response => {
            setLoading(false);
            if (isMountedRef.current) {
                if (response.status) {
                    setDocument(response.data);
                } else {
                    setInputErrors(response.errors);
                }

                if (response.message) {
                    addAlert('', response.message, response.status ? 'success' : 'error', '');
                }
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }


    return (
        <div className={classes.paper}>
            <Typography component="p" variant="h5">
                {t('user.myDocument')}
            </Typography>
            <div className={classes.formContainer}>
                <div noValidate>
                    <Grid container spacing={2}>
                        {
                            detailLoading ?
                                <LinearProgress color="primary" />
                                :
                                _.size(detail) > 0 && _.map(detail, (item, index) => {
                                    let existing_document = _.find(document, { type: item.code });
                                    return (
                                        <Grid key={item.code} xs={12} item padding={2}>
                                            {
                                                existing_document &&
                                                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" padding={2}>
                                                    <Grid item xs={9}>
                                                        <Typography>{i18n.language === 'cn' ? item.display_name.cn : item.display_name.en}</Typography>
                                                        <Link href={existing_document.file_name} underline="none" target="_blank" style={{ display: 'flex' }}>
                                                            <MdArticle size={20} /><Typography>{existing_document.original_file_name}</Typography>
                                                        </Link>
                                                    </Grid>
                                                    <Grid item xs={3} display="flex">
                                                        {status_icon[existing_document.status]} {statusList[existing_document.status]['en']}
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid  container spacing={2} direction="row" justifyContent="flex-start" alignItems="center" padding={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={existing_document ? '':(i18n.language === 'cn' ? item.display_name.cn : item.display_name.en)}
                                                        type="file"
                                                        InputLabelProps={{ shrink: true }}
                                                        variant="outlined"
                                                        fullWidth
                                                        error={inputErrors && inputErrors[`data.${index}.new_file`] ? true : false}
                                                        helperText={inputErrors[`data.${index}.new_file`] || ''}
                                                        onChange={({ target }) => uploadFile(target.files[0], item.code)}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                })
                        }
                            <Grid item xs={12}>
                                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={submitData}
                                        disabled={loading ? true : false}
                                    >
                                        {
                                            loading ?
                                                <CircularProgress size={20} />
                                                : t('general.upload')
                                        }
                                    </Button>
                                </Box>
                            </Grid>
                    </Grid>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    formContainer: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    paper: {
        display: 'flex',
        flexDirection: 'column',
        boxShadow: 'none',
        borderRadius: 15
    },
}))

export default MemberAgentEdit;