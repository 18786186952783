import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';

import { AddBoxOutlined, Delete } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Link, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import UploadImage from '../Layout/UploadImage';

const MODULE = "agencies";
const INITIAL_DESCRIPTION = {
    name: '',
    caption: '',
    quickInsight: [],
    attractionPoints: [],
    details: '',
    images: [],
    newImages: [],
    deleteImages: [],
}

const PublicViewEdit = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    let { id } = useParams();
    const [description, setDescription] = useState(INITIAL_DESCRIPTION);

    useEffect(() => {
        getUrl(`/${MODULE}/${id}`).then(response => {
            if (response.status) {
                const { agency } = response.data;
                setDescription({
                    name: agency.description?.name,
                    caption: agency.description?.caption,
                    quickInsight: agency.description?.quick_insight,
                    attractionPoints: agency.description?.attraction_points,
                    details: agency.description?.details,
                    images: agency.images,
                    newImages: [],
                    deleteImages: [],
                });
            } else {
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setDescription({ ...description, [name]: value });
    }

    const handleAddPoint = (name) => {
        let newKey = _.size(description[name]);
        setDescription({ ...description, [name]: { ...description[name], [newKey]: '' } });
    }
    const handlePointChange = (name, key, value) => {
        console.log(name, key, value);
        setDescription({ ...description, [name]: { ...description[name], [key]: value } });
    }
    const handleDeletePoint = (name, key) => {
        let newData = _.filter(description[name], function (o, index) { return index !== key });
        setDescription({ ...description, [name]: newData });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let postData = {
            ...description,
            _method: "put",
            quick_insight: description.quickInsight,
            attraction_points: description.attractionPoints,
            images: description.newImages,
            delete_images: description.deleteImages,
        }
        const formData = new FormData();
        buildFormData(formData, postData);
        postUrl(`/${MODULE}/${id}/description`, formData).then(response => {
            setLoading(false);
            const { status, data, errors, message } = response;
            if (status) {
                addAlert('', message || t('success.editSuccess'), 'success', '');
                setDescription({ ...description, newImages: [], deleteImages: [], images: data.images });
            } else {
                setInputErrors(errors);
                addAlert('', message || t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
                <UploadImage state={description} setState={setDescription} inputErrors={inputErrors} />
            </Grid>
            <Grid item xs={12} sm={6} md={8}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={`${t(`${MODULE}.name`)}`}
                                variant="outlined"
                                value={description.name}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                name="name"
                                onChange={handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={`${t(`${MODULE}.description.caption`)}`}
                                variant="outlined"
                                value={description.caption}
                                helperText={inputErrors && inputErrors.caption ? inputErrors.caption : ''}
                                error={inputErrors && inputErrors.caption ? true : false}
                                name="caption"
                                onChange={handleChange}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body2">
                                            {t(`${MODULE}.description.quickInsight`)}
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleAddPoint("quickInsight")}>
                                                <AddBoxOutlined />
                                            </IconButton>
                                            <Typography>{t(`${MODULE}.description.addQuickInsight`)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {
                                _.map(description.quickInsight, (point, key) => {
                                    return (
                                        <Grid item xs={12} sm={12} key={key}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                        size="small"
                                                        variant="outlined"
                                                        value={point}
                                                        InputLabelProps={{ shrink: true }}
                                                        placeholder={t(`${MODULE}.description.quickInsight`)}
                                                        helperText={inputErrors && inputErrors['quick_insight.' + key] ? inputErrors['quick_insight.' + key] : ''}
                                                        error={inputErrors && inputErrors['quick_insight.' + key] ? true : false}
                                                        onChange={(event) => handlePointChange("quickInsight", key, event.target.value)}
                                                    />
                                                    <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeletePoint("quickInsight", key)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Grid container>
                            <Grid item xs={12} sm={12}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <Typography variant="body2">
                                            {t(`${MODULE}.description.attractionPoints`)}
                                        </Typography>
                                        <Box display="flex" alignItems="center">
                                            <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleAddPoint("attractionPoints")}>
                                                <AddBoxOutlined />
                                            </IconButton>
                                            <Typography>{t(`${MODULE}.description.addAttractionPoints`)}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            {
                                _.map(description.attractionPoints, (point, key) => {
                                    return (
                                        <Grid item xs={12} sm={12} key={key}>
                                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                <Box display="flex" alignItems="center" justifyContent="space-between">
                                                    <TextField
                                                        fullWidth
                                                        multiline
                                                        rows={2}
                                                        size="small"
                                                        variant="outlined"
                                                        value={point}
                                                        InputLabelProps={{ shrink: true }}
                                                        placeholder={t(`${MODULE}.description.attractionPoints`)}
                                                        helperText={inputErrors && inputErrors['attraction_points.' + key] ? inputErrors['attraction_points.' + key] : ''}
                                                        error={inputErrors && inputErrors['attraction_points.' + key] ? true : false}
                                                        onChange={(event) => handlePointChange("attractionPoints", key, event.target.value)}
                                                    />
                                                    <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeletePoint("attractionPoints", key)}>
                                                        <Delete />
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={t(`${MODULE}.description.details`)}
                                multiline
                                rows={4}
                                variant="outlined"
                                value={description.details}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.details ? inputErrors.details : ''}
                                error={inputErrors && inputErrors.details ? true : false}
                                name={"details"}
                                onChange={handleChange}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                    <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                        <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save') + ' ' + t(`${MODULE}.publicView`)}</Button>
                </Box>
            </Grid>
        </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
}))

export default PublicViewEdit