import _ from 'lodash';
import MaterialReactTable from 'material-react-table';
import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';

import { Box, Button, LinearProgress } from '@mui/material';
import { MdDownload } from "react-icons/md";

// 'material-react-table': https://www.material-react-table.com/
// 'react-csv': https://www.npmjs.com/package/react-csv

/** exportType: table or database
table: export from table value cache, not suitable for columns using cell
database: export from row original(database) */

const PaginationMaterialReactTable = ({ data, columns, isLoading, exportFile, exportFileName, exportType, initialColumnVisibility, rowCount, pagination, setPagination, columnFilters, setColumnFilters, sorting, setSorting, searchData, setSearchData, enableRowSelection, enableSelectAll, onRowSelectionChange, rowSelection, getRowId, topActions }) => {
    const { t } = useTranslation();
    const [excelHeaders, setExcelHeaders] = useState([]);

    useEffect(() => {
        let headers = [];
        _.map(columns, value => {
            headers.push({ label: value.header, key: value.accessorKey ? value.accessorKey : value.id });
        })
        setExcelHeaders(headers);
        // eslint-disable-next-line
    }, [columns]);

    const today = new Date();
    const datetime_now = today.getFullYear() +
        ((today.getMonth() + 1) < 10 ? `0${(today.getMonth() + 1)}` : `${(today.getMonth() + 1)}`) +
        ((today.getDate()) < 10 ? `0${(today.getDate())}` : `${(today.getDate())}`);

    const handleApplyFilter = () => {
        setPagination({ ...pagination, pageIndex: 0 });
        setSearchData(!searchData);
    }
    const handleClearFilter = () => {
        setColumnFilters([]);
        setPagination({ ...pagination, pageIndex: 0 });
        setSearchData(!searchData);
    }

    return (
        <>
            {
                isLoading
                    ?
                    <Box style={{ margin: 20, textAlign: "center" }}>
                        <LinearProgress />
                    </Box>
                    :
                    <MaterialReactTable
                        columns={columns}
                        data={data}

                        initialState={{
                            showColumnFilters: true,
                            columnVisibility: initialColumnVisibility ? initialColumnVisibility : null
                        }}
                        state={{ isLoading, columnFilters, pagination, sorting, rowSelection:rowSelection?rowSelection:[], }}

                        // row selection
                        enableSelectAll={enableSelectAll?true:false}
                        enableRowSelection={enableRowSelection?enableRowSelection:false}
                        onRowSelectionChange={onRowSelectionChange?onRowSelectionChange:null}
                        rowSelection={rowSelection}
                        getRowId={getRowId?getRowId:((originalRow) => originalRow.index)}

                        // pagination
                        rowCount={rowCount}
                        manualFiltering
                        manualPagination
                        manualSorting
                        onColumnFiltersChange={setColumnFilters}
                        onPaginationChange={setPagination}
                        onSortingChange={setSorting}
                        muiTablePaginationProps={{
                            rowsPerPageOptions: [10, 20, 30, 50, 100, 500, 1000],
                        }}

                        // disabled functions
                        enableFullScreenToggle={false}
                        enableGlobalFilter={false}
                        enableHiding={false}
                        enableDensityToggle={false}

                        // EXPORT
                        positionToolbarAlertBanner="top"
                        renderToolbarTopCustomActions={({ table }) => (
                            <Box
                                sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                            >
                                {
                                    exportFile
                                        ?
                                        <CSVLink
                                            data={exportType === 'table' ? (table.getPrePaginationRowModel().rows).map((row) => row._valuesCache) : (table.getPrePaginationRowModel().rows).map((row) => row.original)}
                                            headers={excelHeaders}
                                            filename={exportFileName + `_` + datetime_now + `.csv`}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <Button
                                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                startIcon={<MdDownload />}
                                                variant="contained"
                                            >
                                                {t('button.exportData')}
                                            </Button>
                                        </CSVLink>
                                        :
                                        null
                                }
                                <Button variant="contained" onClick={() => handleApplyFilter()}>
                                    {t('button.applyFilter')}
                                </Button>
                                <Button variant="contained" onClick={() => handleClearFilter()}>
                                    {t('button.clearFilter')}
                                </Button>
                                { topActions ? topActions : null }
                            </Box>
                        )}
                    />
            }
        </>
    )
};

export default PaginationMaterialReactTable