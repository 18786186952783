import _ from 'lodash';
import Moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import { useSelector } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';
import UploadDocuments from './UploadDocuments';

import { Box, Button, FormControl, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography, FormControlLabel, FormGroup, Checkbox, FormHelperText, Switch } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { buildFormData } from '../../helpers/Tools';

const MODULE = "agencies";

const Edit = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        name: '',
        slug: '',
        type: '',
        permission: [],
        mobile: '',
        email: '',
        register_no: '',
        public_holidays: [],
        profit_sharing: '',
        status: 1,
        approve_package: 'default',
        cover_photo: '',
        existing_image: '',
        bank_name: '',
        account_name: '',
        account_number: '',
        bank_swift_code: '',
    });
    const [documents, setDocuments] = useState([]);
    const [existingDocuments, setExistingDocuments] = useState([]);
    const [deleteDocuments, setDeleteDocuments] = useState([]);
    const [agencyType, setAgencyType] = useState([]);
    const [agencyPermission, setAgencyPermission] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [statusPending, setStatusPending] = useState(false);

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const { role } = useSelector(state => state.general);

    const approveSelectList = [{ code: 1, label: t('approveSelect.on') }, { code: 0, label: t('approveSelect.off') }, { code: 'default', label: t('approveSelect.default') }];

    useEffect(() => {
        getUrl(`/${MODULE}/${id}`).then(response => {
            if (response.status) {
                const { agency, agency_type, agency_permission, status_list } = response.data;
                setState({
                    name: agency.name,
                    slug: agency.slug,
                    type: agency.type,
                    permission: agency.permission,
                    mobile: agency.mobile ? agency.mobile : '',
                    email: agency.email,
                    register_no: agency.register_no,
                    public_holidays: agency.public_holidays ? agency.public_holidays : [],
                    profit_sharing: agency.profit_sharing,
                    status: agency.status,
                    approve_package: agency.approve_package === null ? 'default' : agency.approve_package,
                    cover_photo: '',
                    existing_image: agency.cover_photo ? agency.cover_photo : null,
                    bank_name: agency.bank_info && agency.bank_info.bank_name ? agency.bank_info.bank_name : '',
                    account_name: agency.bank_info && agency.bank_info.account_name ? agency.bank_info.account_name : '',
                    account_number: agency.bank_info && agency.bank_info.account_number ? agency.bank_info.account_number : '',
                    bank_swift_code: agency.bank_info && agency.bank_info.bank_swift_code ? agency.bank_info.bank_swift_code : '',
                });
                setExistingDocuments(agency.documents);
                setAgencyType(agency_type);
                setAgencyPermission(agency_permission);
                setStatusList(status_list);
                setStatusPending(parseInt(agency.status) === 2 ? true : false);
            } else {
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        if (name === "name") {
            const slug = _.kebabCase(value);
            setState({ ...state, "name": value, "slug": slug });
        } else if (name === "cover_photo") {
            setState({ ...state, [name]: target.files[0] });
        } else if (name === 'status') {
            setState({ ...state, status: target.checked ? 1 : 0 });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const handleAgencyPermissionChange = ({ target }) => {
        const { name, checked } = target;
        let permission_array = state.permission;
        if(checked){
            permission_array.push(name);
        }else{
            permission_array = _.filter(permission_array, function (p) { return p !== name });
        }
        setState({ ...state, "permission": permission_array });
    }

    const handlePublicHolidayChange = (dates) => {
        let dateArray = [];
        _.map(dates, date => {
            dateArray.push(Moment([date.year, date.monthIndex, date.day]).format('YYYY-MM-DD'));
        })
        setState({ ...state, "public_holidays": dateArray });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let postData = {
            ...state,
            public_holidays: _.size(state.public_holidays) > 0 ? state.public_holidays : null,
            approve_package: state.approve_package === 'default' ? null : (state.approve_package ? 1 : 0),
            documents: documents,
            delete_documents: deleteDocuments,
            cover_photo: state.cover_photo ? state.cover_photo : null,
            _method: "put",
        };
        const formData = new FormData();
        buildFormData(formData, postData);
        postUrl(`/${MODULE}/${id}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                setState({
                    ...state,
                    existing_image: data.cover_photo,
                });
                setStatusPending(parseInt(data.status) === 2 ? true : false);
                addAlert('', t('success.editSuccess'), 'success', '');
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.name`)}
                            variant="outlined"
                            value={state.name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                            error={inputErrors && inputErrors.name ? true : false}
                            name="name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.slug`)}
                            variant="outlined"
                            value={state.slug}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.slug ? inputErrors.slug : ''}
                            error={inputErrors && inputErrors.slug ? true : false}
                            name="slug"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel>{t(`${MODULE}.type`)}</InputLabel>
                            <Select
                                value={state.type}
                                label={t(`${MODULE}.type`)}
                                name="type"
                                onChange={handleChange}
                            >
                                {_.map(agencyType, (type_translate, type) => {
                                    return <MenuItem key={type} value={type}>{i18n.language === 'cn'?type_translate.cn:type_translate.en}</MenuItem>
                                })}
                            </Select>
                            <FormHelperText style={{ color: theme.palette.indicator.red }}>{inputErrors && inputErrors.type ? inputErrors.type : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                {
                    _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormGroup>
                                    <InputLabel>{t(`${MODULE}.permission`)}</InputLabel>
                                    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                                        {_.map(agencyPermission, (permission_translate, permission) => (
                                            <Grid item xs={2} sm={4} md={4} key={permission}>
                                                <FormControlLabel control={<Checkbox />} label={permission_translate[i18n.language]} name={permission} onChange={handleAgencyPermissionChange} checked={_.includes(state.permission, permission)} />
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <FormHelperText style={{ color: theme.palette.indicator.red }}>{inputErrors && inputErrors.permission ? inputErrors.permission : ''}</FormHelperText>
                                </FormGroup>
                            </Box>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.register_no`)}
                            variant="outlined"
                            value={state.register_no}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.register_no ? inputErrors.register_no : ''}
                            error={inputErrors && inputErrors.register_no ? true : false}
                            name="register_no"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.mobile`)}
                            variant="outlined"
                            value={state.mobile}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                            error={inputErrors && inputErrors.mobile ? true : false}
                            name="mobile"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.profitSharing`)}
                            variant="outlined"
                            value={state.profit_sharing}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.profit_sharing ? inputErrors.profit_sharing : ''}
                            error={inputErrors && inputErrors.profit_sharing ? true : false}
                            name="profit_sharing"
                            onChange={handleChange}
                            InputProps={{ readOnly: _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0 ? false : true }}
                        />
                    </Box>
                </Grid>
                {
                    _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t(`${MODULE}.approve_package`)}</InputLabel>
                                    <Select
                                        value={state.approve_package}
                                        label={t(`${MODULE}.approve_package`)}
                                        name="approve_package"
                                        onChange={handleChange}
                                    >
                                        {_.map(approveSelectList, selection => {
                                            return <MenuItem key={selection.code} value={selection.code}>{selection.label}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        : null
                }
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        {state.existing_image && <img src={state.existing_image.file_name} alt="cover" style={{ width: 'auto', height: 150, paddingLeft: 10 }} />}
                        <TextField
                            label={t(`${MODULE}.coverPhoto`)}
                            type="file"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.cover_photo ? inputErrors.cover_photo : ''}
                            error={inputErrors && inputErrors.cover_photo ? true : false}
                            name="cover_photo"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    _.size(statusList) > 0
                        ?
                        <Grid item xs={12} sm={6}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <FormControl fullWidth style={{ alignItems: "flex-start" }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={
                                                    _.size(_.intersection(role, ['dev', 'super-admin', 'admin'])) > 0
                                                        ?
                                                        (state.status === 1 ? true : false)
                                                        :
                                                        (state.status ? true : false)
                                                }
                                                name="status"
                                                onChange={handleChange}
                                            />
                                        }
                                        labelPlacement="start"
                                        label={t(`${MODULE}.status`)}
                                    />
                                    {
                                        statusPending
                                            ?
                                            <Typography variant="body2" style={{ marginLeft: "16px", fontStyle: "italic" }}>{t('agencies.pending')}</Typography>
                                            :
                                            null
                                    }
                                    {
                                        inputErrors && inputErrors.status
                                            ?
                                            <Typography variant="body2" style={{ marginLeft: "16px", color: theme.palette.indicator.red }}>{inputErrors.status}</Typography>
                                            :
                                            null
                                    }
                                    {/* <Select
                                            value={state.status}
                                            label={t(`${MODULE}.status`)}
                                            name="status"
                                            onChange={handleChange}
                                        >
                                            {_.map(statusList, statusType => {
                                                return <MenuItem key={statusType.code} value={statusType.code}>{statusType[i18n.language]}</MenuItem>
                                            })}
                                        </Select> */}
                                </FormControl>
                            </Box>
                        </Grid>
                        : null
                }
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`${MODULE}.public_holidays`)}</Typography>
                        <Typography variant="caption">{t(`${MODULE}.phNote`)}</Typography>
                        <DatePicker
                            sort
                            multiple
                            plugins={[
                                <DatePanel />
                            ]}
                            name="public_holidays"
                            value={state.public_holidays}
                            onChange={(dates) => handlePublicHolidayChange(dates)}
                            style={{ width: "100%", padding: "25px 20px" }}
                        />
                    </Box>
                </Grid>
                {/* BANK INFO */}
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`${MODULE}.bank_info`)}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.bank_name`)}
                            variant="outlined"
                            value={state.bank_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bank_name ? inputErrors.bank_name : ''}
                            error={inputErrors && inputErrors.bank_name ? true : false}
                            name="bank_name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.account_name`)}
                            variant="outlined"
                            value={state.account_name}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.account_name ? inputErrors.account_name : ''}
                            error={inputErrors && inputErrors.account_name ? true : false}
                            name="account_name"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.account_number`)}
                            variant="outlined"
                            value={state.account_number}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.account_number ? inputErrors.account_number : ''}
                            error={inputErrors && inputErrors.account_number ? true : false}
                            name="account_number"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.bank_swift_code`)}
                            variant="outlined"
                            value={state.bank_swift_code}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.bank_swift_code ? inputErrors.bank_swift_code : ''}
                            error={inputErrors && inputErrors.bank_swift_code ? true : false}
                            name="bank_swift_code"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <Typography>{t(`${MODULE}.documents`)}</Typography>
                        <UploadDocuments documents={documents} setDocuments={setDocuments} preview={true} existingDocuments={existingDocuments} setExistingDocuments={setExistingDocuments} deleteDocuments={deleteDocuments} setDeleteDocuments={setDeleteDocuments} />
                    </Box>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save') + ' ' + t(`${MODULE}.basicInfo`)}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default Edit