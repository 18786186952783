import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Tab, Tabs, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import AddressEdit from './AddressEdit';
import BasicInfoEdit from './BasicInfoEdit';
import PasswordEdit from './PasswordEdit';
import BackToPrevious from '../Layout/BackToPrevious';

// Tab
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

export default function Edit() {
    const styles = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <div>
            <BackToPrevious />
            <div style={{ paddingBottom: '5%' }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, }}>
                        <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t('title.staffEdit')}</b></Typography>
                    </div>
                    <div style={{ paddingBottom: 15 }}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/dashboard`}>
                                {t('title.dashboard')}
                            </Link>
                            <Link underline="hover" color="inherit" component={RouterLink} to={`/staffs`}>
                                {t('title.staffs')}
                            </Link>
                            <Typography color="text.primary">{t('title.staffEdit')}</Typography>
                        </Breadcrumbs>
                    </div>
                </Grid>
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                        >
                            <Tab
                                label={t("user.basicInfo")}
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={t("user.password")}
                                {...a11yProps(1)}
                            />
                            <Tab
                                label={t("user.addresses")}
                                {...a11yProps(2)}
                            />
                        </Tabs>
                    </Box>
                </Box>
                <TabPanel value={tabValue} index={0}><BasicInfoEdit /></TabPanel>
                <TabPanel value={tabValue} index={1}><PasswordEdit /></TabPanel>
                <TabPanel value={tabValue} index={2}><AddressEdit /></TabPanel>
            </div>
        </div >

    );
}

const useStyles = makeStyles(theme => ({
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));