import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { getUrl, postUrl, putUrl, deleteUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import { useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';

// MUI
import { Breadcrumbs, Button, Grid, ImageList, ImageListItem, Link, Typography, LinearProgress, ImageListItemBar } from '@mui/material';
import { IoAdd, IoTrashOutline } from "react-icons/io5";
import DeleteConfirmationModal from '../Layout/DeleteCofirmationModal';
import ImageShowModal from '../Layout/ImageShowModal';
import BackToPrevious from '../Layout/BackToPrevious';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const MODULE = "media-galleries";
const MODULE_SINGLE = "media-gallery";

export default function MediaGallery() {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();

    const [state, setState] = useState({
        images: [],
        newImages: [],
    });
    const [updateList, setUpdateList] = useState({
		images: state.images
	});
    const [inputErrors, setInputErrors] = useState({});
    const [tableLoading, setTableLoading] = useState(false);
    const [uploadImages, setUploadImages] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);
    const [deleteDialog, setDeleteDialog] = useState({ imageId: '', open: false });
    const [imgDialog, setImgDialog] = useState({ image: '', id: '', open: false });
    const onDrop = useCallback(acceptedFiles => {
        setUploadImages(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    useEffect(() => {
        getApi();
    }, []);

    useEffect(() => {
        if (uploadImages) {
            if (FileReader) {
                _.map(uploadImages, (img, index) => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        setDisplayImages(prevState => (
                            [...prevState, fr.result]
                        ))
                    }
                    fr.readAsDataURL(img);
                    setState(prevState => ({
                        ...prevState,
                        newImages: [...prevState.newImages, img]
                    }))
                })
            }
        }
        // eslint-disable-next-line
    }, [uploadImages]);

    const getApi = () => {
        getUrl(`/${MODULE}`).then(response => {
            setState(prevState => ({ ...prevState, newImages: [], images: response.data.listing }))
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        })
    }

    const removeImage = (index) => {
        let copyImages = [...state.newImages];
        let duplicateDisplayImages = [...displayImages];
        copyImages.splice(index, 1);
        duplicateDisplayImages.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            newImages: copyImages
        }))
        setDisplayImages(duplicateDisplayImages)
    };

    const removeExistingImage = () => {
        setLoading(true)
        deleteUrl(`/${MODULE}/${deleteDialog.imageId}`).then(response => {
            setLoading(false);
            console.log("response", response);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.deleteSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, images: data }))
                setDeleteDialog({ imageId: '', open: false })
            } else {
                setInputErrors(errors);
                addAlert('', t('error.deleteError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const submitData = () => {
        const formData = new FormData();
        _.map(state, (stateItem, stateKey) => {
            console.log(stateKey, stateItem)
            switch (stateKey) {
                case 'newImages':
                    if (_.size(state.newImages) > 0) {
                        for (let i = 0; i < state.newImages.length; i++) {
                            formData.append(`images[]`, state.newImages[i]);
                        }
                    }
                    break;
                case 'images':
                    break;
                default:
                    formData.append(stateKey, stateItem);
                    break;
            }
        });
        formData.append("_method", "post");
        console.log("formdata", ...formData);
        setLoading(true);
        setInputErrors();
        postUrl(`/${MODULE}`, formData).then(response => {
            setLoading(false);
            console.log("response", response);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.editSuccess'), 'success', '');
                setState(prevState => ({ ...prevState, newImages: [], images: data }))
                setDisplayImages([]);
            } else {
                setInputErrors(errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const reorder = (list, startIndex, endIndex) => {
		const result = Array.from(list);
		const [removed] = result.splice(startIndex, 1);
		result.splice(endIndex, 0, removed);

		return result;
	};

    function onDragEnd(result) {
		if (!result.destination) {
			return;
		}

		if (result.destination.index === result.source.index) {
			return;
		}

		const list = reorder(
			state.images,
			result.source.index,
			result.destination.index
		);

		setState(prevState => ({
            ...prevState,
            images: list
        }));
        setUpdateList(prevState => ({ ...prevState, images: list }));
	}

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        boxShadow: '0 0 6px 0 #0002',
        backgroundColor: 'white',
        aspectRatio: '1/1',
      
        // styles we need to apply on draggables
        ...draggableStyle,
    });
      
    const getListStyle = isDraggingOver => ({
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });

    const updatePriority = () => {
		let postData = {
			data: updateList,
		};
		// console.log(updateList);
		putUrl(`/update-media-galleries`, postData).then(response => {
			addAlert("", response.message, response.status ? 'success' : "error", '');
			if (response.status) {
				setState(prevState => ({ ...prevState, images: response.data }))
                setDisplayImages([]);
                setUpdateList({
                    images: response.data
                });
			}
		}).catch(error => {
			addAlert('', error.message || t('error.contactSupport'), 'error', '');
		});
	}

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: 15, color: theme.palette.gray.dtext }}>
                    <Typography style={{ paddingBottom: 15, fontSize: 18 }}><b>{t(`title.mediaGallery`)}</b></Typography>
                </div>
                <div style={{ paddingBottom: 15 }}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Typography color="text.primary">{t(`title.mediaGallery`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} justifyContent="stretch" style={{ paddingBottom: '5%' }}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            {_.size(inputErrors) > 0 && inputErrors.images && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{inputErrors.images}</Typography>}
                            {_.size(inputErrors) > 0 && (Object.keys(state.newImages).some(key => inputErrors[`images.${key}`])) && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{t('error.uploadImageError', {size: '2MB'})}</Typography>}
                            <Typography variant="caption">{t("general.uploadImgMinQty")}</Typography>
                            <label htmlFor="product-images-upload" className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                                <input accept="image/*" id="product-images-upload" multiple type="file" style={{ display: "none" }} onChange={({ target }) => setUploadImages(target.files)} {...getInputProps()} />
                                <IoAdd style={{ fontSize: "3rem" }} />
                                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
                            </label>
                            <Typography>{t(`news.recommendedImageSize`, { width: '200px', height: '200px' })}</Typography>
                        </Grid>
                        {
                            _.size(displayImages) > 0 ?
                                <Grid item xs={12} sm={6} md={8}>
                                    <ImageList 
                                        sx={{ transform: "translateZ(0)", width: '100%', height: 200 }}
                                        cols={5} 
                                        rowHeight={200}
                                        gap={5} 
                                    >
                                        {_.map(displayImages, (item, index) => {
                                            return (
                                                <ImageListItem key={index} cols={1} rows={1} style={{ position: "relative", aspectRatio: '1/1' }} >
                                                    <img src={item} srcSet={item} alt={`uploaded_${index}`} loading="lazy" />
                                                    <ImageListItemBar
                                                        sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top"
                                                        actionPosition="left"
                                                        actionIcon={
                                                            <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeImage(index)} />
                                                        }
                                                    />
                                                </ImageListItem>
                                            );
                                        })}
                                    </ImageList>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12}>
                            <div style={{ marginTop: 15, justifyContent: 'center', display: 'flex' }}>
                                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.uploadImages')}</Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6"><b>{t("general.displayPreview")}</b></Typography>
                    <Grid container spacing={2} style={{ height: "100%" }}>
                        {
                            tableLoading
                                ?
                                <Grid item xs={12}><LinearProgress /></Grid>
                                :
                                _.size(state.images) > 0 ?
                                    <Grid item xs={12}>
                                        <DragDropContext onDragEnd={onDragEnd}>
                                            <Droppable droppableId='media_gallery' direction="horizontal">
                                                {
                                                    (provided, snapshot) => (
                                                        <ImageList 
                                                            sx={{ width: "100%" }}
                                                            rowHeight={240}
                                                            {...provided.droppableProps}
                                                            ref={provided.innerRef}
                                                            style={getListStyle(snapshot.isDraggingOver)}
                                                        >
                                                            {
                                                                _.map(state.images, (item, index) => (
                                                                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                                                        {
                                                                            (provided, snapshot) => (
                                                                                <ImageListItem 
                                                                                    ref={provided.innerRef}
                                                                                    {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}
                                                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                                >
                                                                                    <img
                                                                                        src={`${item.file_name}?w=164&h=164&fit=crop&auto=format`}
                                                                                        srcSet={`${item.file_name}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                                                                        alt={`gallery_${item.id}`}
                                                                                        loading="lazy"
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() => setImgDialog({ ...imgDialog, image: item.file_name, id: item.id, open: true })}
                                                                                    />
                                                                                    <ImageListItemBar
                                                                                        sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top"
                                                                                        actionPosition="left"
                                                                                        actionIcon={
                                                                                            <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => setDeleteDialog({ imageId: item.id, open: true })} />
                                                                                        }
                                                                                    />
                                                                                </ImageListItem>
                                                                            )
                                                                        }
                                                                    </Draggable>
                                                                ))
                                                            }
                                                            {provided.placeholder}
                                                        </ImageList>
                                                    )
                                                }
                                            </Droppable>
                                        </DragDropContext>
                                        <div style={{ marginTop: 15, justifyContent: 'center', display: 'flex' }}>
                                            <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => updatePriority()}>{t('button.savePreference')}</Button>
                                        </div>
                                    </Grid>
                                    :
                                    null
                        }
                    </Grid>
                </Grid>
            </Grid>
            <DeleteConfirmationModal open={deleteDialog.open} closeDeleteDialog={() => setDeleteDialog({ imageId: '', open: false })} submitData={() => removeExistingImage()} />
            <ImageShowModal open={imgDialog.open} closeDialog={() => setImgDialog({ ...imgDialog , image:'' , id:'' , open:false})}  info={imgDialog} />
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150,
        borderRadius: 8,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    },
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed #aeaeae",
        width: "100%",
        height: 170,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    },
}));