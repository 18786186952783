
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

const PaymentCallback = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const location = useLocation();
    const type = (location.pathname).includes('settlement')?'settlement':'order';

    let { payment_id } = useParams();
    const [dialog, setDialog] = useState({
        open: false,
        content: "",
        title: "",
        successPayment: false,
    });

    useEffect(() => {
        if (payment_id) {
            setLoading(true);
            getUrl(`payments/${payment_id}/validate`+(type==='settlement'?'/settlement':'')).then(response => {
                setLoading(false);
                if (response.status) {
                    let status = response.data.payment_status === 40 ? 'success' : (response.data.payment_status === 20 ? 'pending' : 'fail');
                    setDialog({
                        open: true,
                        content: t('payment.' + status + 'Content' + (type === 'settlement'?'Settlement':'')),
                        title: t('payment.' + status),
                        successPayment: status === 'fail' ? false : true,
                    });
                } else {
                    addAlert('', response.message, 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            })
        }
        // eslint-disable-next-line
    }, [payment_id]);

    const handleOkButtonClick = () => {
        if(type === 'settlement'){
            navigate(`/order-settlement`, { replace: true });
        }else{
            navigate(`/agent-orders`, { replace: true });
        }
    }

    return (
        <Box className={classes.paper}>
            <Typography>{t('general.loading')}</Typography>
            <Dialog open={dialog.open}>
                <DialogTitle>{dialog.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialog.content}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleOkButtonClick}>
                        {t('general.ok')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    )
}

export default PaymentCallback;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
        height: 500
    },
}))