import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import _ from 'lodash';

import { Box, Breadcrumbs, Button, FormControl, FormHelperText, Grid, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

const MODULE = "source-payments";
const MODULE_SINGLE = "source-payment";

const Create = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        source: '',
        email: '',
        wallet_id: '',
        payment_type: '',
        limit: '',
    });
    const [walletList, setWalletList] = useState([]);

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();

    const sourceList = [{ code: 'reluxe', label: t('source.reluxe') }];
    const paymentTypeList = [{ code: 'prepaid', label: t('source-payments.prepaid') }, { code: 'postpaid', label: t('source-payments.postpaid') }];

    useEffect(() => {
        getUrl(`${MODULE}/wallets`).then(response => {
            const { status, data } = response;
            if (status) {
                setWalletList(data.listing);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, []);

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        let postData = {
            ...state,
            limit: state.payment_type === 'prepaid' ? '' : state.limit,
        };
        postUrl(`/${MODULE}`, postData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/${MODULE}/${data.id}`, { replace: true });
            } else {
                setInputErrors(errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t(`${MODULE}.source`)}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state.source}
                                label={t(`${MODULE}.source`)}
                                error={inputErrors && inputErrors.source ? true : false}
                                name="source"
                                onChange={handleChange}
                            >
                                {
                                    _.map(sourceList, (source) => {
                                        return <MenuItem key={source.code} value={source.code}>{source.label}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.source ? inputErrors.source : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.email`)}
                            variant="outlined"
                            value={state.email}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                            error={inputErrors && inputErrors.email ? true : false}
                            name="email"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t(`${MODULE}.wallet`)}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state.wallet_id}
                                label={t(`${MODULE}.wallet`)}
                                error={inputErrors && inputErrors.wallet_id ? true : false}
                                name="wallet_id"
                                onChange={handleChange}
                            >
                                {
                                    _.map(walletList, (wallet) => {
                                        return <MenuItem key={wallet.id} value={wallet.id}>{wallet.name}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.wallet_id ? inputErrors.wallet_id : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">{t(`${MODULE}.payment_type`)}</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state.payment_type}
                                label={t(`${MODULE}.payment_type`)}
                                error={inputErrors && inputErrors.payment_type ? true : false}
                                name="payment_type"
                                onChange={handleChange}
                            >
                                {
                                    _.map(paymentTypeList, (payment_type) => {
                                        return <MenuItem key={payment_type.code} value={payment_type.code}>{payment_type.label}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText style={{ color: '#d32f2f' }}>{inputErrors && inputErrors.payment_type ? inputErrors.payment_type : ''}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                {
                    state.payment_type === 'postpaid'
                        ?
                        <Grid item xs={12} sm={6} md={4}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.limit`)}
                                    variant="outlined"
                                    value={state.limit}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.limit ? inputErrors.limit : ''}
                                    error={inputErrors && inputErrors.limit ? true : false}
                                    name="limit"
                                    onChange={handleChange}
                                />
                            </Box>
                        </Grid>
                        : null
                }
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    }
}))

export default Create