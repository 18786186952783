import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { storeProfile } from '../../actions';
import { putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import { Box, Button, Grid, Link, TextField } from '@mui/material';

export default function BasicInfoProfile() {
    const styles = useStyles();
    const { t } = useTranslation();
    const { name, first_name, last_name, username, email, mobile, ic } = useSelector(state => state.user);
    const dispatch = useDispatch();
    const [state, setState] = useState({
        name: name,
        first_name: first_name,
        last_name: last_name,
        username: username,
        email: email,
        mobile: mobile,
        ic: ic,
    });
    const [inputErrors, setInputErrors] = useState({});
    const { addAlert, setLoading } = useNotificationLoading();

    const handleChange = ({ target }) => {
        let { name, value } = target;
        if (name === 'mobile' || name === 'zip') {
            value = !/^\s*$/.test(value) && !isNaN(value) ? value : "";
        }
        setState({ ...state, [name]: value });
    };

    const submitData = (e) => {
        e.preventDefault();
        setLoading(true);
        setInputErrors();
        let apiData = {
            first_name: state.first_name,
            last_name: state.last_name,
            // email: state.email,
            mobile: state.mobile,
            ic: state.ic,
        }

        putUrl(`/user`, apiData).then(response => {
            setLoading(false);
            if (response.status) {
                dispatch(storeProfile(response.data));
                addAlert('', t('success.editSuccess'), 'success', '');
                setState({
                    ...state,
                    existing_image: response.data.profile_picture,
                });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.editError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <div style={{ paddingBottom: '5%' }}>
                <Box component="form" autoComplete="on" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' } }} onSubmit={submitData}>
                    <Grid item xs={12} container spacing={3}>
                        {/* <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.name')}
                                name="name"
                                variant="outlined"
                                type="text"
                                autoComplete="name"
                                value={state.name || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid> */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.firstName')}
                                name="first_name"
                                variant="outlined"
                                type="text"
                                value={state.first_name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.first_name ? inputErrors.first_name : ''}
                                error={inputErrors && inputErrors.first_name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.lastName')}
                                name="last_name"
                                variant="outlined"
                                type="text"
                                value={state.last_name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.last_name ? inputErrors.last_name : ''}
                                error={inputErrors && inputErrors.last_name ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.username')}
                                name="username"
                                variant="outlined"
                                type="text"
                                value={state.username}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.username ? inputErrors.username : ''}
                                error={inputErrors && inputErrors.username ? true : false}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.email')}
                                name="email"
                                variant="outlined"
                                type="email"
                                autoComplete="email"
                                value={state.email || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.email ? inputErrors.email : ''}
                                error={inputErrors && inputErrors.email ? true : false}
                                onChange={handleChange}
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.mobile')}
                                name="mobile"
                                variant="outlined"
                                autoComplete="mobile"
                                value={state.mobile || ''}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={inputErrors && inputErrors.mobile ? inputErrors.mobile : ''}
                                error={inputErrors && inputErrors.mobile ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label={t('user.ic')}
                                name="ic"
                                variant="outlined"
                                autoComplete="ic"
                                value={state.ic || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.ic ? inputErrors.ic : ''}
                                error={inputErrors && inputErrors.ic ? true : false}
                                onChange={handleChange}
                            />
                        </Grid>
                    </Grid>
                    <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Link underline='none' to={`/dashboard`} component={RouterLink}>
                            <Button size="large" variant="outlined" className={styles.buttonStyle} style={{ marginRight: 15 }}>{t('button.cancel')}</Button>
                        </Link>
                        <Button size="large" variant="contained" className={styles.buttonStyle} type="submit" >{t('button.save')}</Button>
                    </Box>
                </Box>
            </div>
        </div >

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    subText: {
        fontSize: '20px!important',
        color: theme.palette.gray.ltext,
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center',
    },
}));