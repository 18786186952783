import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';

import { getUrl, putUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import CustomizeMaterialReactTable from '../Layout/CustomizeMaterialReactTable';

const MODULE = "orders";
const MODULE_SINGLE = "order";

const OrderReceiptApprove = () => {
    const [data, setData] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);

    const [approveReceiptDialog, setApproveReceiptDialog] = useState({
        open: false,
        purchase_no: ''
    });
    const [refreshData, setRefreshData] = useState(false);

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex + 1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
        }
        getUrl(`/order-receipt-approve`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(Object.values(response.data.listing));
                // setRowCount(response.data.listing.total);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData, refreshData]);

    const columns = useMemo(() => ([
        {

            accessorKey: 'purchase_no',
            header: `${t(`${MODULE}.purchaseNo`)}`,
        },
        {
            id: 'orders_no',
            header: `${t(`${MODULE}.orderNo`)}`,
            Cell: ({ cell }) => {
                return (
                    <Box>
                        {
                            _.map(cell.row.original.orders, (order) => {
                                return (
                                    <Box>
                                        {
                                            _.includes(permissions, "order-edit")
                                                ?
                                                <Link component={RouterLink} to={`/orders/${order.id}`} underline="none">
                                                    {order.order_no}
                                                </Link>
                                                :
                                                <Typography>
                                                    {order.order_no}
                                                </Typography>
                                        }
                                    </Box>
                                )
                            })
                        }
                    </Box>
                )
            },
            enableSorting: false,
        },
        {
            accessorKey: 'total_amount',
            header: `${t(`${MODULE}.totalAmount`)}`,
            Cell: ({ cell }) => (
                <Typography variant="body2">
                    {parseFloat(cell.row.original.total_amount).toFixed(2)}
                </Typography>
            )
        },
        {
            accessorFn: (row) => (row.user ? row.user.name : '-'),
            id: 'user.name',
            header: `${t(`${MODULE}.user`)}`,
            enableSorting: false,
        },
        {
            id: 'action',
            header: `${t(`table.actions`)}`,
            enableColumnOrdering: false,
            Cell: ({ cell }) => (
                <Box>
                    <a href={cell.row.original.receipt.file_name} rel="noreferrer" target="_blank">
                        <Button variant="outlined" size="small">
                            {t('orders.viewReceipt')}
                        </Button>
                    </a>
                    <Button variant="outlined" size="small" onClick={() => handleApproveReceiptOpen(cell.row.original.purchase_no)}>
                        {t('orders.approveReceipt')}
                    </Button>
                </Box>
            )
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t]);

    const handleApproveReceiptOpen = (purchase_no) => {
        setApproveReceiptDialog({
            open: true,
            purchase_no: purchase_no
        });
    }
    const handleApproveReceiptClose = () => {
        setApproveReceiptDialog({
            open: false,
            purchase_no: ''
        });
    }
    const approvePayment = () => {
        setLoading(true);
        putUrl(`payments/${encodeURIComponent(approveReceiptDialog.purchase_no)}/approve_payment`).then(response => {
            setLoading(false);
            if (response.status) {
                handleApproveReceiptClose();
                setRefreshData(!refreshData);
                addAlert("", response.message, 'success', '');
            } else {
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`orders.orderReceiptApprove`)}</b></Typography>
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`orders.orderReceiptApprove`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <CustomizeMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={false}
                exportFileName={'OrderReceiptApproveList'}
            />
            {/* Approve Payment Dialog */}
            <Dialog open={approveReceiptDialog.open} onClose={() => handleApproveReceiptClose()} fullWidth maxWidth="sm">
                <DialogTitle>{t('orders.approveReceipt')}</DialogTitle>
                <DialogContent>
                    <Typography>{t('orders.purchaseNo') + ": " + approveReceiptDialog.purchase_no}</Typography>
                    {t('orders.approveReceiptConfirmation')}
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={() => handleApproveReceiptClose()}>{t('button.cancel')}</Button>
                    <Button variant="contained" onClick={approvePayment}>{t('button.approve')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default OrderReceiptApprove