import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

import { Box, Breadcrumbs, Button, Chip, FormControl, Grid, InputAdornment, InputLabel, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';

export default function Create() {
    const styles = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const [state, setState] = useState({
        name: '',
        display_name: '',
        role_level: '',
        type: 'admins',
    });
    const [fieldLang, setFieldLang] = useState({ title: 'en' });
    const [inputErrors, setInputErrors] = useState({});
    const theme = useTheme();
    let navigate = useNavigate();

    const submitData = () => {
        // console.log("state", state);
        setLoading(true);
        setInputErrors();
        postUrl('/roles', state).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', t('success.createSuccess'), 'success', '');
                navigate(`/roles/${response.data.id}`, { replace: true });
            } else {
                setInputErrors(response.errors);
                addAlert('', t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <div>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t('title.roleAdd')}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to="/roles">
                            {t('title.roles')}
                        </Link>
                        <Typography color="text.primary">{t('title.roleAdd')}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <div style={{ paddingBottom: '5%' }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roles.roleName')}
                                variant="outlined"
                                value={state.name}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.name ? inputErrors.name : ''}
                                error={inputErrors && inputErrors.name ? true : false}
                                onChange={({ target }) => setState({ ...state, name: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roles.displayName')}
                                variant="outlined"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        {
                                            _.map(['en', 'cn'], (value) => (
                                                <Chip key={value} onClick={() => setFieldLang({ ...fieldLang, title: value })} label={t(`general.${value}`)} size="small" style={{ backgroundColor: fieldLang.title === value ? theme.palette.primary.main : '#efefef', color: fieldLang.title === value ? '#ffffff' : '#000000', marginLeft: 2 }} />
                                            ))
                                        }
                                    </InputAdornment>,
                                }}
                                onChange={({ target }) => setState({ ...state, display_name: { ...state.display_name, [fieldLang.title]: target.value } })}
                                value={state.display_name && state.display_name[fieldLang.title] ? state.display_name[fieldLang.title] : ""}
                                helperText={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn'])}
                                error={inputErrors && (inputErrors['display_name'] || inputErrors['display_name.en'] || inputErrors['display_name.cn']) ? true : false}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                id="standard-basic"
                                label={t('roles.roleLevel')}
                                variant="outlined"
                                value={state.role_level}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.role_level ? inputErrors.role_level : ''}
                                error={inputErrors && inputErrors.role_level ? true : false}
                                onChange={({ target }) => setState({ ...state, role_level: target.value })}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box component="form" noValidate
                            sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">{t('roles.roleType')}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.type}
                                    label={t('roles.roleType')}
                                    onChange={({ target }) => setState({ ...state, type: target.value })}
                                >
                                    {
                                        _.map(['admins', 'staffs', 'members'], rolesType => {
                                            return <MenuItem key={rolesType} value={rolesType}>{t(`roles.types.${rolesType}`)}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                    </Grid>
                </Grid>

                <Box style={{ paddingTop: "5%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Link underline='none' to={`/roles`} component={RouterLink}>
                        <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={styles.buttonStyle}>{t('button.cancel')}</Button>
                    </Link>
                    <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={styles.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                </Box>
            </div>
        </div>

    );
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}));


