import _ from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AddBoxOutlined, Delete } from '@mui/icons-material';
import { Box, Grid, IconButton, TextField, Typography, Collapse, Button } from '@mui/material';
import { useTheme } from '@mui/styles';
import { MdExpandLess, MdExpandMore } from "react-icons/md";

const MODULE = "packages";
const MODULE_SINGLE = "package";

const Description = (props) => {
    const { state, setState, inputErrors } = props;
    const { t } = useTranslation();
    const theme = useTheme();
    const [rowDatas, setRowDatas] = useState(['points', 'tnc', 'itinerary', 'note']);
    const [collapseOpen, setCollapseOpen] = useState(true);

    const handleDescriptionChange = (name, value) => {
        setState({ ...state, description: { ...state.description, [name]: value } });
    }

    const handleRowDataChange = (columnName, target, key) => {
        const { value } = target;
        setState({ ...state, description: { ...state.description, [columnName]: { ...state.description[columnName], [key]: value } } });
    }
    const handleNewRowData = (columnName) => {
        let newKey = _.size(state.description[columnName]);
        setState({ ...state, description: { ...state.description, [columnName]: { ...state.description[columnName], [newKey]: '' } } });
    }
    const handleDeleteRowData = (columnName, key) => {
        let newRowData = _.filter(state.description[columnName], function (o, index) { return index !== key });
        setState({ ...state, description: { ...state.description, [columnName]: newRowData } });
    }

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                    <Button style={{ display: "flex" }} onClick={() => setCollapseOpen(!collapseOpen)}>
                        <Typography variant="h6">
                            {t(`${MODULE}.description.description`)}
                        </Typography>
                        {collapseOpen ? <MdExpandLess size={30} /> : <MdExpandMore size={30} />}
                    </Button>
                </Box>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                    <TextField
                        label={t(`${MODULE}.description.title`)}
                        variant="outlined"
                        value={state.description.title}
                        InputLabelProps={{ shrink: true }}
                        helperText={inputErrors && inputErrors.description && inputErrors.description.title ? inputErrors.description.title : ''}
                        error={inputErrors && inputErrors.description && inputErrors.description.title ? true : false}
                        name={"description_title"}
                        onChange={(event) => handleDescriptionChange('title', event.target.value)}
                    />
                </Box>
            </Grid>
            <Grid item xs={12} sm={12}>
                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                    <TextField
                        label={t(`${MODULE}.description.subtitle`)}
                        variant="outlined"
                        value={state.description.subtitle}
                        InputLabelProps={{ shrink: true }}
                        helperText={inputErrors && inputErrors.description && inputErrors.description.subtitle ? inputErrors.description.subtitle : ''}
                        error={inputErrors && inputErrors.description && inputErrors.description.subtitle ? true : false}
                        name={"description_subtitle"}
                        onChange={(event) => handleDescriptionChange('subtitle', event.target.value)}
                    />
                </Box>
            </Grid> */}
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit>
                <Grid item xs={12} sm={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.description.body`)}
                            multiline
                            variant="outlined"
                            value={state.description.body}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.description && inputErrors.description.body ? inputErrors.description.body : ''}
                            error={inputErrors && inputErrors.description && inputErrors.description.body ? true : false}
                            name={"description_body"}
                            onChange={(event) => handleDescriptionChange('body', event.target.value)}
                        />
                    </Box>
                </Grid>
                {
                    _.map(rowDatas, columnName => {
                        return (
                            <Grid item xs={12} sm={12} key={columnName}>
                                <Grid container>
                                    <Grid item xs={12} sm={12}>
                                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                                <Typography variant="body2">
                                                    {t(`${MODULE}.description.${columnName}`)}
                                                </Typography>
                                                <Box display="flex" alignItems="center">
                                                    <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleNewRowData(columnName)}>
                                                        <AddBoxOutlined />
                                                    </IconButton>
                                                    <Typography>{t('packages.add' + columnName)}</Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    {
                                        _.map(state.description[columnName], (rowData, key) => {
                                            return (
                                                <Grid item xs={12} sm={12} key={key}>
                                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                        <Box display="flex" alignItems="center" justifyContent="space-between">
                                                            <TextField
                                                                fullWidth
                                                                multiline
                                                                size="small"
                                                                variant="outlined"
                                                                value={rowData}
                                                                InputLabelProps={{ shrink: true }}
                                                                helperText={inputErrors && inputErrors['description.' + rowData + '.' + key] ? inputErrors['description.' + rowData + '.' + key] : ''}
                                                                error={inputErrors && inputErrors['description.' + rowData + '.' + key] ? true : false}
                                                                onChange={(event) => handleRowDataChange(columnName, event.target, key)}
                                                            />
                                                            <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeleteRowData(columnName, key)}>
                                                                <Delete />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Collapse>
        </>
    )
}

export default Description