import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Box, ImageList, ImageListItem, ImageListItemBar, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { IoAdd, IoTrashOutline } from "react-icons/io5";

// state includes: newImages: [], images: [], deleteImages: []
const UploadImage = (props) => {
    const { state, setState, inputErrors, recommendWidth = '400px', recommendHeight = '400px' } = props;

    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const [uploadImages, setUploadImages] = useState([]);
    const [displayImages, setDisplayImages] = useState([]);

    useEffect(() => {
        if (uploadImages) {
            if (FileReader) {
                _.map(uploadImages, (img, index) => {
                    var fr = new FileReader();
                    fr.onload = function () {
                        setDisplayImages(prevState => (
                            [...prevState, fr.result]
                        ))
                    }
                    fr.readAsDataURL(img);
                    setState(prevState => ({
                        ...prevState,
                        newImages: [...prevState.newImages, img]
                    }))
                })
            }
        }
        // eslint-disable-next-line
    }, [uploadImages]);

    const onDrop = useCallback(acceptedFiles => {
        setUploadImages(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true });

    const removeImage = (index) => {
        let copyImages = [...state.newImages];
        let duplicateDisplayImages = [...displayImages];
        copyImages.splice(index, 1);
        duplicateDisplayImages.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            newImages: copyImages
        }))
        setDisplayImages(duplicateDisplayImages)
    };

    const removeExistingImage = (index) => {
        let copyImages = [...state.images];
        let existingImageId = copyImages[index].id;

        copyImages.splice(index, 1);
        setState(prevState => ({
            ...prevState,
            images: copyImages,
            deleteImages: [...prevState.deleteImages, existingImageId]
        }))
    };

    return (
        <Box>
            {_.size(inputErrors) > 0 && inputErrors.images && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{inputErrors.images}</Typography>}
            {_.size(inputErrors) > 0 && _.size(state.images) > 0 && (Object.keys(state.images).some(key => inputErrors[`images.${key}`])) && <Typography style={{ color: 'red' }} variant="subtitle1" gutterBottom component="div">{t('error.uploadImageError', {size: '5MB'})}</Typography>}
            <Typography variant="caption">{t("general.uploadImgMinQty")}</Typography>
            <label htmlFor="package-images-upload" className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                <input accept="image/*" id="package-images-upload" multiple type="file" style={{ display: "none" }} onChange={({ target }) => setUploadImages(target.files)} {...getInputProps()} />
                <IoAdd style={{ fontSize: "3rem" }} />
                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
            </label>
            <Typography style={{ fontSize: 14 }}>{t('general.recommendedSize', { width: recommendWidth, height: recommendHeight })}</Typography>
            {
                _.size(state.images) > 0 || _.size(displayImages) > 0 ?
                    <ImageList sx={{ width: "100%", height: 300, transform: "translateZ(0)", }} sm={{ overflow: "visible" }} rowHeight={200} gap={1} >
                        {_.map(state.images, (item, index) => {
                            return (
                                <ImageListItem key={index} cols={2} rows={1} style={{ position: "relative" }} >
                                    <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeExistingImage(index)} />
                                    <img src={item.file_name} srcSet={item.file_name} alt={`uploaded_${index}`} loading="lazy" />
                                    <ImageListItemBar sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top" actionPosition="left" />
                                </ImageListItem>
                            );
                        })}
                        {_.map(displayImages, (item, index) => {
                            return (
                                <ImageListItem key={index} cols={2} rows={1} style={{ position: "relative" }} >
                                    <IoTrashOutline className={classes.deleteIconStyle} size="1.4em" onClick={() => removeImage(index)} />
                                    <img src={item} srcSet={item} alt={`uploaded_${index}`} loading="lazy" />
                                    <ImageListItemBar sx={{ background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)" }} position="top" actionPosition="left" />
                                </ImageListItem>
                            );
                        })}
                    </ImageList>
                    : null
            }
        </Box>
    )
}

const useStyles = makeStyles(theme => ({
    uploadMedia: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: "#fff",
        boxShadow: "3px 3px 20px 0 #d8d8d8",
        borderRadius: 15,
        border: "2px dashed #aeaeae",
        width: "100%",
        height: 100,
        marginTop: 15,
        padding: 20,
    },
    deleteIconStyle: {
        position: "absolute",
        right: 0,
        color: "white",
        top: 0,
        zIndex: 1,
        background: "black",
        cursor: 'pointer'
    }
}))

export default UploadImage