import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AddBoxOutlined, Delete, Edit, Save } from '@mui/icons-material';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, IconButton, Pagination, PaginationItem, TextField, Tooltip, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { deleteUrl, getUrl, postUrl, putUrl } from '../../../helpers/ApiAction';
import useNotificationLoading from '../../../helpers/useNotificationLoading';

import { AiOutlineCloseCircle } from "react-icons/ai";

const MODULE = "packages";
const MODULE_SINGLE = "package";

const AddOn = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [addOnPackages, setAddOnPackages] = useState({});
    const [addOnAvailablePackages, setAddOnAvailablePackages] = useState({});
    const [rank, setRank] = useState({});
    const [rankPrice, setRankPrice] = useState({});
    const [search, setSearch] = useState('');
    const [state, setState] = useState({
        add_on_id: '',
        package: [],
        package_id: '',
        option_prices: [],
        display_package_name: "",
    });
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [addOnDialog, setAddOnDialog] = useState({
        open: false,
    });
    const [deleteAddOnDialog, setDeleteAddOnDialog] = useState({
        open: false,
        add_on_id: '',
    });

    let { id } = useParams();
    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();

    useEffect(() => {
        setLoading(true);
        getUrl(`/add-ons`, { connect_id: id }).then(response => {
            setLoading(false);
            if (response.status) {
                setAddOnPackages(response.data);
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        getUrl(`/ranks`).then(response => {
            setLoading(false);
            if (response.status) {
                let item = { weekday: '', weekend: '', holiday: '' };
                let value = { "0": item };
                let rankValue = {
                    "0":
                    {
                        id: 0,
                        code: "0001",
                        name: "Normal Price",
                        display_name: { en: 'Normal Price', cn: '正常价格' },
                        status: 1,
                        status_display: { en: 'Active', cn: '活跃' },
                        created_at: "2023-03-23 11:53:12",
                        created_by: null,
                        updated_at: "2023-03-23 11:53:12",
                        updated_by: null,
                    }
                };
                _.map(response.data.listing, (rank) => {
                    if (rank.status === 1) {
                        value[rank.id] = item;
                        rankValue[rank.id] = rank;
                    }
                })
                setRank(rankValue);
                setRankPrice(value);
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [id]);

    const handleDialogOpen = (add_on_id) => {
        if (add_on_id) {
            let addOn = _.find(addOnPackages, function (o) { return o.id === add_on_id; });
            let tempOP = {};
            _.map(addOn.package.package_options, option => {
                let option_price = addOn.option_prices[option.id] ? addOn.option_prices[option.id] : { applyToAll: 0, ranks: rankPrice };
                _.map(rank, r => {
                    if (!option_price.ranks[r.id]) {
                        option_price.ranks[r.id] = { weekday: '', weekend: '', holiday: '' };
                    }
                })
                tempOP[option.id] = option_price;
            })
            setAddOnDialog({
                open: true,
            });
            setState({
                add_on_id: add_on_id,
                package: addOn.package,
                package_id: addOn.package.id,
                option_prices: tempOP,
                display_package_name: addOn.display_package_name,
            });
        } else {
            setAddOnDialog({
                open: true,
            });
            setState({
                add_on_id: '',
                package: [],
                package_id: '',
                option_prices: [],
            });
        }
    }
    const handleDialogClose = () => {
        setInputErrors({});
        setPage(1);
        setTotal(0);
        setSearch('');
        setAddOnAvailablePackages({});
        setAddOnDialog({
            open: false,
        });
        setState({
            add_on_id: '',
            package: [],
            package_id: '',
            option_prices: [],
        });
    }
    const handlePackageSelect = (_package) => {
        let tempOP = {};
        _.map(_package.package_options, option => {
            tempOP[option.id] = { applyToAll: 0, ranks: rankPrice };
        })
        setState({
            add_on_id: '',
            package: _package,
            package_id: _package.id,
            option_prices: tempOP,
            display_package_name: _package.name,
        });
    }

    const handleDeleteDialogOpen = (add_on_id) => {
        setDeleteAddOnDialog({
            open: true,
            add_on_id: add_on_id,
        });
    }
    const handleDeleteDialogClose = () => {
        setDeleteAddOnDialog({
            open: false,
            add_on_id: '',
        });
    }

    const handlePriceChange = (target, rank_id, option_id) => {
        const { name, value } = target;
        if (state.option_prices[option_id].applyToAll === 1) {
            if (name !== 'weekday') {
                setState({ ...state, option_prices: { ...state.option_prices, [option_id]: { applyToAll: 0, ranks: { ...state.option_prices[option_id].ranks, [rank_id]: { ...state.option_prices[option_id].ranks[rank_id], [name]: value } } } } });
            } else {
                setState({ ...state, option_prices: { ...state.option_prices, [option_id]: { ...state.option_prices[option_id], ranks: { ...state.option_prices[option_id].ranks, [rank_id]: { weekday: value, weekend: value, holiday: value } } } } });
            }
        } else {
            setState({ ...state, option_prices: { ...state.option_prices, [option_id]: { ...state.option_prices[option_id], ranks: { ...state.option_prices[option_id].ranks, [rank_id]: { ...state.option_prices[option_id].ranks[rank_id], [name]: value } } } } });
        }
    }
    const handleCheckChange = (event, option_id) => {
        let temp_item = state.option_prices[option_id];
        temp_item.applyToAll = event.target.checked ? 1 : 0;
        if (event.target.checked) {
            _.map(temp_item.ranks, (price_ranks, key) => {
                temp_item.ranks[key].weekend = temp_item.ranks[key].holiday = price_ranks.weekday;
            })
        }
        setState({ ...state, option_prices: { ...state.option_prices, [option_id]: temp_item } });
    };

    // get available add on data
    const searchData = () => {
        searchAddOnPackage(1);
    }
    const handlePaginationChange = (event, value) => {
        searchAddOnPackage(value);
    }
    const searchAddOnPackage = (newPage) => {
        setLoading(true);
        setPage(newPage);
        getUrl(`/add-ons/packages`, { connect_id: id, q: search, page: newPage }).then(response => {
            setLoading(false);
            if (response.status) {
                setAddOnAvailablePackages(response.data.data);
                setTotal(response.data.last_page);
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // create, edit, delete add on
    const createEditAddOn = () => {
        setLoading(true);
        setInputErrors();
        let postData = {
            connect_id: id,
            package_id: state.package_id,
            option_prices: state.option_prices,
            display_package_name: state.display_package_name,
        };
        if (state.add_on_id > 0) {
            // update old addon
            putUrl(`/add-ons/${state.add_on_id}`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    let oldKey = _.findKey(addOnPackages, function (o) { return o.id === state.add_on_id; });
                    setAddOnPackages({ ...addOnPackages, [oldKey]: response.data });
                    handleDialogClose();
                    addAlert('', response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } else {
            // create new addon
            postUrl(`/add-ons`, postData).then(response => {
                setLoading(false);
                if (response.status) {
                    let newKey = _.size(addOnPackages);
                    setAddOnPackages({ ...addOnPackages, [newKey]: response.data });
                    handleDialogClose();
                    addAlert('', response.message, 'success', '');
                } else {
                    setInputErrors(response.errors);
                    addAlert('', response.message || t('error.contactSupport'), 'error', '');
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
    }
    const deleteAddOn = () => {
        setLoading(true);
        deleteUrl(`/add-ons/${deleteAddOnDialog.add_on_id}`).then(response => {
            setLoading(false);
            handleDeleteDialogClose();
            if (response.status) {
                let newAddOn = _.filter(addOnPackages, function (o) { return o.id !== deleteAddOnDialog.add_on_id });
                setAddOnPackages(newAddOn);
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    // sequence
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    function onDragEnd(result) {
        if (!result.destination) {
            return;
        }
        if (result.destination.index === result.source.index) {
            return;
        }
        const list = reorder(
            Object.values(addOnPackages),
            result.source.index,
            result.destination.index
        );
        setAddOnPackages(list);
    }
    const grid = 8;
    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        margin: `0 ${grid}px 0 0`,
        boxShadow: '0 0 6px 0 #0002',
        backgroundColor: theme.palette.primary.main,
        padding: "5px 10px",
        border: "1px white solid",
        borderRadius: "10px",
        color: "white",
        // styles we need to apply on draggables
        ...draggableStyle,
    });
    const getListStyle = isDraggingOver => ({
        display: 'flex',
        padding: grid,
        overflow: 'auto',
    });
    const updateSequence = () => {
        setLoading(true);
        let postData = {
            add_on_ids: _.values(_.mapValues(addOnPackages, 'id'))
        }
        putUrl(`/add-ons/sequence`, postData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert('', response.message, 'success', '');
            } else {
                addAlert('', response.message || t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    return (
        <>
            {
                _.size(addOnPackages) > 0
                    ?
                    <Box>
                        {/* SEQUENCE */}
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Typography variant="body2" style={{ marginRight: 10 }}>{t('packages.addOnSequence')}</Typography>
                            <IconButton style={{ color: theme.palette.primary.main }} onClick={() => updateSequence()}><Save /></IconButton>
                        </Box>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId='add_on_packages' direction="horizontal">
                                {
                                    (provided, snapshot) => (
                                        <Box
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {
                                                _.map(addOnPackages, (addon, key) => {
                                                    return (
                                                        <Draggable key={addon.id} draggableId={`${addon.id}`} index={parseInt(key)}>
                                                            {
                                                                (provided, snapshot) => (
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                                    >
                                                                        {addon.display_package_name?addon.display_package_name:addon.package.name}
                                                                    </Box>
                                                                )
                                                            }
                                                        </Draggable>
                                                    )
                                                })
                                            }
                                            {provided.placeholder}
                                        </Box>
                                    )
                                }
                            </Droppable>
                        </DragDropContext>
                        {/* ADDON PACKAGE LIST */}
                        {
                            _.map(addOnPackages, addon => {
                                return (
                                    <Box style={{ paddingTop: 10 }} key={addon.id}>
                                        <Box style={{ display: "flex", alignItems: "center" }}>
                                            <Tooltip title={t(`${MODULE}.editAddOn`)}>
                                                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDialogOpen(addon.id)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title={t(`${MODULE}.deleteAddOn`)}>
                                                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDeleteDialogOpen(addon.id)}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            <Typography variant="body1" style={{ fontWeight: "bold", paddingLeft: "10px" }}>{addon.package.name + (addon.display_package_name ? (" ("+addon.display_package_name+")") : '') }</Typography>
                                        </Box>
                                        {
                                            _.map(addon.package.package_options, option => {
                                                let prices = addon.option_prices[option.id] ? addon.option_prices[option.id] : null;
                                                return (
                                                    <Box style={{ marginLeft: '30px' }} key={option.id}>
                                                        <Typography variant="body1">{option.name}</Typography>
                                                        {
                                                            prices ?
                                                                <Box style={{ marginLeft: '30px' }}>
                                                                    {
                                                                        _.map(Object.values(rank).sort((a, b) => a.code - b.code), (rankDetail, value) => {
                                                                            let rank_detail = prices.ranks[rankDetail.id];
                                                                            if (rank_detail) {
                                                                                return (
                                                                                    <Typography variant="body2" key={value}>
                                                                                        {
                                                                                            rankDetail.display_name[i18n.language] + ": [" +
                                                                                            t(`${MODULE}.price.ranks.weekday`) + ":" + parseFloat(rank_detail.weekday).toFixed(2) + ", " +
                                                                                            t(`${MODULE}.price.ranks.weekend`) + ":" + parseFloat(rank_detail.weekend).toFixed(2) + ", " +
                                                                                            t(`${MODULE}.price.ranks.holiday`) + ":" + parseFloat(rank_detail.holiday).toFixed(2) + "]"
                                                                                        }
                                                                                    </Typography>
                                                                                )
                                                                            } else {
                                                                                return (
                                                                                    <Typography variant="body2" key={value}>{rankDetail.display_name[i18n.language] + ": -"}</Typography>
                                                                                )
                                                                            }
                                                                        })
                                                                    }
                                                                </Box>
                                                                :
                                                                <Typography style={{ marginLeft: '30px' }}>-</Typography>
                                                        }
                                                    </Box>
                                                )
                                            })
                                        }
                                        <Divider style={{ paddingBottom: "10px" }} />
                                    </Box>
                                )
                            })
                        }
                    </Box>
                    : null
            }
            {/* ADD NEW ADDON */}
            <Box display="flex" alignItems="center" justifyContent="flex-end">
                <IconButton style={{ color: theme.palette.button.main }} onClick={() => handleDialogOpen(0)}>
                    <AddBoxOutlined />
                </IconButton>
                <Typography>{t(t(`${MODULE}.addAddOn`))}</Typography>
            </Box>
            {/* ADD/EDIT ADDON DIALOG */}
            <Dialog open={addOnDialog.open} onClose={handleDialogClose} fullWidth maxWidth="lg">
                <DialogTitle>{state.add_on_id ? t(`${MODULE}.editAddOn`) : t(`${MODULE}.addAddOn`)}</DialogTitle>
                <DialogContent>
                    {
                        !state.add_on_id && !state.package_id ?
                            <Grid container spacing={2} style={{ paddingBottom: '5%' }}>
                                <Grid item xs={8} sm={10}>
                                    <TextField
                                        variant="outlined"
                                        value={search}
                                        name="search"
                                        fullWidth
                                        onChange={(event) => setSearch(event.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={4} sm={2}>
                                    <Button className={classes.confirmButton} onClick={searchData}>{t('button.search')}</Button>
                                </Grid>
                                {
                                    _.map(addOnAvailablePackages, _package => {
                                        return (
                                            <Grid item xs={12} sm={6} key={_package.id}>
                                                <Box className={classes.packageBox} onClick={() => handlePackageSelect(_package)}>
                                                    {_package.name}
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                                {
                                    total > 0
                                        ?
                                        <Grid item xs={12} style={{ textAlign: "center" }}>
                                            <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                                                <Pagination count={total} page={page} onChange={handlePaginationChange} color="secondary" variant="text"
                                                    renderItem={(item) => (
                                                        <PaginationItem
                                                            sx={{ color: theme.palette.secondary.main }}
                                                            {...item}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                        </Grid>
                                        :
                                        null
                                }
                            </Grid>
                            : null
                    }
                    {
                        state.package_id
                            ?
                            <Box>
                                <Box>
                                    <Box style={{ display: "flex", alignItems: "center", paddingBottom: 5 }}>
                                        <Typography>{t('packages.name') + ": " + state.package.name}</Typography>
                                        {!state.add_on_id ? <IconButton onClick={() => setState({ ...state, package: [], package_id: '' })}><AiOutlineCloseCircle /></IconButton> : null}
                                    </Box>
                                    <TextField
                                        label={t('packages.addOnDisplayName')}
                                        variant="outlined"
                                        value={state.display_package_name}
                                        name="display_package_name"
                                        fullWidth
                                        onChange={(event) => setState({ ...state, display_package_name: event.target.value })}
                                    />
                                </Box>
                                <Box style={{ paddingBottom: '5%', padding: '2%' }}>
                                    {
                                        _.map(state.option_prices, (option_rank, option_id) => {
                                            let option_detail = _.find(state.package.package_options, { id: parseInt(option_id) })
                                            return (
                                                <Box key={option_id}>
                                                    <Typography variant="body1" style={{ margin: 5, fontWeight: "bold" }}>{option_detail ? option_detail.name : ''}</Typography>
                                                    {
                                                        _.map(option_rank.ranks, (rankPrice, rank_id) => {
                                                            let rank_detail = _.find(rank, { id: parseInt(rank_id) })
                                                            return (
                                                                <Grid container spacing={2} key={rank_id}>
                                                                    <Grid item xs={12} sm={1.5} className={classes.rankPrice}>
                                                                        <Typography>{rank_detail ? rank_detail.display_name[i18n.language] : ''}</Typography>
                                                                    </Grid>
                                                                    {
                                                                        _.map(rankPrice, (amountDetail, index) => {
                                                                            return (
                                                                                <Grid item xs={6} sm={3.5} key={index}>
                                                                                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                                                                        <TextField
                                                                                            size="small"
                                                                                            margin="dense"
                                                                                            label={`${t(`${MODULE}.price.ranks.${index}`)}*`}
                                                                                            variant="outlined"
                                                                                            value={amountDetail}
                                                                                            InputLabelProps={{ shrink: true }}
                                                                                            helperText={inputErrors && inputErrors['option_prices.' + option_id + '.ranks.' + rank_id + "." + index] ? inputErrors['option_prices.' + option_id + '.ranks.' + rank_id + "." + index] : ''}
                                                                                            error={inputErrors && inputErrors['option_prices.' + option_id + '.ranks.' + rank_id + "." + index] ? true : false}
                                                                                            name={index}
                                                                                            onChange={(event) => handlePriceChange(event.target, rank_id, option_id)}
                                                                                        />
                                                                                    </Box>
                                                                                </Grid>
                                                                            )
                                                                        })
                                                                    }
                                                                </Grid>
                                                            )
                                                        })
                                                    }
                                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                                        <FormGroup>
                                                            <FormControlLabel label={t('packages.applyToAll')} control={
                                                                <Checkbox checked={option_rank.applyToAll === 1 ? true : false} onChange={(event) => handleCheckChange(event, option_id)} inputProps={{ 'aria-label': 'controlled' }} />
                                                            } />
                                                        </FormGroup>
                                                    </Box>
                                                </Box>
                                            )
                                        })
                                    }
                                </Box>
                            </Box>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleDialogClose}>{t('button.cancel')}</Button>
                    <Button className={classes.confirmButton} onClick={createEditAddOn}>{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
            {/* DELETE ADDON DIALOG */}
            <Dialog open={deleteAddOnDialog.open} onClose={handleDeleteDialogClose} fullWidth maxWidth="sm">
                <DialogTitle>{t(`${MODULE}.deleteAddOn`)}</DialogTitle>
                <DialogContent>
                    {t(`${MODULE}.deleteAddOnNote`)}
                </DialogContent>
                <DialogActions>
                    <Button className={classes.cancelButton} onClick={handleDeleteDialogClose}>{t('button.no')}</Button>
                    <Button className={classes.confirmButton} onClick={deleteAddOn}>{t('button.yes')}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    confirmButton: {
        backgroundColor: theme.palette.secondary.main + '! important',
        color: theme.palette.secondary.secondary + '! important',
    },
    cancelButton: {
        backgroundColor: theme.palette.secondary.secondary + '! important',
        color: theme.palette.secondary.main + '! important',
        border: '1px solid ' + theme.palette.secondary.main + '! important',
    },
    rankPrice: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.up('xs')]: {
            justifyContent: "flex-start",
        },
        [theme.breakpoints.up('sm')]: {
            justifyContent: "flex-end",
        },
    },
    packageBox: {
        border: "1px solid gray",
        borderRadius: "10px",
        padding: "10px",
        "&:hover": {
            boxShadow: '0px 0px 8px 4px #0000001f',
            cursor: "pointer"
        }
    },
    selectedPackageBox: {
        border: "1px solid gray",
        borderRadius: "10px",
        padding: "10px",
    }
}))

export default AddOn