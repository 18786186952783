import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import Moment from 'moment';

import { Box, Breadcrumbs, Button, Grid, Link, TextField, Typography, FormControl, InputLabel, Select, MenuItem, FormLabel, RadioGroup, FormControlLabel, Radio, Autocomplete, Checkbox, FormHelperText } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { postUrl, getUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';
import CodeTable from './CodeTable';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const MODULE = "adjustments";
const MODULE_SINGLE = "adjustment";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalScale={2}
            allowedDecimalSeparators={['%']}
            valueIsNumericString={true}
        />
    );
});

NumberFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const Detail = () => {
    const [inputErrors, setInputErrors] = useState({});
    const [state, setState] = useState({
        title: '',
        action: 'deduct',
        type: '',
        value: '',
        min_spend_value: '',
        capped_value: '',
        start_date: '',
        end_date: '',
        agency_type: 'all',
        agency: [],
        code_apply: 'auto',
        code_type: '',
        code_quantity: '',
        status: 0,
        discount_codes: [],
        apply_site: [],
    });
    const [agency, setAgency] = useState([]);
    const [statusList, setStatusList] = useState({});

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const { addAlert, setLoading } = useNotificationLoading();
    const navigate = useNavigate();
    const isMountedRef = useRef(null);
    let { id } = useParams();
    const location = useLocation();
    const formType = location.pathname === `/price-${MODULE_SINGLE}-add` ? "add" : "edit";

    useEffect(() => {
        isMountedRef.current = true;
        if (formType === 'edit' && id) {
            setLoading(true);
            getUrl(`/price-${MODULE}/${id}`).then(response => {
                setLoading(false);
                if (isMountedRef.current) {
                    if (response.status) {
                        let agencies = _.filter(agency, function (data) { return _.includes(response.data.agencies_ids, (data.id).toString()) });
                        setState({
                            title: response.data.title,
                            action: response.data.action,
                            type: response.data.type,
                            value: response.data.value,
                            min_spend_value: response.data.min_spend_value,
                            capped_value: response.data.capped_value,
                            start_date: response.data.start_date,
                            end_date: response.data.end_date,
                            agency_type: _.size(response.data.agencies_ids) > 0 ? 'selected' : 'all',
                            agency: agencies,
                            code_apply: _.size(response.data.discount_codes) > 0 ? 'manual' : 'auto',
                            code_type: _.size(response.data.discount_codes) > 1 ? 'difference' : 'same',
                            code_quantity: _.size(response.data.discount_codes) > 1 ? _.size(response.data.discount_codes) : (_.size(response.data.discount_codes) > 0 ? response.data.discount_codes[0].total_quantity : ''),
                            status: response.data.status,
                            discount_codes: response.data.discount_codes,
                            apply_site: response.data.apply_site,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false };
        // eslint-disable-next-line
    }, [addAlert, id, agency])

    useEffect(() => {
        setLoading(true);
        getUrl(`/price-${MODULE_SINGLE}/option`).then(response => {
            setLoading(false);
            if (response.status) {
                setAgency(response.data.agencies);
                setStatusList(response.data.status_list);
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });

        // eslint-disable-next-line
    }, [])

    const handleChange = ({ target }) => {
        const { name, value } = target;
        setState({ ...state, [name]: value });
    }

    const handleDateChange = (value, name) => {
        let date = '';
        if(value){
            date = Moment([value.$y, value.$M, value.$D]).format('YYYY-MM-DD');
        }
        setState({ ...state, [name]: date });
    }

    const updateApplySite = (target, value) => {
        let tempApplySite = state.apply_site;
        if (target.checked && !_.includes(tempApplySite, value)) {
            tempApplySite.push(value);
        } else if (!target.checked && _.includes(tempApplySite, value)) {
            tempApplySite = _.filter(tempApplySite, function (data) { return data !== value; });
        }
        setState({ ...state, apply_site: tempApplySite });
    }

    const submitData = () => {
        setLoading(true);
        setInputErrors();
        try {
            let apiData = {
                title: state.title,
                action: state.action,
                type: state.type,
                value: state.value,
                min_spend_value: state.min_spend_value,
                capped_value: state.capped_value,
                start_date: state.start_date,
                end_date: state.end_date,
                code_apply: state.code_apply,
                code_type: state.code_type,
                code_quantity: state.code_quantity,
                agency_type: state.agency_type,
                agency: state.agency_type === 'selected' ? _.map(state.agency, 'id') : [],
                apply_site: state.apply_site,
                _method: formType === "edit" ? "put" : "post",
            };
            if (formType === 'edit') {
                apiData = { ...apiData, status: state.status };
            }
            const formData = new FormData();
            buildFormData(formData, apiData);

            postUrl(`/price-${MODULE}${formType === "edit" ? `/${id}` : ""}`, formData).then(response => {
                setLoading(false);
                const { status, data, errors } = response;
                if (status) {
                    if (formType === 'edit') {
                        addAlert('', t('success.editSuccess'), 'success', '');
                    } else {
                        addAlert('', t('success.createSuccess'), 'success', '');
                        navigate(`/price-${MODULE_SINGLE}/${data.id}`, { replace: true });
                    }
                } else {
                    setInputErrors(errors);
                    if (formType === 'edit') {
                        addAlert('', t('error.editError'), 'error', '')
                    } else {
                        addAlert('', t('error.createError'), 'error', '')
                    }
                }
            }).catch(error => {
                setLoading(false);
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        } catch (e) {
            setLoading(false);
            addAlert('', t('general.notJson', { column: t(`${MODULE}.value`) }), 'error', '');
        }
    }

    return (
        <>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/price-${MODULE}`}>
                            {t(`title.${MODULE}`)}
                        </Link>
                        <Typography color="text.primary">{formType === "edit" ? t(`title.${MODULE_SINGLE}Edit`) : t(`title.${MODULE_SINGLE}Add`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            <Grid container spacing={3} style={{ paddingBottom: '5%' }}>
                <Grid item xs={12} sm={formType === 'edit' ? 8 : 12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.title`)}
                            variant="outlined"
                            value={state.title}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.title ? inputErrors.title : ''}
                            error={inputErrors && inputErrors.title ? true : false}
                            name="title"
                            onChange={handleChange}
                        />
                    </Box>
                </Grid>
                {
                    formType === 'edit' &&
                    <Grid item xs={12} sm={4}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                select
                                label={t(`${MODULE}.status`)}
                                onChange={handleChange}
                                name="status"
                                variant="outlined"
                                value={state.status}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.status ? inputErrors.status : ''}
                                error={inputErrors && inputErrors.status ? true : false}
                            >
                                <MenuItem key='' value=''>{t(`${MODULE}.pleaseSelect`)}</MenuItem>
                                {
                                    _.map(statusList, list => {
                                        return <MenuItem key={list.code} value={list.code}>{list[i18n.language] ? list[i18n.language] : list.en}</MenuItem>
                                    })
                                }
                            </TextField>
                        </Box>
                    </Grid>
                }
                {/* <Grid item xs={12} sm={4}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            select
                            label={t(`${MODULE}.action.title`)}
                            onChange={handleChange}
                            name="action"
                            variant="outlined"
                            value={state.action}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.action ? inputErrors.action : ''}
                            error={inputErrors && inputErrors.action ? true : false}
                        >
                            <MenuItem key='' value=''>{t(`${MODULE}.pleaseSelect`)}</MenuItem>
                            {
                                _.map(['add', 'deduct'], action => {
                                    return <MenuItem key={action} value={action}>{t(`${MODULE}.action.${action}`)}</MenuItem>
                                })
                            }
                        </TextField>
                    </Box>
                </Grid> */}
                <Grid item xs={12} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            select
                            label={t(`${MODULE}.type.title`)}
                            onChange={handleChange}
                            name="type"
                            variant="outlined"
                            value={state.type}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.type ? inputErrors.type : ''}
                            error={inputErrors && inputErrors.type ? true : false}
                        >
                            <MenuItem key='' value=''>{t(`${MODULE}.pleaseSelect`)}</MenuItem>
                            {
                                _.map(['percent', 'amount'], type => {
                                    return <MenuItem key={type} value={type}>{t(`${MODULE}.type.${type}`)}</MenuItem>
                                })
                            }
                        </TextField>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <TextField
                            label={t(`${MODULE}.value`) + (state.type === 'percent' ? " (%)" : ' (MYR)')}
                            variant="outlined"
                            type="number"
                            value={state.value}
                            InputLabelProps={{ shrink: true }}
                            helperText={inputErrors && inputErrors.value ? inputErrors.value : ''}
                            error={inputErrors && inputErrors.value ? true : false}
                            name="value"
                            onChange={handleChange}
                            InputProps={{ inputComponent: NumberFormatCustom }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <TextField
                                label={t(`${MODULE}.min_spend_value`) + ' (MYR)'}
                                variant="outlined"
                                type="number"
                                value={state.min_spend_value}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.min_spend_value ? inputErrors.min_spend_value : ''}
                                error={inputErrors && inputErrors.min_spend_value ? true : false}
                                name="min_spend_value"
                                onChange={handleChange}
                                InputProps={{ inputComponent: NumberFormatCustom }}
                            />
                            <FormHelperText style={{ color: 'gray' }}>{t(`${MODULE}.min_spend_value_note`)}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <TextField
                                label={t(`${MODULE}.capped_value`) + ' (MYR)'}
                                variant="outlined"
                                type="number"
                                value={state.capped_value}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.capped_value ? inputErrors.capped_value : ''}
                                error={inputErrors && inputErrors.capped_value ? true : false}
                                name="capped_value"
                                onChange={handleChange}
                                InputProps={{ inputComponent: NumberFormatCustom }}
                            />
                            <FormHelperText style={{ color: 'gray' }}>{t(`${MODULE}.capped_value_note`)}</FormHelperText>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t(`${MODULE}.startDate`)}
                                value={state.start_date}
                                onChange={(newValue) => handleDateChange(newValue, 'start_date')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.start_date ? inputErrors.start_date : ''}
                                        error={inputErrors && inputErrors.start_date ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                label={t(`${MODULE}.endDate`)}
                                value={state.end_date}
                                onChange={(newValue) => handleDateChange(newValue, 'end_date')}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        helperText={inputErrors && inputErrors.end_date ? inputErrors.end_date : ''}
                                        error={inputErrors && inputErrors.end_date ? true : false}
                                    />
                                }
                            />
                        </LocalizationProvider>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <FormLabel>{t(`${MODULE}.apply_site.title`)}</FormLabel>
                            <FormControlLabel
                                control={<Checkbox checked={_.includes(state.apply_site, 'agency')} color="primary" onChange={(event) => updateApplySite(event.target, 'agency')} />}
                                label={t(`${MODULE}.apply_site.agency`)}
                            />
                            <FormControlLabel
                                control={<Checkbox checked={_.includes(state.apply_site, 'member')} color="primary" onChange={(event) => updateApplySite(event.target, 'member')} />}
                                label={t(`${MODULE}.apply_site.member`)}
                            />
                            {inputErrors && inputErrors?.apply_site && <FormHelperText style={{ color: 'red' }}>{inputErrors.apply_site}</FormHelperText>}
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <FormLabel>{t(`${MODULE}.agencyType.title`)}</FormLabel>
                            <RadioGroup row value={state.agency_type} onChange={handleChange} name="agency_type">
                                <FormControlLabel value="all" control={<Radio />} label={t(`${MODULE}.agencyType.allAgency`)} />
                                <FormControlLabel value="selected" control={<Radio />} label={t(`${MODULE}.agencyType.selectedAgency`)} />
                            </RadioGroup>
                            <FormHelperText>{t(`${MODULE}.agencyType.helperText`)}</FormHelperText>
                            {inputErrors && inputErrors?.agency_type && <FormHelperText style={{ color: 'red' }}>{inputErrors.agency_type}</FormHelperText>}
                        </FormControl>
                    </Box>
                </Grid>
                {
                    state.agency_type === 'selected' &&
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <Autocomplete
                                multiple
                                options={agency}
                                fullWidth
                                freeSolo
                                value={state.agency}
                                disableCloseOnSelect
                                limitTags={3}
                                onChange={(event, newValue) => { setState({ ...state, agency: newValue }); }}
                                name="agency"
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option.name}
                                    </li>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t(`${MODULE}.agency`)}
                                        placeholder={t(`${MODULE}.pleaseSelect`) + '...'}
                                        helperText={inputErrors && inputErrors.agency ? inputErrors.agency : ''}
                                        error={inputErrors && inputErrors.agency ? true : false}
                                    />
                                )}
                            />
                        </Box>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                        <FormControl>
                            <FormLabel>{t(`${MODULE}.code_apply.title`)}</FormLabel>
                            <RadioGroup row value={state.code_apply} onChange={handleChange} name="code_apply">
                                <FormControlLabel value="auto" control={<Radio />} label={t(`${MODULE}.code_apply.auto`)} disabled={formType === 'edit' ? true : false} />
                                <FormControlLabel value="manual" control={<Radio />} label={t(`${MODULE}.code_apply.manual`)} disabled={formType === 'edit' ? true : false} />
                            </RadioGroup>
                            {inputErrors && inputErrors?.code_apply && <FormHelperText style={{ color: 'red' }}>{inputErrors.code_apply}</FormHelperText>}
                        </FormControl>
                    </Box>
                </Grid>
                {
                    state.code_apply === 'manual'
                        ?
                        <>
                            <Grid item xs={12} sm={3}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={t(`${MODULE}.code_quantity`)}
                                        variant="outlined"
                                        type="number"
                                        value={state.code_quantity}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.code_quantity ? inputErrors.code_quantity : ''}
                                        error={inputErrors && inputErrors.code_quantity ? true : false}
                                        name="code_quantity"
                                        onChange={handleChange}
                                        InputProps={{ inputComponent: NumberFormatCustom, disabled: formType === 'edit' ? true : false }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <FormControl>
                                        <FormLabel>{t(`${MODULE}.code_type.title`)}</FormLabel>
                                        <RadioGroup row value={state.code_type} onChange={handleChange} name="code_type">
                                            <FormControlLabel value="same" control={<Radio />} label={t(`${MODULE}.code_type.same`)} disabled={formType === 'edit' ? true : false} />
                                            <FormControlLabel value="difference" control={<Radio />} label={t(`${MODULE}.code_type.difference`)} disabled={formType === 'edit' ? true : false} />
                                        </RadioGroup>
                                        {inputErrors && inputErrors?.code_type && <FormHelperText style={{ color: 'red' }}>{inputErrors.code_type}</FormHelperText>}
                                    </FormControl>
                                </Box>
                            </Grid>
                            {
                                _.size(state.discount_codes) > 0
                                    ?
                                    <Grid item xs={12} sm={5}>
                                        <CodeTable codeData={state.discount_codes} name={state.title} />
                                    </Grid>
                                    :
                                    null
                            }
                        </>
                        :
                        null
                }
            </Grid>
            <Box display="flex" justifyContent="center" alignItems="center" paddingTop="5%">
                <Link underline='none' to={`/${MODULE}`} component={RouterLink}>
                    <Button size="large" variant="outlined" style={{ color: theme.palette.button.main, border: theme.palette.button.borderMain, marginRight: 15 }} className={classes.buttonStyle}>{t('button.cancel')}</Button>
                </Link>
                <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
            </Box>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    buttonStyle: {
        minWidth: 150
    },
    flexMiddle: {
        display: 'flex',
        alignItems: 'center'
    }
}))

export default Detail