import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Box, Button, Card, CardContent, CardMedia, FormControl, Grid, InputLabel, MenuItem, Pagination, PaginationItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';

import { getUrl } from '../../helpers/ApiAction';

const PlaceOrderStep1 = (props) => {
    const { addAlert, setLoading, state, setState, currentCurrency, handleNext, inputErrors } = props;

    const theme = useTheme();
    const classes = useStyles();
    const { t } = useTranslation();

    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchField, setSearchField] = useState({
        name: searchParams.get('name') ? searchParams.get('name') : '',
        location_id: ""
    });
    const [locationList, setLocationList] = useState([]);
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        callApi(1);
        // eslint-disable-next-line
    }, []);

    const callApi = (newPage) => {
        setLoading(true);
        let apiData = {
            ...searchField,
            page: newPage,
            per_page: 8,
            site: "admin",
        }
        setPage(newPage);
        getUrl("/agent-packages", apiData).then(response => {
            setLoading(false);
            if (response.status) {
                setPackages(response.data.listing.data);
                setLocationList(response.data.locations);
                setTotal(response.data.listing.last_page);
            }
        }).catch(err => {
            setLoading(false);
            addAlert("", JSON.stringify(err), 'error', '');
        })
    }

    const searchData = () => {
        callApi(1);
    }

    const handlePaginationChange = (event, value) => {
        callApi(value);
    }

    const handlePackageSelect = (packageItem) => {
        setState({ ...state, package_id: packageItem.id });
        handleNext();
    }

    return (
        <Box>
            {/* SEARCH */}
            <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                    <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                        <TextField
                            variant="outlined"
                            margin="dense"
                            fullWidth
                            label={t('packages.name')}
                            value={searchField.name}
                            name="name"
                            onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                        />
                    </Box>
                </Grid>
                {
                    _.size(locationList) > 0
                        ?
                        <Grid item xs={6} md={4}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { marginTop: 1, width: '100%' }, }} >
                                <FormControl fullWidth>
                                    <InputLabel>{t('packages.location_id')}</InputLabel>
                                    <Select
                                        value={searchField.location_id}
                                        label={t('packages.location_id')}
                                        name="location_id"
                                        onChange={({ target }) => setSearchField({ ...searchField, [target.name]: target.value })}
                                    >
                                        <MenuItem value={0}>{t('general.pleaseSelect') + ' ' + t('packages.location_id')}</MenuItem>
                                        {_.map(locationList, resultItem => {
                                            return <MenuItem key={resultItem.id} value={resultItem.id}>{resultItem.name}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Grid>
                        :
                        null
                }
                <Grid item xs={6} md={2} style={{ display: "flex", alignItems: "center" }}>
                    <Button variant="contained" fullWidth onClick={searchData}>{t('button.search')}</Button>
                </Grid>
            </Grid>
            {
                inputErrors && inputErrors.package_id
                    ?
                    <Typography variant="body2" style={{ color: theme.palette.indicator.red }}>{inputErrors.package_id}</Typography>
                    :
                    null
            }
            {/* PACKAGES */}
            {
                _.size(packages) > 0
                    ?
                    <Grid container spacing={3} style={{ padding: 20 }}>
                        {_.map(packages, (packageItem, key) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={key} style={{ display: 'flex', alignItems: "flex-end" }}>
                                <Card xs={12} className={classes.card} onClick={() => handlePackageSelect(packageItem)}>
                                    <CardMedia
                                        sx={{ flex: 1, transition: 'all .2s' }}
                                        image={(packageItem.images && packageItem.images[0]) ? packageItem.images[0].file_name : '/images/emptyStatus/no_ticket.png'}
                                        alt={'package\'s image'}
                                        className={classes.classMediaStyle}
                                    />
                                    <CardContent className={classes.cardContent}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" className={classes.packageNameStyle}>{packageItem.name}</Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="h6" style={{ lineHeight: "1.0" }}>
                                                    <b>{t('packages.from')}</b>
                                                </Typography>
                                                <Typography variant="h6" style={{ lineHeight: "1.0", paddingLeft: 10 }}>
                                                    <b>{" RM " + parseFloat(packageItem.rank_display_price.sell_price).toFixed(2) + t('packages.perPax')}</b>
                                                </Typography>
                                                {
                                                    currentCurrency && currentCurrency.code !== "MYR" &&
                                                    <Typography variant="h6" style={{ lineHeight: "1.0", paddingLeft: 10 }}>
                                                        <b>{"≈ " + currentCurrency.symbol + " " + parseFloat(packageItem.rank_display_price.sell_price * currentCurrency.rate).toFixed(2) + t('packages.perPax')}</b>
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                        <Grid item xs={12} style={{ textAlign: "center" }}>
                            <Box style={{ display: "flex", justifyContent: "center", alignSelf: "center" }}>
                                <Pagination count={total} page={page} onChange={handlePaginationChange} color="secondary" variant="text"
                                    renderItem={(item) => (
                                        <PaginationItem
                                            sx={{ color: theme.palette.secondary.main }}
                                            {...item}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                    :
                    <Box>
                        <Typography>No Data.</Typography>
                    </Box>
            }
        </Box>
    )
}

export default PlaceOrderStep1

const useStyles = makeStyles((theme) => ({
    classMediaStyle: {
        backgroundColor: theme.palette.primary.background,
        objectFit: "cover !important",
        height: "170px !important",
    },
    packageNameStyle: {
        lineHeight: "1.2 !important",
        fontSize: "1.1rem !important",
        paddingBottom: "15px",
    },
    cardContent: {
        padding: "16px !important",
        height: "170px !important",
    },
    card: {
        width: '100%',
        boxShadow: '0 0 6px 0 #0002',
        borderRadius: 0,
        backgroundColor: 'white',
        "&:hover": {
            cursor: 'pointer',
            boxShadow: '0 0 15px 0 #b3b3b3',
        },
    }
}))