import _ from 'lodash';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';

import { Box, Breadcrumbs, Button, CardMedia, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { FiUsers } from "react-icons/fi";
import { MdDownload } from "react-icons/md";

import { BANK_ACC_NAME, BANK_ACC_NO, BANK_NAME, LOCAL_URL } from '../../configs/Config';
import { getUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import BackToPrevious from '../Layout/BackToPrevious';
import VoucherPdf from './VoucherPdf';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const OrderDetail = () => {
    const theme = useTheme();
    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const componentRef = useRef(null);

    let { id } = useParams();
    const [order, setOrder] = useState({});
    const [subTotal, setSubTotal] = useState(0);
    const [agentDiscountAmount, setAgentDiscountAmount] = useState(0);
    const [codeDiscountAmount, setCodeDiscountAmount] = useState(0);
    const [voucherDialog, setVoucherDialog] = useState({
        open: false,
        voucher: []
    });
    const [customerDialog, setCustomerDialog] = useState({
        open: false,
    });
    const [qrcodeTabValue, setQrcodeTabValue] = useState(0);
    const [displayOrder, setDisplayOrder] = useState({});

    useEffect(() => {
        setLoading(true);
        getUrl(`/order/${id}`).then(response => {
            setLoading(false);
            if (response.status) {
                setOrder(response.data.order);
            }
        }).catch(err => {
            setLoading(false);
            addAlert('', JSON.stringify(err), 'error', '');
        })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        calculateTotal(order);
        rearrangeOrderDetail(order);
        // eslint-disable-next-line
    }, [order]);

    const rearrangeOrderDetail = (orders) => {
        let rearrangeOrderDetail = {};
        _.map(order.order_details, (orderDetail, dkey) => {
            if (orderDetail.status) {
                let main_package_id = orderDetail.package_id;
                let main_package = orderDetail.package;
                if (orderDetail.add_on_id) {
                    let main_detail = _.find(order.order_details, { id: parseInt(orderDetail.add_on_id) });
                    main_package_id = main_detail.package_id;
                    main_package = main_detail.package;
                }
                if (!(rearrangeOrderDetail[main_package_id])) {
                    rearrangeOrderDetail[main_package_id] = {};
                }
                if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date])) {
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date] = { items: {}, add_on: {}, package: main_package };
                }
                if (orderDetail.add_on_id) {
                    if (!(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id])) {
                        rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id] = { items: {}, package: orderDetail.package, display_name: orderDetail.package_name };
                    }
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['add_on'][orderDetail.package.id]['items'])] = orderDetail;
                } else {
                    rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'][_.size(rearrangeOrderDetail[main_package_id][orderDetail.booking_date]['items'])] = orderDetail;
                }
            }
        })
        setDisplayOrder(rearrangeOrderDetail);
    }

    const calculateTotal = (order) => {
        let _subTotal = 0;
        let _agent_discount = 0;
        let _code_discount = 0;
        _.map(order.order_details, order_detail => {
            if (order_detail.status) {
                _subTotal += parseFloat(order_detail.quantity * order_detail.price.sell);
                _code_discount += parseFloat(order_detail.quantity * (order_detail.price.sell - order_detail.price.adjust));
                _agent_discount += parseFloat(order_detail.quantity * (order_detail.price.adjust - order_detail.price.discount));
            }
        })
        setSubTotal(_subTotal);
        setAgentDiscountAmount(_agent_discount);
        setCodeDiscountAmount(_code_discount);
    }

    const orderItem = () => {
        return (
            <Box className={classes.cartContainer}>
                <Box className={classes.cartAgencyBox}>
                    <Typography className={classes.cartAgencyTitle}>{t('orders.orderItems')}</Typography>
                    <Button variant="outlined" size="small" style={{ margin: 10, color: "#fff", borderColor: "#fff" }} onClick={() => handleCustomerDialogOpen()}><FiUsers style={{ marginRight: 5 }} />{t('orders.customerList')}</Button>
                </Box>
                <Box>
                    {
                        _.map(displayOrder, (data, packageId) => (
                            _.map(data, (groupItem, booking_date) => {
                                return cartItemBox(groupItem, booking_date);
                            })
                        ))
                    }
                </Box>
                <Box style={{ margin: 20 }}>
                    <Typography>{t('placeOrder.subTotal') + ': MYR ' + parseFloat(subTotal).toFixed(2)}</Typography>
                    {
                        codeDiscountAmount !== 0 &&
                        <Typography>
                            {
                                t('placeOrder.discountCodeAmount') + ': ' +
                                (codeDiscountAmount < 0 ? '' : '-')
                                +
                                " MYR "
                                + (parseFloat(codeDiscountAmount > 0 ? codeDiscountAmount : -codeDiscountAmount)).toFixed(2)
                            }
                        </Typography>
                    }
                </Box>
            </Box>
        )
    }

    const cartItemBox = (groupItem, booking_date) => {
        return (
            <Grid container spacing={2} className={classes.packageBox}>
                <Grid item xs={12} md={2} className={`head ${classes.gridItemBoxCenter} ${classes.imgAlignTop}`} >
                    <CardMedia
                        component="img"
                        image={_.size(groupItem.package.images) > 0 ? groupItem.package.images[0]?.file_name : "../images/emptyStatus/no_image.png"}
                        sx={{ flex: 1, width: "100%", padding: 0 }}
                        alt={'package\'s image'}
                        className={classes.imgStyle}
                    />
                </Grid>
                <Grid item xs={12} md={10} className={classes.gridItemBoxLeft} style={{ paddingLeft: 10 }}>
                    <Link component={RouterLink} to={`/package/${groupItem.package.id}`} underline="none" className={classes.packageLink}>
                        <Typography variant="h6" style={{ fontWeight: "bold" }}>{groupItem.package.name}</Typography>
                    </Link>
                    {
                        booking_date
                            ?
                            <Typography className={classes.bookingDateStyle}>{booking_date}</Typography>
                            :
                            null
                    }
                    {
                        _.map(groupItem.items, mainOption => {
                            return (
                                <Box key={mainOption.id}>
                                    {optionBox(mainOption)}
                                </Box>
                            )
                        })
                    }
                    <Box>
                        {
                            (_.size(groupItem.add_on) > 0) &&
                            <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 20 }}>
                                {t('placeOrder.addOnPackage') + ": "}
                            </Typography>
                        }
                        {
                            _.map(groupItem.add_on, (add_on, key) => {
                                if (_.size(add_on.items) > 0) {
                                    return (
                                        <Box key={key}>
                                            <Typography variant="body1" style={{ fontWeight: "bold" }}>{add_on.display_name}</Typography>
                                            {_.map(add_on.items, addOnOption => {
                                                return (
                                                    <Box key={addOnOption.id}>
                                                        {optionBox(addOnOption)}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )
                                }
                            })
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    }

    const optionBox = (option) => {
        return (
            <Box>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={6}>
                        <Typography variant="body1">{option.package_option_name}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Box style={{ display: "flex" }}>
                            <Typography variant="body1" align="center" stle={{ marginRight: 5 }}>x{option.quantity}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4}>
                        <div style={{ margin: "auto !important", display: "flex", justifyContent: 'flex-end', alignItems: "center" }}>
                            {
                                option.price['retail'] > 0 && (option.price['retail'] - option.price['sell']) > 0
                                    ?
                                    <Typography variant="caption" style={{ textDecoration: "line-through" }}>
                                        {
                                            parseFloat((option.price['retail']) * option.quantity).toFixed(2)
                                        }
                                    </Typography>
                                    :
                                    null
                            }
                            <Typography variant="body1" style={{ marginLeft: 10 }}>
                                {
                                    parseFloat((option.price['sell']) * option.quantity).toFixed(2)
                                }
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <Box style={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body2" style={{ marginRight: 5 }}>{t('vouchers.code') + ": "}</Typography>
                    {_.map(option.vouchers, voucher => {
                        return (
                            <Link key={voucher.id} onClick={() => handleVoucherDialogOpen(voucher)} underline="none" className={classes.voucherLink}>
                                <Typography variant="body2">{voucher.code}</Typography>
                            </Link>
                        )
                    })}
                </Box>
            </Box>
        )
    }

    const handleVoucherDialogOpen = (voucher) => {
        setVoucherDialog({
            open: true,
            voucher: voucher
        })
    }
    const handleVoucherDialogClose = () => {
        setVoucherDialog({
            open: false,
            voucher: []
        })
    }
    const showVoucherDialog = () => {
        return (
            <Dialog open={voucherDialog.open} onClose={() => handleVoucherDialogClose()}>
                <DialogTitle>{t('vouchers.code') + ": " + voucherDialog.voucher.code}</DialogTitle>
                <DialogContent>
                    <Box>
                        <Typography variant="body1">{t('vouchers.price') + ": MYR " + parseFloat(voucherDialog.voucher.voucher_price).toFixed(2)}</Typography>
                        {
                            voucherDialog.voucher.voucher_type === 'tour'
                                ?
                                <>
                                    {/* BOOKING INFO */}
                                    <Typography variant="body1" style={{ marginTop: 10, fontWeight: "bold" }}>{t('vouchers.booking_date') + ": " + voucherDialog.voucher.start_date}</Typography>
                                    <Typography variant="body1">{t('user.name') + ": " + voucherDialog.voucher.user_name}</Typography>
                                    <Typography variant="body1">{t('user.ic') + ": " + voucherDialog.voucher.user_ic}</Typography>
                                    <Typography variant="body1">{t('user.dob') + ": " + (voucherDialog.voucher.user_dob ? voucherDialog.voucher.user_dob : '-')}</Typography>
                                    <Typography variant="body1">{t('user.email') + ": " + (voucherDialog.voucher.user_email ? voucherDialog.voucher.user_email : '-')}</Typography>
                                    <Typography variant="body1">{t('user.mobile') + ": " + (voucherDialog.voucher.user_mobile ? voucherDialog.voucher.user_mobile : '-')}</Typography>
                                    <Typography variant="body1">{t('user.gender') + ": " + (voucherDialog.voucher.user_gender ? t('user.' + voucherDialog.voucher.user_gender) : '-')}</Typography>
                                    <Typography variant="body1">{t('placeOrder.user_role') + ": " + (voucherDialog.voucher.user_role ? t('placeOrder.tour_' + voucherDialog.voucher.user_role) : '-')}</Typography>
                                </>
                                :
                                null
                        }
                    </Box>
                    {
                        voucherDialog.voucher.status === 20 || voucherDialog.voucher.status === 40
                            ?
                            <Box style={{ marginTop: 10 }}>
                                <Tabs
                                    value={qrcodeTabValue}
                                    onChange={(event, newValue) => setQrcodeTabValue(newValue)}
                                    centered
                                >
                                    <Tab style={{ backgroundColor: qrcodeTabValue == 0 ? theme.palette.primary.main : "#fff", color: qrcodeTabValue == 0 ? "#fff" : theme.palette.primary.main }} label={t('vouchers.code')} {...a11yProps(0)} />
                                    <Tab style={{ backgroundColor: qrcodeTabValue == 1 ? theme.palette.primary.main : "#fff", color: qrcodeTabValue == 1 ? "#fff" : theme.palette.primary.main }} label={t('orders.orderNo')} {...a11yProps(1)} />
                                </Tabs>
                                <Box style={{ textAlign: "center", marginTop: 10 }}>
                                    <TabPanel value={qrcodeTabValue} index={0}>
                                        <QRCodeSVG value={`${LOCAL_URL}/voucher-redeem?code=${voucherDialog.voucher.code}`} />
                                    </TabPanel>
                                    <TabPanel value={qrcodeTabValue} index={1}>
                                        <QRCodeSVG value={`${LOCAL_URL}/voucher-redeem?order_id=${voucherDialog.voucher.order_id}`} />
                                    </TabPanel>
                                    <Typography>{t('vouchers.redeemNote')}</Typography>
                                </Box>
                            </Box>
                            :
                            null
                    }
                </DialogContent>
                <DialogActions>
                    {
                        voucherDialog.voucher.status === 20 || voucherDialog.voucher.status === 40
                            ?
                            <VoucherPdf label={t('vouchers.downloadPdf')} voucher={voucherDialog.voucher} />
                            :
                            null
                    }
                    <Button variant="contained" onClick={() => handleVoucherDialogClose()}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: 'Order_',
    });

    const ComponentToPrint = ({ innerRef, items }) => {
        let count = 0;
        let totalValue = 0;
        console.log(items)
        return (
            <div style={{ height: window.innerHeight, padding: 60 }} ref={innerRef} className={classes.backgroundStyle} >
                <Grid container spacing={2} direction="row" justifyContent="space-around" alignItems="center" style={{ paddingBottom: 10 }}>
                    <Grid item xs={6}>
                        <img src={'/images/Mantanani_Colour.png'} width={"100%"} />
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: 'center', lineHeight: 0.2 }}>
                        <Typography variant='h4' style={{ color: '#227d5f', fontWeight: 'bold', fontFamily: 'PlayfairDisplayRegular' }}>Reluxe De Mantanani</Typography>
                        <Typography variant='caption' style={{ color: '#52ab98' }}>(202201015657 / 1461354-M)</Typography><br />
                        <Typography variant='caption'>
                            B-1-22, Block B, Riverson Walks,
                            Coastal Highway
                            <br />88100 Kota Kinabalu, Sabah
                        </Typography><br />
                        <Typography variant='caption' style={{ fontSize: 10 }}>Contact Person: 088-210069 (Office) / 019-808 6933(Hotline)</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                    <table style={{ textAlign: 'left' }}>
                        <tbody>
                            <tr>
                                <th width={100} >{t('document.invoiceTo')}</th>
                                <td width={400}>: </td>
                                <th width={100}>{t('document.date')}</th>
                                <td width={100}>: {Moment(items.created_at).format("DD/MM/YYYY")}</td>
                            </tr>
                            <tr>
                                <th>{t('document.attention')}</th>
                                <td>: {items?.user?.['name']}</td>
                                <th>{t('document.invoiceNo')}</th>
                                <td>: INV{((items.id).toString()).padStart(6, '0')}</td>
                            </tr>
                            <tr>
                                <th style={{ display: 'flex', alignItems: 'flex-start' }}>{t('document.address')}</th>
                                <td>: {_.size(items?.user?.['addresses']) > 0 ? items?.user?.['addresses']?.[0]?.['full_address'] : '-'}</td>
                            </tr>
                            <tr>
                                <th>{t('document.contact')}</th>
                                <td>: {items?.user?.['mobile']}</td>
                            </tr>
                            <tr>
                                <th>{t('document.email')}</th>
                                <td>: {items?.user?.['email']}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <Grid container style={{ paddingTop: 10 }}>
                    <table className={classes.tableDisplay}>
                        <tbody>
                            <tr>
                                <th className={classes.tableHeaderDisplay}>No.</th>
                                <th className={classes.tableHeaderDisplay}>{t('document.description')}</th>
                                <th className={classes.tableHeaderDisplay}>{t('document.quantity')}</th>
                                <th className={classes.tableHeaderDisplay}>{t('document.unitPrice')}</th>
                                <th className={classes.tableHeaderDisplay}>{t('document.total')}</th>
                            </tr>
                            {
                                _.map(items.order_details, (order, key) => {
                                    totalValue += parseFloat(order.sell_price * parseInt(order.quantity));
                                    count += 1;
                                    return (
                                        <tr>
                                            <td className={classes.tableContentDisplay} style={{ textAlign: 'center' }}>{count}</td>
                                            <td className={classes.tableContentDisplay}>{order.package_name} ({order.package_option_name})</td>
                                            <td className={classes.tableContentDisplay} style={{ textAlign: 'center' }} >{order.quantity}</td>
                                            <td className={classes.tableContentDisplay} style={{ textAlign: 'center' }} >{order.sell_price}</td>
                                            <td className={classes.tableContentDisplay} style={{ textAlign: 'right' }}>{parseFloat(order.sell_price * parseInt(order.quantity)).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                codeDiscountAmount > 0 &&
                                <tr>
                                    <td className={classes.tableContentDisplay} style={{ textAlign: 'center' }}>{count + 1}</td>
                                    <td className={classes.tableContentDisplay}>{t('document.discountValue')}</td>
                                    <td className={classes.tableContentDisplay} style={{ textAlign: 'center' }} >-</td>
                                    <td className={classes.tableContentDisplay} style={{ textAlign: 'center' }} >-</td>
                                    <td className={classes.tableContentDisplay} style={{ textAlign: 'right' }}>{(parseFloat(items.total_amount) - totalValue).toFixed(2)}</td>
                                </tr>
                            }
                            <tr style={{ fontWeight: 'bold' }}>
                                <td className={classes.tableContentDisplay} colSpan={2} style={{ fontFamily: 'PlayfairDisplayRegular' }}>{t('document.ringgitMalaysia')}</td>
                                <td className={classes.tableContentDisplay} colSpan={2} style={{ fontSize: 14 }}>{t('document.totalAmount')}</td>
                                <td className={classes.tableContentDisplay} style={{ textAlign: 'right' }}>{items.total_amount}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid>
                <Grid container style={{ paddingTop: 20 }}>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: 13 }}>{t('document.terms')}</Typography>
                        <Typography style={{ fontSize: 13 }}>{t('document.terms1')}</Typography>
                        <Typography style={{ fontSize: 13 }}>{t('document.terms2')}</Typography>
                        <Typography style={{ fontSize: 13 }}>{t('document.terms3')}</Typography>
                    </Grid>
                </Grid>
                <Grid container style={{ paddingTop: 20 }}>
                    <Grid item xs={12}>
                        <Typography style={{ fontSize: 13 }}>{t('document.ps1')}</Typography>
                        <Typography style={{ fontWeight: 'bold' }}>{t('document.bankDetail')}</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('document.bankNameTitle')}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{BANK_NAME}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('document.accountNameTitle')}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{BANK_ACC_NAME}</Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{t('document.accountNoTitle')}</Typography>
                            </Grid>
                            <Grid item xs={9}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 14 }}>{BANK_ACC_NO}</Typography>
                            </Grid>
                        </Grid>
                        <Typography style={{ paddingTop: 20, fontSize: 13 }}>{t('document.ps2')}</Typography>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const handleCustomerDialogOpen = () => {
        setCustomerDialog({
            open: true,
        })
    }
    const handleCustomerDialogClose = () => {
        setCustomerDialog({
            open: false,
        })
    }
    const customerList = () => {
        let excelHeaders = [
            { label: t(`vouchers.booking_date`), key: 'booking_date' },
            { label: t(`orders.packageName`), key: 'package_name' },
            { label: t(`payment.option`), key: 'package_option_name' },
            { label: t(`vouchers.code`), key: 'code' },
            { label: t(`user.name`), key: 'user_name' },
            { label: t(`user.ic`), key: 'user_ic' },
            { label: t(`user.dob`), key: 'user_dob' },
            { label: t(`user.email`), key: 'user_email' },
            { label: t(`user.mobile`), key: 'user_mobile' },
            { label: t(`user.gender`), key: 'user_gender' },
            { label: t(`placeOrder.user_role`), key: 'user_role' },
        ];
        let codeData = [];
        let csvTitle = '';
        _.map(order.order_details, (order_detail) => {
            csvTitle = order_detail.package_name;
            _.map(order_detail.vouchers, (voucher, key) => {
                codeData.push({
                    id: voucher.id,
                    booking_date: voucher.start_date,
                    package_name: order_detail.package_name,
                    package_option_name: order_detail.package_option_name,
                    code: voucher.code,
                    user_name: voucher.user_name,
                    user_ic: voucher.user_ic ? voucher.user_ic : '-',
                    user_dob: voucher.user_dob ? voucher.user_dob : '-',
                    user_email: voucher.user_email ? voucher.user_email : '-',
                    user_mobile: voucher.user_mobile ? voucher.user_mobile : '-',
                    user_gender: voucher.user_gender ? t('user.' + voucher.user_gender) : '-',
                    user_role: voucher.user_role ? t('placeOrder.tour_' + voucher.user_role) : '-',
                    fullData: voucher,
                });
            })
        })
        return (
            <Dialog open={customerDialog.open} onClose={() => handleCustomerDialogClose()} maxWidth="lg">
                <DialogTitle>
                    <Box style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography variant="h6">{t('orders.customerList') + ' (' + order.order_no + ')'}</Typography>
                        <CSVLink
                            data={codeData}
                            headers={excelHeaders}
                            filename={csvTitle + ` Customer List` + `.csv`}
                            style={{ textDecoration: "none" }}
                        >
                            <Button
                                startIcon={<MdDownload />}
                                variant="contained"
                            >
                                {t('button.exportData')}
                            </Button>
                        </CSVLink>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <TableContainer>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        _.map(excelHeaders, (header, key) => {
                                            return (
                                                <TableCell key={key}>{header.label}</TableCell>
                                            )
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    _.map(codeData, (voucher, key) => {
                                        return (
                                            <TableRow
                                                key={key}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell>{voucher.booking_date}</TableCell>
                                                <TableCell>{voucher.package_name}</TableCell>
                                                <TableCell>{voucher.package_option_name}</TableCell>
                                                <TableCell>
                                                    <Link key={voucher.id} onClick={() => handleVoucherDialogOpen(voucher.fullData)} underline="none" className={classes.voucherLink}>
                                                        {voucher.code}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>{voucher.user_name}</TableCell>
                                                <TableCell>{voucher.user_ic}</TableCell>
                                                <TableCell>{voucher.user_dob}</TableCell>
                                                <TableCell>{voucher.user_email}</TableCell>
                                                <TableCell>{voucher.user_mobile}</TableCell>
                                                <TableCell>{voucher.user_gender}</TableCell>
                                                <TableCell>{voucher.user_role}</TableCell>
                                            </TableRow>
                                        )
                                    })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={() => handleCustomerDialogClose()}>{t('general.ok')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Box className={classes.paper}>
            <BackToPrevious />
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Typography style={{ paddingBottom: 15, fontSize: 18, color: theme.palette.gray.main }}><b>{t(`title.agentOrderDetail`)}</b></Typography>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                            {t('title.dashboard')}
                        </Link>
                        <Link underline="hover" color="inherit" component={RouterLink} to={`/agent-orders`}>
                            {t(`title.agentOrder`)}
                        </Link>
                        <Typography color="text.primary">{t(`title.agentOrderDetail`)}</Typography>
                    </Breadcrumbs>
                </div>
            </Grid>
            {
                _.size(order) > 0
                    ?
                    <Box style={{ marginLeft: 0 }}>
                        <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between' }}>
                            <div>
                                <Typography variant="h5" className={classes.titleStyle}>{t('orders.orderNo') + ": " + order.order_no}</Typography>
                                <Typography>{order.status_display[i18n.language] ? order.status_display[i18n.language] : order.status_display.en}</Typography>
                                <Typography>{t('orders.createdAt') + ": " + order.created_at}</Typography>
                            </div>
                            <div>
                                {
                                    (order.settlement === 0 || order.settlement === 2) &&
                                    <>
                                        <Button variant="contained" color="primary" style={{ marginRight: 15 }} onClick={handlePrint}>{t('button.printInvoice')}</Button>
                                        <div style={{ display: "none" }}>
                                            <ComponentToPrint innerRef={componentRef} items={order} />
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        {
                            order.receipt_id
                                ?
                                <Typography>
                                    {t('orders.receipt') + ": "}
                                    <a href={order.receipt?.file_name} rel="noreferrer" target="_blank">{t('orders.viewReceipt')}</a>
                                </Typography>
                                :
                                null
                        }
                        {orderItem()}
                        <Box style={{ margin: 20, textAlign: "right" }}>
                            <Typography variant="h6">{t('orders.totalAmount') + ':  MYR ' + parseFloat(order.total_amount).toFixed(2)}</Typography>
                        </Box>
                        {showVoucherDialog()}
                        {customerList()}
                    </Box>
                    :
                    null
            }
        </Box>
    )
}

export default OrderDetail;

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: 20,
    },
    titleStyle: {
        fontWeight: "bold !important",
        color: theme.palette.primary.main,
        marginTop: "10px !important",
    },
    packageBox: {
        padding: "16px",
        borderBottom: "solid 1px " + theme.palette.primary.secondary,
    },
    imgStyle: {
        objectFit: "cover !important"
    },
    gridItemBoxCenter: {
        margin: "auto !important",
        textAlign: 'center',
    },
    gridItemBoxLeft: {
        margin: "auto !important",
        textAlign: 'left',
    },
    gridItemBoxRight: {
        margin: "auto !important",
        textAlign: 'right',
    },
    cartContainer: {
        border: 'none',
        borderRadius: 20,
        borderColor: theme.palette.gray.secondary,
        overflow: 'hidden',
        boxShadow: '0px 5px 6px 0 #0000001f',
        marginTop: 15,
        marginBottom: 15,
    },
    cartAgencyBox: {
        backgroundColor: theme.palette.secondary.main + ' !important',
        display: "flex",
        justifyContent: "space-between"
    },
    cartAgencyTitle: {
        color: '#fff !important',
        fontWeight: "bold !important",
        padding: "16px"
    },
    optionStyle: {
        backgroundColor: theme.palette.primary.secondary + ' !important',
        // color: theme.palette.primary.secondary + ' !important',
        borderRadius: 20,
        padding: "5px 10px",
        width: "fit-content",
        fontSize: "0.8rem !important",
    },
    voucherLink: {
        margin: "0px 10px !important",
        "&:hover": {
            color: theme.palette.primary.secondary,
            cursor: 'pointer',
        },
    },
    tableDisplay: {
        width: "100%",
        textAlign: 'left',
        border: '1px solid black',
        borderCollapse: 'collapse',
        padding: 10,
    },
    tableHeaderDisplay: {
        textAlign: 'center',
        border: '1px solid black',
        borderCollapse: 'collapse',
        padding: 10,
    },
    tableContentDisplay: {
        textAlign: 'left',
        border: '1px solid black',
        borderCollapse: 'collapse',
        padding: 10,
    }
}))