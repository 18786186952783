import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Breadcrumbs, Grid, Link, Typography, Button, Dialog, DialogActions, DialogTitle, DialogContent, InputLabel, FormHelperText, MenuItem, FormControl, Select, TextField } from '@mui/material';
import { useTheme } from '@mui/styles';

import { buildFormData } from '../../helpers/Tools';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import useNotificationLoading from '../../helpers/useNotificationLoading';
import PaginationMaterialReactTable from '../Layout/PaginationMaterialReactTable';
import StatusTab from '../Layout/StatusTab';

const MODULE = "order-claims";

const Listing = () => {
    const [data, setData] = useState([]);
    const [moduleStatusList, setModuleStatusList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [tableLoading, setTableLoading] = useState(false);

    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const { addAlert, setLoading } = useNotificationLoading();
    const { permissions, role } = useSelector(state => state.general);

    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 20,
    });
    const [columnFilters, setColumnFilters] = useState([]);
    const [sorting, setSorting] = useState([]);
    const [searchData, setSearchData] = useState(false);
    const [rowSelection, setRowSelection] = useState({});
    const [selectedValue, setSelectedValue] = useState({});
    const [tabValue, setTabValue] = useState('');
    
    const [state, setState] = useState({
        open: false,
        order_claim_ids: '',
        order_claim_no: '',
        claim_amount: 0,
        status: '',
        receipt: null,
        remark: '',
        agency_name: '',
        bank_info: '',
    });
    
    const [refreshData, setRefreshData] = useState(false);
    const [inputErrors, setInputErrors] = useState({});

    useEffect(() => {
        setTableLoading(true);
        let apiData = {
            page: pagination.pageIndex+1,
            per_page: pagination.pageSize,
            filters: columnFilters,
            sorting: sorting[0],
            status: tabValue,
        }
        getUrl(`/${MODULE}`, apiData).then(response => {
            setTableLoading(false);
            if (response.status) {
                setData(response.data.listing.data);
                setRowCount(response.data.listing.total);
                setModuleStatusList(response.data.status_list);
            } else {
                addAlert("", t('error.contactSupport'), 'error', '');
            }
        }).catch(error => {
            setTableLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
        // eslint-disable-next-line
    }, [pagination.pageIndex, pagination.pageSize, sorting, searchData, refreshData, tabValue]);

    useEffect(() => {
        let newStatusList = [];
        _.map(moduleStatusList, status => {
            let textDisplay = status[i18n.language] ? status[i18n.language] : status.en;
            newStatusList.push({ text: textDisplay, value: status.code });
        })
        setStatusList(newStatusList);
        // eslint-disable-next-line
    }, [moduleStatusList, i18n.language]);

    const columns = useMemo(() => ([
        {
            accessorKey: 'order_no',
            header: `${t(`${MODULE}.orderNo`)}`,
            id: 'order_no',
            Cell: ({ cell }) => (
                _.includes(permissions, `order-edit`) ?
                    <Link underline='none' to={`/orders/${cell.row.original.id}`} component={RouterLink}>{cell.row.original.order.order_no}</Link>
                    :
                    <>{cell.row.original.order.order_no}</>
            )
        },
        {
            accessorKey: 'order_detail_id',
            header: `${t(`${MODULE}.description`)}`,
            id: 'order_detail',
            Cell: ({ cell }) => (
                <>
                    <Typography><b>{t(`${MODULE}.name`)}</b>{cell.row.original.order_detail.package_name}</Typography>
                    <Typography><b>{t(`${MODULE}.option`)}</b>{cell.row.original.order_detail.package_option_name}</Typography>
                </>
            ),
            enableSorting: false,
        },
        {
            accessorFn: (row) => (row.order.agency ? row.order.agency.name : '-'),
            accessorKey: 'agency_id',
            id: 'merchant',
            header: `${t(`${MODULE}.merchant`)}`,
            Cell: ({ cell }) => (
                _.includes(permissions, `agency-edit`) ?
                    <Link underline='none' to={`/agencies/${cell.row.original.order.agency_id}`} component={RouterLink}>{cell.row.original.order.agency ? cell.row.original.order.agency.name : '-'}</Link>
                    :
                    <>{cell.row.original.order.agency.name}</>
            ),
            enableSorting: false,
        },
        {
            accessorKey: 'sell_price',
            header: `${t(`${MODULE}.sell_price`)}`,
        },
        {
            accessorKey: 'profit_rate',
            header: `${t(`${MODULE}.profit_sharing`)}`,
        },
        {
            accessorKey: 'profit_amount',
            header: `${t(`${MODULE}.amount`)}`,
        },
        {
            accessorKey: 'claim_amount',
            header: `${t(`${MODULE}.claimAmount`)}`,
        },
        {
            accessorFn: (row) => (row.status_display ? row.status_display[i18n.language] : row.status_display.en),
            id: 'status_display.' + i18n.language,
            header: `${t(`${MODULE}.status`)}`,
            filterFn: 'equals',
            filterSelectOptions: statusList,
            filterVariant: 'select',
            enableSorting: false,
            Cell: ({ cell }) => (
                <>
                    <Typography><b>{cell.row.original.status_display[i18n.language]}</b></Typography>
                    {
                        cell.row.original.status_display.code === 30 ?
                        <Typography>{t(`${MODULE}.remarkReason`)}{cell.row.original.remark}</Typography>
                        : cell.row.original.status_display.code === 20 ?
                            _.size(cell.row.original.receipt) > 0 ?
                            <Link href={cell.row.original.receipt.file_name} rel="noreferrer" target="_blank" underline="none">
                                <Button variant="outlined" size="small">
                                    {t(`${MODULE}.view_receipt`)}
                                </Button>
                            </Link>
                            : '-'
                        : null
                    }
                    
                </>
            )
        },
        {
            accessorKey: 'created_at',
            header: `${t('general.createdAt')}`,
        },
        // eslint-disable-next-line
    ]), [i18n.language, permissions, t, statusList]);

    const submitData = () => {
        setLoading(true);
        setInputErrors({});
        let postData = {
            _method: "put",
            status: state.status,
            remark: state.status === 30 ? (state.remark ? state.remark : null) : null,
            receipt: state.status === 20 ? state.receipt : null,
            order_claim_ids: state.order_claim_ids
        };
        const formData = new FormData();
        buildFormData(formData, postData);
        postUrl(`/${MODULE}`, formData).then(response => {
            setLoading(false);
            if (response.status) {
                addAlert("", response.message, 'success', '');
                handleClose();
                setRefreshData(!refreshData);
                setRowSelection({});
            } else {
                setInputErrors(response.errors);
                addAlert("", response.message, 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleClickOpen = () => {
        let status = '';
        let ordersNo = '';
        let totalAmount = 0;
        let temp_data_id = [];
        let remarkValue = '';
        let merchantId = 0;
        let bank_info = [];
        let agency_name = '';
        let error = false;
        _.map(data, (order, key) => {
            if (_.includes(_.keys(rowSelection), (order.id).toString())) {
                status = order.status;
                temp_data_id.push(order.id);
                ordersNo = ordersNo ? ordersNo + ', ' + order.order.order_no : order.order.order_no;
                totalAmount += parseFloat(order.claim_amount);
                remarkValue = order.remark ? order.remark : "";
                if(!merchantId){
                    merchantId = order.order.agency.id;
                    bank_info = order.order.agency.bank_info;
                    agency_name = order.order.agency.name;
                }else if(parseInt(merchantId) !== parseInt(order.order.agency.id)){
                    error = true;
                }
            }
        })
        if(error){
            addAlert('', t('order-claims.differentAgency'), 'error', '');
        }else{
            setState({
                open: true,
                order_claim_ids: temp_data_id,
                order_claim_no: ordersNo,
                claim_amount: totalAmount,
                status: status,
                remark: remarkValue,
                bank_info: {
                    bank_name: bank_info&&bank_info.bank_name?bank_info.bank_name:'-',
                    account_name: bank_info&&bank_info.account_name?bank_info.account_name:'-',
                    account_number: bank_info&&bank_info.account_number?bank_info.account_number:'-',
                    bank_swift_code: bank_info&&bank_info.bank_swift_code?bank_info.bank_swift_code:'-',
                },
                agency_name: agency_name,
            });
        }
    };
    
    const handleClose = () => {
        setInputErrors({});
        setState({
            open: false,
            order_claim_ids: '',
            order_claim_no: '',
            claim_amount: 0,
            status: '',
            receipt: null,
            remark: '',
            agency_name: '',
            bank_info: '',
        });
    };

    const editDialog = () => {
        return (
            <Dialog 
                open={state.open} 
                onClose={() => handleClose()} 
                fullWidth 
                maxWidth="sm"
            >
                <DialogTitle id="alert-dialog-title"><b>{t(`${MODULE}.update_status`)}</b></DialogTitle>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography variant='body1'>{t(`${MODULE}.selectedOrderNo`)}</Typography>
                            <Typography variant='body1'>{state.order_claim_no}</Typography>
                            <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.order_claim_ids ? inputErrors.order_claim_ids : ''}</FormHelperText>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 10 }}>
                            <Typography variant='body1'>{t(`${MODULE}.totalAmount`)+': '}</Typography>
                            <Typography variant='h6' style={{fontWeight: "bold"}}>{ "RM " + parseFloat(state.claim_amount).toFixed(2) }</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 10 }}>
                            <Typography variant='body1'>{t('agencies.name') + ': ' + state.agency_name}</Typography>
                            <Typography variant='body1'>{t('agencies.bank_name') + ': ' + (state.bank_info.bank_name)}</Typography>
                            <Typography variant='body1'>{t('agencies.account_name') + ': ' + (state.bank_info.account_name)}</Typography>
                            <Typography variant='body1'>{t('agencies.account_number') + ': ' + (state.bank_info.account_number)}</Typography>
                            <Typography variant='body1'>{t('agencies.bank_swift_code') + ': ' + (state.bank_info.bank_swift_code)}</Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingTop: 10 }}>
                            <FormControl fullWidth>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <InputLabel id="demo-simple-select-label">{t(`${MODULE}.status`)}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={state.status}
                                        label={t(`${MODULE}.status`)}
                                        onChange={({ target }) => setState({ ...state, status: target.value })}
                                    >
                                        {
                                            _.map(statusList, (status_value, key) => {
                                                if (status_value.value !== '0') {
                                                    return (
                                                        <MenuItem value={status_value.value} key={key}>{status_value.text}</MenuItem>
                                                    )
                                                }
                                            })
                                        }
                                    </Select>
                                </Box>
                            </FormControl>
                        </Grid>
                        {
                            state.status === 20 &&
                            <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <Typography variant='h6'>{t(`${MODULE}.upload_receipt`)}</Typography>
                                    <TextField
                                        id="outlined-basic" 
                                        fullWidth
                                        variant="outlined" 
                                        type="file"
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.receipt ? inputErrors.receipt : ''}
                                        error={inputErrors && inputErrors.receipt ? true : false}
                                        onChange={({ target }) => setState({ ...state, receipt: target.files[0] })}
                                    />
                                </Box>
                            </Grid>
                        }
                        {
                            state.status === 30 &&
                            <Grid item xs={12} style={{ paddingTop: 10 }}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        id="outlined-basic" 
                                        fullWidth
                                        variant="outlined"
                                        value={state.remark ? state.remark : ""}
                                        label={t(`${MODULE}.remark`)}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.remark ? inputErrors.remark : ''}
                                        error={inputErrors && inputErrors.remark ? true : false}
                                        onChange={({ target }) => setState({ ...state, remark: target.value })}
                                    />
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button variant='outlined' color='primary' onClick={handleClose}>{t('button.cancel')}</Button>
                    <Button variant='contained' color='primary' onClick={submitData} autoFocus>{t('button.save')}</Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Grid container direction="row" justifyContent="space-between" alignItems="center" style={{ paddingBottom: 5 }}>
                <Box display="flex" alignItems="center" paddingBottom={'15px'}>
                    <Typography style={{ fontSize: 21, marginRight: 15, color: theme.palette.gray.main }}><b>{t(`title.${MODULE}`)}</b></Typography>
                    {/* {_.includes(permissions, `${MODULE_SINGLE}-create`) ?
                        <Link underline='none' to={`/${MODULE}-add`} component={RouterLink}>
                            <IconButton style={{ color: theme.palette.button.main }}>
                                <AddBoxOutlined />
                            </IconButton>
                        </Link>
                        : null} */}
                </Box>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" component={RouterLink} to="/dashboard">
                        {t('title.dashboard')}
                    </Link>
                    <Typography color="text.primary">{t(`title.${MODULE}`)}</Typography>
                </Breadcrumbs>
            </Grid>
            <StatusTab tabValue={tabValue} setTabValue={setTabValue} moduleStatusList={moduleStatusList} />
            <PaginationMaterialReactTable
                columns={columns}
                data={data}
                isLoading={tableLoading}
                exportFile={true}
                exportFileName={'OrderClaimsList'}
                initialColumnVisibility={{ 'agency.name': _.size(_.intersection(role, ["dev", "super-admin", "admin"])) > 0 ? true : false }}
                rowCount={rowCount}
                pagination={pagination}
                setPagination={setPagination}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                sorting={sorting}
                setSorting={setSorting}
                searchData={searchData}
                setSearchData={setSearchData}
                enableRowSelection={(row) => ((_.size(_.intersection(role, ["dev", "super-admin", "admin"])) > 0) && row.original.status == 10)}
                onRowSelectionChange={setRowSelection}
                rowSelection={rowSelection}
                getRowId={(row) => row.id}
                topActions={
                    _.size(_.intersection(role, ["dev", "super-admin", "admin"])) > 0 &&
                    <Button variant="contained" onClick={() => handleClickOpen()} disabled={_.size(rowSelection) > 0 ? false : true}>
                        {t('order-claims.update_status')}
                    </Button>
                }
                enableSelectAll={true}
            />
            {editDialog()}
        </>
    )
}

export default Listing