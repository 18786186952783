import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Grid, TextField, Typography, Switch, FormControl, FormControlLabel, FormLabel, FormGroup, FormHelperText, RadioGroup, Radio, CircularProgress } from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import { isEmpty } from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { getUrl, postUrl } from '../../helpers/ApiAction';
import { buildFormData } from '../../helpers/Tools';
import useNotificationLoading from '../../helpers/useNotificationLoading';

import { HiArrowNarrowRight } from "react-icons/hi";
import { IoAdd } from "react-icons/io5";

const MODULE = "commercials";
const MODULE_SINGLE = "commercial";

const HomeDetail = (props) => {
    const { type, action, id, refreshData, handleClose, setRefreshData, recommendWidth, recommendHeight } = props;
    
    const theme = useTheme();
    const classes = useStyles();
    const isMountedRef = useRef(null);

    const [inputErrors, setInputErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        title: 'Your Title Goes Here...',
        subtitle: 'Your Subtitle Goes Here...',
        button_text: 'Your Button Text Goes Here...',
        link_preference: 0,
        text_color: '#ffffff',
        image: null,
        existing_image: ''
    });

    const { t } = useTranslation();
    const { addAlert } = useNotificationLoading();

    const onDrop = useCallback(acceptedFiles => {
        setState({ ...state, image: acceptedFiles.map(file => Object.assign(file, { preview: URL.createObjectURL(file) })) });
    }, [state]);
    const { getRootProps, getInputProps } = useDropzone({ onDrop, noClick: true, noKeyboard: true, maxFiles: 1, multiple: false });

    useEffect(() => {
        isMountedRef.current = true;
        if (action === 'edit' && id) {
            getUrl(`/${MODULE}/${id}`).then(response => {
                if (isMountedRef.current) {
                    if (response.status) {
                        setState({
                            ...state,
                            title: response.data.title,
                            subtitle: response.data.subtitle,
                            link_preference: response.data.button_text !== null ? 0 : 1,
                            link: response.data.link,
                            button_text: response.data.button_text,
                            text_color: response.data.text_color,
                            image: null,
                            existing_image: response.data.image,
                            status:response.data.status === 1? true:false ,
                        });
                    } else {
                        addAlert("", t('error.contactSupport'), 'error', '');
                    }
                }
            }).catch(error => {
                addAlert('', error.message || t('error.contactSupport'), 'error', '');
            });
        }
        return () => { isMountedRef.current = false; };
        // eslint-disable-next-line
    }, [addAlert, id])

    const submitData = () => {
        let apiData = {
            title: state.title,
            subtitle: state.subtitle,
            button_text: state.link_preference === 0 ? state.button_text : null,
            text_color: state.text_color,
            link: state.link,
            status: state.status ? 1:0, 
            _method: action === "edit" ? "put" : "post",
        };

        if (state.image) {
            apiData={
                ...apiData,
                image: state.image[0],
            }
        }

        if (state.remove_image === true) {
            apiData={
                ...apiData,
                remove_image: state.remove_image,
            }
        }

        setLoading(true);
        setInputErrors();

        const formData = new FormData();
        buildFormData(formData, apiData);

        postUrl(`/${MODULE}${action === "edit" ? `/${id}` : ""}`, formData).then(response => {
            setLoading(false);
            const { status, data, errors } = response;
            if (status) {
                addAlert('', action === "edit" ? t('success.editSuccess') : t('success.createSuccess'), 'success', '');
                handleClose();
                setRefreshData(!refreshData);
            } else {
                setInputErrors(errors);
                addAlert('', action === "edit" ? t('error.editError') : t('error.createError'), 'error', '');
            }
        }).catch(error => {
            setLoading(false);
            addAlert('', error.message || t('error.contactSupport'), 'error', '');
        });
    }

    const handleChange = ({ target }) => {
        const { name, value } = target;

        if (name === 'status') {
            setState({ ...state, status: target.checked });
        } else {
            setState({ ...state, [name]: value });
        }
    }

    const removeImage = () => {
        setState({ ...state, image: null, existing_image: null, remove_image: true });
    }

    const handleChoiceChange = ({ target }) => {
        const { value } = target;
        setState({ ...state, link_preference: parseInt(value) });
    }

    return (
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item xs={12}>
                <div>
                    <Box style={{ backgroundImage: state?.image?.[0]?.preview ? `url(${state?.image?.[0]?.preview})` : (state?.existing_image?.file_name ? `url(${state?.existing_image?.file_name})` : ''), backgroundSize: 'cover', height: 150, backgroundColor: (state?.image === null ? "#ad9e80" : 'transparent') }}>
                        <Container maxWidth="xl" style={{ padding: 0, display: "flex" }}>
                            <Box className={classes.rsvpTextBox}>
                                <Typography variant="h6" className={classes.rsvpText} style={{ color: `${state?.text_color}`, fontFamily: 'PlayfairDisplayRegular' }}>{state?.title}</Typography>
                                <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "90%" }}>
                                    <Typography className={classes.rsvpText} style={{ color: `${state?.text_color}` }}>{state?.subtitle}</Typography>
                                    {
                                        state?.link_preference === 0 &&
                                        <Box className={classes.rsvpLink}>
                                            <Typography className={classes.rsvpText} style={{ display: "flex", alignItems: "center", margin: "3px 10px", color: `${state?.text_color}` }} >
                                                {state?.button_text} <HiArrowNarrowRight style={{ marginLeft: 5 }} />
                                            </Typography>
                                        </Box>
                                    }
                                </Box>
                            </Box>
                        </Container>
                    </Box>
                    <Box>
                        <Button onClick={removeImage}>{t('button.removeImage')}</Button>
                    </Box>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={t(`${MODULE}.title`)}
                                variant="outlined"
                                value={state.title || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.title ? inputErrors.title : ''}
                                error={inputErrors && inputErrors.title ? true : false}
                                name="title"
                                onChange={handleChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={t(`${MODULE}.subtitle`)}
                                variant="outlined"
                                value={state.subtitle || ''}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.subtitle ? inputErrors.subtitle : ''}
                                error={inputErrors && inputErrors.subtitle ? true : false}
                                name="subtitle"
                                onChange={handleChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormControl>
                                <FormLabel>{t(`${MODULE}.linkDisplay`)}</FormLabel>
                                <RadioGroup row value={state.link_preference || 0} onChange={handleChoiceChange}>
                                    <FormControlLabel value={0} control={<Radio />} label={t(`${MODULE}.buttonLink`)} />
                                    <FormControlLabel value={1} control={<Radio />} label={t(`${MODULE}.imageLink`)} />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Grid>
                    {
                        state.link_preference === 0 ?
                        <>
                            <Grid item xs={6}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={t(`${MODULE}.link`)}
                                        variant="outlined"
                                        value={state.link || ''}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.link ? inputErrors.link : ''}
                                        error={inputErrors && inputErrors.link ? true : false}
                                        name="link"
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                    <Typography style={{ fontSize: 14 }}>{t('general.example', { example: "https://www.google.com" })}</Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} style={{ alignSelf: "baseline" }}>
                                <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                    <TextField
                                        label={t(`${MODULE}.buttonTitle`)}
                                        variant="outlined"
                                        value={state.button_text || ''}
                                        InputLabelProps={{ shrink: true }}
                                        helperText={inputErrors && inputErrors.button_text ? inputErrors.button_text : ''}
                                        error={inputErrors && inputErrors.button_text ? true : false}
                                        name="button_text"
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Box>
                            </Grid>
                        </>
                        :
                        <Grid item xs={12}>
                            <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                                <TextField
                                    label={t(`${MODULE}.link`)}
                                    variant="outlined"
                                    value={state.link || ''}
                                    InputLabelProps={{ shrink: true }}
                                    helperText={inputErrors && inputErrors.link ? inputErrors.link : ''}
                                    error={inputErrors && inputErrors.link ? true : false}
                                    name="link"
                                    onChange={handleChange}
                                    fullWidth
                                />
                                <Typography style={{ fontSize: 14 }}>{t('general.example', { example: "https://www.google.com" })}</Typography>
                            </Box>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <TextField
                                label={t(`${MODULE}.textColor`)}
                                variant="outlined"
                                value={state.text_color || '#ffffff'}
                                InputLabelProps={{ shrink: true }}
                                helperText={inputErrors && inputErrors.text_color ? inputErrors.text_color : ''}
                                error={inputErrors && inputErrors.text_color ? true : false}
                                name="text_color"
                                type='color'
                                onChange={handleChange}
                                fullWidth
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={6} style={{ paddingTop:0}}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <FormGroup row>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={state.status || false } onChange={handleChange} name="status" />}
                                    label={t(`${MODULE}.status`)}
                                    labelPlacement="start"
                                />
                            </FormGroup>
                            <FormHelperText style={{ color: 'red' }}>{inputErrors && inputErrors.status ? inputErrors.status : ''}</FormHelperText>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box component="form" noValidate sx={{ '& > :not(style)': { m: 1, width: '100%' }, }} >
                            <label htmlFor={type} className={classes.uploadMedia} style={{ color: theme.palette.gray.main, cursor: 'pointer' }} {...getRootProps()} component="div">
                                <input accept="image/*" id={type} type="file" style={{ display: "none" }} onChange={({ target }) => setState({ ...state, image: target.files[0] })} {...getInputProps()} />
                                <IoAdd style={{ fontSize: "3rem" }} />
                                <Typography style={{ fontSize: 14 }}>{t("general.uploadImgText")}</Typography>
                            </label>
                            {
                                inputErrors && inputErrors?.image && <Typography variant="caption" style={{ color: 'red' }}>{inputErrors.image}</Typography>
                            }
                            <Typography style={{ fontSize: 14 }}>{t('general.recommendedSize', { width: recommendWidth, height: recommendHeight })}</Typography>
                        </Box>
                    </Grid>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        {
                            loading ?
                            <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} disabled={true}><CircularProgress size={30} /></Button>
                            :
                            <Button size="large" variant="contained" style={{ backgroundColor: theme.palette.button.main }} className={classes.buttonStyle} onClick={() => submitData()}>{t('button.save')}</Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

const useStyles = makeStyles(theme => ({
    uploadMedia: {
       display: "flex",
       justifyContent: "center",
       alignItems: "center",
       flexDirection: "column",
       backgroundColor: "#fff",
       boxShadow: "3px 3px 20px 0 #d8d8d8",
       borderRadius: 15,
       border: "2px dashed #aeaeae",
       width: "100%",
       height: 100,
       marginTop: 15,
       padding: 20,
    },
    documentTitleStyle: {
       cursor: 'pointer',
       "&:hover": {
           color: theme.palette.secondary.main
       },
    },
    // rsvp
    rsvpTextBox: {
       padding: "3% 0% 3% 10% !important",
       [theme.breakpoints.up('xs')]: {
           width: "100% !important",
           padding: "3%",
       },
       [theme.breakpoints.up('sm')]: {
           width: "55% !important",
       },
       [theme.breakpoints.up('md')]: {
           width: "60% !important",
       },
    },
    rsvpImageBox: {
       [theme.breakpoints.up('xs')]: {
           width: "0% !important",
       },
       [theme.breakpoints.up('sm')]: {
           width: "45% !important",
       },
       [theme.breakpoints.up('md')]: {
           width: "40% !important",
       },
       boxShadow: "14px 0 20px 0 " + theme.palette.primary.main + " inset",
       backgroundSize: "cover",
       backgroundRepeat: "no-repeat",
    },
    rsvpText: {
       // do nothing
    },
    rsvpLink: {
       "&:hover": {
           borderColor: "#fff !important"
       }
    },
    //ads
    ads: {
       width: '100%',
       height: '100%',
       objectFit: 'fill',
    },
}))
    
export default HomeDetail;